<template>
  <div>
    <EmptyMessage
      v-if="!instance || !Object.keys(instance).length || !ancestors.length || !features.length"
      >Not Ready
    </EmptyMessage>

    <template v-else>
      <!-- Breadcrumb -->
      <div>
        <a href="#" @click="popTo(0)">{{ rootFeature?.label || tailUri(rootFeature.uri) }}</a>
        <template v-for="(key, index) in ancestors.slice(0, -1)" :key="key">
          »
          <a href="#" @click="popTo(index + 1)">{{ tailUri(key) }}</a>
        </template>
        »
        <span class="breadcrumb-final">
          {{ tailUri(ancestors.slice(-1)[0]) }}
        </span>
        <button @click="deleteCurrentInstance" title="Delete Instance">
          <span>&#128465;</span>
        </button>
      </div>

      <!-- Instance Info -->
      <div>
        <table class="instance-table">
          <tr>
            <td>URI:</td>
            <td>
              <span :title="instance?.['uri']">
                {{ shortenUri(instance?.['uri']) || 'none found' }}
              </span>
            </td>
          </tr>
          <tr v-if="instance?.['label']">
            <td>Label:</td>
            <td>
              {{ instance?.['label'] }}
            </td>
          </tr>
          <tr>
            <td>Feature:</td>
            <td>{{ instance?.['feature']?.label || 'no label found' }}</td>
          </tr>
          <tr v-if="instance?.linkedTo?.uri.trim().length > 0">
            <td>Linked To:</td>
            <td>{{ formatLink(instance.linkedTo) }}</td>
          </tr>
          <tr>
            <td>Linked By:</td>
            <td>
              [<span v-for="(link, index) in instance?.['linkedBy']" :key="link">
                {{ index > 0 ? ', ' : '' }}
                {{ formatLink(link) }} </span
              >]
            </td>
          </tr>
          <!-- Children -->
          <tr>
            <td>Children:</td>
            <td>
              [
              <template v-for="child in instance?.['children'] || []" :key="child">
                <a href="#" @click="push(child?.uri)">
                  {{
                    child?.feature?.label || (child?.uri || 'no uri!').split(':').slice(-1)[0]
                  }} </a
                >,
              </template>
              <button @click="addMode = true">Add</button>
              ]
            </td>
          </tr>
          <tr v-if="addMode">
            <td colspan="2">
              <div class="add-child-container">
                <select v-model="childToAdd">
                  <option v-for="f in features" :key="f.uri" :value="f.uri">
                    {{ f?.label || shortenUri(f.uri) }}
                  </option>
                </select>
                <button @click="addChild">Add</button>
                <button @click="addMode = false">Cancel</button>
              </div>
            </td>
          </tr>
          <!-- Tokens -->
          <tr>
            <td>Tokens:</td>
            <td>
              [
              <template v-if="instance?.['tokens']?.length === 0">
                <span class="none-style">None</span>
              </template>
              <template v-else>
                <button
                  v-for="token in instance?.['tokens']"
                  class="token-button"
                  :key="token"
                  :title="token.ref"
                  @click="handleTokenClick(token)"
                >
                  {{ token?.wordValue || token.ref }}
                </button>
              </template>
              ]
              <button
                @click="removeTokens"
                v-if="instance?.['tokens']?.length > 0"
                title="Remove tokens"
              >
                <span>&#128465;</span>
              </button>
            </td>
          </tr>
          <tr v-if="isNewProposal">
            <td colspan="2">
              <div class="token-proposal-container">
                <template v-if="applyTokenMode === false">
                  You have selected other tokens
                  <button @click="applyTokenMode = true">View Proposal</button>
                </template>
                <template v-else>
                  <div class="container-open">
                    <table>
                      <tr v-if="proposedTokenMap.newTokens.length > 0">
                        <td>New Tokens:</td>
                        <td>
                          <button
                            v-for="token in proposedTokenMap.newTokens"
                            class="token-button"
                            :class="token.proposal"
                            :key="token"
                            :title="token.ref"
                            @click="handleTokenClick(token)"
                          >
                            {{ token?.wordValue || token.ref }}
                          </button>
                        </td>
                      </tr>
                      <tr v-if="proposedTokenMap.removeTokens.length > 0">
                        <td>Tokens to be removed:</td>
                        <td>
                          <button
                            v-for="token in proposedTokenMap.removeTokens"
                            class="token-button"
                            :class="token.proposal"
                            :key="token"
                            :title="token.ref"
                            @click="handleTokenClick(token)"
                          >
                            {{ token?.wordValue || token.ref }}
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <button @click="applyProposedTokens">Apply</button>
                  <button @click="applyTokenMode = false">Cancel</button>
                </template>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
  import { sortOsisIds } from '@/common/refUtils';
  import EmptyMessage from '../components/EmptyMessage.vue';

  export default {
    components: {
      EmptyMessage,
    },
    props: {
      ancestors: {
        type: Array,
        default: () => [],
      },
      rootFeature: {
        type: Object,
        default: () => ({}),
      },
      instance: {
        type: Object,
        default: () => ({}),
      },
      features: {
        type: Array,
        default: () => [],
        required: true,
      },
      proposedTokens: {
        type: Array,
        default: () => [],
      },
    },
    emits: [
      'push',
      'pop',
      'deleteCurrentInstance',
      'addChild',
      'onTokenClick',
      'applyProposedTokens',
      'removeTokens',
    ],
    data() {
      return {
        addMode: false,
        childToAdd: null,
        applyTokenMode: false,
      };
    },
    watch: {
      features: {
        handler() {
          if (this.features.length === 0) return;
          this.childToAdd = this.features[0]?.uri;
        },
        immediate: true,
      },
      instance: {
        handler() {
          this.addMode = false;
          this.applyTokenMode = false;
        },
        immediate: true,
      },
    },
    computed: {
      isNewProposal() {
        if (!this.proposedTokens || this.proposedTokens.length === 0) return false;
        if (this.instance?.tokens?.length !== this.proposedTokens.length) return true;

        // if there is a difference between the tokens in the instance and the proposed tokens, return true
        const proposedTokenSet = new Set(this.proposedTokens.map(t => t.ref));
        const setsAreDifferent = this.instance.tokens.some(t => !proposedTokenSet.has(t.ref));
        return setsAreDifferent;
      },
      proposedTokenMap() {
        const instanceTokensSet = new Set(this.instance.tokens.map(t => t.ref));
        const proposedTokensSet = new Set(this.proposedTokens.map(t => t.ref));
        const tokensToAdd = this.proposedTokens.filter(t => !instanceTokensSet.has(t.ref));
        const tokensToRemove = this.instance.tokens.filter(t => !proposedTokensSet.has(t.ref));

        const getProposal = tokenId => {
          if (tokensToAdd.some(t => t.ref === tokenId)) return 'propose-add';
          if (tokensToRemove.some(t => t.ref === tokenId)) return 'propose-remove';
          return 'propose-unchanged';
        };

        const sortTokensByOsisIds = (a, b) => sortOsisIds(a.ref, b.ref);

        const allTokens = this.instance.tokens
          .concat(...tokensToAdd)
          .sort(sortTokensByOsisIds)
          .map(t => ({
            ...t,
            proposal: getProposal(t.ref),
          }));

        return {
          newTokens: allTokens.filter(t => t.proposal !== 'propose-remove'),
          removeTokens: allTokens.filter(t => t.proposal === 'propose-remove'),
        };
      },
    },
    methods: {
      shortenUri(uri) {
        if (!uri) return '';
        const parts = uri.split('/').filter(p => p);
        const lastPart = parts[parts.length - 1];
        const lastPartShort =
          lastPart.length > 35 ? `${lastPart.slice(0, 15)}...${lastPart.slice(-20)}` : lastPart;
        return `${uri.slice(0, 10)}.../${lastPartShort}`;
      },
      tailUri(uri) {
        if (!uri) return '';
        const parts = uri.split(/[/:]/).filter(p => p);
        return parts[parts.length - 1];
      },
      formatLink(linkedInstance) {
        if (!linkedInstance) return 'none';

        const labelForLinkedInstance =
          linkedInstance.label?.trim().length > 0
            ? linkedInstance.label
            : this.tailUri(linkedInstance.uri);
        return linkedInstance.feature?.label
          ? `${linkedInstance.feature.label} (${labelForLinkedInstance})`
          : labelForLinkedInstance;
      },
      popTo(index) {
        const numberOfInstancesToPop = this.ancestors.length - index;
        this.$emit('pop', numberOfInstancesToPop);
      },
      push(uri) {
        this.$emit('push', uri);
      },
      deleteCurrentInstance() {
        this.$emit('deleteCurrentInstance');
      },
      addChild() {
        if (!this.childToAdd) return;
        this.$emit('addChild', this.childToAdd);
      },
      handleTokenClick(token) {
        this.$emit('onTokenClick', token);
      },
      applyProposedTokens() {
        this.$emit('applyProposedTokens');
      },
      removeTokens() {
        this.$emit('removeTokens', this.instance.tokens);
      },
    },
  };
</script>

<style scoped lang="scss">
  .breadcrumb-final {
    font-weight: bold;
    margin-right: 0.3rem;
  }
  .instance-table {
    width: 100%;
  }
  .add-child-container {
    padding: 1rem;
    background-color: #eee;
  }
  .none-style {
    color: #aaa;
    font-style: italic;
  }

  .token-button {
    border: 1px solid #ddd;
    background-color: transparent;
    border-radius: 0.3rem;
    padding: 0.2rem 0.4rem;
    margin-right: 0.1rem;
    cursor: pointer;
  }
  .token-button:hover {
    background-color: #ccc;
    border-color: #555;
  }
  .token-button:active {
    background-color: #aaa;
    border-color: #333;
  }
  .propose-add {
    color: blue;
  }
  .propose-remove {
    color: rgb(128, 128, 128);
  }
  .propose-unchanged {
    color: black;
  }

  .token-proposal-container {
    padding: 1rem;
    background-color: #eee;
  }
  .container-open {
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }
</style>
