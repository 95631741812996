<template>
  <div class="treedown-display">
    <div>
      <span
        :class="{ active: outputs.treedownDisplay.value === 1 }"
        @click.prevent="setTreedownDisplay(1)"
        >Basic</span
      >
    </div>
    <div>
      <span
        :class="{ active: outputs.treedownDisplay.value === 2 }"
        @click.prevent="setTreedownDisplay(2)"
        >Advanced</span
      >
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';

  export default {
    name: 'TreedownDisplayToggleWidget',
    mixins: [mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          label: 'Treedown Display',
        },
        outputs: {
          treedownDisplay: 1,
        },
      },
    },
    methods: {
      setTreedownDisplay(v) {
        this.outputs.treedownDisplay.value = v;
        this.submit();
      },
    },
    mounted() {
      this.setTreedownDisplay(1);
    },
  };
</script>

<style scoped lang="scss">
  span {
    cursor: pointer;
    &.active {
      font-weight: bold;
    }
  }
</style>
