<template>
  <button
    :disabled="!previousSibling"
    @click="$emit('pinAsRoot', previousSibling.uri)"
    :title="`Previous Sibling ${getLabel(previousSibling)}`"
  >
    Previous Sibling
  </button>
  <button
    :disabled="!nextSibling"
    @click="$emit('pinAsRoot', nextSibling.uri)"
    :title="`Next Sibling ${getLabel(nextSibling)}`"
  >
    Next Sibling
  </button>
</template>

<script setup>
  import { defineProps } from 'vue';

  defineProps({
    previousSibling: {
      type: Object,
    },
    nextSibling: {
      type: Object,
    },
  });

  defineEmits(['pinAsRoot']);

  const getLabel = item => {
    if (!item) {
      return '';
    }
    return item.label ? `(${item.label})` : `(${item.feature.label})`;
  };
</script>
