<template>
  <slot />
</template>

<script setup>
  import { ref, provide, computed, watch, defineComponent } from 'vue';

  const tokenIds = ref([]);
  const unwrappedTokenProxy = {};
  const tokens = new Proxy(unwrappedTokenProxy, {
    get(target, tokenId) {
      if (!(tokenId in unwrappedTokenProxy)) {
        unwrappedTokenProxy[tokenId] = ref({});
      }
      return unwrappedTokenProxy[tokenId];
    },
    set: (target, tokenId, newValue) => {
      if (!(tokenId in unwrappedTokenProxy)) {
        unwrappedTokenProxy[tokenId] = ref(newValue);
      } else {
        unwrappedTokenProxy[tokenId].value = newValue;
      }
    },
  });

  // token shape:
  //  - data
  //  - featureUnderlines
  //  - altTexts
  //  - color
  //  - majorUnderline
  //  - backgroundColor

  const props = defineProps({
    tokens: {
      type: Array,
      default: () => [],
    },
  });

  watch(
    () => props.tokens,
    newTokens => {
      if (!Array.isArray(newTokens)) {
        tokenIds.value = [];
        return;
      }
      const tokensForRemoval = new Set(Object.keys(tokens));

      newTokens.forEach(token => {
        if (tokensForRemoval.has(token.tokenId)) {
          tokensForRemoval.delete(token.tokenId);
        }

        // Crudely check for changes—major performance improvement
        const oldToken = JSON.stringify(tokens[token.tokenId]?.value);
        const newToken = JSON.stringify(token);
        if (oldToken !== newToken) {
          tokens[token.tokenId].value = token;
        }
      });
      tokensForRemoval.forEach(tokenId => {
        delete tokens[tokenId];
      });
      tokenIds.value = newTokens.map(token => token.tokenId);
    },
    { immediate: true },
  );

  const getTokenIds = computed(() => tokenIds.value);
  provide('getTokenIds', getTokenIds);
  const getToken = tokenId => computed(() => tokens[tokenId].value);
  provide('getToken', getToken);

  defineComponent({
    name: 'TokenProvider',
    inheritAttrs: false,
  });
</script>
