<script setup>
  /* eslint-disable max-len */
  /* eslint-disable no-unused-vars */
  import { computed, defineProps, ref, defineEmits } from 'vue';
  import mixins from '@/mixins';
  import { useStore } from 'vuex';

  const store = useStore();

  const ENABLE_HOVER_FUNCTIONALITY = false;

  const emit = defineEmits({ 'select-node': Object });

  const props = defineProps({
    node: Object,
    showAnalysis: Boolean,
    showWordGroups: Boolean,
    showClauses: Boolean,
    showChildren: Boolean,
    showMilestones: Boolean,
    showRules: Boolean,
    selectedNodeIndex: Number,
    parentAttributes: Object,
    grandParentAttributes: Object,
    languageDirectionIsRTL: Boolean,
    sentenceIsFocused: Boolean,
    zoomToWord: Boolean,
    currentlySelectedCharIndex: Number,
    shouldBreakToNewline: Boolean,
    indexInParent: Number,
    matchingElementIds: Array,
    showEnglish: Boolean,
    showTransliteration: Boolean,
  });

  const emitSelectNode = value => {
    emit('select-node', value);
  };

  function getIsPElement() {
    // UNUSED ??
    return props?.node?.name === 'p';
  }
  function getIsSentence() {
    // UNUSED ??
    return props?.node?.name === 'sentence';
  }
  const isWord = ref(props?.node?.name === 'w');
  function getIsMilestone() {
    return props?.node?.name === 'milestone';
  }
  function getIsPunctuation() {
    return props?.node?.name === 'pc';
  }
  function getNodeClass() {
    return props?.node?.attributes?.class;
  }
  function getNodeOriginalPosition() {
    return props?.node.originalPosition;
  }
  function getWordCharacterArray() {
    const wordCharacterArray = [];
    if (isWord.value) {
      const textContent = (props?.node?.children && props?.node?.children[0]?.value) || '';
      textContent.split('').forEach((char, index) => wordCharacterArray.push({ char, index }));
    }
    return wordCharacterArray;
  }
  function getNodeShouldBeRow() {
    // FIXME: This function should not be necessary once we fix all CSS styling.
    // return (
    //   props?.node?.children &&
    //   props?.node.children.some(child => child?.name === 'w') &&
    //   !props?.node.children.some(child => child?.class === 'cl') &&
    //   props?.node?.attributes?.class !== 'cl' &&
    //   !props?.node?.attributes?.rule?.startsWith('Conj') &&
    //   props?.node?.attributes?.rule !== 'ppandpp'
    // );
    return false;
  }
  function getChildShouldBreakToNewline(child) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { children } = props?.node;
    const currentChildIndex = children.indexOf(child);
    const previousChild = children[currentChildIndex - 1];
    if (previousChild) {
      // return previousChild.name === 'wg'
      return previousChild.attributes?.class === 'cl';
    }
    return false;
  }
  function getNodeIncludesApposition() {
    return props?.indexInParent > 0 && props?.parentAttributes.rule === ('Np-Appos' || 'np-appos');
  }
  function getNodeShouldBreakToNewLine() {
    const nodeIsWordGroup = props?.node?.name === 'wg';

    const nChildrenToForceLineBreak = 3;
    const nodeHasMoreThanNChildren = props?.node?.children?.length > nChildrenToForceLineBreak;
    const { shouldBreakToNewline } = props;
    const nodeIsConjunction =
      props?.node?.attributes?.class === 'cjp' ||
      props?.node?.attributes?.class === 'cj' ||
      props?.node?.attributes?.rule?.startsWith('Conj');
    const parentIsClause = props?.parentAttributes?.class === 'cl'; // NOTE: Comment for conjunction line break with wgs
    const nodeIsClause = props?.node?.attributes?.class === 'cl';
    const nodeIsSentence = props?.node?.name === 'sentence';
    const nodeIsInApposition = getNodeIncludesApposition();
    return (
      (nodeIsConjunction && parentIsClause) ||
      nodeIsInApposition ||
      shouldBreakToNewline ||
      nodeIsClause ||
      nodeIsSentence ||
      (nodeIsWordGroup && nodeHasMoreThanNChildren)
    );
  }

  const showFloatingInfoBar = ref(false);
  function getIsWord() {
    return props?.node?.name === 'w';
  }

  function handleFocusNode() {
    emitSelectNode(props?.node);
  }

  function handleBlurNode() {
    emitSelectNode(null);
  }

  function handleHoverNode(event) {
    if (ENABLE_HOVER_FUNCTIONALITY) {
      // TODO: specify conditions for highlighting nodes (certain widgets open?)
      // remove highlight class from all other nodes
      const nodes = document.getElementsByClassName('highlight');
      for (let i = 0; i < nodes.length; i += 1) {
        nodes[i].classList.remove('highlight');
      }
      // add highlight class to node
      const { classList } = event.target;
      if (classList.contains('highlight')) {
        classList.remove('highlight');
      } else {
        classList.add('highlight');
      }
    }
  }
  function handleMouseExitNode() {
    if (ENABLE_HOVER_FUNCTIONALITY) {
      // TODO: specify conditions for highlighting nodes (certain widgets open?)
      const nodes = document.getElementsByClassName('highlight');
      for (let i = 0; i < nodes.length; i += 1) {
        nodes[i].classList.remove('highlight');
      }
    }
  }

  // TODO: allow clicking node to mutate local and global state (e.g. change selection cursor from deeply nested node)
  // TODO: Handle punctuation https://github.com/Clear-Bible/symphony-tree-editor/issues/9
  // FIXME: I need to clean up the number of spans below. Classes should be more directly tied to the node name (e.g. 'w' for word, 'pc' for punctuation, etc.)
</script>

<template>
  <span class="tree-node-container">
    <hr v-if="getNodeShouldBreakToNewLine()" class="forced-line-break" />
    <!-- NOTE: this forced line break works in conjunction with shouldBreakToNewline -->
    <!-- <span
    debug="span1"
    :class="{
      additionalSpacing:
        node?.attributes?.role && parentAttributes.attributes?.class === 'cl' && showAnalysis,
    }"
  /> -->
    <span debug="span6" v-if="showMilestones && getIsMilestone(node)" class="milestone">
      {{ node?.children[0]?.value }}
    </span>
    <span
      debug="span2"
      v-if="node?.children?.length > 0"
      class="role-and-unit-container"
      :class="[
        // { constituent: !node?.attributes?.head },
        { rtl: languageDirectionIsRTL },
      ]"
    >
      <sub
        >c{{ node?.currentPosition }},o{{ node?.originalPosition }},cw{{
          node?.currentWordPosition
        }},id:{{ node?.attributes?.id }}</sub
      >
      <span
        debug="span3"
        class="role"
        v-if="
          showAnalysis && parentAttributes?.class === 'cl'
          // !props?.node?.name === 'pc' &&
          // (node?.attributes?.role === 'v' || !node?.attributes?.head)
        "
        :class="[{ rtl: languageDirectionIsRTL }]"
      >
        {{
          node?.attributes?.class === 'conj'
            ? '+'
            : node?.attributes?.role === 'adv'
            ? '+'
            : node?.attributes?.role === 'aux'
            ? '~'
            : node?.attributes?.role || '☐'
        }}
      </span>
      <span
        debug="span4"
        v-if="!getIsMilestone(node)"
        :rule="node?.attributes?.rule"
        :class="[
          'unit',
          { unitRule: showRules && node?.attributes?.rule },
          getNodeClass() === 'cl' ? getNodeClass() : node?.name === 'wg' ? 'wg' : undefined,
          showAnalysis && parentAttributes?.class === 'cl' ? node?.attributes?.role : '',
          {
            rowWg: getNodeShouldBeRow(),
          },
          { apposition: getNodeIncludesApposition() },
          { forceWordToNewLine: parentAttributes?.nodeShouldBeRow && shouldBreakToNewline },
          // { additionalSpacing: parentAttributes?.class !== 'cl' && node?.name === 'wg' },
          { head: showRules && node?.attributes?.head },
          {
            borderedClause: getNodeClass() === 'cl' && /* !node?.attributes?.head && */ showClauses,
          },
          {
            borderedWordGroup: node?.name === 'wg' && getNodeClass() !== 'cl' && showWordGroups,
          },
          { rtl: languageDirectionIsRTL },
          {
            selectedGroup:
              sentenceIsFocused &&
              node?.name !== 'w' &&
              node?.currentPosition === selectedNodeIndex,
          },
          { rootWg: parentAttributes.class === 'root' },
          { matchedUnit: matchingElementIds?.includes(node?.attributes?.id) },
          node?.attributes?.rule,
          {
            'cl-layout':
              node?.attributes?.rule?.includes('Cl') ||
              node?.attributes?.rule?.includes('CL') ||
              node?.attributes?.rule?.includes('cl') ||
              node?.attributes?.role === 'cl' || // NOTE: Jonathan has been using @role='cl' for display purposes, but he has also removed this from some iterations of the lowfat data.
              node?.attributes?.rule?.includes('that-'),
          },
        ]"
        @mouseenter.stop="handleHoverNode"
        @mouseleave="handleMouseExitNode"
        @click.stop="handleFocusNode"
        @focus="handleFocusNode"
        @blur="handleBlurWord"
      >
        <span
          debug="span5-word-element-when-navigating-tree"
          v-if="
            isWord || (node?.children.some(node => node.type === 'text') && !getIsPunctuation())
          "
          class="word"
          :class="[
            { selected: sentenceIsFocused && node?.currentPosition === selectedNodeIndex },
            {
              construct: getWordCharacterArray()[getWordCharacterArray().length - 1]?.char === '־',
            },
            {
              zoomedWord: zoomToWord,
            },
            { rtl: languageDirectionIsRTL },
            { gloss: showEnglish },
            { transliteration: showTransliteration },
          ]"
          :gloss="node?.attributes?.gloss || node?.attributes?.english"
          :transliteration="node?.attributes?.transliteration"
        >
          <span
            v-for="wordCharacter in getWordCharacterArray()"
            :key="
              wordCharacter + wordCharacter.index // TODO: are these keys causing issues with marking original positions?
            "
            class="word-character"
            :class="[
              {
                selectedCharacter: currentlySelectedCharIndex === wordCharacter.index, // TODO: two identical characters may be selected using this approach. Refactor.
              },
              { selected: sentenceIsFocused && node?.currentPosition === selectedNodeIndex },
              { rtl: languageDirectionIsRTL },
              { zoomedChar: zoomToWord },
            ]"
          >
            {{ wordCharacter.char }}
          </span>
          <!-- <WordMenu
          v-if="isWord && wordIsClicked"
          :attributes="node?.attributes"
          :textContent="getTextContent()"
        /> -->
        </span>
        <span v-if="props?.node?.attributes?.after" class="punctuation">{{
          props?.node?.attributes?.after
        }}</span>
        <sup
          v-if="
            node?.currentWordPosition && getNodeOriginalPosition() !== node?.currentWordPosition
          "
          :class="{ rtl: languageDirectionIsRTL }"
          >{{ getNodeOriginalPosition() }}</sup
        >
        <treeNode
          v-for="(child, index) in node?.children.filter(child => child.type !== 'text')"
          :key="child.attributes?.id || child.value"
          :node="child"
          :showAnalysis="showAnalysis"
          :showWordGroups="showWordGroups"
          :showClauses="showClauses"
          :showMilestones="showMilestones"
          :showRules="showRules"
          :selectedNodeIndex="selectedNodeIndex"
          :parentAttributes="{
            ...node?.attributes,
            nodeShouldBeRow: getNodeShouldBeRow(),
            hasChildren: node?.children?.length > 0,
          }"
          :grandParentAttributes="parentAttributes"
          :languageDirectionIsRTL="languageDirectionIsRTL"
          :sentenceIsFocused="sentenceIsFocused"
          :zoomToWord="zoomToWord"
          :currentlySelectedCharIndex="currentlySelectedCharIndex"
          :shouldBreakToNewline="getChildShouldBreakToNewline(child)"
          :indexInParent="index"
          :matchingElementIds="matchingElementIds"
          :showEnglish="showEnglish"
          :showTransliteration="showTransliteration"
          @select-node="emitSelectNode"
        ></treeNode>
      </span>
    </span>
  </span>
</template>

<style lang="scss" scoped>
  .rtl {
    direction: rtl;
  }
  sub {
    font-size: 0.4em;
    display: none; /** NOTE: comment this attribute in order to see id and position info for each unit */
    color: #05afec;
  }
  .milestone {
    display: flex;
    position: relative;
    align-items: center;
    align-self: flex-start;
    height: 0.8em;
    color: #fefefe;
    font-size: 0.5em;
    font-weight: bold;
    padding: 0.5em;
    border-radius: 1em;
    background-color: #828282;
    margin-right: 0.2em;
    margin-bottom: 0.2em;
    margin: 0 0.2em 0.2em;
  }
  .unit {
    display: flex;
    flex-wrap: wrap;
    border-radius: 0.2em;
    border: 2px solid transparent;
  }
  .highlight {
    background-color: #05aeec3e;
  }
  .sentence-root {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }
  .role-and-unit-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1em;
    align-self: baseline;
  }
  .additionalSpacing {
    margin-left: 1em;
    display: flex;
    flex-direction: row;
    &.rtl {
      /* margin-right: 1em; */
      margin-left: 0;
    }
  }
  sup {
    font-size: 0.5em;
    color: #999;
    &.rtl {
      margin-left: 0.5em;
      margin-right: -0.2em;
    }
  }
  .cl {
    display: flex;
    flex-direction: column;
    margin-left: 0.5em;
    transition: all 0.2s ease-in-out;
  }
  .borderedClause {
    border-radius: 0.2em;
    border: 2px solid #aaa;
    transition: all 0.2s ease-in-out;
    padding: 0.1em 0.2em;
    margin: 0.1em 0.2em;
  }
  .wg {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    &.rtl {
      display: flex;
      align-items: flex-start;
    }
  }
  .forced-line-break {
    flex-basis: 100%;
    height: 0;
    margin: 0;
    border: 0;
  }
  .forceWordToNewLine {
    flex-basis: 100%;
  }
  .rowWg {
    flex-direction: row;
    /* flex-wrap: nowrap; */
    // overflow-x: scroll;
    align-self: baseline;
  }
  .borderedWordGroup {
    /* flex-wrap: wrap; */
    // overflow-x: scroll;
    align-items: baseline;
    border-radius: 0.2em;
    border: 2px solid #ccc;
    transition: all 0.5s ease-in-out;
    padding: 0.1em 0.2em;
    margin: 0.1em 0.2em;
    /* margin-left: 0.5em; */
  }
  .word {
    padding: 0.1em;
    border: none;
    border-left: 3px solid #ffffff00;
    transition: all 0.2s ease-in-out;
    align-self: baseline;
    &.rtl {
      border: none;
      border-bottom: 3px solid transparent;
      /* margin-left: 0.2em; */
      &.construct {
        /** Words in construct */
        margin-left: -0.2em;
      }
    }
    &.selected {
      border-left: 3px solid #05afec;
      animation: blink 0.5s step-end infinite alternate;
      transition: none;
    }
  }
  .punctuation {
    display: none;
    margin-left: -0.25em;
    margin-right: 0.25em;
    margin-bottom: -0.1em;
    margin-top: 0.1em;
    border: none;
    border-left: 3px solid #ffffff00;
  }
  .rtl > .punctuation {
    margin-left: 0.25em;
    margin-right: -0.1em;
  }
  @keyframes blink {
    50% {
      border-color: #05aeec46;
    }
  }
  .selected.rtl {
    border-left: none;
    border-bottom: 3px solid #05afec;
  }
  .selected.zoomedWord {
    transition: transform 0.2s, border-left 0s, border-bottom 0s, background-color 0.2s;
    transform: scale(1.5);
    margin: 0em 1em;
    animation: none;
    background-color: white;
    border-left: transparent;
    border-bottom: transparent;
    padding: 0.1em;
    border-radius: 0.2em;
  }
  .word-character.selected {
    border-bottom: 3px solid transparent;
  }
  .word-character.selected.selectedCharacter.zoomedChar {
    background-color: #71ca36ae;
    animation: none;
  }
  .selectedGroup {
    border: 2px solid #05afec;
    transition: all 0.2s ease-in-out;
    animation: blink 0.5s step-end infinite alternate;
  }
  .rootWg {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .matchedUnit .word {
    /* font-weight: bold; */
    background-color: lightgoldenrodyellow;
  }
  .role {
    font-size: 0.7em;
    background-color: #ccc;
    border-radius: 0.25em;
    font-family: monospace;
    align-self: flex-start;
    padding: 0.125em;
    /* margin-left: -1.2em; */
    margin-top: 0.25em;
    color: black;
    text-align: center;
    max-width: max-content;
  }
  .role.rtl {
    margin-left: inherit;
  }
  .role.topLevelConjunction {
    display: none;
  }
  .apposition:not(:first-child)::before {
    content: ':';
    margin-left: 1em;
  }
  .apposition.rtl:not(:first-child)::before {
    content: ':';
    margin-right: 1em;
  }
  /* .adv {
    color: blue;
  }
  .v {
    color: red;
  }
  .s {
    color: green;
  }
  .o {
    color: orange;
  }
  .p {
    color: purple;
  }
  .pp {
    color: purple;
  } */
  .ppandpp {
    flex-direction: column;
  }
  /* .io {
    color: lightseagreen;
  }
  .vc {
    color: cornflowerblue;
  }
  .o2 {
    color: olivedrab;
  } */
  .unitRule {
    border: 1px solid seagreen;
    padding: 0.1em;
    margin: 0.1em;
  }
  .unitRule:before {
    content: attr(rule);
    font-size: 0.5em;
    color: seagreen;
  }
  .unit.head:hover {
    border: 2px solid seagreen;
  }
  .CLaCL {
    display: flex;
    flex-direction: column;
  }
  .All-NP,
  .DetCL {
    display: flex;
    flex-direction: column;
  }
  .cl-layout {
    display: flex;
    flex-direction: column;
  }
  .word.gloss::after {
    display: flex;
    flex-direction: column;
    content: attr(gloss);
    font-size: 0.5em;
    font-style: italic;
    color: blue;
    margin-left: 0.5em;
  }
  .word.transliteration::after {
    display: flex;
    flex-direction: column;
    content: attr(transliteration);
    font-size: 0.5em;
    font-style: italic;
    color: blue;
    margin-left: 0.5em;
  }
</style>
