<template>
  <div class="inline-version-picker">
    <label for="version-select">Version: </label>
    <select
      id="version-select"
      :value="selectedTextualEdition"
      @change="$emit('set-selected-textual-edition', $event.target.value)"
    >
      <option v-for="textualEdition in textualEditions" :key="textualEdition.usfmRef">
        {{ textualEdition.usfmRef }}
      </option>
    </select>
  </div>
</template>

<script>
  export default {
    name: 'InlineVersionPicker',
    props: {
      selectedTextualEdition: String,
      textualEditions: Array,
    },
    emits: ['set-selected-textual-edition'],
  };
</script>
<style scoped lang="scss">
  .inline-version-picker {
    text-align: center;
    // TODO: Revisit "deep"" .tei-content style
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    //   'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: initial;
    word-spacing: initial;
  }
  #version-select {
    margin-inline-start: 1em;
  }
</style>
