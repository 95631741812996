// Maps a list of descendants onto a descendantsTree object array
// eslint-disable-next-line import/prefer-default-export
export function mapDescendantsToTree(descendants, descendantsTree) {
  if (!Array.isArray(descendantsTree) || !Array.isArray(descendants)) {
    return [];
  }

  const descendantsIndex = Object.fromEntries(descendants.map(d => [String(d.id), d]));

  const traverse = node => {
    const nodeContent = descendantsIndex[String(node.id)];
    return {
      ...nodeContent,
      children: Array.isArray(node?.children) ? node.children.map(traverse) : [],
    };
  };
  return descendantsTree.map(traverse);
}
