import { WORD_TOKEN_ID_FIELD } from '@/store/constants';

export const features = [
  { key: 'focus', displayName: 'focus', apiName: 'Focus+', color: '5ff53c' },
  { key: 'speech', displayName: 'speech', apiName: 'Reported Speech', color: 'a49352' },
  {
    key: 'situational',
    displayName: 'situationalPoD',
    apiName: 'Situational PoD',
    color: '9ba499',
  },
  {
    key: 'constituentNegation',
    displayName: 'constituentNegation',
    apiName: 'Constituent Negation',
    color: '3ee0e6',
  },
  {
    key: 'tailHeadLinkage',
    displayName: 'tailHeadLinkage',
    apiName: 'Tail-Head linkage',
    color: '501c93',
  },
  { key: 'dfe', displayName: 'dfe', apiName: 'DFE', color: 'e1ef6d' },
  {
    key: 'topicalGenitive',
    displayName: 'topicalGenitive',
    apiName: 'Topical Genitive',
    color: 'ee12e1',
  },
  {
    key: 'historicalPresent',
    displayName: 'historicalPresent',
    apiName: 'Historical Present',
    color: '3c9832',
  },
  {
    key: 'thematicProminence',
    displayName: 'thematicProminence',
    apiName: 'Thematic Prominence',
    color: 'ddce37',
  },
  { key: 'appostitive', displayName: 'appositive', apiName: 'Appositive', color: '17774b' },
  {
    key: 'embeddedFocus',
    displayName: 'embeddedFocus',
    apiName: 'Embedded Focus+',
    color: 'efa1a1',
  },
  { key: 'highlighter', displayName: 'highlighter', apiName: 'Highlighter', color: 'db58cf' },
  {
    key: 'cataphoricReferent',
    displayName: 'cataphoricReferent',
    apiName: 'Cataphoric referent',
    color: '136035',
  },
  {
    key: 'articularPronoun',
    displayName: 'articularPronoun',
    apiName: 'Articular Pronoun',
    color: '9e037f',
  },
  { key: 'ambiguous', displayName: 'ambiguous', apiName: 'Ambiguous', color: '333bf7' },
  { key: 'overEncoding', displayName: 'overEncoding', apiName: 'Over-encoding', color: 'b97789' },
  { key: 'embeddedDfe', displayName: 'embeddedDfe', apiName: 'Embedded DFE', color: '930782' },
  {
    key: 'specificCircumstance',
    displayName: 'specificCircumstance',
    apiName: 'Specific Circumstance',
    color: '107d1a',
  },
  {
    key: 'referentialPod',
    displayName: 'referentialPoD',
    apiName: 'Referential PoD',
    color: '889583',
  },
  { key: 'otQuotes', displayName: 'otQuotes', apiName: 'OT quotes', color: '18427e' },
  { key: 'verbFocus', displayName: 'verbFocus', apiName: 'Verb Focus+', color: '53d853' },
  {
    key: 'embeddedRepSpeech',
    displayName: 'embeddedRepSpeech',
    apiName: 'EmbeddedRepSpeech',
    color: 'edb2d8',
  },
  {
    key: 'postposedThemSubject',
    displayName: 'postposedThemSubject',
    apiName: 'Postposed them subject',
    color: '36570e',
  },
  {
    key: 'rightDislocated',
    displayName: 'rightDislocated',
    apiName: 'Right-Dislocated',
    color: '60d92c',
  },
  {
    key: 'historicalPerfect',
    displayName: 'historicalPerfect',
    apiName: 'Historical Perfect',
    color: 'fbdd34',
  },
  {
    key: 'cataphoricFocus',
    displayName: 'CataphoricFocus',
    apiName: 'Cataphoric Focus',
    color: 'dd3307',
  },
  {
    key: 'futuristicPresent',
    displayName: 'futuristicPresent',
    apiName: 'Futuristic Present',
    color: '7c8505',
  },
  {
    key: 'nounIncorporation',
    displayName: 'nounIncorporation',
    apiName: 'Noun Incorporation',
    color: 'a34acd',
  },
  {
    key: 'leftDislocation',
    displayName: 'leftDislocation',
    apiName: 'Left-Dislocation',
    color: '56a15c',
  },
  { key: 'mainClauses', displayName: 'mainClauses', apiName: 'Main clauses', color: 'b403c9' },
  {
    key: 'referentialPod',
    displayName: 'Referential PoD',
    apiName: 'Referential PoD+',
    color: '628a7c',
  },
  { key: 'splitFocal', displayName: 'Split Focal', apiName: 'Split Focal', color: 'd21b5b' },
  // { key: '', displayName: '', apiName: '' },
  //
];
export const featuresByApiName = Object.fromEntries(features.map(item => [item.apiName, item]));

export function updateOrCreateToken(tokens, token) {
  let newTokens = tokens.concat([]);

  const existingIndex = tokens.findIndex(
    existingToken => existingToken[WORD_TOKEN_ID_FIELD] === token[WORD_TOKEN_ID_FIELD],
  );
  if (existingIndex > -1) {
    newTokens[existingIndex] = { ...newTokens[existingIndex], ...token };
  } else {
    newTokens = newTokens.concat(token);
  }
  return newTokens;
}
