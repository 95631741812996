// eslint-disable-next-line import/prefer-default-export
export const flattenTree = tree => {
  if (!tree) {
    return [];
  }
  const traverse = node => {
    if ('children' in node && node.children.length > 0) {
      return [node, ...node.children.map(child => traverse(child)).flat()];
    }
    return [node];
  };
  return tree.children.map(traverse).flat(Infinity);
};
