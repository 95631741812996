<template>
  <div class="range-slider">
    {{ outputs.number.value }}
    <input type="range" min="1" max="20" v-model="outputs.number.value" @change="submit()" />
  </div>
</template>

<script>
  import mixins from '@/mixins';

  export default {
    name: 'RangeSliderWidget',
    mixins: [mixins.OutputsMixin],
    props: {
      doric: {
        outputs: {
          number: 12,
        },
      },
    },
  };
</script>

<style scoped lang="scss"></style>
