import {
  DEFAULT_GROUP_KEY,
  LOCAL_DATA_STATE,
  DEFAULT_OSIS_REF,
  TEXT_STRUCTURE_KEY_FOR_VERSES,
  ER_PLUS_PLUS_WORKSPACE_ID,
  TEXT_STRUCTURE_KEY_FOR_DISCOURSE_ANNOTATIONS,
  NESTLE_1904,
} from '@/store/constants';
import { populateWorkspacesWithDefaults } from '@/common/widgetUtils';
import hydratedWidgets from '@/config/widgets';
// eslint-disable-next-line import/prefer-default-export

const DISABLE_ANNOTATION_WORKSPACE = process.env.VUE_APP_DISABLE_ANNOTATION_WORKSPACE === '1';
const ONLY_ANNOTATION_WORKSPACE = process.env.VUE_APP_ONLY_ANNOTATION_WORKSPACE === '1';

const annotationsWorkspace = {
  workspaceId: 'annotation',
  workspaceName: 'Annotation Workspace',
  columns: [
    ['passage-range-widget', 'text-annotator-widget'],
    ['rapid-discourse-flow-widget'],
    ['annotated-text-widget'],
  ],
  variableGroups: [
    {
      label: 'Default group',
      key: DEFAULT_GROUP_KEY,
      color: '#05afec',
      variables: {
        num1: 2,
        num2: 12,
        selectedLemma: null,
        osisRef: DEFAULT_OSIS_REF,
        structure: TEXT_STRUCTURE_KEY_FOR_DISCOURSE_ANNOTATIONS,
        showBaseText: 1,
        selectedGlosses: '',
        alignment: null,
        treedownNode: null,
        json: null,
      },
    },
  ],
};
let userSpecifiedWorkspaces = [
  {
    workspaceId: 'reading',
    workspaceName: 'Reading Workspace',
    columns: [
      {
        label: 'Nav / Structure',
        widgets: [
          'passage-ref-widget',
          'text-structure-widget',
          'interlinear-widget',
          'text-decoration-widget',
        ],
        width: 0,
      },
      { label: 'Passage Display', widgets: ['annotated-text-widget'], width: 1 },
      {
        label: 'Reference Materials',
        widgets: [
          'word-info-widget',
          'places-widget',
          'dictionary-entry-widget',
          'lsj-dictionary-entry-widget',
        ],
        width: 0,
      },
    ],
    variableGroups: [
      {
        label: 'Default group',
        key: DEFAULT_GROUP_KEY,
        color: '#05afec',
        variables: {
          num1: 2,
          num2: 12,
          selectedLemma: null,
          osisRef: DEFAULT_OSIS_REF,
          showBaseText: 1,
          selectedGlosses: '',
          structure: TEXT_STRUCTURE_KEY_FOR_VERSES,
          treedownNode: null,
          json: null,
        },
      },
      {
        label: 'Red Group',
        key: 'groupred',
        color: '#ff0000',
        variables: {
          osisRef: 'John.1.1',
        },
      },
      {
        label: 'Third Group',
        key: 'group3',
        color: '#00ff00',
        variables: {
          num3: 3,
          num4: 4,
          osisRef: 'Mark.10.2',
        },
      },
      {
        label: 'Another Group',
        key: 'anothergroup',
        color: '#94ca36',
        variables: {
          num5: 5,
          num6: 6,
          osisRef: 'Heb.1.8',
        },
      },
      {
        label: 'Blue Group',
        key: 'groupblue',
        variables: {
          num7: 7,
          num8: 8,
          osisRef: 'Eph.1.1',
        },
        color: '#0000ff',
      },
    ],
  },
  {
    workspaceId: ER_PLUS_PLUS_WORKSPACE_ID,
    workspaceName: 'ER++ Workspace',
    columns: [
      {
        label: 'Nav / Structure',
        widgets: [
          'passage-ref-widget',
          'translation-picker-widget',
          // TODO: Restore text decoration widget
          // 'text-decoration-widget'
        ],
        width: 0,
      },
      { label: 'Passage Display', widgets: ['aligned-translation-widget'], width: 1 },
      {
        label: 'Reference Materials',
        // TODO: Update reference widgets for "multi-output" world (rather than just the "first" click)
        widgets: [
          'word-info-widget',
          'places-widget',
          'dictionary-entry-widget',
          'lsj-dictionary-entry-widget',
        ],
        width: 0,
      },
    ],
    variableGroups: [
      {
        label: 'Default group',
        key: DEFAULT_GROUP_KEY,
        color: '#05afec',
        variables: {
          num1: 2,
          num2: 12,
          selectedLemma: null,
          osisRef: DEFAULT_OSIS_REF,
          showBaseText: 1,
          selectedGlosses: '',
          alignment: null,
          treedownNode: null,
          json: null,
        },
      },
    ],
  },
  {
    workspaceId: 'tree-comparison',
    workspaceName: `Tree Comparison Workspace`,
    columns: [
      // Column 1
      [
        {
          component: 'tree-query-widget',
          inputs: {
            label: {
              value: 'Query A',
              groupKey: LOCAL_DATA_STATE,
            },
            endpoint: {
              value: '',
              groupKey: 'local',
            },
            showBaseText: {
              value: 'showBaseText',
              groupKey: DEFAULT_GROUP_KEY,
            },
            selectedGlosses: {
              value: 'selectedGlosses',
              groupKey: DEFAULT_GROUP_KEY,
            },
            treedownDisplay: {
              value: 'treedownDisplay',
              groupKey: DEFAULT_GROUP_KEY,
            },
            treeQuery: {
              value: 'treeQuery',
              groupKey: DEFAULT_GROUP_KEY,
            },
            textualEdition: {
              value: 'textualEdition',
              groupKey: DEFAULT_GROUP_KEY,
            },
          },
          outputs: {
            // FIXME: it should not be necessary to specify these outputs here, since they are defaults.
            treedownNode: {
              value: null,
              send: 'treedownNode',
              groupKey: DEFAULT_GROUP_KEY,
            },
            json: {
              value: null,
              send: 'json',
              groupKey: DEFAULT_GROUP_KEY,
            },
            treeQuery: {
              value: null,
              send: 'treeQuery',
              groupKey: DEFAULT_GROUP_KEY,
            },
            textualEdition: {
              value: 'Nestle1904',
              send: 'textualEdition',
              groupKey: DEFAULT_GROUP_KEY,
            },
            osisRef: {
              value: null,
              send: 'osisRef',
              groupKey: DEFAULT_GROUP_KEY,
            },
            viewingGNT: {
              value: 2,
              send: 'viewingGNT',
              groupKey: DEFAULT_GROUP_KEY,
            },
          },
        },
      ],
      // Column 2
      [
        {
          component: 'tree-query-widget',
          inputs: {
            label: {
              value: 'Query B',
              groupKey: LOCAL_DATA_STATE,
            },
            endpoint: {
              value: undefined,
              groupKey: 'local',
            },
            showBaseText: {
              value: 'showBaseText',
              groupKey: DEFAULT_GROUP_KEY,
            },
            selectedGlosses: {
              value: 'selectedGlosses',
              groupKey: DEFAULT_GROUP_KEY,
            },
            treedownDisplay: {
              value: 'treedownDisplay',
              groupKey: DEFAULT_GROUP_KEY,
            },
            treeQuery: {
              value: 'treeQuery',
              groupKey: DEFAULT_GROUP_KEY,
            },
            textualEdition: {
              value: NESTLE_1904,
              groupKey: 'local',
            },
          },
          outputs: {
            // FIXME: it should not be necessary to specify these outputs here, since they are defaults.
            treedownNode: {
              value: null,
              send: 'treedownNode',
              groupKey: DEFAULT_GROUP_KEY,
            },
            json: {
              value: null,
              send: 'json',
              groupKey: DEFAULT_GROUP_KEY,
            },
            treeQuery: {
              value: null,
              // NOTE: This is sent to treeQuery
              // to avoid clobbering `treeQuery` in OUTPUTS_TO_PUSH_TO_URL.
              send: 'treeQuery2',
              groupKey: DEFAULT_GROUP_KEY,
            },
          },
        },
      ],
      // Column 3 - Uses a default widget, therefore only the label is specified.
      {
        width: 0,
        widgets: ['treedown-info-widget', 'interlinear-widget', 'treedown-display-toggle-widget'],
      },
    ],
  },
  {
    workspaceId: 'query',
    workspaceName: 'Query Workspace',
    columns: [
      ['tree-query-widget'],
      ['treedown-info-widget', 'interlinear-widget', 'treedown-display-toggle-widget'],
    ],
  },
  {
    workspaceId: 'editor',
    workspaceName: 'Editor Workspace',
    columns: [
      {
        width: 1,
        widgets: ['sentences-query-list-widget'],
      },
      {
        width: 2,
        widgets: ['tree-editor-widget'],
      },
      {
        width: 1,
        widgets: ['saved-trees-list-widget', 'treedown-info-widget'],
      },
    ],
  },
  {
    workspaceId: 'reading-readonly',
    workspaceName: 'Reading Workspace',
    readOnly: true, // NOTE: Overrides readOnly default
    columns: [
      {
        label: 'Nav / Structure',
        widgets: [
          'passage-ref-widget',
          'text-structure-widget',
          'interlinear-widget',
          'text-decoration-widget',
        ],
        width: 0,
      },
      { label: 'Passage Display', widgets: ['annotated-text-widget'], width: 1 },
      {
        label: 'Reference Materials',
        widgets: ['dictionary-entry-widget', 'lsj-dictionary-entry-widget'],
        width: 0,
      },
    ],
  },
  {
    workspaceId: 'word-query',
    workspaceName: 'Word Query Workspace',
    columns: [
      { label: 'Build Query', widgets: ['word-query-widget'], width: 1 },
      {
        label: 'Display Results',
        widgets: [
          {
            component: 'word-query-results-widget',
            outputs: {
              selectedLemma: {
                value: null,
                send: 'selectedLemma',
                groupKey: DEFAULT_GROUP_KEY,
              },
              osisRef: {
                value: DEFAULT_OSIS_REF,
                send: 'osisRef',
                groupKey: DEFAULT_GROUP_KEY,
              },
              selectedTokenIds: {
                value: null,
                send: 'selectedTokenIds',
                groupKey: DEFAULT_GROUP_KEY,
              },
            },
          },
        ],
        width: 1,
      },
      { label: 'Passage Display', widgets: ['annotated-text-widget'], width: 0 },
      { label: 'Lookup Words', widgets: ['word-info-widget', 'dictionary-entry-widget'], width: 0 },
    ],
  },
];
if (!DISABLE_ANNOTATION_WORKSPACE) {
  userSpecifiedWorkspaces.push(annotationsWorkspace);
}
if (ONLY_ANNOTATION_WORKSPACE) {
  // FIXME: More configuration of workspaces is required
  userSpecifiedWorkspaces = [annotationsWorkspace];
}

// Sanity check that all workspaces have IDs and none are duplicated
const workspaceIds = userSpecifiedWorkspaces.map(w => w.workspaceId);
// All have IDs
if (workspaceIds.some(id => !id)) {
  const missingIds = userSpecifiedWorkspaces.filter(w => !w.workspaceId);
  console.debug('Failed Workspace Objects:', missingIds);
  throw new Error(
    `All workspaces must have IDs: ${missingIds.map(w => w.workspaceName).join(', ')}`,
  );
}
// None are duplicated
const deduplicatedIds = new Set(workspaceIds);
if (deduplicatedIds.size !== workspaceIds.length) {
  const duplicates = workspaceIds.filter((id, index) => workspaceIds.indexOf(id) !== index);
  throw new Error(`Workspace IDs must be unique: ${duplicates.join(', ')}`);
}

export default populateWorkspacesWithDefaults(userSpecifiedWorkspaces, hydratedWidgets);
