<template>
  <div class="pagination">
    <!-- spacer -->
    <div class="spacer" />
    <!-- left chevrons -->
    <button @click="emit('selectPage', 0)" :disabled="currentPage === 0 || !pageCount">
      <font-awesome-icon icon="angle-double-left" />
    </button>
    <button
      @click="emit('selectPage', currentPage - 1)"
      :disabled="currentPage === 0 || !pageCount"
    >
      <font-awesome-icon icon="angle-left" />
    </button>
    <!-- page numbers -->
    <template v-for="i in pageNumbersToShow" :key="i">
      <button @click="emit('selectPage', i)" :disabled="currentPage === i">
        {{ i + 1 }}
      </button>
    </template>
    <!-- right chevrons -->
    <button
      @click="emit('selectPage', currentPage + 1)"
      :disabled="currentPage === pageCount - 1 || !pageCount"
    >
      <font-awesome-icon icon="angle-right" />
    </button>
    <button
      @click="emit('selectPage', pageCount - 1)"
      :disabled="currentPage === pageCount - 1 || !pageCount"
    >
      <font-awesome-icon icon="angle-double-right" />
    </button>
    <!-- spacer -->
    <div class="spacer" />
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    currentPage: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    numberOfPagesLinks: {
      type: Number,
      default: 5,
    },
  });
  const emit = defineEmits(['selectPage']);

  const clamp = (number, min, max) => Math.max(min, Math.min(number, max));
  const pageNumbersToShow = computed(() => {
    const halfMaxNumbersToShow = (props.numberOfPagesLinks - 1) / 2;
    const idealStartIndex = props.currentPage - halfMaxNumbersToShow;
    const startIndex = clamp(idealStartIndex, 0, props.pageCount - props.numberOfPagesLinks);
    const endIndex = clamp(startIndex + props.numberOfPagesLinks, 0, props.pageCount);
    const pages = [...Array(props.pageCount).keys()].slice(startIndex, endIndex);
    return pages;
  });
</script>

<style scoped lang="scss">
  .pagination {
    display: flex;

    .spacer {
      flex-grow: 1;
    }

    button {
      margin: 2px;
      width: 2rem;
    }
  }
</style>
