<script setup>
  // TODO: Refactor with TreeQueryWidget

  import { watch, ref, computed } from 'vue';
  import { useStore } from 'vuex';

  import useOutputs from '@/composables/useOutputs';
  import inputData from '@/composables/inputData';

  import commonProps from '@/common/props';
  import {
    DEFAULT_GROUP_KEY,
    WLC,
    SBLGNT,
    NESTLE_1904,
    DEFAULT_GNT_TEXTUAL_EDITION,
  } from '@/store/constants';

  import { fromXml } from 'xast-util-from-xml';
  import { visit } from 'unist-util-visit';
  import { removePosition } from 'unist-util-remove-position';
  import { paratextToOsis } from 'bible-reference-formatter';
  import useFetchXML from './composables/useFetchXML';
  // TREEDOWN SHARED COMPONENTS
  import configuration from './config';
  import UILoading from './components/UILoading.vue';
  import { buildPElementOfSentence, isSentenceRTL } from './util';
  // TREE QUERY WIDGET COMPONENTS
  import Search from './components/Search.vue';
  import ResultsCount from './components/ResultsCount.vue';
  import Pagination from './components/Pagination.vue';

  const store = useStore();

  // TODO: Factor out to pagination constant
  const INITIAL_CURSOR_POS = 0;
  const props = defineProps({
    ...commonProps(),
    doric: {
      inputs: {
        treeQuery: {
          value: 'treeQuery',
          groupKey: DEFAULT_GROUP_KEY,
        },
        textualEdition: {
          value: 'textualEdition',
          groupKey: DEFAULT_GROUP_KEY,
        },
      },
      outputs: {
        treedownNode: null,
        json: null,
        osisRef: null,
        treeQuery: null,
        textualEdition: null,
      },
    },
  });
  const initialTreeQueryValue = inputData(store, props?.info?.inputs, 'treeQuery');
  const { submit, outputs } = useOutputs(props);

  // const { localStorage } = window;
  const originalTree = ref(undefined);
  const encounteredError = ref(false); // NOTE: is this needed for viewing only?
  const searchString = ref(configuration.defaultXqueryString);
  const languageDirectionIsRTL = ref(
    configuration.defaultXqueryString ? false : configuration.defaultLanguageDirectionIsRTL,
  );
  const paginationCursor = ref(0);
  const { xml, usingXq, isLoading, pageSize, fetchXML } = useFetchXML(props);

  const totalNumberOfResults = ref(0);
  const sentences = ref(null);

  const rowIsClicked = ref(false);

  const selectedTextualEdition = ref(undefined);
  const searchQueryObject = ref(undefined);
  const showTextualEditionToggle = computed(() => {
    return usingXq?.value;
  });

  // TODO: Query for these values from backend
  const textualEditionOptions = [NESTLE_1904, SBLGNT, WLC];

  const textualEdition = computed(() => {
    const { value } = inputData(store, props?.info?.inputs, 'textualEdition');
    return value;
  });

  /** LIFECYCLE AND DIRECTIVES */

  /** COMPONENT METHODS (TODO: subdivide further) */

  function handleSelectNode(value) {
    outputs.value.treedownNode.value = {
      ...JSON.parse(JSON.stringify(value)),
      timestamp: new Date().getTime(),
      languageDirectionIsRTL: languageDirectionIsRTL.value, // TODO: @xml:lang should be returned by the API in the future, cf. https://github.com/Clear-Bible/symphony-backend/issues/68#issue-1317392867
    };
    outputs.value.json.value = value;
    submit();
  }

  function handleSelectOsisRef(value) {
    rowIsClicked.value = true;
    outputs.value.osisRef.value = value;
    submit();
    setTimeout(() => {
      rowIsClicked.value = false;
    }, 1000);
  }

  function handleClickRow(sentence) {
    handleSelectOsisRef(sentence.osisRef);
    handleSelectNode(sentence);
  }

  function handleError(error) {
    // NOTE: slight screen flash when error encountered
    console.info(error);
    encounteredError.value = true;
  }

  function handleUpdatePage({ newCursor, newPageSize }) {
    paginationCursor.value = newCursor;
    pageSize.value = newPageSize;
    fetchXML({
      query: searchString.value,
      usingXquery: true,
      fetchPageSize: newPageSize,
      fetchCursor: newCursor,
      textualEdition,
    });
  }

  function handleSearch(queryObject) {
    searchQueryObject.value = queryObject;
    if (!selectedTextualEdition.value) {
      selectedTextualEdition.value = textualEdition.value || DEFAULT_GNT_TEXTUAL_EDITION;
    }

    try {
      const { query, xquery, directId } = queryObject;
      searchString.value = query;

      let newTextualEdition = selectedTextualEdition.value;
      if (!xquery) {
        newTextualEdition = null;
      }
      fetchXML({
        query,
        usingXquery: xquery,
        usingDirectId: directId,
        fetchCursor: INITIAL_CURSOR_POS,
        textualEdition,
      });
      outputs.value.treeQuery.value = query;
      paginationCursor.value = INITIAL_CURSOR_POS;

      if ('textualEdition' in outputs.value) {
        outputs.value.textualEdition.value = newTextualEdition;
      }

      submit();
    } catch (error) {
      handleError(error);
    }
  }
  function setLanguageDirectionIsRTL(languageDirectionIsRTLProp) {
    languageDirectionIsRTL.value = !!languageDirectionIsRTLProp;
  }

  function getMatchElement(sentence) {
    const matchElement = sentence?.children.find(child => child.name === 'match');
    const matchingElementIds = matchElement?.attributes?.ids.split(' ');
    return { matchElement, matchingElementIds };
  }
  function getPElementMatch(sentence) {
    const wordObjects = buildPElementOfSentence(sentence)?.textContentArray;
    wordObjects[0].isFirstWord = true;
    wordObjects[wordObjects.length - 1].isLastWord = true;
    const { matchingElementIds } = getMatchElement(sentence);
    if (matchingElementIds) {
      const matchingWords = wordObjects?.filter(word => matchingElementIds.includes(word.id));
      const wordPrecedingFirstMatchingWordIndex =
        wordObjects.indexOf(matchingWords[0]) > 0
          ? wordObjects.indexOf(matchingWords[0]) - 1
          : null;
      const wordPrecedingFirstMatchingWord =
        wordObjects[wordPrecedingFirstMatchingWordIndex] ?? null;
      const wordFollowingLastMatchingWordIndex =
        wordObjects.indexOf(matchingWords[matchingWords.length - 1]) < wordObjects.length - 1
          ? wordObjects.indexOf(matchingWords[matchingWords.length - 1]) + 1
          : null;
      const wordFollowingLastMatchingWord = wordObjects[wordFollowingLastMatchingWordIndex] ?? null;
      const results = [
        { ...wordPrecedingFirstMatchingWord, precedingWord: true },
        ...matchingWords.map(word => ({ ...word, isMatching: true })),
        { ...wordFollowingLastMatchingWord, followingWord: true },
      ];
      return results;
    }
    return wordObjects;
  }

  /** WATCHERS */

  watch(xml, newData => {
    if (newData && xml.value) {
      try {
        originalTree.value = removePosition(fromXml(xml.value), true);
        if (originalTree.value) {
          const sentencesPrivate = [];
          visit(originalTree.value, node => {
            if (node.name === 'sentence') {
              totalNumberOfResults.value = parseInt(node.attributes.totalResults, 10);

              const usfmRef = node.children
                ?.find(child => child.name === 'p')
                ?.children?.find(child => child.name === 'milestone')?.attributes?.id;
              sentencesPrivate.push({ ...node, usfmRef, osisRef: paratextToOsis(usfmRef) });
            }
          });
          sentences.value = sentencesPrivate;
        }
      } catch (e) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        encounteredError.value = true;
        // NOTE: this keeps the app from crashing right now when it encounters invalid XML
        return undefined;
      }
    }
    return null;
  });

  watch(encounteredError, becomesTrue => {
    if (becomesTrue) {
      setTimeout(() => {
        encounteredError.value = false;
      }, 100);
    }
  });
  watch(sentences, newValue => {
    if (newValue) {
      languageDirectionIsRTL.value = isSentenceRTL(newValue.find(() => true));
    }
  });
  // eslint-disable-next-line no-unused-vars
  watch(selectedTextualEdition, (newValue, oldValue) => {
    if (oldValue) {
      // Checking for the oldValue ensures
      // we avoid a recursive loop in handleSearch
      // when we set the initial selectedTextualEdition value
      handleSearch(searchQueryObject.value);
    }
  });
</script>

<template>
  <div class="query-results-container">
    <div class="ui-container">
      <Search
        @language-direction-is-rtl="setLanguageDirectionIsRTL"
        @query-object="handleSearch"
        :initialTreeQuery="initialTreeQueryValue"
      />
      <div>
        <ResultsCount
          v-if="usingXq && !isLoading"
          :count="sentences.length > 0 ? totalNumberOfResults : 0"
        />
        <select v-if="showTextualEditionToggle" v-model="selectedTextualEdition">
          <option v-for="textualEditionOption in textualEditionOptions" :key="textualEditionOption">
            {{ textualEditionOption }}
          </option>
        </select>
      </div>
    </div>
    <div v-show="isLoading">
      <UILoading />
    </div>
    <div
      v-if="originalTree && !isLoading"
      id="sentence-root"
      :class="[{ errorNotification: encounteredError }, { rtl: languageDirectionIsRTL }]"
      tabindex="0"
    >
      <div v-if="sentences.length < 1">
        <p>No results for query.</p>
      </div>
      <div v-else class="query-results">
        <div class="query-results">
          <div
            class="row"
            v-for="sentence in sentences"
            :key="sentence.id"
            @click="handleClickRow(sentence)"
          >
            <span class="sentence-osis" :class="rowIsClicked">
              {{ sentence.osisRef }}
            </span>
            <span class="sentence-text">
              <span
                v-for="word in getPElementMatch(sentence)"
                :key="word?.id"
                class="sentence-preview-content-word"
                :class="[
                  { match: word?.isMatching },
                  { precedingWord: word?.id && !word?.isFirstWord && word?.precedingWord },
                  { followingWord: word?.id && !word?.isLastWord && word?.followingWord },
                  { isFirstWord: word?.isFirstWord },
                  { isLastWord: word?.isLastWord },
                ]"
              >
                {{ word?.value }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <Pagination
        v-if="originalTree && !isLoading && usingXq"
        :totalNumberOfResults="totalNumberOfResults"
        :paginationCursor="paginationCursor"
        :resultsPerPage="pageSize"
        @update-page="handleUpdatePage"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .query-results-container {
    font-family: 'Times New Roman', Times, serif;
    max-width: 100%;
  }
  .ui-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    line-height: 1.5;
    margin-bottom: 1em;
  }
  .ui-container button {
    margin-left: 0.5em;
    height: 100%;
    line-height: 1.5;
  }
  .query-results {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
  .row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0.5em;
    &:hover {
      background-color: #f1f1f1;
    }
  }
  .rowIsClicked {
    background-color: #05afec;
  }
  .data {
    padding: 0.5em;
  }
  .sentence-osis {
    padding: 0.5em;
    margin-bottom: 0.5em;
    max-width: 5em;
    flex: 1;
    border-radius: 0.25em;
    text-align: center;
  }
  .sentence-text {
    text-align: start;
    padding: 0.5em;
    flex: 5;
  }
  .rtl {
    direction: rtl;
  }
  .sentence-preview-content-word::after {
    content: ' ';
  }
  .sentence-preview-content-word.match {
    font-weight: bold;
  }
  .sentence-preview-content-word.precedingWord::before {
    content: '...';
  }
  .sentence-preview-content-word.followingWord::after {
    content: '...';
  }
  .sentence-preview-content-word.isFirstWord::before {
    content: '';
  }
  .sentence-preview-content-word.isLastWord::after {
    content: '';
  }

  /** when window is smaller than 600 pixels, make .sentence-preview-content column */
  @media (max-width: 600px) {
    .sentence-preview-content {
      flex-direction: column;
    }
  }
</style>
