<script setup>
  import { watch, ref, reactive, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { toXml } from 'xast-util-to-xml';
  import { map as mapXML } from 'unist-util-map';
  import find from 'unist-util-find';
  import { visitParents } from 'unist-util-visit-parents';
  import { visit, EXIT, CONTINUE } from 'unist-util-visit';
  import commonProps from '@/common/props';
  import inputData from '@/composables/inputData';
  import useOutputs from '@/composables/useOutputs';
  import { DEFAULT_GROUP_KEY, XML_ID } from '@/store/constants';
  import EmptyMessage from '../../components/EmptyMessage.vue';

  // TREEDOWN SHARED COMPONENTS
  import { parseWordId, getHotkeysFromLabels, isSentenceRTL } from '../util';
  import configuration from '../config';
  import TreeNode from '../components/TreeNode.vue';
  import UILoading from '../components/UILoading.vue';
  import Controls from './Controls.vue';
  import Legend from './Legend.vue';
  import DisplayTogglesMenu from '../components/DisplayTogglesMenu.vue';

  const store = useStore();

  const props = defineProps({
    ...commonProps(),
    doric: {
      inputs: {
        treedownNode: {
          value: 'treedownNode',
          groupKey: DEFAULT_GROUP_KEY,
        },
      },
      // Q: would it make sense to have an array of 'accompanying'
      // widgets that automatically populate but can be removed individually?
      outputs: {
        json: null,
        savedDrafts: null,
        treedownNode: null,
      },
    },
  });
  const { submit, outputs } = useOutputs(props);

  const treedownNode = inputData(store, props?.info?.inputs, 'treedownNode');

  // const SYMPHONY_REST_API_ENDPOINT =
  //   process.env.VUE_APP_SYMPHONY_REST_API_ENDPOINT || 'https://symphony-basex-svc.clearlabs.biblica.com/api';
  const { localStorage } = window;
  const selectedNodeIndex = ref(1);
  // NOTE: for reactivity I am passing this into the node component. This should be in global state
  const sentenceIsFocused = ref(undefined);
  const xml = ref(undefined);
  const numberOfWordsInVirtualTree = ref(0);
  const numberOfNodesInVirtualTree = ref(0);
  const wordIdsInVirtualTree = ref(undefined);
  const originalTree = ref(undefined);
  const virtualTree = ref(undefined);
  const encounteredError = ref(false);
  const isSaving = ref(false);
  const treeReference = ref(null);
  const languageDirectionIsRTL = ref(configuration.defaultLanguageDirectionIsRTL);
  const isLoading = ref(true);
  const zoomToWord = ref(false);
  const currentlySelectedCharIndex = ref(0);
  const shouldUpdateVirtualTree = ref(undefined);
  const { syntaxTypes } = ref(configuration);
  const hotkeys = ref(getHotkeysFromLabels(configuration.syntaxTypes));
  const dirtyEditorFlag = ref(false); // Whether the current tree has been modified since the last save
  const draftTreeFlag = ref(false); // Whether the current tree is a draft or the 'official' version

  const baselineTree = ref(undefined);
  // baselineTree mirrors the first virtualTree setup from originalTree.
  // It is used to calculate whether any changes have been made, and it
  // has word/node position assigned by the virtualTree setup function.

  const displayOptions = reactive({
    usePlainText: false,
    showAnalysis: true,
    showWordGroups: true,
    showClauses: true,
    showMilestones: true,
    showControls: false,
  });

  async function setXmlValueFromTreedownNodeInput() {
    isLoading.value = true;
    zoomToWord.value = false;
    const tree = treedownNode.value?.tree || treedownNode.value;
    xml.value = JSON.parse(JSON.stringify(tree)); // make a copy
    isLoading.value = false;
  }

  /** LIFECYCLE AND DIRECTIVES */

  onMounted(() => {
    // setXmlValueFromTreedownNodeInput();
    // set up the hotkeys
    hotkeys.value = getHotkeysFromLabels(configuration.syntaxTypes);
  });

  const vAutofocusForKeyBindings = {
    // TODO: what is the script setup way to do this?
    mounted(element) {
      element.focus({
        preventScroll: true,
      });
    },
  };

  /** COMPONENT METHODS (TODO: subdivide further) */

  function handleError(error) {
    // NOTE: slight screen flash when error encountered
    // eslint-disable-next-line no-console
    console.info(error);
    encounteredError.value = true;
  }

  function getWordIdsInVirtualTree(treeToUse) {
    // NOTE: All word ids are sorted in order to determine their order,
    // but since they are not all of the same magnitude (some ids are 11-digit
    // word ids, and some are 12-digit SUBword ids) their final @id digits cannot
    // simply be used as an index
    const wordIdsInVirtualTreePrivate = [];
    visit(
      treeToUse,
      node => node?.name === 'w',
      currentWord => {
        const { verseWordId } = parseWordId(currentWord.attributes?.[XML_ID]);
        wordIdsInVirtualTreePrivate.push(verseWordId);
      },
    );
    wordIdsInVirtualTreePrivate.sort((a, b) => a - b);
    // NOTE: need to use mathematical sort not alphabetical
    // FIXME: when a chapter is queried (e.g., GEN 1:1), it does not return @id AND @n - see issue: https://github.com/Clear-Bible/symphony-backend/issues/67
    return wordIdsInVirtualTreePrivate;
  }

  function assignVirtualTreePositions(treeToUse) {
    numberOfWordsInVirtualTree.value = 0;
    numberOfNodesInVirtualTree.value = 0;
    const wordIdsInVirtualTreePrivate = getWordIdsInVirtualTree(treeToUse);
    return mapXML(treeToUse, node => {
      let newNode = node;
      if (node?.name === 'w' || node?.name === 'wg') {
        numberOfNodesInVirtualTree.value += 1;
        newNode = {
          ...newNode,
          currentPosition: numberOfNodesInVirtualTree.value,
        };
      }
      if (node.attributes?.role && !syntaxTypes?.value?.roles?.includes(node.attributes?.role)) {
        syntaxTypes?.value.roles.push(node.attributes?.role);
      }
      if (node?.name === 'w') {
        numberOfWordsInVirtualTree.value += 1;
        const { verseWordId } = parseWordId(node.attributes?.[XML_ID]);
        newNode = {
          ...newNode,
          originalPosition: wordIdsInVirtualTreePrivate.indexOf(verseWordId) + 1,
          // NOTE: numberOfWordsInVirtualTree starts at 1, not 0,
          // but wordIdsInVirtualTree is 0-indexed
          currentWordPosition: numberOfWordsInVirtualTree.value,
        };
      }
      return newNode;
    });
  }

  function setupVirtualTree(shouldReset) {
    let treeToUse = originalTree.value;
    languageDirectionIsRTL.value = isSentenceRTL(originalTree.value);
    if (!shouldReset) {
      treeToUse = virtualTree.value || originalTree.value;
    }

    // NOTE: plain text is not yet supported in the Doric implementation

    // if (displayOptions.usePlainText) {
    //   // NOTE: the plaintext from the API does not have a top-level wg
    //   treeToUse = mapXML(treeToUse, node => {
    //     let nodeToReturn = node;
    //     if (node?.name === 'verse') {
    //       const { children } = node;
    //       nodeToReturn = {
    //         name: 'wg',
    //         attributes: {
    //           [XML_ID]: 'wrapper-word-group', // TODO: remove when serializing XML or persisting to localStorage? Or maybe generate a valid id somehow?
    //         },
    //         children,
    //       };
    //     }
    //     return nodeToReturn;
    //   });
    // }
    if (treeToUse) {
      return assignVirtualTreePositions(treeToUse);
    }
    return null;
  }

  function getNumberOfCharactersInWord() {
    if (virtualTree.value) {
      const currentWord = find(
        virtualTree.value,
        node => node.currentPosition === selectedNodeIndex.value,
      );
      if (currentWord) {
        return currentWord.children[0]?.value.split('').length;
      }
    }
    return 0;
  }

  function left() {
    if (zoomToWord.value) {
      if (languageDirectionIsRTL.value) {
        currentlySelectedCharIndex.value =
          currentlySelectedCharIndex.value < getNumberOfCharactersInWord()
            ? currentlySelectedCharIndex.value + 1
            : currentlySelectedCharIndex.value;
      } else {
        currentlySelectedCharIndex.value =
          currentlySelectedCharIndex.value > 0
            ? currentlySelectedCharIndex.value - 1
            : currentlySelectedCharIndex.value;
      }
    } else {
      if (languageDirectionIsRTL.value) {
        selectedNodeIndex.value += 1;
      }
      if (!languageDirectionIsRTL.value) {
        selectedNodeIndex.value -= 1;
      }
    }
  }
  function right() {
    if (zoomToWord.value) {
      if (languageDirectionIsRTL.value) {
        currentlySelectedCharIndex.value =
          currentlySelectedCharIndex.value > 0
            ? currentlySelectedCharIndex.value - 1
            : currentlySelectedCharIndex.value;
      } else {
        currentlySelectedCharIndex.value =
          currentlySelectedCharIndex.value < getNumberOfCharactersInWord()
            ? currentlySelectedCharIndex.value + 1
            : currentlySelectedCharIndex.value;
      }
    } else {
      if (languageDirectionIsRTL.value) {
        selectedNodeIndex.value -= 1;
      }
      if (!languageDirectionIsRTL.value) {
        selectedNodeIndex.value += 1;
      }
    }
  }

  function swapCurrentNodeWithPreviousWord(nodeToMoveBack) {
    if (virtualTree.value) {
      try {
        visit(
          virtualTree.value,
          node => node === nodeToMoveBack || node.currentPosition === selectedNodeIndex.value,
          (currentNode, index, parent) => {
            const indexOfPreviousNode = index - 1;
            const previousNode = parent.children[indexOfPreviousNode];
            if (previousNode && previousNode.name === 'w') {
              selectedNodeIndex.value = previousNode.currentPosition;
              const updatedCurrentNode = { ...previousNode };
              const updatedPreviousNode = { ...currentNode };
              // eslint-disable-next-line no-param-reassign
              parent.children[index] = updatedCurrentNode;
              // eslint-disable-next-line no-param-reassign
              parent.children[indexOfPreviousNode] = updatedPreviousNode;

              return EXIT;
            }
            if (previousNode && previousNode.name === 'wg') {
              selectedNodeIndex.value = previousNode.currentPosition;
              const updatedCurrentNode = { ...previousNode };
              const updatedPreviousNode = { ...currentNode };
              parent.children.splice(index, 1, updatedCurrentNode);
              parent.children.splice(indexOfPreviousNode, 1, updatedPreviousNode);
            }
            return EXIT;
          },
        );
        virtualTree.value = assignVirtualTreePositions(virtualTree.value);
      } catch (e) {
        handleError(e);
      }
    }
  }

  function swap() {
    if (zoomToWord.value) {
      // navigate within the word instead of the tree
    } else {
      // shift+left or shift+right
      try {
        if (selectedNodeIndex.value > 0) {
          swapCurrentNodeWithPreviousWord();
        }
      } catch (error) {
        handleError(error);
      }
    }
  }

  function wrapCurrentNode(wrapperType) {
    const treeToSearch = virtualTree.value;
    if (treeToSearch) {
      visit(treeToSearch, null, (currentNode, index, parent) => {
        if (currentNode.currentPosition === selectedNodeIndex.value) {
          // TODO: Document the fallbacks
          const newNodeId =
            currentNode?.currentPosition ||
            currentNode?.attributes?.[XML_ID] ||
            currentNode?.attributes?.n;
          const newWordGroupNode = {
            name: 'wg',
            type: 'element',
            attributes: {
              class: wrapperType || undefined,
              role: '☐',
              [XML_ID]: `${parent?.attributes?.[XML_ID] || parent?.attributes?.n}!${newNodeId}`,
            },
            children: [currentNode],
          };
          try {
            parent.children.splice(index, 1, newWordGroupNode);
          } catch (error) {
            handleError(error);
          }
          return EXIT;
        }
        return CONTINUE;
      });
    }
    return false;
  }

  function getParent(selectedNode) {
    const treeToSearch = virtualTree.value;
    let parentNode = null;
    if (treeToSearch) {
      visitParents(virtualTree.value, null, (wordNode, parents) => {
        if (wordNode === selectedNode || wordNode.currentPosition === selectedNodeIndex.value) {
          parentNode = parents[parents.length - 1];
          return EXIT;
        }
        return CONTINUE;
      });
    }
    return parentNode;
  }
  function moveParentChildrenToGrandParent(parentNode) {
    visit(
      virtualTree.value,
      node => node === parentNode,
      (parent, index, grandParent) => {
        if (parent.name !== 'w' && grandParent.name === 'wg') {
          const currentParentChildren = [...parent.children];
          // NOTE: I use destructuring to create a copy instead of
          // simply referring to the original
          const indexOfParent = grandParent.children.indexOf(parent);
          grandParent.children.splice(indexOfParent, 1, ...currentParentChildren);
          return EXIT;
        }
        return CONTINUE;
      },
    );
    virtualTree.value = assignVirtualTreePositions(virtualTree.value);
    selectedNodeIndex.value -= 1;
  }

  function splitWordsIntoTwoWordGroups() {
    const treeToSearch = virtualTree.value;
    if (treeToSearch) {
      let parentNode = null;
      // let grandParent = null;
      visit(
        treeToSearch,
        node => node.currentPosition === selectedNodeIndex.value,
        (currentVisitorNode, indexOfCurrentVisitorNode, parent) => {
          if (currentVisitorNode.name !== 'w') {
            // NOTE: if the currently selected node is not a word,
            // then you cannot split it into one of two word groups
            return CONTINUE;
          }
          if (parent.children.length === 1) {
            // NOTE: if the parent node has only one child, then we can't split it
            return EXIT;
          }

          parentNode = parent;
          // grandParent = this.getParentWordGroup(parentNode);
          const childrenPrecedingSelectionCursor = [];
          const childrenFollowingSelectionCursor = [];
          parent.children.forEach((childNode, childIndex) => {
            if (childIndex >= indexOfCurrentVisitorNode) {
              childrenFollowingSelectionCursor.push(childNode);
            }
            if (childIndex < indexOfCurrentVisitorNode) {
              childrenPrecedingSelectionCursor.push(childNode);
            }
          });

          const newNodeFollowingSelectionCursor = {
            name: 'wg',
            type: 'element',
            attributes: {
              role: '☐',
              [XML_ID]: `${currentVisitorNode.currentPosition}.${1}`,
            },
            children: childrenFollowingSelectionCursor,
          };

          const newNodePrecedingSelectionCursor = {
            name: 'wg',
            type: 'element',
            attributes: {
              role: '☐',
              [XML_ID]: `${currentVisitorNode.currentPosition}.${0}`,
            },
            children: childrenPrecedingSelectionCursor,
          };

          const newGrandParentChildNodes = [];
          if (childrenPrecedingSelectionCursor.length > 0) {
            if (childrenPrecedingSelectionCursor.some(child => child.name === 'w')) {
              newGrandParentChildNodes.push(newNodePrecedingSelectionCursor);
            } else {
              newGrandParentChildNodes.push(...childrenPrecedingSelectionCursor);
            }
          }
          if (childrenFollowingSelectionCursor.some(child => child.name === 'w')) {
            newGrandParentChildNodes.push(newNodeFollowingSelectionCursor);
          } else {
            newGrandParentChildNodes.push(...childrenFollowingSelectionCursor);
          }

          // NOTE: tree manipulation happens here, so we need to return EXIT
          // to ensure we stop traversing the tree after we've made the changes
          // eslint-disable-next-line no-param-reassign
          parent.children = [...newGrandParentChildNodes];
          return EXIT;
        },
      );

      if (getParent(parentNode)?.type !== 'root') {
        moveParentChildrenToGrandParent(parentNode);
      }
      selectedNodeIndex.value += 1;
      virtualTree.value = assignVirtualTreePositions(virtualTree.value);
    }
  }

  function brk(flag) {
    if (zoomToWord.value) {
      // navigate within the word instead of the tree
    } else {
      try {
        // 'enter'
        if (flag === 'node-only') {
          wrapCurrentNode();
        } else {
          splitWordsIntoTwoWordGroups();
        }
        dirtyEditorFlag.value = true;
      } catch (error) {
        handleError(error);
      }
    }
  }

  function getParentWordGroup(selectedNode) {
    // FIXME: this function is the source of a number of problems.
    // It should find the parent WG, not any parent.
    const treeToSearch = virtualTree.value;
    let parentNode = null;

    if (treeToSearch) {
      visitParents(virtualTree.value, null, (wordNode, parents) => {
        if (wordNode === selectedNode || wordNode.currentPosition === selectedNodeIndex.value) {
          const filteredParents = parents.filter(parent => parent.name === 'wg');
          if (filteredParents.length > 0) {
            parentNode = filteredParents[filteredParents.length - 1];
            return EXIT;
          }
          return CONTINUE;
        }
        return CONTINUE;
      });
    }
    return parentNode;
  }

  function moveCurrentWordGroupContentsToPreviousSibling() {
    // 'Backspace'
    const treeToSearch = virtualTree.value;
    if (treeToSearch) {
      visit(
        treeToSearch,
        node => node.currentPosition === selectedNodeIndex.value,
        (currentNode, index, parent) => {
          // NOTE: if current node is wg, then we need to move its children to the previous sibling
          if (currentNode.name === 'wg') {
            const previousSibling = parent.children[index - 1];
            if (previousSibling && previousSibling.name === 'wg') {
              previousSibling.children.push(...currentNode.children);
              parent.children.splice(index, 1);
            } else {
              // NOTE: in this case you need to move the current word group contents to
              // the parent at index
              parent.children.splice(index, 1, ...currentNode.children);
            }
            return EXIT;
          }
          // NOTE: if current node is w, then we need to move its parent's
          // children to the w's previous sibling
          if (currentNode.name === 'w') {
            const grandParent = getParentWordGroup(parent);
            const currentParentChildren = [...parent.children];
            const indexOfParent = grandParent.children.indexOf(parent);
            const currentNodeIsIndented =
              parent.name === 'wg' && parent.attributes?.[XML_ID] !== 'wrapper-word-group';

            if (currentNodeIsIndented) {
              grandParent.children.splice(indexOfParent, 1, ...currentParentChildren);
              selectedNodeIndex.value -= 1;
              return EXIT;
            }

            if (!currentNodeIsIndented) {
              const indexOfPreviousSibling = indexOfParent - 1;
              grandParent.children[indexOfPreviousSibling].children.push(
                // TODO: determine if pushing instead of splicing causes re-ordering issues
                ...currentParentChildren,
              );
              grandParent.children.splice(indexOfParent, 1);
              selectedNodeIndex.value -= 1;
              return EXIT;
            }
          }
          return EXIT;
        },
      );
      virtualTree.value = assignVirtualTreePositions(virtualTree.value);
    }
  }

  function join() {
    if (zoomToWord.value) {
      // navigate within the word instead of the tree
    } else {
      // 'backspace'
      try {
        moveCurrentWordGroupContentsToPreviousSibling();
        dirtyEditorFlag.value = true;
      } catch (error) {
        handleError(error);
      }
    }
  }

  function moveCurrentNodeToPreviousSibling() {
    // 'Indent'
    // NOTE: moves the selected word group (or parent word group if a word is selected)
    // to the previous sibling's children
    const treeToSearch = virtualTree.value;
    if (treeToSearch) {
      visit(
        treeToSearch,
        node => node.currentPosition === selectedNodeIndex.value,
        // NOTE: selects parent wg if supplied as arg, or current word if no arg is supplied
        (matchingNode, index, parent) => {
          const previousSibling = parent.children[index - 1];
          if (matchingNode.name === 'wg') {
            if (previousSibling && previousSibling.name === 'wg') {
              previousSibling.children.push(matchingNode);
              parent.children.splice(index, 1);
              return EXIT;
            }
          }
          if (matchingNode.name === 'w') {
            // NOTE: if parent is a wg, then we need to move the parent's
            // children to its previous sibling
            if (parent.name === 'wg') {
              const parentIsClause = parent.attributes?.class === 'cl';
              // Wrap the matching node in a word group
              const newChildWordGroup = {
                name: 'wg',
                type: 'element',
                attributes: {
                  role: '☐',
                  [XML_ID]: `${parent.currentPosition}.${matchingNode.currentPosition}`,
                },
                children: [matchingNode],
              };
              if (parentIsClause) {
                // NOTE: When the node is a w, and the parent is a wg/@class='cl',
                // then the current node and previous word need to be wrapped in a wg,
                // and the current node moved to that wg's children
                if (previousSibling && previousSibling.name === 'wg') {
                  previousSibling.children.push(newChildWordGroup);
                  parent.children.splice(index, 1);
                  return EXIT;
                }
                if (previousSibling && previousSibling.name === 'w') {
                  // Wrap the previous sibling word in a word group that contains
                  // the matching node word group
                  const newSiblingWordGroup = {
                    name: 'wg',
                    type: 'element',
                    attributes: {
                      role: '☐',
                      [XML_ID]: `${previousSibling.currentPosition}.${1}`,
                    },
                    children: [previousSibling, newChildWordGroup],
                  };
                  parent.children.splice(index - 1, 2, newSiblingWordGroup);
                  selectedNodeIndex.value += 2;
                  // NOTE: in this case, two new word groups have been added
                  return EXIT;
                }
              }

              const grandParent = getParentWordGroup(parent);
              if (grandParent.name !== 'wg') {
                return EXIT;
              }

              const indexOfParentInGrandParent = grandParent?.children.indexOf(parent);
              const previousSiblingInGrandParent =
                grandParent?.children[indexOfParentInGrandParent - 1];
              if (
                grandParent &&
                previousSiblingInGrandParent &&
                previousSiblingInGrandParent.name === 'wg'
              ) {
                previousSiblingInGrandParent.children.push(parent);
                grandParent.children.splice(indexOfParentInGrandParent, 1);
                return EXIT;
              }
            }
          }
          return EXIT;
        },
      );
      shouldUpdateVirtualTree.value = true;
    }
  }

  function indent() {
    if (zoomToWord.value) {
      // navigate within the word instead of the tree
    } else {
      // 'tab'
      try {
        moveCurrentNodeToPreviousSibling();
        dirtyEditorFlag.value = true;
      } catch (error) {
        handleError(error);
      }
    }
  }

  function moveNodeToGreatGrandParent(specifiedNode) {
    const treeToSearch = virtualTree.value;
    if (treeToSearch) {
      visit(
        treeToSearch,
        node => node === specifiedNode || node.currentPosition === selectedNodeIndex.value,
        (currentNode, indexOfCurrentNode, parentNode) => {
          const grandParentNode = getParentWordGroup(parentNode);
          if (parentNode.attributes?.[XML_ID] === 'wrapper-word-group') {
            throw new Error('Parent is top-level word group');
          }

          if (currentNode.name === 'wg') {
            if (
              parentNode.type === 'root' ||
              grandParentNode?.type === 'root' ||
              parentNode.name === ('sentence' || 'sentences') ||
              grandParentNode.name === ('sentences' || 'sentence')
            ) {
              throw new Error('Parent is top-level word group');
            }
            const grandParentContainsAtLeastOneWordGroup =
              grandParentNode?.children.filter(node => node?.name === 'wg').length > 0;
            if (parentNode.name === 'verse' || grandParentNode.name === 'verse') {
              throw new Error('Cannot dedent from top-level verse'); // TODO: This works for the plain text, check what the top-level node is in the syntax trees
            }

            if (!grandParentContainsAtLeastOneWordGroup) {
              // NOTE: Don't dedent if the parent word group only contains words or is empty.
              return EXIT;
            }

            const indexOfParentInGrandParent = grandParentNode?.children
              ?.indexOf(parentNode)
              .isNan()
              ? 0
              : // eslint-disable-next-line no-unsafe-optional-chaining
                grandParentNode?.children?.indexOf(parentNode) + 1;
            // eslint-disable-next-line no-unused-expressions
            grandParentNode?.children?.splice(indexOfParentInGrandParent, 0, currentNode);
            parentNode.children.splice(indexOfCurrentNode, 1);
            return EXIT;
          }
          if (currentNode.name === 'w') {
            if (
              grandParentNode.name === ('sentences' || 'sentence') ||
              parentNode.name === ('sentences' || 'sentence')
            ) {
              throw new Error('Parent is top-level word group');
            }
            moveNodeToGreatGrandParent(parentNode);
          }
          return EXIT;
        },
      );
      shouldUpdateVirtualTree.value = true;
    }
  }
  function dedent() {
    if (zoomToWord.value) {
      // navigate within the word instead of the tree
    } else {
      // 'shift+tab'
      try {
        moveNodeToGreatGrandParent();
        dirtyEditorFlag.value = true;
      } catch (error) {
        handleError(error);
      }
    }
  }

  function getCurrentNode() {
    if (virtualTree.value) {
      return find(virtualTree.value, node => node.currentPosition === selectedNodeIndex.value);
    }
    return null;
  }

  function toggleSubwordEditing() {
    // 'alt+enter'
    try {
      const currentNodeName = getCurrentNode().name;
      if (currentNodeName === 'w') {
        currentlySelectedCharIndex.value = 0;
        zoomToWord.value = !zoomToWord.value;
      }
    } catch (error) {
      handleError(error);
    }
  }

  function moveNodeToOriginalPosition(nodeA) {
    const nodeB = find(virtualTree.value, node => node.currentPosition === nodeA.originalPosition);
    visit(virtualTree.value, null, (currentNode, index, parent) => {
      if (currentNode === nodeA) {
        try {
          parent.children.splice(index, 1, nodeB);
        } catch (error) {
          handleError(error);
        }
      }
      if (currentNode === nodeB) {
        try {
          parent.children.splice(index, 1, nodeA);
        } catch (error) {
          handleError(error);
        }
      }
      return CONTINUE;
    });
    return { nodeA, nodeB };
  }
  function swapNodes(nodeA, nodeB) {
    visit(virtualTree.value, null, (currentNode, index, parent) => {
      if (currentNode === nodeA) {
        try {
          parent.children.splice(index, 1, nodeB);
        } catch (error) {
          handleError(error);
        }
      }
      if (currentNode === nodeB) {
        try {
          parent.children.splice(index, 1, nodeA);
        } catch (error) {
          handleError(error);
        }
      }
      return CONTINUE;
    });
  }

  function reassignVirtualTreeWordPositions() {
    // NOTE: This function checks for any swapped words where
    // node.originalPosition !== node.currentPosition and temporarily reverses them,
    // then reassigns positions, and then reverses them back
    numberOfWordsInVirtualTree.value = 1;
    const wordIdsInVirtualTreePrivate = [];
    const nodesToReverseAfterReassignment = [];
    if (virtualTree.value) {
      visit(virtualTree.value, null, node => {
        if (node?.name === 'w') {
          if (node.originalPosition !== node.currentPosition) {
            const { nodeA, nodeB } = moveNodeToOriginalPosition(node);
            nodesToReverseAfterReassignment.push({ nodeA, nodeB });
            // TODO: reversing swaps before and after reassigning positions is buggy.
          }
          const wordId =
            parseWordId(node.attributes?.n) || parseWordId(node.attributes?.[XML_ID]).wordId;
          wordIdsInVirtualTreePrivate.push(wordId);
          wordIdsInVirtualTreePrivate.sort((a, b) => a - b);

          // eslint-disable-next-line no-param-reassign
          node = {
            ...node,
            originalPosition: wordIdsInVirtualTree.value.indexOf(wordId)
              ? wordIdsInVirtualTree.value.indexOf(wordId) + 1
              : 'ERROR', // numberOfWordsInVirtualTree starts at 1, not 0
            // FIXME: for some reason the first and third words wind up producing errors
            currentPosition: numberOfWordsInVirtualTree.value,
          };
          numberOfWordsInVirtualTree.value += 1;
        }
        return EXIT;
      });
      nodesToReverseAfterReassignment.forEach(({ nodeA, nodeB }) => {
        swapNodes(nodeA, nodeB);
      });
    }
  }

  function splitWordIntoTwoWords() {
    const treeToSearch = virtualTree.value;
    visit(
      treeToSearch,
      node => node.currentPosition === selectedNodeIndex.value,
      (currentWord, currentWordIndexInVisitor, parent) => {
        const currentNodeId = currentWord.attributes?.[XML_ID];
        // NOTE: if splitting a non-final sub-word into two more sub-words,
        // then you should first search the parent Node for all w nodes with
        // the same id.wordId as the current sub-word, and then reassign all
        // the final (13th) digits of the @id to be ordered properly and reflect
        // the correct sub-word breakdown.
        const currentWordFirstHalfSubwordId =
          currentNodeId?.length === 12
            ? 1 // If the word has a 12-digit id, it has no sub-word id, and the id can be 1
            : parseInt(currentNodeId?.substring(12), 10);
        // If there are only 12 chars, then the current word is not a sub-word,
        // but a word, and thus you can simply use 1; otherwise, use its id
        const currentWordCharacters = currentWord.children[0]?.value.split('');

        const firstHalfOfWord = currentWordCharacters.slice(0, currentlySelectedCharIndex.value);
        const secondHalfOfWord = currentWordCharacters.slice(currentlySelectedCharIndex.value);
        const firstHalfOfWordId = `${currentNodeId.substring(
          0,
          12,
        )}${currentWordFirstHalfSubwordId}`;
        const secondHalfOfWordId = `${currentNodeId.substring(0, 12)}${
          currentWordFirstHalfSubwordId + 1
        }`;

        const subWord1 = {
          type: 'element',
          name: 'w',
          attributes: {
            [XML_ID]: firstHalfOfWordId,
          },
          children: [
            {
              type: 'text',
              value: firstHalfOfWord.join(''),
            },
          ],
          originalPosition: currentWord.originalPosition,
          currentPosition: currentWord.currentPosition,
        };
        const subWord2 = {
          type: 'element',
          name: 'w',
          attributes: {
            [XML_ID]: secondHalfOfWordId,
          },
          children: [
            {
              type: 'text',
              value: secondHalfOfWord.join(''),
            },
          ],
          originalPosition: currentWord.originalPosition + 1,
          currentPosition: currentWord.currentPosition + 1,
        };

        const newArrayOfParentChildren = [...parent.children];
        newArrayOfParentChildren.splice(currentWordIndexInVisitor, 1, subWord1, subWord2);
        // NOTE: Reassign all parent child w nodes @n ids to be ordered properly
        // — this needs to happen AFTER the splice because there may be existing
        // sub-words that need new @n ids
        newArrayOfParentChildren.forEach((child, index) => {
          // NOTE: some of these sub-word ids could be null, for example if the
          // child were a punctuation node
          const childId = child.attributes?.[XML_ID];
          const childIsSubWord = childId && childId.length === 13;
          // NOTE: If the child is not a sub-word, just leave it with its existing 12-digit @n id

          if (childIsSubWord) {
            const subWordIdDigit = parseInt(childId.substring(12), 10);
            let counter = subWordIdDigit;
            if (subWordIdDigit === 9) {
              throw new Error(
                'Cannot currently split a single orthographic word from the original data into more than 8 sub-words', // NOTE: once an @n id is XXXXXXXXXX19 for example, it cannot spill over into word XXXXXXXXXX20, because then it would be a sub-word of the second word. This is a limitation of numerical ids.
              );
            }
            const previousSubWordId = newArrayOfParentChildren[index - 1]?.attributes?.[XML_ID];
            // NOTE: It should not matter if there is a preceding subword from the same
            // original word in another unit (i.e., not in parent.children),
            // because reassignment here begins at the current subword id digit
            // and only compares with an immediately previous subword which may be identical

            // TODO: refactor in some way to check the entire tree for any duplicate
            // subword ids in order to recognize subsequent ids that are duplicates
            // of the current subword id but not found in parent.children
            // (e.g., located in a subsequent word group)
            if (previousSubWordId && previousSubWordId === childId) {
              counter += 1;
            }
            const currentWordId = childId.substring(0, 12);
            // NOTE: If the child is not a sub-word originally from the currently selected word
            // (the currentNode in the context of the visitor function), just leave it with its
            // existing 12-digit @n id)
            if (currentWordId === currentNodeId.substring(0, 12)) {
              const newSubWordId = `${currentNodeId.substring(0, 12)}${counter}`;
              newArrayOfParentChildren[index].attributes.id = newSubWordId;
              // subWordIdDigit += 1; // NOTE: Because the counter is incremented at the end of
              // the loop, it cannot be allowed to go to 10, or else the counter will be 0 on
              // the next loop.
            }
          }
        });
        // eslint-disable-next-line no-param-reassign
        parent.children = newArrayOfParentChildren;
        reassignVirtualTreeWordPositions();
        return EXIT;
      },
    );
    shouldUpdateVirtualTree.value = true;
    toggleSubwordEditing();
  }

  function breakWord() {
    // 'space bar' *only when zoomed to a word*
    try {
      if (zoomToWord.value) {
        splitWordIntoTwoWords();
        dirtyEditorFlag.value = true;
      }
    } catch (error) {
      handleError(error);
    }
  }
  function handleControlSelection(control) {
    // lookup the correct method for each control string
    sentenceIsFocused.value = true;
    switch (control) {
      case 'left':
        left();
        break;
      case 'right':
        right();
        break;
      case 'new-line':
        brk();
        break;
      case 'indent':
        indent();
        break;
      case 'dedent':
        dedent();
        break;
      case 'toggle-subword-editing':
        toggleSubwordEditing();
        break;
      case 'split-word':
        breakWord();
        break;
      case 'backspace':
        join();
        break;
      case 'swap':
        swap();
        break;

      default:
        throw new Error(`Unknown control: ${control}`);
    }
  }

  function assignRole(roleLabel) {
    if (virtualTree.value) {
      visitParents(
        virtualTree.value,
        node => node.currentPosition === selectedNodeIndex.value,
        (wordNode, parents) => {
          try {
            if (wordNode.name === 'wg') {
              // eslint-disable-next-line no-param-reassign
              wordNode.attributes.role = roleLabel;
            }
            const firstAncestorWordGroup = parents[parents.length - 1];

            if (firstAncestorWordGroup && firstAncestorWordGroup.attributes.class === 'cl') {
              // eslint-disable-next-line no-param-reassign
              wordNode.attributes.role = roleLabel;
            } else if (firstAncestorWordGroup && firstAncestorWordGroup.name === 'wg') {
              firstAncestorWordGroup.attributes.role = roleLabel;
            } else {
              // eslint-disable-next-line no-param-reassign
              wordNode.attributes.role = roleLabel;
            }
            dirtyEditorFlag.value = true;
          } catch (error) {
            handleError(error);
          }
        },
      );
    }
  }
  function assignType(typeLabel) {
    if (virtualTree.value) {
      visitParents(
        virtualTree.value,
        node => node.currentPosition === selectedNodeIndex.value,
        (wordNode, parents) => {
          try {
            if (wordNode.name === 'wg') {
              // eslint-disable-next-line no-param-reassign
              wordNode.attributes.class = typeLabel;
            } else {
              const parentWordGroups = parents.filter(parent => parent.name === 'wg');
              const firstParentWordGroup = parentWordGroups[parentWordGroups.length - 1];
              if (firstParentWordGroup) {
                firstParentWordGroup.attributes.class = typeLabel;
              }
            }
            dirtyEditorFlag.value = true;
          } catch (error) {
            handleError(error);
          }
        },
      );
    }
  }

  function handleKeyDown(e, specifiedKey) {
    try {
      if (sentenceIsFocused.value) {
        const { code: keyPress } = e;
        const { key } = e;
        switch (keyPress) {
          case 'ArrowLeft':
            e.preventDefault();
            if (e.shiftKey) {
              swap();
              dirtyEditorFlag.value = true;
            } else {
              left();
            }
            break;
          case 'ArrowRight':
            e.preventDefault();
            if (e.shiftKey) {
              swap();
              dirtyEditorFlag.value = true;
            } else {
              right();
            }
            break;
          case 'ArrowUp':
            e.preventDefault();
            // up();
            break;
          case 'ArrowDown':
            e.preventDefault();
            // down();
            break;
          case 'Enter':
            if (e.altKey) {
              e.preventDefault();
              toggleSubwordEditing();
            } else if (zoomToWord.value) {
              e.preventDefault();
              breakWord();
            } else {
              e.preventDefault();
              brk();
            }
            break;
          case 'Backspace':
            e.preventDefault();
            join();
            break;
          case 'Tab':
            if (e.shiftKey) {
              e.preventDefault();
              dedent();
            } else {
              e.preventDefault();
              indent();
            }
            break;
          case 'Space':
            e.preventDefault();
            if (zoomToWord.value) {
              e.preventDefault();
              breakWord();
            }
            break;
          case 'Delete':
            e.preventDefault();
            join();
            break;
          case 'Escape':
            e.preventDefault();
            if (zoomToWord.value) {
              zoomToWord.value = false;
            }
            if (zoomToWord.value) {
              zoomToWord.value = false;
            }
            if (displayOptions.showDropdownMenu) {
              displayOptions.showDropdownMenu = false;
            }
            assignRole('☐');
            break;
          // SYNTAX-TYPE EDITING HOTKEYS
          default:
            if ((e.which > 47 && e.which < 91) || specifiedKey) {
              const assignmentKey = key || specifiedKey;
              // 48-57 (0-9) and 65-90 (a-z)
              const { label, unit } = hotkeys.value.find(
                hotkey =>
                  hotkey.label.toString() === assignmentKey ||
                  hotkey.key.toString() === assignmentKey,
              );
              if (label) {
                if (unit === 'class') {
                  e.preventDefault();
                  assignType(label);
                }
                if (unit === 'role') {
                  e.preventDefault();
                  assignRole(label);
                }
              }
            }
            break;
        }
      }
    } catch (error) {
      handleError(error);
    }
  }
  function persistVirtualTreeToLocalStorage() {
    // FIXME: when I edit, then save, then edit, then save, there is no timestamp
    isSaving.value = true;

    const timestamp = new Date();
    const date = timestamp.toLocaleDateString('en-US').split('/').join('-');
    const time = timestamp.toLocaleTimeString('en-US');

    const { externalId } = treedownNode.value.attributes;
    const treedownNodeMatchId = treedownNode.value?.osisRef || treedownNode.value?.tree?.osisRef;
    const updatedTreeReferenceTimestamp = `${treedownNodeMatchId}_${externalId}_${date}_${time}`;

    // currentReference is used to filter out the old copy of the current draft
    // This functionality will be handled by the API in the future
    let currentReference = treedownNode.value?.treeReference;
    if (!currentReference) {
      currentReference = updatedTreeReferenceTimestamp;
    }
    treeReference.value = currentReference;

    const currentSavedDrafts =
      JSON.parse(localStorage.getItem('savedDrafts'))?.filter(
        // Delete the old version of the current draft before updating timestamp
        tree => tree.treeReference !== treeReference.value,
      ) || [];

    const draftTree = {
      treeReference: updatedTreeReferenceTimestamp, // Update timestamp
      type: 'draft',
      tree: virtualTree.value,
    };
    currentSavedDrafts.push(draftTree);
    localStorage.setItem('savedDrafts', JSON.stringify(currentSavedDrafts));

    outputs.value.savedDrafts.value = currentSavedDrafts;
    // FIXME: here I am updating the treedownNode output so
    // that SavedTreesListWidget knows which tree is currently
    // being edited, but it seems like the wrong way to alert
    // another widget about a state update. It shouldn't be a
    // problem, however, because treedownNode is already current with
    // this widget's local tree state, and all that is being updated
    // is the treeReference property and any edits to the tree.
    outputs.value.treedownNode.value = draftTree;
    submit();

    dirtyEditorFlag.value = false;
    draftTreeFlag.value = true;
  }
  function resetTree() {
    selectedNodeIndex.value = 1;
    // Passing the reset = true flag will reset the tree based
    // on the originalTree that only updates when the treedownNode
    // input changes
    setXmlValueFromTreedownNodeInput();
    draftTreeFlag.value = false;
    dirtyEditorFlag.value = false;
  }
  function toggleDisplayOption(toggleValue) {
    displayOptions[toggleValue] = !displayOptions[toggleValue];
  }
  function handleClickLegendLabel(label) {
    sentenceIsFocused.value = true;
    document.getElementById('sentence-root').focus();
    handleKeyDown(new KeyboardEvent('keydown'), label); // NOTE: call this method with a dummy keydown event
  }
  function downloadTree() {
    const treeToDownload = JSON.parse(JSON.stringify(virtualTree.value));
    const cleanedTree = mapXML(treeToDownload, node => {
      const newNode = { ...node };
      if (node.currentPosition) {
        delete newNode.currentPosition;
      }
      if (node.depth) {
        delete newNode.depth;
      }
      return newNode;
    });
    const virtualTreeXML = toXml(cleanedTree);
    const blob = new Blob([virtualTreeXML], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    const { externalId } = cleanedTree.attributes;
    const treedownNodeMatchId = cleanedTree?.osisRef || cleanedTree?.tree?.osisRef;
    const filename = treeReference.value
      ? `${treeReference.value}.xml`
      : `${treedownNodeMatchId}_${externalId}.xml`;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }

  function handleNewLabel(label) {
    if (!hotkeys.value.find(hotkey => hotkey.label === label)) {
      syntaxTypes?.value.roles.push(label);
      const newHotkeys = getHotkeysFromLabels(configuration.syntaxTypes);
      hotkeys.value = newHotkeys;
    }
  }

  /** WATCHERS */

  watch(xml, newData => {
    if (newData && xml.value) {
      try {
        // xml.value is updated whenever treedownNode input is changed
        // this watcher sets a copy of the original tree to be used for resetting the tree
        originalTree.value = xml.value;
      } catch (e) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        encounteredError.value = true;
        // NOTE: this keeps the app from crashing right now when it encounters invalid XML
        return undefined;
      }
    }
    return null;
  });

  watch(originalTree, newTree => {
    if (newTree) {
      virtualTree.value = setupVirtualTree(true);
      // Reset the baseline tree when the original tree changes.
      baselineTree.value = JSON.parse(JSON.stringify(virtualTree.value));
    }
  });

  watch(encounteredError, becomesTrue => {
    if (becomesTrue) {
      setTimeout(() => {
        encounteredError.value = false;
      }, 100);
    }
  });
  watch(isSaving, becomesTrue => {
    if (becomesTrue) {
      // Reset the baseline tree when saving changes.
      setTimeout(() => {
        isSaving.value = false;
        dirtyEditorFlag.value = false;
      }, 100);
    }
  });
  watch(displayOptions.showAnalysis, newValue => {
    if (newValue) {
      virtualTree.value = setupVirtualTree();
    }
  });
  watch(displayOptions.showWordGroups, newValue => {
    if (newValue) {
      virtualTree.value = setupVirtualTree();
    }
  });
  watch(displayOptions.showClauses, newValue => {
    if (newValue) {
      virtualTree.value = setupVirtualTree();
    }
  });
  watch(shouldUpdateVirtualTree, newValue => {
    if (newValue) {
      virtualTree.value = setupVirtualTree();
      shouldUpdateVirtualTree.value = false;
    }
  });
  watch(selectedNodeIndex, newValue => {
    if (numberOfNodesInVirtualTree.value > 0) {
      if (newValue < 1) {
        selectedNodeIndex.value = 1;
      }
      if (newValue > numberOfNodesInVirtualTree.value) {
        selectedNodeIndex.value = numberOfNodesInVirtualTree.value;
      }
    }
  });
  watch(sentenceIsFocused, newValue => {
    if (!newValue) {
      // if the sentence is not focused, cancel sub-word editing
      zoomToWord.value = false;
    }
  });
  watch(treedownNode, () => {
    if (treedownNode.value) {
      const { type } = treedownNode.value;
      if (type === 'element') {
        // The tree has been passed directly and it is not a draft
        draftTreeFlag.value = false;
      } else if (type === 'draft') {
        // The tree has been passed as a draft
        treeReference.value = treedownNode.value?.treeReference;
        draftTreeFlag.value = true;
      } else {
        handleError('Invalid treedown node passed to the editor.');
      }
      selectedNodeIndex.value = 1;
      dirtyEditorFlag.value = false;
      setXmlValueFromTreedownNodeInput();
    }
  });

  function checkForDirtyEditor() {
    const baselineTreeString = JSON.stringify(baselineTree.value);
    const virtualTreeString = JSON.stringify(virtualTree.value);
    if (baselineTreeString !== virtualTreeString) {
      dirtyEditorFlag.value = true;
    }
  }
  watch(virtualTree, () => {
    checkForDirtyEditor();
  });
</script>

<template>
  <div id="tree-editor-container" v-if="treedownNode">
    <div class="ui-container">
      <DisplayTogglesMenu
        :expanded-menu="true"
        @toggle-display-option="toggleDisplayOption"
        @persist-virtual-tree-to-local-storage="persistVirtualTreeToLocalStorage"
        @download-tree="downloadTree"
        @reset-tree="resetTree"
        :displayOptions="displayOptions"
      />
    </div>
    <Legend
      v-if="displayOptions.showAnalysis"
      :types="syntaxTypes"
      :hotkeys="hotkeys"
      @assign-label="handleClickLegendLabel"
      @new-label="handleNewLabel"
    />
    <Controls v-if="displayOptions.showControls" @control="handleControlSelection" />
    <div v-show="isLoading">
      <UILoading />
    </div>
    <div class="floating-notifications">
      <div v-if="dirtyEditorFlag" class="dirty-editor floating-notification">
        <div class="floating-notification__text">
          <font-awesome-icon icon="fa-solid fa-exclamation-triangle" />
          Warning: You have unsaved changes.
        </div>
        <div class="floating-notification__buttons">
          <span class="ui-button" @click="resetTree">
            <font-awesome-icon icon="fa-solid fa-undo" />
            Reset to last saved version
          </span>
          <span class="ui-button" @click="persistVirtualTreeToLocalStorage">
            <font-awesome-icon icon="fa-solid fa-save" />
            Save draft
          </span>
        </div>
      </div>
      <div v-if="draftTreeFlag" class="draft-tree floating-notification">
        <div class="floating-notification__text">Draft version</div>
      </div>
    </div>
    <div
      v-if="virtualTree && !isLoading"
      id="sentence-root"
      :class="[
        { errorNotification: encounteredError },
        { isSaving: isSaving },
        { rtl: languageDirectionIsRTL },
        { focusOnSubword: zoomToWord },
      ]"
      tabindex="0"
      :key="treedownNode?.timestamp"
      v-autofocusForKeyBindings
      @focus="sentenceIsFocused = true"
      @blur="sentenceIsFocused = false"
      @keydown.stop="handleKeyDown"
    >
      <TreeNode
        :node="virtualTree"
        :showAnalysis="displayOptions.showAnalysis"
        :showWordGroups="displayOptions.showWordGroups"
        :showClauses="displayOptions.showClauses"
        :showChildren="true"
        :showMilestones="displayOptions.showMilestones"
        :selectedNodeIndex="selectedNodeIndex"
        :parentAttributes="{ class: 'root' }"
        :grandParentAttributes="{ class: 'root' }"
        :languageDirectionIsRTL="languageDirectionIsRTL"
        :sentenceIsFocused="sentenceIsFocused"
        :zoomToWord="zoomToWord"
        :currentlySelectedCharIndex="currentlySelectedCharIndex"
        :showRules="displayOptions.showRules"
        :showEnglish="displayOptions.showEnglish"
        :showTransliteration="displayOptions.showTransliteration"
      />
    </div>
  </div>
  <EmptyMessage v-else>
    Select a Treedown Node in the Tree Query Widget or Sentences Query List Widget
  </EmptyMessage>
</template>

<style scoped lang="scss">
  #sentence-root {
    font-size: 2em;
    max-width: 98%;
    line-height: 1.5;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 1em;
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.6em;
  }
  .ui-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    line-height: 1.5;
    margin-bottom: 1em;
  }
  .rtl {
    direction: rtl;
    font-size: 2.2em;
    font-family: 'SBLBibLit';
  }
  #sentence-root:focus {
    outline: none;
  }
  .focusOnSubword {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5em;
  }
  .errorNotification {
    background-color: #ffcdd2;
    transition: background-color 0.25s ease;
  }
  .isSaving {
    background-color: #b2ebf2;
    transition: background-color 0.25s ease;
  }
  #save-icon {
    font-size: 1em;
    /** shorter top and bottom margins and some right margin */
    margin-right: 0.5em;
  }

  /** From tree-edit app file */
  .button {
    border-radius: 5px;
    font-size: 0.8em;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0.5em 1em;
    user-select: none;
    border: none;
    /* display: inline-block; */
    /* vertical-align: middle; */
    overflow: hidden;
    /* text-align: center; */
    cursor: pointer;
    white-space: nowrap;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.984);
    margin: 0 0.5em;
    content: '';
  }

  .goButton {
    background-color: #04aa6d !important;
  }
  .goButton:hover {
    background-color: #039c64 !important;
  }

  .stopButton {
    background-color: #ff0000 !important;
    padding: 0.5em 1em;
  }
  .stopButton::after {
    content: 'X';
  }
  .stopButton:hover::after {
    content: 'Delete saved tree';
    padding: 0.5em 1em;
    transition: all 0.2s ease-in-out;
  }
  .ui-button {
    font-size: 1em;
    padding: 0.5em 1em;
    margin: 0.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 10pt;
    background-color: #eee;
    text-transform: uppercase;
    line-height: 1.2em; // Line height keeps emojis from expanding button height
  }
  .ui-button:hover {
    background-color: #ddd;
  }
  input {
    font-family: monospace;
    font-size: 1.2em;
    background-color: white;
    padding: 0.2em 0.5em;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
  }
  #tree-editor-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    line-height: 1.5;
    margin-bottom: 1em;
  }
  .floating-notifications {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1000;
    margin: 1em;
    align-content: flex-end;
    align-self: flex-end;
    .floating-notification {
      display: flex;
      justify-content: space-between;
      align-self: flex-end;
      align-items: center;
      padding: 0.5em 1.5em;
      margin: 0.5em;
      border-radius: 5px;
      font-size: 0.8em;
      > .floating-notification__text {
        font-weight: bold;
      }
      > .floating-notification__buttons {
        display: flex;
        flex-direction: row;
        cursor: pointer;
      }
      &.dirty-editor {
        background-color: #ffcdd2;
        display: flex;
        flex-direction: column;
        /* max-width: 100%; */
      }
      &.draft-tree {
        background-color: #b2ebf2;
      }
    }
  }
  .no-data {
    font-size: 1.2em;
    padding: 1em;
  }
</style>
