<template>
  <div class="controls">
    <span class="toggle" :class="{ active: toggleState }" @click="flipToggle">
      {{ toggleName }}: {{ toggleState ? 'On' : 'Off' }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'toggle',
    props: {
      toggleState: Boolean,
      toggleName: String,
    },
    methods: {
      flipToggle() {
        this.$emit('toggledState', !this.toggleState);
      },
    },
  };
</script>

<style scoped lang="scss">
  .controls {
    text-align: end;
    margin-top: 1em;
  }
  span.toggle {
    display: inline-block;
    cursor: pointer;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 0px 10px;
    &.active {
      font-weight: bold;
    }
  }
</style>
