<template>
  <div @click="clickHandler">
    <TokenProvider :tokens="tokens">
      <EmptyMessage v-if="this.canDoQuery">
        Create a query with the Word Query Widget to search for a word
      </EmptyMessage>
      <div v-else>
        <div class="query-title">
          <span>Found {{ totalCount }} Results: </span>
          <span
            v-for="(value, key) in queryParams"
            class="param-badge"
            :title="getWordTokenVerboseName(key)"
            :key="key"
          >
            {{ value }}</span
          >
          <button @click.prevent="share" title="Share query" class="icon-button share">
            <font-awesome-icon icon="fa-solid fa-share-nodes" />
          </button>
        </div>
        <div class="show-context-container">
          <label>
            <input type="checkbox" v-model="showContext" />
            <span>Show context</span>
          </label>
        </div>
        <!-- pagination -->
        <Pagination
          :numberOfPagesLinks="5"
          :currentPage="currentPage"
          :pageCount="pageCount"
          @selectPage="selectPage"
        />
        <!-- show results -->
        <LoadingSpinner v-if="$apollo.loading" />
        <div v-else-if="errorMessage">{{ errorMessage }}</div>
        <div v-else-if="wordTokens && wordTokens.length > 0" class="table">
          <div v-for="entry in wordTokens" :key="entry" class="table-row">
            <div class="table-cell">
              <button @click.prevent="selectReference(entry.osisRef)" class="verse-ref-button">
                {{ entry.friendlyRef }}
              </button>
            </div>
            <div class="table-cell">
              <Verse v-if="showContext" :tokenIds="entry.tokenIds" />
              <div v-else>
                <Token :tokenId="entry.id" />
              </div>
            </div>
          </div>
        </div>
        <EmptyMessage v-else>No results</EmptyMessage>
      </div>
      <!-- pagination -->
      <Pagination
        :numberOfPagesLinks="5"
        :currentPage="currentPage"
        :pageCount="pageCount"
        @selectPage="selectPage"
      />
    </TokenProvider>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import formatOsis, { paratextToOsis } from 'bible-reference-formatter';
  import mixins from '@/mixins';
  import wordTokenFields from '@/symphony/shared/wordTokenFields';
  import { DEFAULT_OSIS_REF, TOKEN_ID_FIELD, APOLLO_PAGE_SIZE } from '@/store/constants';
  import TokenProvider from './tokens/TokenProvider.vue';
  import Verse from './word-query-results-components/Verse.vue';
  import Token from './tokens/Token.vue';
  import Pagination from './word-query-results-components/Pagination.vue';
  import LoadingSpinner from './components/LoadingSpinner.vue';
  import EmptyMessage from './components/EmptyMessage.vue';

  const wordTokenKeys = new Set(wordTokenFields.map(field => field.name));
  // Not technically a word token, but we need to grab it from the query string.
  wordTokenKeys.add('passageReference');

  let initialLoad = true;

  export default {
    name: 'WordQueryResultsWidget',
    mixins: [mixins.OutputsMixin],
    data() {
      return {
        queryParams: {},
        showContext: true,
        errorMessage: null,
        tokens: [],
        currentPage: 0,
        totalCount: 0,
      };
    },
    props: {
      doric: {
        outputs: {
          osisRef: DEFAULT_OSIS_REF,
          selectedLemma: null,
          selectedTokenIds: null,
        },
      },
    },
    apollo: {
      wordTokens: {
        query: gql`
          query WordTokens($pagination: OffsetPaginationInput, $filters: WordTokenFilter) {
            paginatedWordTokens(pagination: $pagination, filters: $filters) {
              results {
                id
                ref
                lemma
                wordValue
                verse {
                  tokens {
                    id
                    ref
                    lemma
                    value
                  }
                }
              }
              totalCount
            }
          }
        `,
        variables() {
          const { passageReference, ...filters } = this.queryParams;
          if (passageReference) {
            filters.scriptureReference = {
              usfmRef: passageReference,
            };
          }
          return {
            filters,
            pagination: {
              limit: APOLLO_PAGE_SIZE,
              offset: this.currentPage * APOLLO_PAGE_SIZE,
            },
          };
        },
        skip() {
          return this.canDoQuery;
        },
        update(data) {
          this.errorMessage = null;
          const { results } = data.paginatedWordTokens;
          const resultsIds = new Set(results.map(result => result[TOKEN_ID_FIELD]));
          this.tokens = results
            .map(entry => [
              {
                tokenId: entry[TOKEN_ID_FIELD],
                data: {
                  ref: entry.ref,
                  value: entry.wordValue,
                  lemma: entry.lemma,
                },
              },
              ...entry.verse.tokens.map(verseToken => ({
                tokenId: verseToken[TOKEN_ID_FIELD],
                data: {
                  ref: verseToken.ref,
                  value: verseToken.value,
                  lemma: verseToken.lemma,
                },
                majorUnderline: resultsIds.has(verseToken[TOKEN_ID_FIELD]) ? 'paleturquoise' : null,
              })),
            ])
            .flat();

          this.totalCount = data.paginatedWordTokens.totalCount;
          // if the currentPage does not exist, set it to the last page
          if (this.pageCount === 0) {
            this.currentPage = 0;
          } else if (this.currentPage < 0) {
            this.currentPage = 0;
          } else if (this.currentPage >= this.pageCount) {
            this.currentPage = this.pageCount - 1;
          }

          return data.paginatedWordTokens.results.map(entry => {
            const osisRef = paratextToOsis(entry.ref.split('!')[0]);
            return {
              id: entry[TOKEN_ID_FIELD],
              osisRef,
              friendlyRef: formatOsis('niv-short', osisRef),
              tokenIds: entry.verse.tokens.map(t => t[TOKEN_ID_FIELD]),
            };
          });
        },
        error(error) {
          this.errorMessage = error.message;
          console.error(error);
        },
      },
    },
    computed: {
      canDoQuery() {
        return (
          // Prevent query if no params are set
          !Object.keys(this.queryParams).length ||
          // Prevent query if the only param set is the passageReference
          (Object.keys(this.queryParams).length === 1 && 'passageReference' in this.queryParams)
        );
      },
      pageCount() {
        return Math.ceil(this.totalCount / APOLLO_PAGE_SIZE);
      },
    },
    watch: {
      $route: {
        handler(routeParams) {
          const { query } = routeParams;
          if (query.resultsPage) {
            try {
              const pageNumber = parseInt(query.resultsPage, 10) - 1;
              this.currentPage = pageNumber > 0 ? pageNumber : 0;
            } catch (error) {
              console.error('Failed to parse page number from query string.');
            }
          }

          // Get any query params that are in the wordTokenFields list.
          const queryPairs = Object.fromEntries(
            Object.keys(query)
              .filter(key => wordTokenKeys.has(key))
              .map(key => [key, query[key]]),
          );

          if (Object.keys(queryPairs).length === 0) {
            console.warn('Route changed, but not searching: No query parameters.');
            return;
          }

          // Check whether all kv pairs are the same as the current query.
          if (
            Object.keys(queryPairs).length === Object.keys(this.queryParams).length &&
            Object.entries(queryPairs).every(([key, value]) => this.queryParams[key] === value)
          ) {
            console.warn('Route changed, but not searching: Query parameters are the same.');
            return;
          }

          // Reset the page number to 0 if the query params have changed
          // except for the initial load, which should keep the page number.
          if (initialLoad) {
            initialLoad = false;
          } else {
            this.selectPage(0);
          }
          this.queryParams = queryPairs;
        },
        immediate: true,
      },
    },
    methods: {
      clickHandler(event) {
        const $token = event.target.closest('.token-container');
        if (!$token) {
          return;
        }
        const { tokenId } = $token.dataset;

        const clickedToken = this.tokens.find(token => token.tokenId === tokenId);
        this.outputs.selectedLemma.value = clickedToken.data.lemma;
        this.outputs.selectedTokenIds.value = JSON.stringify([tokenId]);
        this.submit();

        this.tokens = this.tokens.map(token => ({
          ...token,
          backgroundColor: token.tokenId === tokenId ? 'yellow' : null,
        }));
      },
      selectReference(osisRef) {
        this.outputs.osisRef.value = osisRef;
        this.submit();
      },
      getWordTokenVerboseName(key) {
        if (key === 'passageReference') {
          return 'Passage Reference';
        }
        return wordTokenFields.find(field => field.name === key)?.verboseName || key;
      },
      share() {
        const activeWorkspaceId = this.$store.getters.getActiveWorkspaceId;
        const url = new URL(window.location.href);
        // Ensure that active workspace is set in the url
        url.searchParams.set('workspace', activeWorkspaceId);
        navigator.clipboard.writeText(url.toString()).then(
          () => {
            /* clipboard successfully set */
            // TODO: Use better UI than alert
            console.log('Success: Copied URL to clipboard.');
            // eslint-disable-next-line no-alert
            alert('Copied URL to clipboard!');
          },
          () => {
            /* clipboard write failed */
            console.error('Failed to copy URL to clipboard.');
          },
        );
      },
      selectPage(page) {
        this.currentPage = page;
        this.$router.replace({
          query: {
            ...this.$route.query,
            resultsPage: page + 1,
          },
        });
      },
    },
    components: {
      TokenProvider,
      Token,
      Verse,
      Pagination,
      LoadingSpinner,
      EmptyMessage,
    },
  };
</script>

<style scoped lang="scss">
  .query-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    span {
      margin: 0.25rem;
      font-weight: bold;
    }
    .param-badge {
      position: relative;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      background-color: #eee;
      font-size: small;
      cursor: default;
      &::after {
        content: ' ';
        position: absolute;
        display: block;
        bottom: 0;
        height: 0.1rem;
        left: 15%;
        right: 15%;
        background-color: blue;
        border-radius: 0.2rem;
      }
    }
    .share {
      margin-right: 0.5rem;
    }
  }
  .table {
    display: table;
    box-sizing: border-box;
    width: 100%;
    margin: 0.25rem;
    border-collapse: collapse;
    border-spacing: 0 !important;
    .table-row {
      display: table-row;
      .table-cell {
        padding: 0.1rem 0.2rem;
        display: table-cell;
        &:first-child {
          width: 10%;
          padding-left: 0;
        }
        button {
          white-space: nowrap;
        }
      }
    }
  }
  .show-context-container {
    margin: 0.5rem 0;
  }
  button {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    cursor: pointer;
    &.verse-ref-button {
      width: 100%;
      padding: 0.25rem 0.5rem;
    }
    &:hover {
      background-color: #ddd;
      border-color: #999;
    }
  }
  label {
    user-select: none;
  }
  .icon-button {
    display: flex;
    margin: 0.25rem;
    height: 100%;
    box-sizing: border-box;
    aspect-ratio: 1;
    border: 1px solid #ccc;
    border-radius: 0.2rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &:hover {
      border-color: #999;
      color: blue;
    }
  }
</style>
