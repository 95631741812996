<template>
  <div>
    <LoadingSpinner v-if="customApolloIsLoading" />
    <TokenProvider :tokens="tokens">
      <NoInstanceHelper
        v-if="mode === modes.NO_ANNOTATION_SET"
        :instanceList="annotationSets"
        :instanceName="'Annotation Set'"
        :emptyMessage="'You have not selected an Annotation Set'"
        @selectInstance="selectAnnotationSet"
        @createInstance="mode = modes.CREATE_ANNOTATION_SET"
      />
      <CreateAnnotationSet
        v-if="mode === modes.CREATE_ANNOTATION_SET"
        :fieldErrors="createAnnotationSetErrors"
        @createAnnotationSet="createAnnotationSet"
        @back="mode = modes.NO_ANNOTATION_SET"
      />
      <NoInstanceHelper
        v-if="mode === modes.NO_ANNOTATION_FEATURE"
        :instanceList="annotationFeatures"
        :instanceName="'Annotation Feature'"
        :emptyMessage="'You have not selected an Annotation Feature'"
        @selectInstance="selectAnnotationFeature"
        @createInstance="mode = modes.CREATE_ANNOTATION_FEATURE"
        @back="mode = modes.NO_ANNOTATION_SET"
      />
      <CreateAnnotationFeature
        v-if="mode === modes.CREATE_ANNOTATION_FEATURE"
        :fieldErrors="createAnnotationSetErrors"
        @createAnnotationFeature="createAnnotationFeature"
        @back="mode = modes.NO_ANNOTATION_FEATURE"
      />
      <NoInstanceHelper
        v-if="mode === modes.NO_ROOT_INSTANCE"
        :instanceList="rootInstances"
        :instanceName="'Root Annotation Instance'"
        :emptyMessage="'You have not selected a Root Instance'"
        @selectInstance="selectRootAnnotationInstance"
        @createInstance="mode = modes.CREATE_ROOT_INSTANCE"
        @deleteInstance="deleteInstance"
        @back="mode = modes.NO_ANNOTATION_FEATURE"
      />
      <CreateRootInstance
        v-if="mode === modes.CREATE_ROOT_INSTANCE"
        :feature="annotationFeatures.find(f => f.uri === this.currentAnnotationFeature)"
        @createRootInstance="createRootInstance"
        @back="mode = modes.NO_ROOT_INSTANCE"
      />
      <TreeBuilder
        v-if="mode === modes.TREE_BUILDER"
        :rtl="isRTL"
        :currentInstanceUri="currentInstanceUri"
        :annotationSetUri="currentAnnotationSetUri"
        :annotationFeatures="annotationFeatures"
        :proposedTokenIds="parsedSelectedTokenIds"
        :activeInstanceUri="activeInstanceUri"
        @setCurrentInstanceUri="setCurrentInstanceUri"
        @back="goBackFromTreeBuilder"
        @setHoveredUri="setHoveredUri"
        @setOsisRefRange="setOsisRefRange"
        @deselectTokens="deselectTokens"
      />
    </TokenProvider>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import mixins from '@/mixins';
  import { determineLanguageDirectionForOsisRef } from '@/common/refUtils';
  import {
    DEFAULT_GROUP_KEY,
    DORIC_VARIABLE_UNSET,
    TOKEN_ID_FIELD,
    APOLLO_PAGE_SIZE,
    APOLLO_MAX_TOKENS,
    APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
    APOLLO_QUERY_FOR_ANNOTATION_SETS,
    APOLLO_QUERY_FOR_ANNOTATION_FEATURES,
    APOLLO_QUERY_FOR_ANNOTATION_INSTANCES,
  } from '@/store/constants';
  import TokenProvider from '../tokens/TokenProvider.vue';
  import NoInstanceHelper from './discourse-flow/NoInstanceHelper.vue';
  import CreateAnnotationSet from './discourse-flow/CreateAnnotationSet.vue';
  import CreateAnnotationFeature from './discourse-flow/CreateAnnotationFeature.vue';
  import CreateRootInstance from './discourse-flow/CreateRootInstance.vue';
  import TreeBuilder from './discourse-flow/TreeBuilder.vue';
  import LoadingSpinner from '../components/LoadingSpinner.vue';

  const NAMESPACED_LOCAL_STORAGE_KEY_FOR_CURRENT_URI = 'RDFW.currentUri';

  const MODES = {
    NO_ANNOTATION_SET: 'NO_ANNOTATION_SET',
    CREATE_ANNOTATION_SET: 'CREATE_ANNOTATION_SET',
    NO_ANNOTATION_FEATURE: 'NO_ANNOTATION_FEATURE',
    CREATE_ANNOTATION_FEATURE: 'CREATE_ANNOTATION_FEATURE',
    NO_ROOT_INSTANCE: 'NO_ROOT_INSTANCE',
    CREATE_ROOT_INSTANCE: 'CREATE_ROOT_INSTANCE',
    TREE_BUILDER: 'TREE_BUILDER',
    EDIT_INSTANCE: 'EDIT_INSTANCE',
  };

  export default {
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          selectedTokenIds: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'selectedTokenIds',
          },
          osisRefRange: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'osisRefRange',
          },
          osisRef: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'osisRef',
          },
          activeInstanceUri: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'activeInstanceUri',
          },
        },
        outputs: {
          hoveredUri: DORIC_VARIABLE_UNSET,
          osisRef: DORIC_VARIABLE_UNSET,
          osisRefRange: DORIC_VARIABLE_UNSET,
          focusedInstanceUri: DORIC_VARIABLE_UNSET,
          selectedTokenIds: DORIC_VARIABLE_UNSET,
          parentAnnotationInstanceUri: DORIC_VARIABLE_UNSET,
          currentAnnotationSetUri: DORIC_VARIABLE_UNSET,
        },
      },
    },
    computed: {
      parsedSelectedTokenIds() {
        try {
          return JSON.parse(this.selectedTokenIds);
        } catch (e) {
          return [];
        }
      },
      parsedOsisRefRange() {
        try {
          return JSON.parse(this.osisRefRange);
        } catch (e) {
          return {};
        }
      },
      rootInstance() {
        return this.rootInstances?.find(i => i.uri === this.currentRootInstance);
      },
      customApolloIsLoading() {
        // We don't care about whether tokens are loading.
        // So we exclude `this.$apollo.queries.tokens.loading` below:
        return (
          this.$apollo.queries.annotationSets.loading ||
          this.$apollo.queries.annotationFeatures.loading ||
          this.$apollo.queries.rootInstances.loading
        );
      },
      tokens() {
        return (this.selectedTokens || []).concat(this.impliedTokens || []);
      },
      isRTL() {
        const direction = determineLanguageDirectionForOsisRef(this.osisRef);
        return direction === 'rtl';
      },
    },
    watch: {
      osisRef: {
        handler() {
          this.outputs.osisRef.value = this.osisRef;
        },
        immediate: true,
      },
      osisRefRange: {
        handler() {
          this.outputs.osisRefRange.value = this.osisRefRange;
        },
        immediate: true,
      },
      selectedTokenIds: {
        handler() {
          this.outputs.selectedTokenIds.value = this.selectedTokenIds;
        },
        immediate: true,
      },
      currentInstance: {
        handler(newValue) {
          if (newValue && !this.annotationSetUri) {
            this.currentAnnotationSetUri = newValue.annotationSet.uri;
          }
        },
        immediate: true,
      },
    },
    data() {
      return {
        mode: MODES.NO_ANNOTATION_SET,
        modes: Object.freeze(MODES),
        createAnnotationSetErrors: [],
        currentAnnotationSetUri: null,
        currentAnnotationFeature: null,
        currentRootInstance: null,
        currentInstanceUri: null,
      };
    },
    mounted() {
      // check route params for current uri
      if (this.$route.query.rdfwUri) {
        this.setCurrentInstanceUri(this.$route.query.rdfwUri);
        this.mode = MODES.TREE_BUILDER;
        return;
      }
      const currentUri = localStorage.getItem(NAMESPACED_LOCAL_STORAGE_KEY_FOR_CURRENT_URI);
      if (currentUri) {
        this.setCurrentInstanceUri(currentUri);
        this.mode = MODES.TREE_BUILDER;
      }
    },
    apollo: {
      annotationSets: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_SETS} {
            annotationSets {
              id
              label
              uri
            }
          }
        `,
        update(data) {
          return data.annotationSets;
        },
      },
      // NOTE: This is required to fire off the annotationFeatures
      // query.
      currentInstance: {
        query: gql`
          query CurrentInstance($uri: String!) {
            annotations(filters: { uri: { exact: $uri } }) {
              id
              annotationSet {
                id
                uri
              }
            }
          }
        `,
        variables() {
          return { uri: this.currentInstanceUri };
        },
        update(data) {
          return data.annotations[0];
        },
        skip() {
          return !this.currentInstanceUri || this.currentAnnotationSetUri;
        },
      },
      annotationFeatures: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_FEATURES}($annotationSet: String!) {
            annotationFeatures(filters: { annotationSet: { uri: { exact: $annotationSet } } }) {
              id
              label
              uri
            }
          }
        `,
        fetchPolicy: APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
        skip() {
          return !this.currentAnnotationSetUri;
        },
        variables() {
          return { annotationSet: this.currentAnnotationSetUri };
        },
        update(data) {
          return data.annotationFeatures;
        },
      },
      rootInstances: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_INSTANCES}(
            $annotationSetUri: String!
            $annotationFeatureUri: String!
          ) {
            annotations(
              filters: {
                depth: 1
                annotationSetUri: $annotationSetUri
                featureUri: $annotationFeatureUri
              }
              pagination: { limit: ${APOLLO_PAGE_SIZE} }
            ) {
              id
              label
              uri
              children {
                uri
              }
            }
          }
        `,
        fetchPolicy: APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
        skip() {
          return !this.currentAnnotationSetUri || !this.currentAnnotationFeature;
        },
        variables() {
          return {
            annotationSetUri: this.currentAnnotationSetUri,
            annotationFeatureUri: this.currentAnnotationFeature,
          };
        },
        update(data) {
          return data.annotations;
        },
      },
      selectedTokens: {
        query: gql`
          query FeaturesAndTokens($ids: [ID!]!) {
            tokens: wordTokens(filters: { id: { inList: $ids } }, pagination: { limit: ${APOLLO_MAX_TOKENS} }) {
              id
              ref
              lemma
              value
              skipSpaceAfter
            }
          }
        `,
        variables() {
          return {
            ids: this.parsedSelectedTokenIds,
          };
        },
        skip() {
          return (
            !Array.isArray(this.parsedSelectedTokenIds) || this.parsedSelectedTokenIds.length === 0
          );
        },
        update(data) {
          return data.tokens.map(t => ({
            tokenId: t[TOKEN_ID_FIELD],
            data: {
              ref: t.ref,
              lemma: t.lemma,
              value: t.value,
              skipSpaceAfter: t.skipSpaceAfter,
            },
          }));
        },
      },
      impliedTokens: {
        query: gql`
          query FeaturesAndTokens($uri: String!) {
            annotations(filters: { uri: { exact: $uri } }, pagination: { limit: ${APOLLO_MAX_TOKENS} }) {
              impliedTokens {
                id
                lemma
                ref
                value
                skipSpaceAfter
              }
            }
          }
        `,
        variables() {
          return {
            uri: this.currentRootInstance,
          };
        },
        skip() {
          return !this.currentRootInstance;
        },
        update(data) {
          return data.annotations[0].impliedTokens.map(t => ({
            tokenId: t[TOKEN_ID_FIELD],
            data: {
              ref: t.ref,
              lemma: t.lemma,
              value: t.value,
              skipSpaceAfter: t.skipSpaceAfter,
            },
          }));
        },
      },
    },
    methods: {
      goBackFromTreeBuilder() {
        // because we jump straight to the tree builder if there is a currentUri,
        // we need to check whether we have an annotation set or feature selected before deciding which mode to go to
        if (this.currentAnnotationFeature) {
          this.mode = MODES.NO_ROOT_INSTANCE;
        } else if (this.currentAnnotationSetUri) {
          this.mode = MODES.NO_ANNOTATION_FEATURE;
        } else {
          this.mode = MODES.NO_ANNOTATION_SET;
        }
        this.setCurrentInstanceUri(null);
      },
      setCurrentInstanceUri(uri) {
        // Passing a falsy uri resets currentInstanceUri
        this.currentInstanceUri = uri || null;
        if (uri) {
          localStorage.setItem(NAMESPACED_LOCAL_STORAGE_KEY_FOR_CURRENT_URI, uri);
        } else {
          localStorage.removeItem(NAMESPACED_LOCAL_STORAGE_KEY_FOR_CURRENT_URI);
        }
        // Passing undefined to the query object removes the rdfwUri from the querystring.
        this.$router.push({
          query: {
            ...this.$route.query,
            rdfwUri: uri || undefined,
          },
        });
      },
      selectAnnotationSet(annotationSet) {
        this.currentAnnotationSetUri = annotationSet;
        this.mode = MODES.NO_ANNOTATION_FEATURE;
        this.outputs.currentAnnotationSetUri.value = annotationSet;
        this.submit();
      },
      selectAnnotationFeature(annotationFeature) {
        this.currentAnnotationFeature = annotationFeature;
        this.mode = MODES.NO_ROOT_INSTANCE;
      },
      selectRootAnnotationInstance(instanceUri) {
        this.currentRootInstance = instanceUri;
        this.setCurrentInstanceUri(instanceUri);
        this.mode = MODES.TREE_BUILDER;
        this.outputs.parentAnnotationInstanceUri.value = instanceUri;
        this.submit();
      },
      setHoveredUri(uri) {
        this.outputs.hoveredUri.value = uri;
        this.submit();
      },
      setOsisRefRange(osisRefRange) {
        this.outputs.osisRefRange.value = JSON.stringify(osisRefRange);
        this.outputs.osisRef.value = osisRefRange.start;
        this.submit();
      },
      deselectTokens() {
        this.outputs.selectedTokenIds.value = '[]';
        this.submit();
      },
      createAnnotationSet({ uri, label }) {
        const variables = {
          label,
        };
        if (uri) {
          variables.uri = uri;
        }
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateAnnotationSet($label: String!, $uri: String) {
                createAnnotationSet(label: $label, uri: $uri) {
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                  ... on AnnotationSet {
                    id
                    label
                    uri
                  }
                }
              }
            `,
            variables,
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_SETS],
          })
          .then(({ data }) => {
            // eslint-disable-next-line no-underscore-dangle
            if (data?.createAnnotationSet?.__typename === 'OperationInfo') {
              console.error('createAnnotationSet error:', data);
              this.createAnnotationSetErrors = data.createAnnotationSet.messages.map(
                ({ field, message }) => ({
                  field,
                  message,
                }),
              );
              return;
            }
            this.selectAnnotationSet(data.createAnnotationSet.uri);
          })
          .catch(error => {
            this.createAnnotationSetErrors = [{ error }]; // We don't know what but something went wrong
          });
      },
      createAnnotationFeature({ uri, label }) {
        if (!this.currentAnnotationSetUri) {
          console.error("Couldn't create feature: No currentAnnotationSetUri");
          return;
        }
        const variables = {
          annotationSetUri: this.currentAnnotationSetUri,
          label,
        };
        if (uri) {
          variables.uri = uri;
        }
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateAnnotationFeature(
                $annotationSetUri: String!
                $label: String!
                $uri: String
              ) {
                createAnnotationFeature(
                  annotationSetUri: $annotationSetUri
                  label: $label
                  uri: $uri
                ) {
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                  ... on AnnotationFeature {
                    id
                    label
                    uri
                  }
                }
              }
            `,
            variables,
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_FEATURES],
          })
          .then(({ data }) => {
            // eslint-disable-next-line no-underscore-dangle
            if (data?.createAnnotationFeature?.__typename === 'OperationInfo') {
              console.error('createAnnotationFeature error:', data);
              this.createAnnotationSetErrors = data.createAnnotationFeature.messages.map(
                ({ field, message }) => ({
                  field,
                  message,
                }),
              );
              return;
            }
            this.selectAnnotationFeature(data.createAnnotationFeature.uri);
          })
          .catch(error => {
            this.createAnnotationSetErrors = [{ error }]; // We don't know what but something went wrong
          });
      },
      createRootInstance({ label }) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($annotationFeatureUri: String!, $label: String!) {
                createAnnotation(annotationFeatureUri: $annotationFeatureUri, label: $label) {
                  __typename
                  ... on Annotation {
                    id
                    label
                    uri
                  }
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables: {
              annotationFeatureUri: this.currentAnnotationFeature,
              label,
            },
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_INSTANCES],
          })
          .then(({ data }) => {
            this.selectRootAnnotationInstance(data.createAnnotation.uri);
          })
          .catch(error => {
            console.error('There was an error sending the query', error);
          });
      },
      deleteInstance(id) {
        const instanceToDelete = this.rootInstances.find(i => i.id === id);
        if (!instanceToDelete) {
          console.error('Could not find instance to delete');
          return;
        }
        // eslint-disable-next-line no-alert
        const areYouSure = window.confirm(
          `Are you sure you want to delete this "${instanceToDelete.label}" annotation?`,
        );
        if (!areYouSure) return;
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!) {
                deleteAnnotation(id: $id) {
                  __typename
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables: {
              id,
            },
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_INSTANCES],
          })
          .then(({ data }) => {
            // eslint-disable-next-line no-underscore-dangle
            if (data.deleteAnnotation.__typename === 'OperationInfo') {
              console.error('deleteAnnotation error:', data);
              return;
            }
            this.mode = MODES.NO_ROOT_INSTANCE;
          })
          .catch(error => {
            console.error('There was an error sending the query', error);
          });
      },
    },
    components: {
      TokenProvider,
      NoInstanceHelper,
      CreateAnnotationSet,
      CreateAnnotationFeature,
      CreateRootInstance,
      TreeBuilder,
      LoadingSpinner,
    },
  };
</script>
