<template>
  <div class="interlinear">
    <div class="options-group">
      <div class="options-group-header">Base Text:</div>
      <div>
        <input v-model="baseText" type="checkbox" id="baseText" name="baseText" />
        <label for="baseText">{{ languageLabel }}</label>
      </div>
    </div>
    <div class="options-group">
      <div class="options-group-header">Glosses:</div>
      <span class="checkbox-container" v-for="gloss in glosses" :key="gloss.fieldName">
        <input
          class="gloss"
          type="checkbox"
          :id="gloss.fieldName"
          :name="gloss.fieldName"
          :checked="activeGlosses.has(gloss.fieldName) || false"
          @change="setGloss(gloss.fieldName)"
        />
        <label :for="gloss.Fieldname">{{ gloss.label }}</label>
      </span>
      <div class="toggles">
        <span class="toggle">
          <button @click.prevent="selectAllGlosses" id="allGlosses" name="allGlosses">On</button>
        </span>
        <span class="toggle">
          <button @click.prevent="clearAllGlosses" type="checkbox" id="noGlosses" name="noGlosses">
            Off
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  // TODO: Prefer typed values / named constants
  import mixins from '@/mixins';
  import { determineTestamentForOsisRef } from '@/common/refUtils';
  import { DEFAULT_GROUP_KEY } from '@/store/constants';

  import { HOT_GLOSSES, GNT_GLOSSES } from '@/symphony/shared/glosses';

  export default {
    name: 'InterlinearWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    data() {
      return {
        baseText: true,
        activeGlosses: new Set(),
      };
    },
    props: {
      doric: {
        inputs: {
          osisRef: {
            // groupKey: { type: String, default: 'default' },
            groupKey: DEFAULT_GROUP_KEY,
            // value: { type: String, default: 'osisRef' },
            value: null,
          },
        },
        // TODO: Define an API for setting
        // a default value; if this value differs
        // from the global value in the store, we would need to update the store
        // when the widget is initialzed.
        outputs: {
          // TODO: prefer Boolean
          showBaseText: 1,
          // TODO: prefer Map or Set
          selectedGlosses: '',
        },
      },
    },
    watch: {
      activeGlossArray: {
        handler() {
          this.syncInterlinear();
        },
      },
      baseText: {
        handler() {
          this.syncInterlinear();
        },
      },
    },
    methods: {
      // FIXME: Disabled
      // FIXME: Prevent bad state
      setInterlinear(options) {
        this.outputs.showBaseText.value = this.baseText ? 1 : '';
        this.outputs.selectedGlosses.value = options?.join('|') || '';
        this.submit();
      },
      setGloss(value) {
        const exists = this.activeGlosses.has(value);
        if (exists) {
          this.activeGlosses.delete(value);
        } else {
          this.activeGlosses.add(value);
        }
      },
      selectAllGlosses() {
        this.glosses.forEach(gloss => this.activeGlosses.add(gloss.fieldName));
      },
      clearAllGlosses() {
        this.activeGlosses.clear();
      },
      syncInterlinear() {
        const options = [...this.activeGlossArray];
        this.setInterlinear(options);
      },
    },
    computed: {
      // FIXME: activeGlosses is not reactive
      activeGlossArray() {
        const stack = [];
        this.activeGlosses.forEach(gloss => {
          if (this.availableGlosses.has(gloss)) {
            stack.push(gloss);
          }
        });
        return stack;
      },
      isGNT() {
        // TODO: Refactor this as passing the actual textualEdition
        if (this.osisRef) {
          const currentTestament = determineTestamentForOsisRef(this.osisRef);
          if (currentTestament === 'OT') {
            return false;
          }
        }
        // We default to true if `determineTestamentForOsisRef` returns anything unrecognized
        return true;
      },
      languageLabel() {
        return this.isGNT ? 'Greek' : 'Hebrew';
      },
      glosses() {
        return this.isGNT ? GNT_GLOSSES : HOT_GLOSSES;
      },
      availableGlosses() {
        return new Set(this.glosses.map(gloss => gloss.fieldName));
      },
    },
    mounted() {
      this.setInterlinear();
    },
  };
</script>

<style scoped lang="scss">
  span {
    cursor: pointer;
    &.active {
      font-weight: bold;
    }
  }
  .options-group {
    margin-bottom: 0.5rem;
    .options-group-header {
      margin-bottom: 0.5rem;
    }
  }
  .checkbox-container {
    margin-right: 0.5rem;
  }
  .toggles {
    margin: 0.5rem 0;
    > span {
      margin-left: 0.5rem;
    }
  }
</style>
