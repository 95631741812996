/*
Usage: import WORD_TOKEN_FIELDS from '@/symphony/shared/wordTokenFields';

name: GraphQL filter field name
verboseName: Human-friendly label for the field
description: Extracted from https://docs.google.com/document/d/1UQgu4Oyql_wnu-Dn_kGE_38ZqPW2czKMDfVARbBgXyA/edit#heading=h.4oedsxfdsoc4
*/
const FIELDS = [
  {
    name: 'ln',
    verboseName: 'Louw & Nida Number',
    description:
      'The @ln attribute contains the number of an entry in Louw & Nida, Johannes P. Louw and Eugene Albert Nida, Greek-English Lexicon of the New Testament Based on Semantic Domains (New York United Bible Societies, 1996)',
  },
  {
    name: 'ref',
    verboseName: 'USFM Ref',
    description:
      'The value of a @ref attribute you will recognize as a USFM identifier, with a bang (\u201c!\u201d) extension that gives the number of a word in a verse, reading from left to right. This makes lowfat trees addressable down to the word level of the text, for reference or combining with other resources.',
  },
  {
    name: 'case',
    verboseName: 'Case',
    description:
      'The @case attribute has five values accusative, dative, genitive, nominative, vocative.',
  },
  {
    name: 'mood',
    verboseName: 'Mood',
    description:
      'The @mood attribute has one of six values imperative, indicative, infinitive, optative, participle, subjective.',
  },
  // NOTE: Role ommitted intentionally because it is really
  // a wg-level property
  //   {
  //     name: 'role',
  //     verboseName: 'Role',
  //     description: '',
  //   },
  {
    name: 'text',
    verboseName: 'Text value',
    description: '',
  },
  {
    name: 'type',
    verboseName: 'Type',
    description:
      'The @type attribute distinguishes different types of nouns and pronouns. When present, it has one of the following values, adverbial, common, demonstrative, indefinite, interrogative, personal, possessive, proper, or relative.',
  },
  {
    name: 'after',
    verboseName: 'After',
    description: 'The @after attribute captures whitespace or punctuation following a word.',
  },
  {
    name: 'class',
    verboseName: 'Class',
    description:
      'The @class attribute has the following values:\n\nadj (adjective)\n\nadv (adverb)\n\nconj (conjunction)\n\ndet (determiner)\n\nintj (interjection)\n\nnoun\n\nnum (numeral)\n\nprep (preposition)\n\npron (pronoun)\n\nptcl (particle)\n\nverb',
  },
  {
    name: 'frame',
    verboseName: 'Frame',
    description:
      'The @frame attribute adds semantic information on predicate arguments as inspired by the original PropBank project (http//propbank.github.io). The attribute value consists of xml:ids of agents (A0), agents of induced actions (AA), patients (A1), and recipients (A2), for verbs. When multiple xml:ids appear for any value, they are separated by the \u201c;\u201d (semi-colon) character.',
  },
  {
    name: 'gloss',
    verboseName: 'Gloss',
    description:
      'The @gloss attribute contains a short English translation of a word in the given context. Glosses are drawn from the Berean Interlinear Bible.',
  },
  {
    name: 'lemma',
    verboseName: 'Lemma',
    description:
      'The @lemma attribute contains the form of a word as it appears at the head of a dictionary entry.',
  },
  {
    name: 'morph',
    verboseName: 'Morph',
    description:
      'The @morph attribute has a morphological parsing of the word on which it appears. Parsings are represented in Robinson\u2019s Morphological Analysis Codes. (http//www.modernliteralversion.org/bibles/bs2/RMAC/RMACindex.html)',
  },
  {
    name: 'tense',
    verboseName: 'Tense',
    description:
      'The @tense attribute when present has one of these values aorist, future, imperfect, perfect, pluperfect, and present.',
  },
  {
    name: 'voice',
    verboseName: 'Voice',
    description:
      'The @voice attribute has one of the following values active, middle, middlepassive, or passive.',
  },
  {
    name: 'degree',
    verboseName: 'Degree',
    description: 'The @degree attribute has two values, comparative and superlative.',
  },
  {
    name: 'domain',
    verboseName: 'Semantic Domain',
    description:
      'The @domain attribute contains semantic domain identifiers drawn from the Semantic Dictionary of Biblical Greek. The numbering of these domains differ from the same domains specified by Louw & Nida.',
  },
  {
    name: 'gender',
    verboseName: 'Gender',
    description: 'The @gender attribute has the values of masculine, feminine, and neuter.',
  },
  {
    name: 'number',
    verboseName: 'Number',
    description: 'The @number attribute has the values of plural or singular.',
  },
  {
    name: 'person',
    verboseName: 'Person',
    description: 'The @person attribute has the values first, second, or third.',
  },
  {
    name: 'strong',
    verboseName: "Strong's Number",
    description:
      'The @strong attribute captures a Strong\u2019s reference number. (Strong, James (1890), The Exhaustive Concordance of the Bible, Cincinnati Jennings & Graham.)',
  },
  {
    name: 'xmlId',
    verboseName: 'xml:id',
    description:
      'The @xmlid attribute has the following values:\n\nadj (adjective)\n\nadv (adverb)\n\nconj (conjunction)\n\ndet (determiner)\n\nintj (interjection)\n\nnoun\n\nnum (numeral)\n\nprep (preposition)\n\npron (pronoun)\n\nptcl (particle)\n\nverb',
  },
  {
    name: 'subjref',
    verboseName: 'Subject Referent',
    description:
      'The @subjref attribute has one or more xml:id values pointing to other <w> elements. This reference is to the nearest noun referent that serves as the implied subject of a verb.',
  },
  {
    name: 'referent',
    verboseName: 'Referent',
    description:
      'The @referent attribute has one or more xml:id values pointing to other <w> elements. The xml:ids of <w> elements have an n followed by an 11 digit number. See /w/@xml:id. This reference is to the nearest noun referent of a pronoun.',
  },
  {
    name: 'normalized',
    verboseName: 'Normalized Text',
    description:
      'The @normalized attribute contains a word subject to these changes\n\na) Punctuation has been removed.\n\nb) Most accents due to throwback clitics have been eliminated.\n\nc) Any final grave accent has been made acute when not eliminated by (b).\n\nNote that process (b) is not perfect. It only normalizes words which have more than one accent. A consequence of this is that clitics such as MOU will not get the accent removed even when the accent is present (e.g., due to a throwback clitic that follows it)....\n\nUlrik Petersen https//github.com/biblicalhumanities/Nestle1904/tree/master/morph',
  },
];

export default FIELDS;
