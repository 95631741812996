import { GLOBAL_DATA_STATE, LOCAL_DATA_STATE } from '@/store/constants';

import { convertOsisToUsfm, convertUsfmToOsis, parseOsisRef } from '@/common/refUtils';

/**
 * Used to make a widget's inputs available as computed properties.
 * Currently requires fields to be declared via doricConfiguration.dataFields
 */
const InputDataMixin = {
  props: {
    info: Object,
  },
  methods: {
    getDataFieldValue(variableName) {
      let value;
      const inputsContext = this.info.inputs;
      const groupKeyFromInput = inputsContext[variableName]?.groupKey;

      const workspace = this.$store.getters.getActiveWorkspace;
      if (!workspace) {
        return null;
      }

      if (groupKeyFromInput === GLOBAL_DATA_STATE) {
        // If the variableName is 'global', then return the global data
        value = workspace.globalFields[inputsContext[variableName].value];
      } else if (groupKeyFromInput === LOCAL_DATA_STATE) {
        // If the variableName is 'local', then return the local data
        value = inputsContext[variableName]?.value;
      } else {
        // Otherwise, use the variableName to access the correct variable group
        value = workspace.variableGroups.find(group => group.key === groupKeyFromInput)?.variables[
          inputsContext[variableName]?.value
        ];
      }
      return value;
    },
  },
  beforeCreate() {
    const computed = {
      ...this.$options.computed,
    };
    const doricInputs = this.info.inputs;
    Object.keys(doricInputs).forEach(inputFieldKey => {
      computed[inputFieldKey] = () => this.getDataFieldValue(inputFieldKey);
    });
    this.$options.computed = computed;
  },
};

const OutputsMixin = {
  props: {
    info: Object,
  },
  data() {
    return {
      outputs: { ...this.info.outputs },
    };
  },
  methods: {
    submit() {
      // TODO: What kind of a contract do we want on inputs?
      const inputs = this.info.inputs || {};
      this.$emit('configuration-update', { inputs, outputs: this.outputs });
    },
  },
};

const RefAdapterMixin = {
  computed: {
    usfmRef() {
      return convertOsisToUsfm(this.osisRef);
    },
    parsedOsisRef() {
      return parseOsisRef(this.osisRef);
    },
    contextualizedOsisRef() {
      return `${this.parsedOsisRef.book}.${this.parsedOsisRef.chapter ?? 1}`;
    },
    contextualizedUsfmRef() {
      return convertOsisToUsfm(this.contextualizedOsisRef);
    },
  },
  methods: {
    toOsisRef(value) {
      // TODO: Improve inclusion / exclusion
      // of tokens
      const [ref, token] = value.split('!');
      const osisRef = convertUsfmToOsis(ref);
      return `${osisRef}!${token}`;
    },
  },
};

export default { InputDataMixin, OutputsMixin, RefAdapterMixin };
