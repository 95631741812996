<template>
  <div class="book-refs">
    <div
      v-for="(book, bookIdx) in books"
      :key="bookIdx"
      class="book-ref"
      :class="{ active: this.isSelectedBook(book) }"
      @click.prevent="selectRef(book)"
    >
      {{ book.name }}
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import { DEFAULT_OSIS_REF, DEFAULT_GROUP_KEY } from '../../store/constants';

  const SYMPHONY_REST_API_ENDPOINT =
    process.env.VUE_APP_SYMPHONY_STRUCTURE_REST_API_ENDPOINT ||
    'https://symphony-basex-svc.clearlabs.biblica.com/api';

  export default {
    name: 'BookRefsWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          label: null,
          globalOsisRef: {
            value: 'osisRef',
            groupKey: DEFAULT_GROUP_KEY,
          },
        },
        outputs: {
          osisRef: DEFAULT_OSIS_REF,
        },
      },
    },
    mounted() {
      this.fetchData();
      this.submit();
    },
    data() {
      return {
        structureData: null,
      };
    },
    computed: {
      books() {
        return this.structureData?.books || [];
      },
    },
    methods: {
      fetchData() {
        const endpoint = SYMPHONY_REST_API_ENDPOINT;
        const path = '/GNT/Nestle1904/structure';
        const structureUrl = `${endpoint}${path}`;
        this.loading = true;
        fetch(structureUrl).then(resp => {
          resp.json().then(data => {
            this.structureData = data;
            this.$nextTick(() => {
              this.loading = false;
            });
          });
        });
      },
      isSelectedBook(book) {
        return book.name === this.osisRef.split('.')[0];
      },
      selectRef(book) {
        // TODO: Get a suitable "first passage"
        // reference from the API
        const newRef = `${book.chapters[0]}.1`;
        this.outputs.osisRef.value = newRef;
        this.submit();
      },
    },
  };
</script>

<style scoped lang="scss">
  .book-refs {
    display: grid;
    // NOTE: Option A
    // grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(8, 10px) 5%;
    // grid-auto-flow: column;
    // gap: 1.5em;

    // OPTION B:
    // TODO: Improve wide / narrow layout
    grid-template-columns: repeat(auto-fill, 1em) 5%;
    gap: 10px 5em;
  }
  .book-ref {
    padding: 10px;
    &.active {
      font-weight: bold;
    }
  }
</style>
