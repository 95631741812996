<template>
  <template v-if="node?.type === 'text'">{{
    node.value.includes('\n') ? '' : node.value
  }}</template>
  <component
    v-else-if="node?.name == 'milestone'"
    :is="node?.name"
    :data-has-active-milestone="hasActiveMilestone"
  >
    <TreeDownMilestone :active="hasActiveMilestone">
      {{
        node.children
          ?.filter(child => child.value)
          .map(child => child.value)
          .join('')
      }}
    </TreeDownMilestone>
  </component>
  <w
    v-else-if="node?.name == 'w'"
    v-bind="node.attributes"
    :data-macula-after="node.attributes?.after"
  >
    <!-- selectTokenLemma should be an emit up to the root -->
    <word
      v-if="hasToken"
      :tokenId="tokenId"
      :tokenIsEmpty="!textValue"
      :highlights="highlights"
      :showGreek="showGreek"
      :showGlosses="showGlosses"
      :showWordPartConnector="true"
      :selectedGlosses="selectedGlosses"
      :glosses="wordGlosses"
      @select-token-lemma="selectTokenLemma"
      @select-token-ids="selectTokenIds"
    >
      <!-- NOTE: Overriding the `text-value` slot here ensures we are mimicing the
       data as encoded in the LowfatXML, rather than the token `value` returned by
       ATLAS which includes whitespace, maqqef, etc.
       -->
      <template v-slot:text-value>{{ textValue || '' }}</template>
    </word>
    <!-- TODO: Wordish -->
    <word
      v-else-if="noToken"
      :tokenId="null"
      :tokenIsEmpty="!textValue"
      :highlights="{}"
      :showGreek="showGreek"
      :showGlosses="showGlosses"
      :selectedGlosses="selectedGlosses"
      :glosses="wordGlosses"
      @select-token-lemma="selectTokenLemma"
      @select-token-ids="selectTokenIds"
      :isHighlightedId="isHighlightedId"
    >
      <template v-slot:text-value>{{ textValue || '&nbsp;' }}</template>
    </word>
  </w>
  <component
    v-else-if="node?.name"
    :is="node.name"
    v-bind="node.attributes"
    :class="{
      boxwood: showClauses && node?.name === 'sentence',
    }"
  >
    <TreeDownNode
      v-for="(child, idx) in node?.children"
      :key="`child-${child.attributes?.id || child.value || `unknown-${idx}`}`"
      :node="child"
      :highlights="highlights"
      :showGreek="showGreek"
      :showGlosses="showGlosses"
      :selectedGlosses="selectedGlosses"
      :noToken="noToken"
      :showMilestones="showMilestones"
      :hasActiveMilestone="hasActiveMilestone"
      :highlightedIds="highlightedIds"
      @select-token-lemma="selectTokenLemma"
      @select-token-ids="selectTokenIds"
      @select-node="handleSelectNode"
      v-bind="child.attributes"
      @click.stop="handleSelectNode(node)"
    />
  </component>
</template>

<script>
  // https://vuejs.org/api/application.html#app-config-compileroptions-iscustomelement
  // John.4.2
  import mixins from '@/mixins';

  import Word from '@/symphony/widgets/Word.vue';
  import TreeDownMilestone from '@/symphony/widgets/TreeDownMilestone.vue';
  import { XML_ID } from '@/store/constants';
  // DDN: scoped vuex versus props / attrs
  export default {
    name: 'TreeDownNode',
    props: [
      'node',
      'parentIsClause',
      'highlights',
      'showGreek',
      'showGlosses',
      'selectedGlosses',
      'showClauses',
      'noToken',
      'showMilestones',
      'highlightedIds',
      'hasActiveMilestone',
    ],
    mixins: [mixins.RefAdapterMixin],
    components: {
      Word,
      TreeDownMilestone,
    },
    computed: {
      isHighlightedId() {
        return this.highlightedIds?.includes(this.node.attributes[XML_ID]);
      },
      isClause() {
        const value = this.node.attributes?.class === 'cl';
        // debugger;
        return value;
      },
      isSentence() {
        return this.node.name === 'sentence';
      },
      milestones() {
        const milestoneParts = [];
        this.node.children
          .filter(pChild => pChild.name === 'p')[0]
          .children.forEach(child => {
            if (child?.attributes?.id) {
              const ref = this.toOsisRef(`${child.attributes.id}!1`);
              milestoneParts.push(ref.split('!')[0]);
            }
          });
        return milestoneParts;
      },
      milestoneText() {
        if (!this.isSentence) {
          return null;
        }
        const firstPart = this.milestones[0];
        const lastPart = this.milestones.slice(-1)[0];
        if (firstPart === lastPart) {
          return firstPart;
        }
        // FIXME: Standardize on human-readable refs?
        return [firstPart, lastPart].join('-');
      },
      tokenId() {
        return this.node.attributes?.[XML_ID];
      },
      hasToken() {
        return (
          this.tokenId && this.$store.getters.getActiveWorkspace.globalTokens.has(this.tokenId)
        );
      },
      textValue() {
        return (
          this.node.children
            .filter(child => child.type === 'text')
            .map(textChild => textChild.value)
            .join('') || ''
        );
      },
      // TODO: Refactor with findGloss
      wordGlosses() {
        return {
          english: this.node.attributes?.english || this.node.attributes?.gloss,
          greek: this.node.attributes?.greek,
          mandarin: this.node.attributes?.mandarin,
        };
      },
    },
    methods: {
      roleLabel(value) {
        return value === 'adv' ? '+' : value;
      },
      selectTokenLemma(value) {
        this.$emit('select-token-lemma', value);
      },
      selectTokenIds(value) {
        this.$emit('select-token-ids', value);
      },
      handleSelectNode(value) {
        this.$emit('select-node', value);
      },
    },
  };
</script>

<style scoped lang="scss">
  // TODO: Revisit styles with flexbox
  // or actual elements instead of content
  // selectors
  *[data-macula-direction='rtl'] {
    @import '@/symphony/assets/treedown-css/hebrew-treedown.scss';
    @import '@/symphony/assets/treedown-css/hebrew-boxwood.scss';
    @import '@/symphony/assets/treedown-css/hebrew-treedown-overrides.scss';
  }
  *[data-macula-direction=''] {
    @import '@/symphony/assets/treedown-css/treedown.scss';
    @import '@/symphony/assets/treedown-css/boxwood.scss';
    @import '@/symphony/assets/treedown-css/treedown-overrides.scss';
  }
</style>
