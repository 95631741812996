<template>
  <div class="tei-passage" :class="{ loading }">
    <!-- TODO: Review what we've done before with Scaife -->
    <!-- TODO: Also think about XSLT -->

    <toggle
      toggleName="Milestones"
      :toggleState="showMilestones"
      @toggledState="toggleShowMilestones"
    />

    <div v-if="osisRef && content" :class="{ 'tei-content': true, rtl: isRTL, ltr: isLTR }">
      <component
        v-for="element in content"
        :id="cleanId(getIdOrRef(element))"
        :key="element.id"
        :is="teiTagMap[element.tagName]"
        :class="element.tagName"
        v-html="getContentsByTag(element)"
      />
    </div>
  </div>
</template>
<script>
  import { nextTick } from 'vue';
  import { DEFAULT_GROUP_KEY } from '@/store/constants';

  import mixins from '@/mixins';
  import Toggle from '@/symphony/widgets/Toggle.vue';
  import {
    parseOsisRef,
    parseUsfmRef,
    convertOsisToUsfm,
    encodeForURI,
    determineTestamentForOsisRef,
    determineLanguageDirectionForOsisRef,
  } from '@/common/refUtils';

  export default {
    name: 'TextWidget',
    mixins: [mixins.InputDataMixin],
    components: {
      Toggle,
    },
    props: {
      doric: {
        inputs: {
          label: null,
          osisRef: {
            value: 'osisRef',
            groupKey: DEFAULT_GROUP_KEY,
          },
        },
      },
    },
    data() {
      return {
        apiXML: null,
        loading: false,
        showMilestones: true,
      };
    },
    watch: {
      osisRef: {
        handler() {
          this.loadXML();
        },
        immediate: true,
      },
    },
    computed: {
      testament() {
        return determineTestamentForOsisRef(this.contextualizedOsisRef);
      },
      isRTL() {
        const direction = determineLanguageDirectionForOsisRef(this.contextualizedOsisRef);
        return direction === 'rtl';
      },
      isLTR() {
        const direction = determineLanguageDirectionForOsisRef(this.contextualizedOsisRef);
        return direction === 'ltr';
      },

      teiTagMap() {
        return {
          w: 'span',
          pc: 'span',
          milestone: 'div',
          title: 'h1',
        };
      },
      content() {
        if (!this.apiXML || this.loading) {
          return null;
        }

        const parser = new DOMParser();
        const parsedXml = parser.parseFromString(this.apiXML, 'application/xml');
        return parsedXml.querySelectorAll("milestone[unit='verse'], w, pc, title");
      },
      osisRef() {
        return this.osisRef;
      },
      usfmRef() {
        if (!this.osisRef) {
          return null;
        }
        return convertOsisToUsfm(this.osisRef);
      },
      parsedOsisRef() {
        if (!this.osisRef) {
          return null;
        }
        return parseOsisRef(this.osisRef);
      },
      contextualizedOsisRef() {
        if (!this.parsedOsisRef?.book) {
          return null;
        }

        return `${this.parsedOsisRef.book}.${this.parsedOsisRef.chapter ?? 1}`;
      },
      parsedUsfmRef() {
        if (!this.usfmRef) {
          return null;
        }

        return parseUsfmRef(this.usfmRef);
      },
      contextualizedUsfmRef() {
        const usfmRef = convertOsisToUsfm(this.contextualizedOsisRef);
        return encodeForURI(usfmRef);
      },
      scrollLocation() {
        if (!this.parsedOsisRef.book) {
          return null;
        }
        if (this.testament === 'NT') {
          return this.cleanId(
            `${this.parsedOsisRef.book}.${this.parsedOsisRef.chapter ?? 1}.${
              this.parsedOsisRef.verse ?? 1
            }`,
          );
        }

        if (this.testament === 'OT') {
          return this.cleanId(
            `${this.parsedUsfmRef.book} ${this.parsedUsfmRef.chapter ?? 1}.${
              this.parsedUsfmRef.verse ?? 1
            }`,
          );
        }
        return null;
      },
    },
    methods: {
      toggleShowMilestones() {
        this.showMilestones = !this.showMilestones;
      },
      cleanId(id) {
        return id.replaceAll('.', '-');
      },
      getIdOrRef(element) {
        if (element.id) {
          return element.id;
        }

        if (element.attributes.ref) {
          return element.attributes.ref.value;
        }

        return '';
      },
      getContentsByTag(element) {
        if (element.tagName === 'milestone') {
          if (this.showMilestones) {
            return this.getIdOrRef(element);
          }
          return null;
        }
        if (element.tagName === 'w') {
          return ` ${element.innerHTML}`;
        }

        if (element.tagName === 'title') {
          if (this.showMilestones) {
            return element.innerHTML;
          }
          return null;
        }

        return element.innerHTML;
      },
      loadXML() {
        if (this.contextualizedOsisRef === null) {
          this.apiXml = null;
          return;
        }

        const SYMPHONY_REST_API_ENDPOINT =
          process.env.VUE_APP_SYMPHONY_REST_API_ENDPOINT ||
          'https://symphony-basex-svc.clearlabs.biblica.com/api';

        const pathNT = `GNT/Nestle1904/text?usfm-ref=${this.contextualizedUsfmRef}`;
        const pathOT = `HOT/macula-hebrew/text?usfm-ref=${this.contextualizedUsfmRef}`;

        let entryUrl;
        if (this.testament === 'OT') {
          entryUrl = `${SYMPHONY_REST_API_ENDPOINT}/${pathOT}`;
        }

        if (this.testament === 'NT') {
          entryUrl = `${SYMPHONY_REST_API_ENDPOINT}/${pathNT}`;
        }

        this.loading = true;
        fetch(entryUrl).then(resp => {
          resp.text().then(data => {
            this.apiXML = data;
            this.loading = false;
            nextTick(() => {
              try {
                document.getElementById(this.scrollLocation).scrollIntoView({ behavior: 'smooth' });
              } catch (e) {
                console.error('Unable to autoscroll.');
              }
            });
          });
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  // TODO: Shared styles?
  .loading {
    opacity: 50%;
  }

  .tei-passage {
    font-family: 'Gentium Book Plus';
    font-size: 14pt;
    line-height: 1.7;
  }

  w + pc {
    margin-left: -0.66rem;
  }

  .milestone {
    opacity: 75%;
    margin-top: 16px;
    font-size: 12pt;
    text-align: right;
  }

  .title {
    text-align: center;
  }

  :deep(.tei-content) {
    font-family: 'Gentium Book Plus';
    font-size: 14pt;
    line-height: 1.7;

    .milestone:not(:first-of-type) {
      margin-top: 2rem;
    }

    w {
      padding: 0.2rem 0.5rem;
      margin-bottom: 0.2rem;
    }

    w + pc {
      margin-left: -0.66rem;
    }
  }

  .osis-ref {
    display: block;
    text-align: right;
    opacity: 50%;
  }

  .rtl {
    font-family: 'SBLBibLit';
    direction: rtl;
    // TODO: should consolidate typography styles and units app-wide.
    font-size: 1.4rem;
    line-height: 1.7;
  }

  .ltr {
    font-family: 'Gentium Book Plus';
    direction: ltr;
  }
</style>
