<template>
  <div class="osis-ref">
    <div class="ref">{{ osisRef }}</div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import { DEFAULT_GROUP_KEY, DEFAULT_OSIS_REF } from '@/store/constants';

  export default {
    name: 'OsisRefWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    // FIXME: This is already being used and likely can be replaced
    // by introspecting inputs within GlobalLocalDataMixin.
    // TODO: Once we have doric.inputs defined, remove doricConfiguration
    // doricConfiguration: {
    //   dataFields: ['osisRef'],
    // },
    // doric or doricConfig or ?
    // $options.doric
    props: {
      doric: {
        inputs: {
          label: null, //! TODO: make sure this works even when a default value is provided. Need to refactor this line in store:

          // this form would assume the input is local
          // e.g.
          // label: {
          //   value: null,
          //   groupKey: 'local'
          // }
          // At some point we might make it an optional declaration
          // or manage via a settings object, but we'll keep
          // it as is for the first pass
          // Simple syntax can _only_ ever get you local inputs
          // eg:
          // osisRef: 'John.14.1'
          // FIXME: distinguish between the value of a local input
          // and the variable name within the group when registering a default
          osisRef: {
            value: 'osisRef',
            groupKey: DEFAULT_GROUP_KEY,
            // TODO: Consider refactoring to a more explicit
            // declaration
            //
            // OPTION A:
            // value: 'John.14.1',
            // listenToField?: 'osisRef',
            // groupKey?: 'default'
            // NOTE: Add a linting check that widget developer doesn't
            // define both `value` and `listenToField`.
            // If `listenToField` is defined, groupKey remains optional.
            // listenToField: 'osisRef'
            //
            // OPTION B:
            // Define either value or a `listen` object:
            // value: 'John.14.1'  // assumes this is a local input value
            // _or_
            // listen: true  // assumes
            // listen: {
            //   'toField': 'osisRef',
            //   'groupKey': 'default'
            // }
            // ONLY listen OR value can be defined
          },
        },
        outputs: {
          // We could have the most basic thing of
          // pass the value as the value to the
          // output key
          osisRef: DEFAULT_OSIS_REF,
          // In the "simple" implementation, we can assume
          // that the send value is the output "key"
          // and that we would set groupKey to default:
          // osisRef: {
          //   value:'John.14.1'
          //   (send | sendToField): 'osisRef',
          //   groupKey: 'default',
          // },
          // Eventually we will probably want to support the entirety of
          // the Vue props declaration spec:
          // https://vuejs.org/guide/components/props.html#prop-validation
          // (which include things like defaults, validators, other callbacks, etc)
        },
      },
    },
    mounted() {
      this.submit();
    },
    watch: {
      osisRef: {
        // FIXME: Think through implications of two-way data binding
        handler(newValue) {
          this.outputs.osisRef.value = newValue;
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  .osis-ref {
    .ref {
      color: #666;
      font-family: monospace;
      margin-bottom: 1em;
      font-size: 14pt;
    }
  }
</style>
