import bibleBooks from '@/common/bible-books-lookup.json';

const defaultOsisId = 'JHN 14.1'; // FIXME: if this string is an OT ref, RTL will not be detected because it won't pass through the Search component, in which case it needs to be properly set here
const defaultXqueryString = '//w';
const defaultXquerySearchObject = { query: defaultXqueryString, usingXquery: true };
const bookNameOfDefaultRef = defaultOsisId.split(/\.|:| |!/)[0];
const defaultLanguageDirectionIsRTL = !!bibleBooks.find(book => book.abbr === bookNameOfDefaultRef)
  ?.language_direction;

const GreekLowfatRoles = ['v', 'o', 's', 'o2', 'adv', 'p', 'vc', 'io']; // cl p s v o adv vc io o2
// const GreekLowfatRoles = [
//   'verb',
//   'object',
//   'subject',
//   'object 2',
//   'adverbial',
//   'participle',
//   'verbal complement',
//   'indirect object',
// ]; // cl p s v o adv vc io o2
const HebrewLowfatRoles = ['v', 'o', 's', 'o2', 'adv', 'pp']; // pp v s o cl p adv o2
// const HebrewLowfatRoles = [
//   'verb',
//   'object',
//   'subject',
//   'object 2',
//   'adverbial',
//   'prepositional phrase',
// ]; // pp v s o cl p adv o2

const uniqueRoles = [...new Set([...GreekLowfatRoles, ...HebrewLowfatRoles])];
const syntaxTypes = {
  roles: uniqueRoles,
  classes: ['cl', 'wg'],
};

const configuration = {
  bibleBooks,
  defaultOsisId,
  defaultXqueryString,
  defaultXquerySearchObject,
  defaultLanguageDirectionIsRTL,
  syntaxTypes,
};

export default configuration;
