<template>
  <div>
    <LoadingSpinner v-if="$apollo.loading" />
    <EmptyMessage v-else-if="!annotationSets.length"
      >No annotation sets found for
      <VerseReference>{{ contextualizedUsfmRef || '' }}</VerseReference
      >. Please navigate to a new passage.
    </EmptyMessage>
    <template v-else>
      <div>Please choose an annotation set:</div>
      <div>
        <ul>
          <li v-for="annotationSet in annotationSets" :key="annotationSet.uri">
            <a href="#" @click.prevent="$emit('selectAnnotationSet', annotationSet.uri)">{{
              annotationSet.label || annotationSet.uri
            }}</a>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import { DEFAULT_ANNOTATION_SET_URI } from '@/store/constants';
  import EmptyMessage from '@/symphony/widgets/components/EmptyMessage.vue';
  import VerseReference from './VerseReference.vue';
  import LoadingSpinner from '../../components/LoadingSpinner.vue';

  export default {
    props: {
      contextualizedUsfmRef: String,
    },
    components: {
      EmptyMessage,
      VerseReference,
      LoadingSpinner,
    },
    emits: ['selectAnnotationSet'],
    apollo: {
      annotationSets: {
        query: gql`
          query AnnotationSetsQuery($filters: AnnotationSetFilter) {
            annotationSets(filters: $filters) {
              uri
              label
            }
          }
        `,
        variables() {
          return {
            filters: {
              scriptureReference: {
                usfmRef: this.contextualizedUsfmRef,
              },
              uri: DEFAULT_ANNOTATION_SET_URI,
            },
          };
        },
      },
    },
  };
</script>
