<template>
  <div class="form">
    <label>{{ property }}</label>
    <input v-model="value" @change="fieldUpdate()" placeholder="No output value yet" />
    <label>Sent to:</label>
    <div class="output-row">
      <span>Group:</span>
      <select v-model="groupKey" @change="fieldUpdate()">
        <option disabled>Select group</option>
        <option
          v-for="variableGroup in workspaceVariableGroups"
          :key="variableGroup.key"
          :selected="groupKey === variableGroup.key"
          :value="variableGroup.key"
        >
          {{ variableGroup.label }}
        </option>
      </select>
      <div>
        <span>As:</span>
        <input v-model="send" @change="fieldUpdate()" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OutputFieldConfiguration',
    props: {
      property: String,
      initialValue: Object,
    },
    data() {
      return {
        value: this.initialValue.value,
        groupKey: this.initialValue.groupKey,
        send: this.initialValue.send,
      };
    },
    computed: {
      workspaceVariableGroups() {
        return this.$store.getters.getActiveWorkspaceVariableGroups;
      },
      variablesFromSelectedGroup() {
        return this.workspaceVariableGroups.find(group => group.key === this.groupKey).variables;
      },
    },
    methods: {
      fieldUpdate() {
        this.$emit('field-update', {
          property: this.property,
          value: this.value,
          send: this.send,
          groupKey: this.groupKey,
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .form {
    padding: 5px;
    margin: 2px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #ccc;
    .output-row {
      display: flex;
      flex-flow: row wrap;
      gap: 5px;
      justify-content: space-between;
    }
    label {
      font-weight: 500;
    }
    input {
      text-align: right;
      max-width: fit-content;
    }
    select.remote {
    }
  }
</style>
