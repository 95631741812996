<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    wordNode: Object,
    index: Number,
  });

  const wordIsCollapsed = ref(props?.index !== 0);
</script>

<template>
  <div class="attribute-container">
    <div class="header" @click="wordIsCollapsed = !wordIsCollapsed">
      <span class="caret" :class="{ closed: wordIsCollapsed }">{{ '\u25BC' }}</span>
      <span class="word-header">{{
        wordNode?.attributes?.normalized || wordNode?.attributes?.unicode
      }}</span>
    </div>
    <div class="collapse-content" :class="{ collapsed: wordIsCollapsed }">
      <div v-for="(value, key, index) in wordNode?.attributes" :key="key">
        <div class="attribute-item" :class="{ 'first-attribute': index === 0 }">
          <span class="attribute-title">{{ key }}:</span
          ><span v-show="value" class="attribute-value">{{
            value === ' ' ? 'word is followed by blank space' : value
          }}</span
          ><span v-show="!value" class="attribute-value">N/A</span>
        </div>
      </div>
      <div v-if="wordNode?.currentWordPosition" class="attribute-item">
        <span class="attribute-title">Current word position in verse:</span
        ><span class="attribute-value">{{ wordNode?.currentWordPosition }}</span>
      </div>
      <div v-if="wordNode?.originalPosition" class="attribute-item">
        <span class="attribute-title">Canonical word position in verse:</span
        ><span class="attribute-value">{{ wordNode?.originalPosition }}</span>
      </div>
      <div v-if="wordNode?.currentPosition" class="attribute-item">
        <span class="attribute-title">Position of node in tree:</span
        ><span class="attribute-value">{{ wordNode?.currentPosition }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .attribute-container {
    z-index: 100;
    color: rgba(0, 0, 0, 0.959);
    display: flex;
    direction: ltr;
    flex-flow: row wrap;
    padding: 1em;
    font-family: 'Times New Roman', Times, serif;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.5em;
    margin-bottom: 0.5em;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.5em;
  }
  .attribute-title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: smaller;
  }
  .attribute-item {
    line-height: inherit;
    margin: 0.3em;
    width: 100%;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #ccc;
    font-family: Arial, Helvetica, sans-serif;
    &.first-attribute {
      border-top: 1px solid #ccc;
      padding-top: 0.3em;
      margin-top: 0.5em;
    }
  }
  .header {
    display: flex;
    width: 100%;
  }
  .attribute-value {
    z-index: 101;
    margin-left: 0.5em;
    color: darkblue;
  }

  .collapse-content {
    visibility: visible;
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 1;
    max-height: 100%;
    transition: /* visibility 0s,  */ opacity 0.5s, max-height 0.5s, height 0.5s ease-in-out;
    /** first child attribute-item has top margin */
  }
  .collapsed {
    /* visibility: hidden; */
    max-height: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  .word-header {
    font-weight: bold;
    font-size: 1em;
  }
  .caret {
    /* height: 0em; */
    margin-right: 1em;
    align-self: center;
    font-size: 0.5em;
    transition: transform 0.2s ease-in-out;
    color: #ccc;
    &.closed {
      transform: rotate(-90deg);
    }
  }
</style>
