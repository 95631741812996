<template>
  <div>
    <div v-show="loading">
      <UILoading />
    </div>
    <div v-show="!loading" class="tei-passage">
      <div
        v-if="allowedRef && tokensByMilestone"
        class="tei-content"
        :class="{ sgr: showGreek, sgl: showGlosses }"
      >
        <AlignedTokensView
          :highlights="{}"
          :showGreek="showGreek"
          :showGlosses="showGlosses"
          @select-token-lemma="selectTokenLemma"
          @select-token-ids="selectTokenIds"
          :tokensByMilestone="tokensByMilestone"
          :alignmentData="myAlignmentLinks"
          :alignedText="myAlignedText"
        />
      </div>
      <em v-if="!allowedRef"> Rendering "{{ osisRef }}" is not yet supported. </em>
      <EmptyMessage v-if="alignedTranslation && !tokensByMilestone?.size"
        >No aligned translation found in {{ alignedTranslation?.usfmRef }} for
        {{ friendlyRef }}.</EmptyMessage
      >
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag';
  import formatOsis from 'bible-reference-formatter';

  import mixins from '@/mixins';
  import { DEFAULT_GROUP_KEY, TOKEN_ID_FIELD } from '@/store/constants';
  import {
    parseOsisRef,
    convertOsisToUsfm,
    createOsisWordIdFromUsfm,
    convertUsfmToOsis,
  } from '@/common/refUtils';

  import AlignedTokensView from '@/symphony/widgets/aligned-translation-widgets/AlignedTokensView.vue';

  import UILoading from '@/symphony/widgets/treedown-widgets/components/UILoading.vue';
  import EmptyMessage from '@/symphony/widgets/components/EmptyMessage.vue';

  export default {
    name: 'AlignedTranslationWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin, mixins.RefAdapterMixin],
    components: {
      AlignedTokensView,
      UILoading,
      EmptyMessage,
    },
    props: {
      doric: {
        inputs: {
          label: null,
          osisRef: {
            value: 'osisRef',
            groupKey: DEFAULT_GROUP_KEY,
          },
          alignedTranslation: {
            value: 'alignedTranslation',
            groupKey: DEFAULT_GROUP_KEY,
          },
          showBaseText: {
            value: 'showBaseText',
            groupKey: DEFAULT_GROUP_KEY,
          },
          selectedGlosses: {
            value: 'selectedGlosses',
            groupKey: DEFAULT_GROUP_KEY,
          },
          discourseFeatures: {
            value: 'discourseFeatures',
            groupKey: DEFAULT_GROUP_KEY,
          },
          highlightVerbs: {
            value: 'highlightVerbs',
            groupKey: DEFAULT_GROUP_KEY,
          },
        },
        outputs: {
          selectedLemma: null,
          selectedTokenIds: null,
        },
      },
    },
    apollo: {
      alignmentLinks: {
        query: gql`
          query AlignmentLinks($filters: AlignmentLinkFilter) {
            alignmentLinks(filters: $filters) {
              sourceTokens {
                ${TOKEN_ID_FIELD}
                lemma
                ref
              }
              targetTokens {
                ${TOKEN_ID_FIELD}
                data
                ref
              }
            }
          }
        `,
        variables() {
          return {
            filters: {
              alignment: {
                inList: this.alignedTranslation.targetAlignments.map(a => a.id),
              },
              targetScriptureReference: {
                usfmRef: this.contextualizedUsfmRef,
                textualEdition: this.alignedTranslation.usfmRef,
              },
            },
          };
        },
        skip() {
          return !this.alignedTranslation;
        },
        update(data) {
          this.errorMessage = null;
          return data.alignmentLinks;
        },
        error(error) {
          this.errorMessage = error.message;
          console.error(error);
        },
      },
      passageData: {
        query: gql`
          query passageData($chapterRef: String!, $textualEdition: String!) {
            passageWithTokens: passage(
              filters: {
                scriptureReference: { usfmRef: $chapterRef, textualEdition: $textualEdition }
              }
            ) {
              usfmRef
              tokens {
                value
                skipSpaceAfter
                ${TOKEN_ID_FIELD}
                maculaId
                ref
                wordValue
                xmlId
                data
              }
            }
          }
        `,
        variables() {
          const textualEdition = this.alignedTranslation?.usfmRef;
          return {
            chapterRef: this.contextualizedUsfmRef,
            textualEdition,
          };
        },
        skip() {
          return !this.osisRef || !this.alignedTranslation;
        },
        update(data) {
          return {
            passageVerses: data.passageWithTokens,
            wordTokens: data.passageWithTokens.flatMap(passage => passage.tokens),
          };
        },
      },
    },
    data() {
      return {
        errorMessage: null,
        apiXML: null,
        propositions: null,
        lowfatData: null,
        tokensByMilestone: null,
        tokenMapIsLoading: false,
      };
    },
    watch: {
      wordTokens: {
        handler() {
          this.resolvePassageData();
        },
      },
      passageData: {
        // FIXME: Prevent race condition
        handler() {
          this.resolvePassageData();
        },
        immediate: false,
      },
    },
    // When we work with Jonathan on what these glosses actually are...
    computed: {
      alignmentLinksMap() {
        const map = new Map();
        if (!this.alignmentLinks) {
          return map;
        }
        this.alignmentLinks.forEach(link => {
          if (link.targetTokens?.length === 0) {
            return;
          }
          link.targetTokens.forEach(token => {
            map.set(token[TOKEN_ID_FIELD], link);
          });
        });
        return map;
      },
      myAlignmentLinks() {
        if (!this.alignmentLinks || !this.wordTokens) {
          return [];
        }
        const value = this.formatAlignmentLinks(this.alignmentLinks);
        return value.map(token => {
          const wordToken = this.wordTokens.get(token[TOKEN_ID_FIELD]);
          const { hasAlignmentData, maculaId, ref } = wordToken;
          return {
            ...token,
            maculaId,
            hasAlignmentData,
            ref,
          };
        });
      },
      myAlignedText() {
        if (this.alignmentLinksMap.size === 0) {
          return [];
        }
        if (!this.wordTokens) {
          return [];
        }
        if (!this.alignmentLinksMap) {
          return [];
        }
        const tokensArray = [...this.wordTokens.values()];
        return tokensArray.map(token => {
          const alignmentData = this.alignmentLinksMap.get(token[TOKEN_ID_FIELD]);
          const sourceTokens = alignmentData?.sourceTokens || [];
          return {
            ...token,
            hasAlignmentData: alignmentData?.sourceTokens?.length > 0,
            sourceTokenId: sourceTokens?.[0]?.[TOKEN_ID_FIELD],
            sourceIds: sourceTokens?.map(t => t[TOKEN_ID_FIELD]),
            targetIds: alignmentData?.targetTokens?.map(t => t[TOKEN_ID_FIELD]),
          };
        });
      },
      parsedOsisRef() {
        return parseOsisRef(this.osisRef);
      },
      friendlyRef() {
        return formatOsis('niv-long', this.osisRef);
      },
      tokensForOsisRef() {
        return this.tokensByMilestone.get(this.osisRef);
      },
      lowfatNodes() {
        return this.lowfatData ? this.lowfatData.children[0] : undefined;
      },
      allowedRef() {
        const { chapter } = this.parsedOsisRef;
        return chapter;
      },
      osisRef() {
        return this.osisRef;
      },
      contextualizedOsisRef() {
        return `${this.parsedOsisRef.book}.${this.parsedOsisRef.chapter ?? 1}`;
      },
      contextualizedUsfmRef() {
        return convertOsisToUsfm(this.contextualizedOsisRef);
      },
      osisChapterRef() {
        return this.osisRef.match(/(?<book>\w+)\.(?<chapter>\d+)/)[0];
      },
      // TODO: Refactor as showBaseText
      showGreek() {
        return !!this.showBaseText;
      },
      selectedGlossesSet() {
        const parts = this.selectedGlosses ? this.selectedGlosses.split('|') : [];
        return new Set(parts);
      },
      showGlosses() {
        return this.selectedGlossesSet.size > 0;
      },
      wordTokens() {
        const tokenMap = new Map();
        if (!this.passageData) {
          return tokenMap;
        }
        this.passageData.wordTokens.forEach(token => {
          tokenMap.set(token[TOKEN_ID_FIELD], token);
        });
        return tokenMap;
      },
      loading() {
        /*
          Indicates that there is data loading / processing happening from the API.
        */
        return this.tokenMapIsLoading || this.$apollo.loading;
      },
    },
    methods: {
      formatAlignmentLinks(alignmentLinks) {
        if (!alignmentLinks) {
          return [];
        }
        const translationTokens = alignmentLinks
          .map(alignmentDatum => {
            alignmentDatum.sourceTokens.forEach(sourceToken => {
              this.$store.getters.getActiveWorkspace.globalTokens.set(sourceToken[TOKEN_ID_FIELD], {
                osisId: createOsisWordIdFromUsfm(sourceToken.ref),
                ...sourceToken,
              });
            });
            return alignmentDatum.targetTokens
              .map(targetToken => {
                this.$store.getters.getActiveWorkspace.globalTokens.set(
                  targetToken[TOKEN_ID_FIELD],
                  {
                    osisId: createOsisWordIdFromUsfm(targetToken.ref),
                    ...targetToken,
                  },
                );
                const sourceIds = alignmentDatum.sourceTokens.map(sToken => sToken[TOKEN_ID_FIELD]);
                return {
                  [TOKEN_ID_FIELD]: targetToken[TOKEN_ID_FIELD],
                  sourceIds,
                  targetId: targetToken[TOKEN_ID_FIELD],
                  hasAlignmentData: sourceIds.length > 0,
                  maculaId: targetToken.maculaId,
                };
              })
              .flat();
          })
          .flat();
        return translationTokens;
      },
      // TODO: Resolve multiple calls; may need some sort of "chained" caller
      resolvePassageData() {
        // Refactoring goal --- separate concerns:
        // 1. fetching data from api
        // 2. converting data into desired format
        // 3. loading data into store
        if (!this.passageData) {
          return;
        }
        this.tokenMapIsLoading = true;
        // FIXME: teiTokenMap is required to generate the full "word-level" value
        // refactor so that getWordTextValue can go away
        const teiTokenMap = this.simpleFetchTei();
        this.tokensByMilestone = teiTokenMap;
        teiTokenMap.forEach(verseMap => {
          verseMap.forEach((tokenDatum, tokenId) => {
            this.$store.getters.getActiveWorkspace.globalTokens.set(tokenId, tokenDatum);
          });
        });
        this.tokenMapIsLoading = false;
      },
      selectTokenLemma(lemma) {
        if (lemma) {
          this.selectLemma(lemma);
        }
      },
      selectTokenIds(ids) {
        if (ids) {
          this.selectRef(ids);
        }
      },
      getWordTextValue(existingData, token) {
        // FIXME: Remove dependency on teiTokenMap
        return token.maculaId.startsWith('o') && existingData?.textValue
          ? existingData?.textValue
          : token.value;
      },
      simpleFetchTei() {
        if (!this.passageData) {
          return null;
        }
        // TODO: Baby steps.  We need to get the fetchers all switched over, but this can now use
        // this.teiXml.
        const verseMap = new Map();
        // FIXME: Refactor with enrichTokenMap
        this.passageData.passageVerses.forEach(verse => {
          const verseOsisRef = convertUsfmToOsis(verse.usfmRef);
          verseMap.set(verseOsisRef, new Map());
          const tokenMap = verseMap.get(verseOsisRef);

          verse.tokens.forEach(token => {
            const tokenId = createOsisWordIdFromUsfm(token.ref);
            const existingData = tokenMap.get(tokenId);
            tokenMap.set(tokenId, {
              ...existingData,
              maculaId: token.maculaId,
              usfmRef: token.ref,
              textValue: this.getWordTextValue(existingData, token),
            });
          });
          verseMap.set(verseOsisRef, tokenMap);
        });
        return verseMap;
      },
      selectLemma(lemma) {
        this.outputs.selectedLemma.value = lemma;
        this.submit();
      },
      selectRef(values) {
        this.outputs.selectedTokenIds.value = JSON.stringify(values);
        this.submit();
      },
    },
  };
</script>

<style scoped lang="scss">
  // TODO: Shared styles across text widgets
  .loading {
    opacity: 50%;
  }
  .tei-passage {
    font-family: 'Gentium Book Plus';
    font-size: 1.2rem;
  }
  .tei-content {
    width: 100%;
  }
  :deep(.tei-content) {
    font-family: 'Gentium Book Plus';
    font-size: 1.2rem;
    word-spacing: normal;
    // NOTE: Mimics the default paragraph spacing
    // .milestone {
    //   margin-bottom: 1rem;
    // }

    // NOTE: From Interlinear Display on beyond-translation
    // .milestone {
    //   margin: 20px 0px;
    // }

    // NOTE: From John 14 demo
    .milestone:not(:first-of-type) {
      margin-top: 2rem;
    }
  }
  .sgr {
    .word {
      display: block;
    }
  }
  .sgl {
    .gloss {
      display: block;
    }
  }
  .osis-ref {
    display: block;
    text-align: right;
    opacity: 50%;
  }
  .controls {
    text-align: right;
    margin-top: 1em;
  }
  .no-token {
    opacity: 10%;
  }
</style>
