<script setup>
  import { defineProps, defineEmits, ref } from 'vue';

  const INITIAL_CURSOR_POS = 0;

  const props = defineProps({
    totalNumberOfResults: Number,
    paginationCursor: Number,
    resultsPerPage: Number,
  });

  const emit = defineEmits({
    'update-page': {
      newCursor: Number,
      newPageSize: Number,
    },
  });
  const pageSizeOptions = [5, 10, 20, 50, 100];
  const pageSize = ref(props?.resultsPerPage);
  const maxPageNumbers = ref(Math.ceil(parseInt(props.totalNumberOfResults, 10) / pageSize.value));

  // NOTE: Work with zero-based pagination, but display as one-based.
  const currentPageZero = ref(Math.ceil(props.paginationCursor / pageSize.value));
  const currentPage = currentPageZero.value + 1;
  function getNumberOfPages() {
    const pageNumbers = [];
    for (let i = 1; i <= maxPageNumbers.value; i += 1) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  function getPageNumberButtons() {
    const pageNumbers = getNumberOfPages();
    let pageNumberButtons = pageNumbers.map(pageNumber => {
      const isCurrentPage = pageNumber === currentPage;
      const isFirstPage = pageNumber === 1;
      const isLastPage = pageNumber === maxPageNumbers.value;
      // eslint-disable-next-line no-nested-ternary
      const buttonClass = isCurrentPage
        ? 'current-page'
        : // eslint-disable-next-line no-nested-ternary
        isFirstPage
        ? 'first-page'
        : // eslint-disable-next-line no-nested-ternary
        isLastPage
        ? 'last-page'
        : '';
      return {
        pageNumber,
        buttonClass,
      };
    });
    const maxButtons = 5;
    if (pageNumberButtons.length > maxButtons) {
      const startIndex = currentPage > maxButtons ? currentPage - maxButtons : 0;
      const endIndex = startIndex + maxButtons;
      pageNumberButtons = pageNumberButtons.slice(startIndex, endIndex);
    }
    return pageNumberButtons;
  }
  function handlePageSelection(pageNumber) {
    emit('update-page', {
      newCursor: (pageNumber - 1) * pageSize.value,
      newPageSize: pageSize.value,
    });
  }
  function handleNextPage() {
    emit('update-page', {
      newCursor: props.paginationCursor + pageSize.value,
      newPageSize: pageSize.value,
    });
  }
  function handlePreviousPage() {
    emit('update-page', {
      newCursor: props.paginationCursor - pageSize.value,
      newPageSize: pageSize.value,
    });
  }
  function handlePageSizeSelection() {
    emit('update-page', {
      newCursor: INITIAL_CURSOR_POS,
      newPageSize: pageSize.value,
    });
  }
</script>

<!-- TODO: Consider implementing via Bootstrap for first / last, etchttps://bootstrap-vue.org/docs/components/pagination -->
<template>
  <div class="pagination-container">
    <button class="pagination-button" :disabled="currentPage < 2" @click="handlePreviousPage">
      {{ '\u2190' }}
    </button>
    <div class="pagination-pages">
      <button
        class="pagination-button"
        v-for="pageNumberButton in getPageNumberButtons()"
        :key="pageNumberButton.pageNumber"
        :class="pageNumberButton.buttonClass"
        @click="handlePageSelection(pageNumberButton.pageNumber)"
      >
        {{ pageNumberButton.pageNumber }}
      </button>
    </div>
    <button
      class="pagination-button"
      :disabled="currentPage === maxPageNumbers"
      @click="handleNextPage"
    >
      {{ '\u2192' }}
    </button>
    <div class="pagination-page-size-dropdown-select">
      <select
        class="pagination-page-size-select"
        @change="handlePageSizeSelection"
        v-model="pageSize"
      >
        <option
          v-for="option in pageSizeOptions"
          :key="option"
          :selected="{ selected: option === pageSize ? true : false }"
          :value="option"
        >
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</template>

<style scoped>
  button:disabled {
    cursor: not-allowed;
  }
  .pagination-container {
    display: flex;
    justify-content: center;
    gap: 1em;
    align-items: center;
    margin-bottom: 1rem;
  }
  .pagination-button {
    font-size: 1em;
    cursor: pointer;
    line-height: 2em;
    background-color: #fefefe;
    border-radius: 0.3em;
    border: 1px solid #ccc;
  }
  .pagination-pages {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: max-content;
    gap: 0.1em;
  }
  .current-page {
    background-color: lightskyblue;
    color: white;
    cursor: unset;
    pointer-events: none;
  }
  button.last-page:before {
    content: '...';
  }
  .pagination-page-size {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    padding-top: none;
    margin-top: none;
  }
  select {
    line-height: 2em;
  }
</style>
