import { inject } from 'vue';

const notReady = defaultValue => () => {
  console.error('TokenProvider is not ready. This is probably a bug in the TokenProvider.');
  return defaultValue;
};

export const getTokenIds = () => {
  return inject('getTokenIds', notReady({ value: [] }));
};

export const getToken = tokenId => {
  const fn = inject('getToken', notReady({}));
  return fn(tokenId);
};
