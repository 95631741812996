<template>
  <span class="verse-reference"><slot></slot></span>
</template>

<style scoped lang="scss">
  .verse-reference {
    font-weight: bold;
    color: rgb(64, 64, 255);
  }
</style>
