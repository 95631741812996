import { createRouter, createWebHistory } from 'vue-router';

import App from './App.vue';

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || ''),
  routes: [{ path: '/', component: App, name: 'home' }],
});

export default router;
