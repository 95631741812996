<!-- 
    Tokens have the following properties:
    - token: The word-level reference (id) of the token
      - chapterNumber: Chapter number to display with the token
      - verseNumber: Verse number to display with the token
      - value: The text value of the token without append/prepend
      - lemma: The lemma of the token
      - ### NOT IMPLEMENTED YET:
      - ### displayValue: The displayable text value of the token
      - ### prepend: The prepend value of the token
      - ### append: The append value of the token
    - altTexts: The alternate text values of the token (stacked below)
    - color: The color of the token
    - majorUnderline: The color of the underline of the token itself
    - backgroundColor: The background color of the token
    - featureUnderlines: The underline annotations (stacked if multiple) of the token

    Note: Bold and italic are not an option because they don't work
          consistently across fonts for different languages.
 -->
<template>
  <div class="outermost-container">
    <div v-if="token?.data?.chapterNumber" class="chapter">{{ token?.data?.chapterNumber }}</div>
    <div :n="token?.data?.verseNumber" v-if="token?.data?.verseNumber" class="verse">
      {{ token?.data?.verseNumber }}
    </div>
    {{ '' /* token.prepend */ }}
    <!-- Note: prepend must be a child of the template or it will be elided -->
    <div
      :data-token-id="tokenId"
      class="token-container"
      :style="tokenContainerBottomMarginStyle"
      ref="tokenDiv"
    >
      <div class="token" :style="tokenStyle">
        <div v-if="token?.majorUnderline" class="major-underline" :style="majorUnderlineStyle" />
        <!-- THIS IS WHERE THE ACTUAL VALUE GETS PRINTED OUT -->
        <div class="value">{{ displayValue }}</div>
      </div>
      <div v-if="token?.altTexts?.length" class="alt-text-container" :class="{ gloss: useGlosses }">
        <span v-for="altText in token.altTexts" :key="altText">{{ altText }}</span>
      </div>
      <div class="feature-underline-container">
        <template v-for="underlineColor in token?.featureUnderlines" :key="underlineColor">
          <div class="feature-underline" :style="{ backgroundColor: underlineColor }" />
        </template>
      </div>
    </div>
  </div>
  <span v-if="!token?.data?.skipSpaceAfter">{{ ' ' }}</span>
</template>

<script setup>
  import { computed } from 'vue';
  import { getToken } from './useToken';

  const props = defineProps({
    tokenId: {
      type: String,
      required: true,
    },
    useGlosses: {
      type: Boolean,
      default: false,
    },
  });

  const token = getToken(props.tokenId);
  const displayValue = computed(() => token?.value?.data?.value);

  const tokenContainerBottomMarginStyle = computed(() => {
    const numberOfThingsUsingVerticalSpace =
      (token?.value?.altTexts?.value?.length || 0) +
      (token?.value?.featureUnderlines?.value?.length || 0);
    const newMarginBottom = numberOfThingsUsingVerticalSpace * 0.15;
    return {
      marginBottom: `${newMarginBottom}rem`,
    };
  });
  const tokenStyle = computed(() => ({
    backgroundColor: token?.value?.backgroundColor,
    color: token?.value?.color,
  }));
  const majorUnderlineStyle = computed(() => ({
    backgroundColor: token?.value?.majorUnderline,
  }));
</script>

<style scoped lang="scss">
  .outermost-container {
    display: inline-block;
  }
  .chapter {
    position: relative;
    display: inline;
    font-size: 1.6rem;
    color: #94a3b8;
    margin-inline-start: 0.25rem;
    margin-inline-end: 0.1rem;
    font-weight: bolder;
    top: 0.2rem;
  }
  .verse {
    position: relative;
    display: inline;
    font-size: 0.7rem;
    color: #64748b;
    margin-left: 0.25rem;
    margin-right: 0.1rem;
    font-weight: bold;
    top: -0.25rem;
  }
  .token-container {
    // border: 1px solid gray;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &:hover .token {
      color: #6366f1;
    }
    &:active .token {
      color: #a5b4fc !important;
    }

    .token {
      display: inline-block;
      position: relative;
      z-index: 1;

      .major-underline {
        position: absolute;
        inset-inline-start: 0;
        bottom: 0;
        height: 40%;
        width: 100%;
        z-index: -1;
      }

      .value {
        display: inline-block;
      }
    }

    .alt-text-container {
      padding: 0 0.5rem;
      font-size: 0.8em;
      &.gloss {
        padding: 0 0.1rem;
        margin-top: -0.3rem;
        font-size: 0.7em;
      }
      color: gray;
    }

    .feature-underline-container {
      width: 100%;

      .feature-underline {
        height: 0.2rem;
        width: 100%;
        margin-top: 0.2rem;
      }
    }
  }
</style>
