<template>
  <div ref="thisWidget">
    <LoadingSpinner v-if="$apollo.loading" />
    <template v-if="!tree || !rootLevelAncestor">
      <EmptyMessage>Instance is not loaded</EmptyMessage>
      <button @click="$emit('back')">Back</button>
    </template>

    <template v-else>
      <div class="sticky-header">
        <div class="nav">
          <span class="location">{{ rootLevelAncestor?.label || rootLevelAncestor.uri }}</span>
          <button @click="$emit('back')">Back</button>
        </div>
        <template v-if="tree.depth > 1 && (tree.label || tree.uri)">
          <div class="pinnedLabel">{{ tree.label || tree.uri }}</div>
          <div v-if="!traverseFlag">
            <button v-if="ancestors.length > 1" @click="traverseFlag = true">Traverse</button>
            <button @click.stop="pinAsRoot(rootLevelAncestor.uri)" title="Unpin">
              <font-awesome-icon icon="fa-solid fa-thumbtack" size="xs" />
              Unpin (back to root)
            </button>
            <NextPrevButtons
              :previousSibling="tree.previousSibling"
              :nextSibling="tree.nextSibling"
              @pinAsRoot="pinAsRoot"
            />
          </div>
          <div v-else>
            <button @click="traverseFlag = false">Cancel</button>
            <select v-model="ancestorSelector">
              <!-- we have to ignore the last ancestor because it's self -->
              <option
                v-for="ancestor in ancestors.slice(0, -1)"
                :key="ancestor.id"
                :value="ancestor.uri"
              >
                {{ getLabel(ancestor) }}
              </option>
            </select>
            <button @click.stop="pinAsRoot(ancestorSelector)" title="Pin This">
              <font-awesome-icon icon="fa-solid fa-thumbtack" size="xs" />
              Pin This
            </button>
            <NextPrevButtons
              :previousSibling="tree.previousSibling"
              :nextSibling="tree.nextSibling"
              @pinAsRoot="pinAsRoot"
            />
          </div>
        </template>
        <div v-if="scriptureReference">
          <button @click="pushCurrentTokenRange">{{ scriptureReference }}</button>
        </div>
        <div>
          <button @click="expandTreeItems">Expand</button>
          <button @click="collapseTreeItems">Collapse</button>
        </div>
      </div>

      <div v-if="tree?.children" class="tree-container">
        <EmptyMessage v-if="tree.children.length === 0">
          There are no children attached to this instance yet.
        </EmptyMessage>
        <div v-else>
          <VueTreeDnd
            v-model="tree.children"
            :component="$treeItem"
            :locked="isLocked"
            @move="moveNode"
          />
          <div class="keyboard-hint">
            Hold
            <span class="keyboard-key">ctrl</span>
            to keep current token highlights
          </div>
        </div>
      </div>

      <div
        v-if="addOrEditMode.mode === 'add' && addOrEditMode.uri === currentInstanceUri"
        class="add-instance-container"
      >
        <InlineCreateChildInstance
          :parentUri="currentInstanceUri"
          :annotationSetUri="annotationSetUri"
          :annotationFeatures="annotationFeatures"
          :proposedTokenIds="proposedTokenIds"
          @deselectTokens="deselectTokens"
          @setRecentlyUpdatedInstanceUri="setRecentlyUpdatedInstanceUri"
          @close="toggleAddOrEditMode(false)"
        />
      </div>
      <template v-else>
        <button @click="toggleAddOrEditMode(true)">Add Child</button>
      </template>
    </template>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import { computed } from 'vue';
  import VueTreeDnd from 'vue-tree-dnd';
  import formatOsis, { paratextToOsis } from 'bible-reference-formatter';

  import { TOKEN_ID_FIELD, APOLLO_QUERY_FOR_ANNOTATION_INSTANCES } from '@/store/constants';
  import { getMostLikelyRefFromFreeInput } from '@/common/refUtils';

  import { mapDescendantsToTree } from '@/common/apolloUtils';
  import { flattenTree } from '@/common/annotationUtils';
  import TreeItem from './TreeItem.vue';
  import InlineCreateChildInstance from './InlineCreateChildInstance.vue';
  import NextPrevButtons from './NextPrevButtons.vue';
  import EmptyMessage from '../../components/EmptyMessage.vue';
  import LoadingSpinner from '../../components/LoadingSpinner.vue';

  const EXPAND_NODES_DEFAULT = true;

  let recentUriTimeout = null;
  let attempts = 0;

  const getValueOrFallback = (key, lookup, fallback) =>
    lookup && key in lookup ? lookup[key] : fallback;

  const setTreeNodesExpanded = (tree, value, expandedByUri) => {
    const traverse = node => {
      const expanded = getValueOrFallback(node.uri, expandedByUri, value);
      return {
        ...node,
        children: node.children.map(traverse),
        expanded,
      };
    };
    return traverse(tree);
  };

  const getExpandedByUri = flatTree => {
    return Object.fromEntries(
      flatTree.map(node => {
        return [node.uri, node.expanded];
      }),
    );
  };

  export default {
    props: {
      currentInstanceUri: {
        type: String,
        required: true,
      },
      annotationSetUri: {
        type: String,
        required: false,
      },
      annotationFeatures: {
        type: Array,
        required: false,
      },
      proposedTokenIds: {
        type: Array,
        required: false,
      },
      // i.e., recently updated/changed instance
      activeInstanceUri: {
        type: String,
        required: false,
      },
      rtl: {
        type: Boolean,
        required: false,
      },
    },
    created() {
      this.$treeItem = TreeItem;
    },
    provide() {
      return {
        annotationSetUri: computed(() => this.annotationSetUri),
        annotationFeatures: computed(() => this.annotationFeatures),
        proposedTokenIds: computed(() => this.proposedTokenIds),
        recentlyUpdatedInstanceUri: computed(() => this.recentlyUpdatedInstanceUri),
        setRecentlyUpdatedInstanceUri: this.setRecentlyUpdatedInstanceUri,
        pinAsRoot: this.pinAsRoot,
        deleteInstance: this.deleteInstance,
        setHoveredUri: this.setHoveredUri,
        setOsisRefRange: this.setOsisRefRange,
        deselectTokens: this.deselectTokens,
        locked: computed(() => this.isLocked),
        setLock: this.setLock,
        rtl: computed(() => this.rtl),
      };
    },
    data() {
      return {
        isDragging: false,
        addOrEditMode: {
          mode: null,
          uri: null,
        },
        isLocked: false,
        recentlyUpdatedInstanceUri: null,
        traverseFlag: false,
        tree: {},
      };
    },
    emits: ['back', 'setHoveredUri', 'setOsisRefRange', 'deselectTokens', 'setCurrentInstanceUri'],
    watch: {
      activeInstanceUri: {
        handler() {
          this.setRecentlyUpdatedInstanceUri(this.activeInstanceUri);
        },
      },
      ancestors: {
        handler() {
          this.traverseFlag = false;
          this.ancestorSelector = this.ancestors.slice(-2)[0].uri;
        },
      },
      treeQuery: {
        handler(newTree, oldTree) {
          if (!newTree) {
            // No data yet; nothing to render
            return;
          }
          if (!oldTree) {
            // No tree yet, so we can use the default value
            this.tree = setTreeNodesExpanded(newTree, EXPAND_NODES_DEFAULT);
            return;
          }
          // Preserve current expansion states when traversing up/down
          const expandedByUri = getExpandedByUri(this.flatTree);
          this.tree = setTreeNodesExpanded(newTree, EXPAND_NODES_DEFAULT, expandedByUri);
        },
        immediate: true,
      },
    },
    methods: {
      setDragging(isDragging) {
        this.isDragging = isDragging;
      },
      toggleAddOrEditMode(turnOn) {
        this.addOrEditMode =
          turnOn === false || this.addOrEditMode.uri === this.currentInstanceUri
            ? {
                mode: null,
                uri: null,
              }
            : {
                mode: 'add',
                uri: this.currentInstanceUri,
              };
      },
      deselectTokens() {
        this.$emit('deselectTokens');
      },
      setHoveredUri(data) {
        if (this.isLocked) {
          return;
        }
        this.$emit('setHoveredUri', data);
      },
      setLock(value) {
        this.isLocked = value;
      },
      setRecentlyUpdatedInstanceUri(uri) {
        this.recentlyUpdatedInstanceUri = uri;
        clearTimeout(recentUriTimeout);
        recentUriTimeout = setTimeout(() => {
          this.recentlyUpdatedInstanceUri = null;
        }, 4000);

        // ATTEMPT_LIMIT_INTERVAL * ATTEMPT_LIMIT
        //    = number of seconds to try to find dom node
        // We should try long enough for the gql round tripping
        // But not so long that the user is busy with something else
        // 100 * 50 = 10s (5000ms)
        const ATTEMPT_LIMIT_INTERVAL = 50;
        const ATTEMPT_LIMIT = 100;
        attempts = 0;
        const scrollTo = () => {
          attempts += 1;
          if (!this.$refs?.thisWidget?.querySelector(`[data-tree-item-uri="${uri}"]`)) {
            if (attempts > ATTEMPT_LIMIT) {
              console.error('Could not find node to scroll to', uri);
              return;
            }
            setTimeout(scrollTo, ATTEMPT_LIMIT_INTERVAL);
            return;
          }
          this.$nextTick(() => {
            this.$refs?.thisWidget?.querySelector(`[data-tree-item-uri="${uri}"]`).scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          });
        };
        scrollTo();
      },
      setOsisRefRange(data) {
        this.$emit('setOsisRefRange', data);
      },
      pushCurrentTokenRange() {
        if (!this.scriptureReference) {
          return;
        }

        const scriptureRangeObj = getMostLikelyRefFromFreeInput(this.scriptureReference, {
          respondWithRange: true,
        });
        this.setOsisRefRange(scriptureRangeObj);
      },
      moveNode({ id, targetId, position }) {
        // uri references the source node
        const uri = this.flatTree.find(node => node.id === id)?.uri;
        const targetUri = this.flatTree.find(node => node.id === targetId)?.uri;

        if (!uri || !targetUri) {
          console.error('moveNode', uri, targetUri, 'missing');
          return;
        }

        // skip if the node is being moved to the same place
        if (uri === targetUri) {
          return;
        }
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($uri: String!, $targetUri: String!, $position: TreebeardPositions!) {
                moveAnnotation(uri: $uri, targetUri: $targetUri, position: $position) {
                  __typename
                  ... on Annotation {
                    id
                    label
                    uri
                    depth
                    tokens {
                      ${TOKEN_ID_FIELD}
                      ref
                    }
                  }
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables: {
              uri,
              targetUri,
              position,
            },
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_INSTANCES],
          })
          .then(({ data }) => {
            // eslint-disable-next-line no-underscore-dangle
            if (data.moveAnnotation.__typename === 'OperationInfo') {
              console.error('moveNode error', data.moveAnnotation);
            }
          })
          .catch(error => {
            console.error('there was an error sending the query', error);
          });
      },
      pinAsRoot(instanceUri) {
        this.$emit('setCurrentInstanceUri', instanceUri);
      },
      deleteInstance(instanceId) {
        // eslint-disable-next-line no-alert
        const areYouSure = window.confirm(
          'Are you sure you want to delete this instance and any children?',
        );
        if (!areYouSure) {
          return;
        }
        this.$apollo.mutate({
          mutation: gql`
            mutation ($id: ID!) {
              deleteAnnotation(id: $id) {
                __typename
                ... on Annotation {
                  label
                  uri
                }
                ... on OperationInfo {
                  messages {
                    kind
                    message
                    field
                  }
                }
              }
            }
          `,
          variables: {
            id: instanceId,
          },
          refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_INSTANCES],
        });
      },
      expandTreeItems() {
        this.tree = setTreeNodesExpanded(this.tree, true);
      },
      collapseTreeItems() {
        this.tree = setTreeNodesExpanded(this.tree, false);
      },
      getLabel(instance) {
        const prefix = instance.depth > 0 ? '• '.repeat(instance.depth - 1) : '';
        return instance?.label
          ? `${prefix}${instance.label} (${instance.feature.label})`
          : prefix + (instance.feature?.label || instance.uri);
      },
    },
    apollo: {
      treeQuery: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_INSTANCES} ($instanceUri: String!) {
            annotations(filters: { uri: { exact: $instanceUri } }) {
              id
              depth
              uri
              label
              scriptureReference {
                usfmRef
              }
              descendants {
                id
                uri
                label
                depth
                feature {
                  label
                }
                scriptureReference {
                  usfmRef
                }
              }
              descendantsTree
              previousSibling {
                id
                uri
                label
                feature {
                  label
                }
              }
              nextSibling {
                id
                uri
                label
                feature {
                  label
                }
              }
            }
          }
        `,
        skip() {
          return !this?.currentInstanceUri;
        },
        variables() {
          return { instanceUri: this.currentInstanceUri };
        },
        update(data) {
          if (data?.annotations?.length < 1) {
            return [];
          }
          const { descendants, descendantsTree, ...restOfRootAnnotation } = data.annotations[0];
          return {
            ...restOfRootAnnotation,
            children: mapDescendantsToTree(descendants, descendantsTree),
          };
        },
      },
      ancestors: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_INSTANCES} ($instanceUri: String!) {
            annotations(filters: { uri: { exact: $instanceUri } }) {
              ancestors(includeSelf: true) {
                id
                uri
                label
                depth
                feature {
                  id
                  label
                }
              }
            }
          }
        `,
        skip() {
          return !this?.currentInstanceUri;
        },
        variables() {
          return { instanceUri: this.currentInstanceUri };
        },
        update(data) {
          if (data?.annotations?.length !== 1) {
            return [];
          }

          return data.annotations[0].ancestors;
        },
      },
    },
    computed: {
      flatTree() {
        return flattenTree(this.tree);
      },
      // TODO: Refactor with TreeItem
      scriptureReference() {
        const usfmRef = this.tree?.scriptureReference?.usfmRef;
        if (!usfmRef) {
          return '';
        }
        const osisRef = paratextToOsis(usfmRef);
        return formatOsis('niv-short', osisRef);
      },
      rootLevelAncestor() {
        return this.ancestors?.find(ancestor => ancestor.depth === 1);
      },
    },
    components: {
      InlineCreateChildInstance,
      VueTreeDnd,
      EmptyMessage,
      LoadingSpinner,
      NextPrevButtons,
    },
  };
</script>

<style scoped lang="scss">
  .sticky-header {
    position: sticky;
    top: 0;
    background-color: rgba(247, 247, 247, 0.85);
    z-index: 1;
    padding: 0.5rem 0;
    margin-top: -0.5rem;

    .nav {
      display: flex;
      flex-direction: row;
      align-items: center;

      .location {
        font-weight: bold;
        font-size: 1.3rem;
        margin-right: 1rem;
      }
    }

    .pinnedLabel {
      font-weight: bold;
      margin-right: 1rem;
    }
  }

  .tree-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .keyboard-hint {
    font-size: 0.8rem;
    color: #6b7280;
    padding-top: 0.5rem;

    .keyboard-key {
      border: 1px solid #9ca3af;
      padding: 0 0.2rem;
      border-radius: 0.2rem;
    }
  }

  .add-instance-container {
    border-top: 3px solid #eee;
  }
  :deep(.token-styles) {
    font-family: 'Gentium Book Plus';
    &.rtl {
      font-family: 'SBLBibLit';
      font-size: 1.1rem;
      line-height: 1.7;
      direction: rtl;
    }
  }
</style>
