<template>
  <div>
    <LoadingSpinner v-if="$apollo.loading" />
    <TokenProvider :tokens="apolloTokens">
      <EmptyMessage v-if="!currentAnnotationSetUri">
        Before you can create an instance from a token selection, you will need to select an
        Annotation Set. You can do this using the
        <span class="inline-widget-name-style">Annotation Sets</span>
        or the
        <span class="inline-widget-name-style">Rapid Discourse Flow Widget</span>
        widget.
      </EmptyMessage>
      <template v-else>
        <table>
          <tr>
            <td>Annotation Set:</td>
            <td>{{ annotationSetLabel ?? parentAnnotationInstanceUri }}</td>
          </tr>
          <tr>
            <td>Feature:</td>
            <td>
              <select v-model="selectedAnnotationFeature">
                <option
                  v-for="annotationFeature in annotationFeatures || []"
                  :value="annotationFeature.uri"
                  :key="annotationFeature.uri"
                >
                  {{ annotationFeature?.label || annotationFeature.uri }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Parent:</td>
            <td>
              <label>
                <input
                  type="checkbox"
                  v-model="addToParent"
                  :disabled="!this.parentAnnotationInstanceUri"
                />
                Add to Parent
              </label>
              <div v-if="addToParent">{{ shortenUri(parentAnnotationInstanceUri) }}</div>
            </td>
          </tr>
          <tr>
            <td>Label:</td>
            <td>
              <input placeholder="Label (optional)" v-model="newInstanceLabel" />
              <template v-for="error in fieldErrors.filter(f => d === 'label')" :key="error">
                <div class="error-message">{{ error.message }}</div>
              </template>
            </td>
          </tr>
          <tr>
            <td>Tokens:</td>
            <td>
              <template v-if="parsedSelectedTokenIds.length > 0">
                <div>
                  <Token
                    v-for="token in apolloTokens || []"
                    :tokenId="token.tokenId"
                    :key="token.tokenId"
                  />
                </div>
                <label>
                  <input type="checkbox" v-model="shouldSortTokens" />
                  Sort Tokens
                </label>
              </template>
              <EmptyMessage v-else>None</EmptyMessage>
            </td>
          </tr>
        </table>
        <button @click="createAnnotation">Create New Annotation</button>
      </template>
    </TokenProvider>
  </div>
</template>

<script>
  import { gql } from 'graphql-tag';
  import mixins from '@/mixins';
  import {
    DORIC_VARIABLE_UNSET,
    DEFAULT_GROUP_KEY,
    TOKEN_ID_FIELD,
    APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
    APOLLO_QUERY_FOR_ANNOTATION_FEATURES,
    APOLLO_QUERY_FOR_ANNOTATION_INSTANCES,
  } from '@/store/constants';
  import TokenProvider from '../tokens/TokenProvider.vue';
  import Token from '../tokens/Token.vue';
  import EmptyMessage from '../components/EmptyMessage.vue';
  import LoadingSpinner from '../components/LoadingSpinner.vue';

  export default {
    name: 'CreateInstanceFromTokensWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          parentAnnotationInstanceUri: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'parentAnnotationInstanceUri',
          },
          selectedTokenIds: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'selectedTokenIds',
          },
          currentAnnotationSetUri: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'currentAnnotationSetUri',
          },
        },
        outputs: {
          selectedLemma: DORIC_VARIABLE_UNSET,
          selectedTokenIds: DORIC_VARIABLE_UNSET,
          osisRef: DORIC_VARIABLE_UNSET,
          activeInstanceUri: DORIC_VARIABLE_UNSET,
        },
      },
    },
    data: () => ({
      fieldErrors: [],
      addToParent: false,
      annotationSetLabel: '',
      newInstanceLabel: '',
      newInstanceUri: '',
      parsedSelectedTokenIds: [],
      currentAnnotationInstance: null,
      selectedAnnotationFeature: '',
      shouldSortTokens: true,
    }),
    watch: {
      selectedTokenIds: {
        handler() {
          if (!this.selectedTokenIds) {
            this.parsedSelectedTokenIds = [];
            return;
          }
          try {
            const tokenIds = JSON.parse(this.selectedTokenIds);
            if (!Array.isArray(tokenIds)) {
              this.parsedSelectedTokenIds = [];
              throw new Error('selectedTokenIds is not an array');
            }
            this.parsedSelectedTokenIds = tokenIds;
          } catch (e) {
            this.parsedSelectedTokenIds = [];
          }
        },
        immediate: true,
      },
      parentAnnotationInstanceUri: {
        handler() {
          this.addToParent = !!this.parentAnnotationInstanceUri;
        },
        immediate: true,
      },
    },
    apollo: {
      annotationFeatures: {
        // Get the parent annotation set's children[features] (i.e., siblings)
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_FEATURES}($uri: String!) {
            annotationSets(filters: { uri: $uri }) {
              label
              features {
                uri
                label
                id
              }
            }
          }
        `,
        fetchPolicy: APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
        skip() {
          return !this.currentAnnotationSetUri;
        },
        variables() {
          return {
            uri: this.currentAnnotationSetUri,
          };
        },
        update(data) {
          if (
            !data?.annotationSets?.[0]?.features ||
            data.annotationSets[0].features.length === 0
          ) {
            return null;
          }
          this.$nextTick(() => {
            this.selectedAnnotationFeature = data.annotationSets[0].features[0].uri;
          });
          this.annotationSetLabel = data.annotationSets[0].label;
          return data.annotationSets[0].features;
        },
      },
      apolloTokens: {
        query: gql`
          query WordTokenQuery($tokenIds: [ID!]!) {
            wordTokens(filters: { ${TOKEN_ID_FIELD}: { inList: $tokenIds } }) {
              id
              ref
              lemma
              value
            }
          }
        `,
        skip() {
          return this.parsedSelectedTokenIds.length === 0;
        },
        variables() {
          return {
            tokenIds: this.parsedSelectedTokenIds,
          };
        },
        update(data) {
          return data.wordTokens.map(token => ({
            tokenId: token[TOKEN_ID_FIELD],
            data: {
              // We don't want to be dumping the annotations here and we don't need other gql metadata
              ref: token.ref,
              lemma: token.lemma,
              value: token.value,
            },
          }));
        },
      },
    },
    methods: {
      shortenUri(uri) {
        if (!uri) return '';
        const parts = uri.split('/').filter(p => p);
        const lastPart = parts[parts.length - 1];
        const lastPartShort =
          lastPart.length > 35 ? `${lastPart.slice(0, 15)}...${lastPart.slice(-20)}` : lastPart;
        return `${uri.slice(0, 10)}.../${lastPartShort}`;
      },
      createAnnotation() {
        const variables = {
          label: this.newInstanceLabel,
          featureUri: this.selectedAnnotationFeature,
          tokens: this.parsedSelectedTokenIds,
          sortTokens: this.shouldSortTokens,
        };
        if (this.addToParent) {
          variables.parentUri = this.parentAnnotationInstanceUri;
        }

        this.$apollo
          .mutate({
            mutation: gql`
              mutation (
                $featureUri: String!
                $label: String
                $parentUri: String
                $tokens: [ID!]
                $sortTokens: Boolean
              ) {
                createAnnotation(
                  annotationFeatureUri: $featureUri
                  label: $label
                  parentUri: $parentUri
                  tokens: $tokens
                  sortTokens: $sortTokens
                ) {
                  __typename
                  ... on Annotation {
                    label
                    uri
                  }
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables,
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_INSTANCES],
          })
          .then(({ data }) => {
            // eslint-disable-next-line no-underscore-dangle
            if (data.createAnnotation.__typename === 'Annotation') {
              this.outputs.selectedTokenIds.value = '[]';
              this.outputs.activeInstanceUri.value = data.createAnnotation.uri;
              this.submit();
            } else {
              console.error('createInstanceFromTokens', data);
            }
          })
          .catch(error => {
            console.error('createInstanceFromTokens', error);
          });
      },
    },
    components: {
      TokenProvider,
      Token,
      EmptyMessage,
      LoadingSpinner,
    },
  };
</script>

<style scoped>
  .loading {
    pointer-events: none;
    opacity: 0.5;
  }
  .inline-widget-name-style {
    border: 1px solid #ccc;
    padding: 0 0.25rem;
    margin: 0 0.25rem;
    border-radius: 0.25rem;
    background-color: #e8e8e8;
  }

  label {
    user-select: none;
  }
</style>
