<script setup>
  import { defineEmits } from 'vue';

  const controls = [
    { label: '◀', emit: 'left', subLabel: 'left arrow key' },
    { label: '▶', emit: 'right', subLabel: 'right arrow key' },
    { label: 'Enter', emit: 'new-line', subLabel: 'new line' },
    { label: 'Tab', emit: 'indent', subLabel: 'indent' },
    { label: 'Shift + Tab', emit: 'dedent', subLabel: 'dedent' },
    { label: 'Alt + Enter', emit: 'toggle-subword-editing', subLabel: 'toggle subword editing' },
    { label: 'Space', emit: 'split-word', subLabel: 'split word' },
    { label: 'Backspace', emit: 'backspace', subLabel: 'backspace' },
    { label: 'Shift + ◀', emit: 'swap', subLabel: 'swap with previous word/group' },
    { label: 'Shift + ▶', emit: 'swap', subLabel: 'swap with previous word/group' },
  ];

  const emit = defineEmits({
    control: String,
  });

  function handleClick(e, control) {
    e.target.classList.add('control-button-clicked');
    emit('control', control.emit);
    setTimeout(() => {
      e.target.classList.remove('control-button-clicked');
    }, 200);
  }
</script>

<template>
  <div class="controls-container">
    <button
      v-for="control in controls"
      :key="control"
      class="control-button"
      @click="e => handleClick(e, control)"
    >
      <span class="key-label">{{ control.label }}</span>
      <span class="key-sub-label">{{ control.subLabel }}</span>
    </button>
  </div>
</template>

<style scoped>
  .controls-container {
    direction: ltr;
    /* position: fixed; */
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0.3em;
    /** slightly darken background */
    background: #02668a;
    opacity: 0.9;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    user-select: none;
    transition: bottom 0.4s;
  }
  .control-button {
    padding: 0.5em;
    margin: 3px;
    border-radius: 4px;
    border: none;
    background: rgba(255, 255, 255, 0.3);
    color: #ffffff;
    font-size: 1.05rem;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    padding: 0.3em;
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }
  .control-button:active {
    background: rgba(255, 255, 255, 0.12);
  }
  .control-button--active::after {
    background: #08ff00;
  }
  .control-button--wide {
    width: 12%;
  }
  .control-button--extra-wide {
    width: 36%;
    max-width: 500px;
  }
  .control-button--activatable::after {
    content: '';
    top: 10px;
    right: 10px;
    position: absolute;
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
  }
  .control-button--dark {
    background: rgba(0, 0, 0, 0.25);
  }
  .key-label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 5px;
  }
  .key-sub-label {
    font-size: 0.8rem;
    font-weight: normal;
    margin-right: 5px;
    text-transform: capitalize;
  }
</style>
