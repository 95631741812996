import {
  DEFAULT_OSIS_REF,
  DEFAULT_GROUP_KEY,
  LOCAL_DATA_STATE,
  TEXT_STRUCTURE_KEY_FOR_VERSES,
} from '@/store/constants';

export const defaultVariableGroupFields = {
  // default variable group values
  num1: 2,
  num2: 12,
  selectedLemma: null,
  osisRef: DEFAULT_OSIS_REF,
  showBaseText: 1,
  selectedGlosses: '',
  structure: TEXT_STRUCTURE_KEY_FOR_VERSES,
  alignment: null,
  treedownNode: null,
  json: null,
};

export const defaultWidgetStateValues = {
  expanded: true,
  generation: 1, // NOTE: generation is the index of the widget in the column
};

export const defaultColumnValues = {
  expanded: true,
  width: 1,
  widgets: [],
  generation: 1, // NOTE: generation is the index of the column in the workspace
  nextGeneration: 2, // NOTE: nextGeneration is the index of the next widget to be added to the column
};

export const defaultWorkspaceValues = {
  workspaceName: '',
  readOnly: false,
  globalTokens: new Map(),
  nextGeneration: 1, // NOTE: The default workspace populates with 0 columns, so the next column generation is 1.
  columns: [],
  globalFields: defaultVariableGroupFields,
  variableGroups: [
    { label: DEFAULT_GROUP_KEY, key: DEFAULT_GROUP_KEY, variables: defaultVariableGroupFields },
  ],
};

export function generateRandomHexColor() {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColor}`;
}

export function camelCaseToCapitalizeEachWord(camelCaseString) {
  // do not add space between multiple capital letters (e.g. 'USFM')
  return camelCaseString
    .replace(/([A-Z])([^A-Z])/g, ' $1$2')
    .replace(/\s./, str => str.toUpperCase());
}

// turn SomeWidget into some-widget
export function camelCaseToKebabCase(camelCaseString) {
  return camelCaseString.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

// turn some-widget into SomeWidget
// FIXME: look for a vue hook that does this
export function kebabCaseToCamelCase(kebabCaseString) {
  return kebabCaseString
    .replace(/-([a-z])/g, g => g[1].toUpperCase())
    .replace(/^[a-z]/, g => g.toUpperCase());
}

function handleSimpleInput(input, key) {
  // TODO: refactor based on updated send/listen logic in widget configuration

  // If a widget is configured with { doric:
  //  { inputs:
  //    { label: 'My Label', endpoint: 'https://api.endpoint.com' }
  //  }
  // }
  // then the simple inputs should be converted to the following:
  // { doric:
  //  { inputs:
  //    { label: { value: 'My Label', groupKey: LOCAL_DATA_STATE },
  //      endpoint: { value: 'https://api.endpoint.com', groupKey: LOCAL_DATA_STATE }
  //    }
  //  }
  // }
  const inputIsSimple = !input || typeof input !== 'object';
  if (inputIsSimple) {
    return { value: input, groupKey: LOCAL_DATA_STATE }; // NOTE: Any changes to input handling should be reflected in the `handleSimpleOutput` function
  }
  // If input is not simple, and the value is the
  // variable key the widget is listening to, then
  // the input is correctly configured to listen to
  // an external variable.
  if (input.value === key && input.groupKey) {
    return input;
  }
  // If input is not simple, and the groupKey is local,
  // then the input is correctly configured to listen to
  // a local value.
  if (input.groupKey === LOCAL_DATA_STATE) {
    return { value: input.value || null, groupKey: LOCAL_DATA_STATE };
  }
  // Catch misconfigured inputs and add defaults
  return {
    ...input, // store any extra information passed in by the widget config
    value: key, // since this is not a local/hardcoded input, the value should be the variable key
    groupKey: input.groupKey || DEFAULT_GROUP_KEY, // since no groupkey was provided, use the default group key
  };
}
function handleSimpleOutput(output, key) {
  // TODO: refactor based on updated send/listen logic in widget configuration

  // If a widget is configured with { doric: { outputs: { foo: null, bar: null } }
  // then the output should send `null` to 'foo' and 'bar' on the default groupKey.
  // If a widget is configured with { doric: { outputs: { osisRef: 'John.14.1' } } }
  // then the output should send the value of the widget to 'John.14.1',
  // and send `osisRef` on the default groupKey.
  if (!output || typeof output !== 'object') {
    return { value: output, groupKey: DEFAULT_GROUP_KEY, send: key }; // NOTE: Any changes to output handling should be reflected in the `handleSimpleInput` function
  }
  return output;
}

function getWidgetDataByLabel(label, allWidgets) {
  // TODO: Suggest standardizing around kebab-case or PascalCase
  // per Vue style guide:
  // https://v2.vuejs.org/v2/style-guide/?redirect=true#Single-file-component-filename-casing-strongly-recommended
  const widgetData =
    allWidgets[label] ||
    allWidgets[kebabCaseToCamelCase(label)] ||
    Object.values(allWidgets).find(
      // This additional check is necessary because sometimes widgets
      // have names that include acronyms that do not match the generated
      // component name. E.g., 'LSJDictionaryEntryWidget' becomes
      // 'lsjdictionary-entry-widget'
      widget => widget.component === label,
    );
  if (!widgetData) {
    throw new Error(`Widget with label ${label} not found in '@/config/widgets.js' file.`);
  }
  try {
    return widgetData;
  } catch (e) {
    console.error(
      `Could not find Doric configuration for widget ${label}.
      Did you add the correct widget name to your workspace configuration?
      If so, the widget may be misconfigured.
      Either add a doric prop to the widget's defineProps (if using Vue's composition API), or else add a doric option to the widget alongside its data(), methods, etc. (if using Vue's options API).
      Error message: ${e}.
      Widget Data: ${widgetData}`,
    );
  }
  return null;
}

export function processWidgets(unprocessedWidgets, allWidgets) {
  // Iterate over each widget component and access
  // the widget's doric configuration, if any,
  // and handle any simple inputs or outputs

  const processedWidgets = {};
  // If allWidgetsInput is not provided, then use unprocessedWidgetsArray
  // as the source of truth for all widgets. In widgets.js, the first
  // argument is an array of all of the widgets. In processColumnWidgets,
  // the second argument is an object of all of the widgets, and the first
  // argument is an array of the widgets in the column that need processing
  // because they have overrides in the workspace configuration.

  unprocessedWidgets.forEach(widget => {
    const widgetLabel = kebabCaseToCamelCase(widget.component);
    const widgetDefaults = getWidgetDataByLabel(widgetLabel, allWidgets);
    // const passedInputsAndOutputs
    const { doric } = widgetDefaults.props;
    if (!doric) {
      throw new Error(`Widget ${widgetLabel} is missing a Doric configuration.`);
    } else {
      const updatedInputs = {};
      if (doric.inputs) {
        Object.keys(doric.inputs).forEach(key => {
          const updatedInput = handleSimpleInput(doric.inputs[key], key);
          updatedInputs[key] = updatedInput;
        });
      }
      const updatedOutputs = {};
      if (doric.outputs) {
        Object.keys(doric.outputs).forEach(key => {
          const updatedOutput = handleSimpleOutput(doric.outputs[key], key);
          updatedOutputs[key] = updatedOutput;
        });
      }

      const updatedDoricConfiguration = {
        ...doric,
        name: widgetLabel,
        inputs: {
          ...widgetDefaults?.props?.doric?.inputs,
          ...updatedInputs,
        },
        outputs: {
          ...widgetDefaults?.props?.doric?.outputs,
          ...updatedOutputs,
        },
      };

      const updatedWidgetData = {
        component: widget.component,
        ...updatedDoricConfiguration,
      };

      processedWidgets[widgetLabel] = updatedWidgetData;
    }
  });
  return processedWidgets;
}

function populateColumnDefaults({ widgetsInColumn, columnIndex, allWidgets, columnSettings }) {
  const hydratedWidgets = widgetsInColumn.map((widget, widgetIndexInColumn) => {
    // If the widget is a string, then it should be hydrated with default state
    if (typeof widget === 'string') {
      return {
        ...defaultWidgetStateValues,
        generation: widgetIndexInColumn + 1,
        ...getWidgetDataByLabel(widget, allWidgets),
      };
    }
    // If the widget is an object, then it should be hydrated with default state
    // and then overridden with any values provided in the workspace configuration
    if (typeof widget === 'object') {
      const widgetName = widget.name || kebabCaseToCamelCase(widget.component);
      const widgetDefaults = getWidgetDataByLabel(widgetName, allWidgets);
      const widgetWithOverrides = {
        ...defaultWidgetStateValues,
        generation: widgetIndexInColumn + 1,
        ...widgetDefaults,
        ...widget,
      };
      return widgetWithOverrides;
    }
    return widget;
  });
  const column = {
    ...defaultColumnValues,
    ...columnSettings,
    generation: columnIndex + 1,
    nextGeneration: widgetsInColumn.length + 1,
    widgets: hydratedWidgets,
  };
  return column;
}

// Column config should look like an array of string arrays or objects.
// String arrays should be a list of widget labels.
// Objects should be a list of widget objects.
function populateColumns(columns, allWidgets) {
  if (!columns) {
    return [];
  }
  return columns.map((column, columnIndex) => {
    // if the column is an array of strings and/or objects, then it is a list of widgets and the column can be passed directly to populateColumnDefaults
    if (
      Array.isArray(column) &&
      column.every(widgetName => typeof widgetName === 'string' || typeof widgetName === 'object')
    ) {
      return populateColumnDefaults({ widgetsInColumn: column, columnIndex, allWidgets });
    }
    // if the column is an object, then it is a column object with a widgets property
    if (typeof column === 'object' && column.widgets) {
      const columnSettings =
        // everything on the column except for widgets
        Object.keys(column).reduce((acc, key) => {
          if (key !== 'widgets') {
            acc[key] = column[key];
          }
          return acc;
        }, {});
      return populateColumnDefaults({
        widgetsInColumn: column.widgets,
        columnIndex,
        allWidgets,
        columnSettings,
      });
    }
    throw new Error(
      `Column ${columnIndex} is not formatted correctly in workspaces.js. Please ensure that each column is an array of strings (widget names) and/or objects (configured widgets), or an object with a widgets property.`,
    );
  });
}

// Workspace config should include a workspace name and a list of columns, plus any specified values with fallbacks to defaults.
function populateWorkspaceDefaults(workspace, workspaceIndex, allWidgets) {
  const workspaceWithDefaults = {
    ...defaultWorkspaceValues,
    workspaceName: `Workspace ${workspaceIndex + 1}`, // Default workspace name is index
    ...workspace,
    columns: populateColumns(workspace.columns, allWidgets),
    nextGeneration: workspace.columns?.length ? workspace.columns.length + 1 : 1,
  };
  return workspaceWithDefaults;
}

export function populateWorkspacesWithDefaults(workspacesAndOverrides, allWidgets) {
  const workspacesWithDefaults = workspacesAndOverrides.map((workspace, workspaceIndex) =>
    populateWorkspaceDefaults(workspace, workspaceIndex, allWidgets),
  );
  return workspacesWithDefaults;
}
