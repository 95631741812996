<template>
  <div>
    <div class="reference-form">
      <input
        v-model="userRef"
        :class="{ error: inputError }"
        @keyup.enter="handleSubmit"
        @input="handleChange"
      />
    </div>
    <div class="reference-info">
      <div v-show="userRef.length === 0 || !hasChangedSinceSubmit" class="infoMessage">
        Type a new reference and hit enter
      </div>
      <div v-show="!!friendlyImpliedRef && hasChangedSinceSubmit">
        {{ friendlyImpliedRef }}
      </div>
      <div class="errorMessage" v-show="userRef.length > 0">{{ validationState.message }}</div>
      <div><!-- spacer -->&nbsp;</div>
    </div>
  </div>
</template>

<script>
  import formatOsis from 'bible-reference-formatter';
  import mixins from '@/mixins';
  import bibleBooks from '@/common/bible-books-lookup.json';
  import { getMostLikelyRefFromFreeInput } from '@/common/refUtils';
  import { DEFAULT_GROUP_KEY } from '@/store/constants';

  // TODO: Decide what separator to use and standardize somewhere...
  const RANGE_SEPARATOR = '–';

  const tryFormatOsis = (format, ref) => {
    if (!ref) {
      return '';
    }
    try {
      return formatOsis(format, ref);
    } catch (e) {
      console.warn(e);
      return '';
    }
  };

  const getFriendlyRef = (start, end) => {
    if (start === end) {
      return tryFormatOsis('niv-long', start);
    }
    const [startBook, startChapter] = start.split('.');
    const [endBook, endChapter, endVerse] = end.split('.');
    if (startBook !== endBook) {
      const startRef = tryFormatOsis('niv-long', start);
      const endRef = tryFormatOsis('niv-long', end);
      return `${startRef} - ${endRef}`;
    }
    const chv = startChapter === endChapter ? endVerse : `${endChapter}:${endVerse}`;
    return `${tryFormatOsis('niv-long', start)}${RANGE_SEPARATOR}${chv}`;
  };

  const validateReference = osisRef => {
    if (!osisRef) {
      return {
        valid: false,
        message: 'Unrecognized reference',
      };
    }
    const [book, chapter, verse] = osisRef.split('.');
    // Check that chapter and verse are in book
    // TODO: From Jake's review: "we might want to surface some hints from the backend given a particular textual edition, accounting for OT / NT / Apocrypha differences across editions, etc."
    const bookData = bibleBooks.find(b => b.osisId === book);
    if (chapter in bookData.chapters) {
      if (verse > bookData.chapters[chapter]) {
        return {
          valid: false,
          message: 'Invalid verse',
        };
      }
      return {
        valid: true,
        message: '',
      };
    }

    return {
      valid: false,
      message: 'Invalid chapter',
    };
  };

  export default {
    name: 'PassageRange',
    data() {
      return {
        userRef: '',
        inputError: false,
        hasChangedSinceSubmit: false,
      };
    },
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          osisRef: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'osisRef',
          },
          osisRefRange: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'osisRefRange',
          },
        },
        outputs: {
          osisRefRange: null,
          osisRef: null,
        },
      },
    },
    watch: {
      osisRef: {
        handler() {
          this.userRef = getFriendlyRef(this.osisRef, this.osisRef);
        },
        immediate: true,
      },
      osisRefRange: {
        handler() {
          if (!this.osisRefRange) {
            return;
          }
          try {
            const { start, end } = JSON.parse(this.osisRefRange);
            this.userRef = getFriendlyRef(start, end);
          } catch (e) {
            console.error('osisRefRange', this.osisRefRange);
            console.error(e);
          }
        },
        immediate: true,
      },
    },
    computed: {
      impliedRef() {
        return getMostLikelyRefFromFreeInput(this.userRef, { respondWithRange: true });
      },
      validationState() {
        const startValid = validateReference(this.impliedRef.start);
        if (!startValid.valid) {
          return startValid;
        }

        const endValid = validateReference(this.impliedRef.end);
        if (!endValid.valid) {
          return endValid;
        }

        // Check that startChapter1 > startChapter2
        // If the same, check that startVerse1 > startVerse2
        const [startBook, startChapter, startVerse] = this.impliedRef.start.split('.');
        const [endBook, endChapter, endVerse] = this.impliedRef.end.split('.');
        if (startBook !== endBook) {
          return {
            valid: false,
            message: 'Start and end references must be in the same book',
          };
        }
        if (+startChapter > +endChapter) {
          return {
            valid: false,
            message: 'Start chapter must be before end chapter',
          };
        }
        if (startChapter === endChapter && +startVerse > +endVerse) {
          return {
            valid: false,
            message: 'Start verse must be before end verse',
          };
        }
        return {
          valid: true,
          message: '',
        };
      },
      friendlyImpliedRef() {
        return getFriendlyRef(this.impliedRef.start, this.impliedRef.end);
      },
    },
    methods: {
      handleSubmit() {
        if (this.userRef.length === 0) {
          return;
        }

        if (!this.validationState.valid) {
          this.inputError = true;
          return;
        }
        this.hasChangedSinceSubmit = false;
        this.outputs.osisRefRange.value = JSON.stringify(this.impliedRef);
        this.outputs.osisRef.value = this.impliedRef.start;
        this.userRef = this.friendlyImpliedRef;
        this.submit();
      },
      handleChange() {
        this.inputError = false;
        this.hasChangedSinceSubmit = true;
      },
    },
  };
</script>

<style scoped lang="scss">
  .reference-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 0.25rem;
      margin-right: 0.25rem;
      font-size: 1.25rem;
      font-weight: bold;
      &.error {
        color: red;
      }
    }
    button {
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 0.25rem;
      background-color: #eee;

      &:hover {
        cursor: pointer;
        background-color: #ddd;
      }
      &:active {
        background-color: #ccc;
      }
    }
  }
  .reference-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5rem;
    margin-left: 0.25rem;
    margin-bottom: 0;
    font-size: 0.75rem;

    > div {
      margin-right: 0.25rem;
    }
    .errorMessage {
      color: red;
    }
    .infoMessage {
      color: #999;
      font-style: italic;
    }
  }
</style>
