<template>
  <div class="by-treedown" :class="{ rtl: isRTL }" :data-macula-direction="isRTL ? 'rtl' : ''">
    <TreeDownNode
      v-for="(node, idx) in lowfatNodes?.children"
      :key="`tree-node-${idx}`"
      :node="node"
      :highlights="highlights"
      :hasActiveMilestone="hasActiveMilestone(node)"
      :showGreek="showGreek"
      :showGlosses="showGlosses"
      :selectedGlosses="selectedGlosses"
      :showClauses="showClausalBoundaries"
      :showMilestones="true"
      @select-token-lemma="selectTokenLemma"
      @select-token-ids="selectTokenIds"
      v-bind="node.attributes"
    />
    <toggle
      toggleName="Boundaries"
      :toggleState="showClausalBoundaries"
      @toggledState="toggleClauses"
    />
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import Toggle from '@/symphony/widgets/Toggle.vue';
  import TreeDownNode from '@/symphony/widgets/TreeDownNode.vue';
  import { visit } from 'unist-util-visit';

  export default {
    name: 'Treedown',
    mixins: [mixins.RefAdapterMixin],
    components: {
      Toggle,
      TreeDownNode,
    },
    props: {
      lowfatNodes: Object,
      highlights: Object,
      showGreek: Boolean,
      showGlosses: Boolean,
      selectedGlosses: Set,
    },
    emits: ['selectTokenLemma', 'selectTokenIds'],
    data() {
      return {
        showClausalBoundaries: true,
      };
    },
    computed: {
      isRTL() {
        return this.lowfatNodes?.attributes?.['xml:lang'] === 'heb';
      },
    },
    methods: {
      selectTokenLemma(lemma) {
        this.$emit('selectTokenLemma', lemma);
      },
      selectTokenIds(ids) {
        this.$emit('selectTokenIds', ids);
      },
      toggleClauses() {
        this.showClausalBoundaries = !this.showClausalBoundaries;
      },
      tokenRefs(lowfatNode) {
        const tokenRefs = [];
        visit(
          lowfatNode,
          node => node?.name === 'w',
          currentWord => {
            const { ref } = currentWord.attributes;
            tokenRefs.push(this.toOsisRef(ref));
          },
        );
        return tokenRefs;
      },
      hasActiveMilestone(lowfatNode) {
        const { osisRef } = this.$route.query;
        const currentActiveOsisId = `${osisRef}!1`;
        const tokenRefs = this.tokenRefs(lowfatNode);
        return !!tokenRefs.find(token => token === currentActiveOsisId);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .rtl {
    direction: rtl;
    // TODO: Appropriate font / size?
    font-family: 'SBLBibLit';
    font-size: 1.4rem;
    line-height: 1.7;
  }
</style>
