<template>
  <div class="chapter-refs">
    <div
      v-for="(chapter, chapterIdx) in chapters"
      :key="chapterIdx"
      class="chapter-ref"
      :class="{ active: this.isSelectedChapter(chapter) }"
      @click.prevent="selectRef(chapter)"
    >
      {{ chapter }}
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import { DEFAULT_OSIS_REF, DEFAULT_GROUP_KEY } from '../../store/constants';

  const SYMPHONY_REST_API_ENDPOINT =
    process.env.VUE_APP_SYMPHONY_STRUCTURE_REST_API_ENDPOINT ||
    'https://symphony-basex-svc.clearlabs.biblica.com/api';

  export default {
    name: 'ChapterRefsWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          label: null,
          globalOsisRef: {
            value: 'osisRef',
            groupKey: DEFAULT_GROUP_KEY,
          },
        },
        outputs: {
          osisRef: DEFAULT_OSIS_REF,
        },
      },
    },
    mounted() {
      this.fetchData();
      this.submit();
    },
    data() {
      return {
        structureData: null,
      };
    },
    computed: {
      books() {
        return this.structureData?.books || [];
      },
      currentBook() {
        return this.books.length
          ? this.books.filter(book => book.name === this.osisRef.split('.')[0])[0]
          : null;
      },
      chapters() {
        const chapters = this.currentBook?.chapters || [];
        return chapters.map(chapter => chapter.split('.').slice(-1)[0]);
      },
    },
    methods: {
      fetchData() {
        // FIXME: Refactor with BookRefsWidget
        const endpoint = SYMPHONY_REST_API_ENDPOINT;
        const path = '/GNT/Nestle1904/structure';
        const structureUrl = `${endpoint}${path}`;
        this.loading = true;
        fetch(structureUrl).then(resp => {
          resp.json().then(data => {
            this.structureData = data;
            this.$nextTick(() => {
              this.loading = false;
            });
          });
        });
      },
      isSelectedChapter(chapter) {
        return chapter === this.osisRef.split('.')[1];
      },
      selectRef(chapter) {
        // TODO: Get a suitable "first passage"
        // reference from the API
        const newRef = `${this.currentBook.name}.${chapter}.1`;
        this.outputs.osisRef.value = newRef;
        this.submit();
      },
    },
  };
</script>

<style scoped lang="scss">
  .chapter-refs {
    display: grid;
    grid-template-columns: repeat(auto-fill, 1em) 5%;
    gap: 10px 1em;
  }
  .chapter-ref {
    padding: 10px;
    &.active {
      font-weight: bold;
    }
  }
</style>
