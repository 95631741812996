/* eslint-disable no-unused-vars */
<template>
  <div>
    <div v-show="loading && !this.currentStructureUsesGql">
      <UILoading />
    </div>
    <div
      v-show="!loading || this.currentStructureUsesGql"
      class="tei-passage"
      :class="{ loading: loading && !this.currentStructureUsesGql }"
    >
      <div
        v-if="(allowedRef && tokensByMilestone) || currentStructureUsesGql"
        class="tei-content"
        :class="{ sgr: showGreek, sgl: showGlosses }"
      >
        <span v-if="!showGreek && !showGlosses">
          <em>Make at least one selection in the interlinear widget.</em>
        </span>
        <component
          v-else
          :is="supportedStructures[structure]"
          :highlights="highlights"
          :showGreek="showGreek"
          :showGlosses="showGlosses"
          :selectedGlosses="selectedGlossesSet"
          :loading="loading"
          @select-token-lemma="selectTokenLemma"
          @select-token-ids="selectTokenIds"
          :tokensByMilestone="tokensByMilestone"
          :propositions="propositions"
          :tokens="tokensForOsisRef"
          :lowfatNodes="lowfatNodes"
          :teiObj="teiObj"
          :osisRef="osisRef"
          :currentAnnotationSetUri="currentAnnotationSetUri"
          :selectedTokenIds="selectedTokenIds"
        />
      </div>
      <em v-if="!allowedRef"> Rendering "{{ osisRef }}" is not yet supported. </em>
      <!-- TODO: Error / empty state -->
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag';
  import { fromXml } from 'xast-util-from-xml';

  import mixins from '@/mixins';

  import {
    DEFAULT_GROUP_KEY,
    DEFAULT_GNT_TEXTUAL_EDITION,
    DEFAULT_HOT_TEXTUAL_EDITION,
    TEXT_STRUCTURE_KEY_FOR_VERSES,
    TEXT_STRUCTURE_KEY_FOR_LEVINSOHN_PROPOSITIONS,
    TEXT_STRUCTURE_KEY_FOR_TREEDOWN_CLAUSES,
    TEXT_STRUCTURE_KEY_FOR_DISCOURSE_ANNOTATIONS,
    TEXT_STRUCTURE_KEY_FOR_FLAT_DISCOURSE,
    TOKEN_ID_FIELD,
    WORD_TOKEN_ID_FIELD,
  } from '@/store/constants';
  import {
    determineTestamentForOsisRef,
    convertUsfmToOsis,
    createOsisWordIdFromUsfm,
  } from '@/common/refUtils';
  import { extractGlosses } from '@/symphony/shared/glosses';
  import {
    features,
    featuresByApiName,
    updateOrCreateToken,
  } from '@/symphony/shared/featuresToTokenData';
  import enrichTokenMap from '@/symphony/shared/enrichTokenMap';

  import LevinsohnPropositions from '@/symphony/widgets/annotated-text-widgets/LevinsohnPropositions.vue';
  import Treedown from '@/symphony/widgets/annotated-text-widgets/Treedown.vue';
  import TEIWrapper from '@/symphony/widgets/annotated-text-widgets/TEIWrapper.vue';
  import UILoading from '@/symphony/widgets/treedown-widgets/components/UILoading.vue';
  import DiscourseAnnotations from '@/symphony/widgets/annotated-text-widgets/DiscourseAnnotations.vue';
  import FlatDiscourse from '@/symphony/widgets/annotated-text-widgets/FlatDiscourse.vue';

  // TODO: Change our data model to make this exclusion explicit
  const LEVINSOHN_MAIN_CLAUSES_FEATURE_URI =
    'https://github.com/biblicalhumanities/levinsohn:main-clauses';
  const LEVINSOHN_REGEX = 'https://github.com/biblicalhumanities/levinsohn:(?!main-clauses).+';
  export default {
    name: 'AnnotatedTextWidget',
    apollo: {
      // TODO: Type hints in VS Code?
      passageData: {
        // FIXME: Hard-coded textual edition
        query: gql`
          query passageData($chapterRef: String!, $textualEdition: String!) {
            passageWithXML: textualNodes(
              filters: {
                scriptureReference: { usfmRef: $chapterRef, textualEdition: $textualEdition }
              }
            ) {
              xmlContent
            }
            passageWithTokens: passage(
              filters: {
                scriptureReference: { usfmRef: $chapterRef, textualEdition: $textualEdition }
              }
            ) {
              usfmRef
              tokens {
                ref
                maculaId
                value
                skipSpaceAfter
              }
            }
          }
        `,
        variables() {
          const testament = determineTestamentForOsisRef(this.osisRef);
          const textualEdition =
            testament === 'OT' ? DEFAULT_HOT_TEXTUAL_EDITION : DEFAULT_GNT_TEXTUAL_EDITION;
          return {
            chapterRef: this.contextualizedUsfmRef,
            textualEdition,
          };
        },
        skip() {
          return !this.osisRef;
        },
        update(data) {
          const passageXML = data.passageWithXML;
          return {
            teiXml: passageXML.length ? passageXML[0].xmlContent : '',
            passageVerses: data.passageWithTokens,
          };
        },
      },
      propositions: {
        query: gql`
          query Annotations($filters: AnnotationFilter) {
            annotations(filters: $filters) {
              uri
              data
              tokens {
                ref
                maculaId
              }
            }
          }
        `,
        variables() {
          return {
            // TODO: change these to named constants
            filters: {
              scriptureReferenceWithAncestors: { usfmRef: this.contextualizedUsfmRef },
              featureUri: LEVINSOHN_MAIN_CLAUSES_FEATURE_URI,
            },
          };
        },
        skip() {
          return !this.structureIsPropositions;
        },
        update(data) {
          const propositions = data.annotations.map(annotation => {
            return {
              level: annotation.data.level,
              tokens: annotation.tokens.map(token => {
                const { maculaId, ref } = token;
                return { ref: this.toOsisRef(ref), maculaId };
              }),
            };
          });
          return propositions;
        },
      },
      clauses: {
        query: gql`
          query SyntaxTrees($filters: SyntaxTreeFilters) {
            syntaxTrees(filters: $filters) {
              lang
              data
            }
          }
        `,
        variables() {
          return {
            filters: {
              scriptureReference: { usfmRef: this.contextualizedUsfmRef },
            },
          };
        },
        skip() {
          return !this.structureIsTreedown;
        },
        update(data) {
          const trees = data.syntaxTrees;
          if (!trees.length) {
            return {
              children: [],
            };
          }
          return {
            // FIXME: Rebuild the attributes wrapper
            // e.g. https://symphony-basex-svc.clearlabs.biblica.com/api/GNT/Nestle1904/lowfat?usfm-ref=PHM%201
            attributes: {
              'xml:lang': trees[0].lang,
            },
            children: trees.map(tree => {
              const xmlData = fromXml(tree.data);
              return xmlData.children[0];
            }),
          };
        },
      },
      // NOTE: This allows us to replicate the current `pos: verbs` functionality, but we may desire
      // a more full-featured morphology data model / GraphQL field.
      // see https://github.com/Clear-Bible/symphony-frontend/blob/1d3b49628b811528471853252e99f5138c5129b9/src/symphony/shared/morphToTokenData.js
      tokens: {
        query: gql`
          query tokens($filters: WordTokenFilter) {
            wordTokens(filters: $filters) {
              id
              ref
              value
              lemma
              maculaId
              data
            }
          }
        `,
        variables() {
          return {
            filters: {
              scriptureReference: { usfmRef: this.contextualizedUsfmRef },
            },
          };
        },
        update(data) {
          return data.wordTokens.map(token => {
            const { maculaId } = token;
            const value = {
              maculaId,
              // TODO: Remove osisId
              osisId: createOsisWordIdFromUsfm(token.ref),
              verb: token.data.class === 'verb',
              lemma: token.data.lemma,
              id: token[TOKEN_ID_FIELD],
            };
            value.gloss = extractGlosses(token);
            return value;
          });
        },
        skip() {
          return !this.contextualizedUsfmRef;
        },
      },
      featureTokens: {
        query: gql`
          query Features($filters: AnnotationFilter) {
            annotations(filters: $filters) {
              uri
              feature {
                label
              }
              tokens {
                ${WORD_TOKEN_ID_FIELD}
              }
            }
          }
        `,
        skip() {
          return !this.contextualizedUsfmRef;
        },
        variables() {
          return {
            filters: {
              scriptureReferenceWithAncestors: { usfmRef: this.contextualizedUsfmRef },
              uri: { regex: LEVINSOHN_REGEX },
            },
          };
        },
        update(data) {
          let featureTokens = [];
          data.annotations.forEach(annotation => {
            const { feature } = annotation;
            // TODO: Main clauses is an edge case
            const featureApiName = feature.label;
            const featureStruct = featuresByApiName[featureApiName];
            if (!featureStruct) {
              // TODO: Review inline notes and other annotations we haven't yet mapped
              return;
            }
            annotation.tokens.forEach(featureToken => {
              featureTokens = updateOrCreateToken(featureTokens, {
                [WORD_TOKEN_ID_FIELD]: featureToken[WORD_TOKEN_ID_FIELD],
                [featureStruct.key]: true,
              });
            });
          });
          return featureTokens;
        },
      },
    },
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin, mixins.RefAdapterMixin],
    components: {
      TEIWrapper,
      Treedown,
      LevinsohnPropositions,
      DiscourseAnnotations,
      FlatDiscourse,
      UILoading,
    },
    props: {
      doric: {
        inputs: {
          label: null,
          osisRef: {
            value: 'osisRef',
            groupKey: DEFAULT_GROUP_KEY,
          },
          structure: {
            value: 'structure',
            groupKey: DEFAULT_GROUP_KEY,
          },
          showBaseText: {
            value: 'showBaseText',
            groupKey: DEFAULT_GROUP_KEY,
          },
          selectedGlosses: {
            value: 'selectedGlosses',
            groupKey: DEFAULT_GROUP_KEY,
          },
          discourseFeatures: {
            value: 'discourseFeatures',
            groupKey: DEFAULT_GROUP_KEY,
          },
          highlightVerbs: {
            value: 'highlightVerbs',
            groupKey: DEFAULT_GROUP_KEY,
          },
          selectedTokenIds: {
            value: 'selectedTokenIds',
            groupKey: DEFAULT_GROUP_KEY,
          },
          currentAnnotationSetUri: {
            value: 'currentAnnotationSetUri',
            groupKey: DEFAULT_GROUP_KEY,
          },
        },
        outputs: {
          selectedLemma: null,
          selectedTokenIds: null,
        },
      },
    },
    data() {
      return {
        apiXML: null,
        propositions: null,
        lowfatData: null,
        // FIXME: revisit after merge
        tokensByMilestone: new Map(),
        // FIXME: revisit after merge
        tokenMapIsLoading: false,
        teiObj: null,
      };
    },
    watch: {
      tokens: {
        handler() {
          this.resolvePassageData();
        },
      },
      passageData: {
        // FIXME: Prevent race condition
        handler() {
          this.resolvePassageData();
        },
        immediate: false,
      },
      featureTokens: {
        // FIXME: Prevent race condition
        handler() {
          this.resolvePassageData();
        },
        immediate: false,
      },
    },
    // When we work with Jonathan on what these glosses actually are...
    computed: {
      currentStructure() {
        return this.structure;
      },
      currentStructureUsesGql() {
        const nonGqlQueries = [
          TEXT_STRUCTURE_KEY_FOR_VERSES,
          TEXT_STRUCTURE_KEY_FOR_LEVINSOHN_PROPOSITIONS,
          TEXT_STRUCTURE_KEY_FOR_TREEDOWN_CLAUSES,
        ];
        return !nonGqlQueries.includes(this.structure);
      },
      supportedStructures() {
        return {
          [TEXT_STRUCTURE_KEY_FOR_VERSES]: TEIWrapper,
          [TEXT_STRUCTURE_KEY_FOR_LEVINSOHN_PROPOSITIONS]: LevinsohnPropositions,
          [TEXT_STRUCTURE_KEY_FOR_TREEDOWN_CLAUSES]: Treedown,
          [TEXT_STRUCTURE_KEY_FOR_DISCOURSE_ANNOTATIONS]: DiscourseAnnotations,
          [TEXT_STRUCTURE_KEY_FOR_FLAT_DISCOURSE]: FlatDiscourse,
        };
      },
      tokensForOsisRef() {
        return this.tokensByMilestone.get(this.osisRef);
      },
      lowfatNodes() {
        return this.clauses ? this.clauses : undefined;
      },
      allowedRef() {
        const { chapter } = this.parsedOsisRef;
        return chapter;
      },
      structureIsPropositions() {
        return this.structure === TEXT_STRUCTURE_KEY_FOR_LEVINSOHN_PROPOSITIONS;
      },
      structureIsTreedown() {
        return this.structure === TEXT_STRUCTURE_KEY_FOR_TREEDOWN_CLAUSES;
      },
      highlights() {
        if (this.discourseFeatures) {
          return features.reduce(
            (acc, curr) => {
              const keyProperCase = `${curr.key.charAt(0).toUpperCase()}${curr.key.slice(1)}`;
              return {
                ...acc,
                [curr.key]: this.discourseFeatures[`highlight${keyProperCase}`] ?? false,
              };
            },
            { verbs: this.highlightVerbs },
          );
        }
        return {};
      },
      osisRef() {
        return this.osisRef;
      },
      osisChapterRef() {
        return this.osisRef.match(/(?<book>\w+)\.(?<chapter>\d+)/)[0];
      },
      // TODO: Refactor as showBaseText
      showGreek() {
        return !!this.showBaseText;
      },
      selectedGlossesSet() {
        const parts = this.selectedGlosses ? this.selectedGlosses.split('|') : [];
        return new Set(parts);
      },
      showGlosses() {
        return this.selectedGlossesSet.size > 0;
      },
      loading() {
        /*
          Indicates that there is data loading / processing happening from the API.
        */
        return this.tokenMapIsLoading || this.$apollo.loading;
      },
    },
    methods: {
      // TODO: Resolve multiple calls; may need some sort of "chained" caller
      resolvePassageData() {
        // Refactoring goal --- separate concerns:
        // 1. fetching data from api
        // 2. converting data into desired format
        // 3. loading data into store
        if (!this.passageData || !this.tokens || !this.featureTokens) {
          return;
        }
        this.tokenMapIsLoading = true;
        const teiTokenMap = this.simpleFetchTei();
        // NOTE: Set IDs of tokens sharing the same word "slot", e.g.
        // o010010010011 and o010010010012
        const wordPartData = this.tokens.map(wpt => {
          const { maculaId } = wpt;
          // TODO: Consider simplifying this if we "roll up" word part data to word data
          const wordPartSiblings = this.tokens.filter(t => t.osisId === wpt.osisId);
          return { maculaId, wordPartIds: wordPartSiblings.map(st => st[TOKEN_ID_FIELD]) };
        });
        const tokensWithWords = enrichTokenMap(teiTokenMap, wordPartData);
        const tokensWithMorph = enrichTokenMap(tokensWithWords, this.tokens);
        const fullTokenMap = enrichTokenMap(tokensWithMorph, this.featureTokens);
        this.tokensByMilestone = fullTokenMap;
        fullTokenMap.forEach(verseMap => {
          verseMap.forEach((tokenDatum, tokenId) => {
            this.$store.getters.getActiveWorkspace.globalTokens.set(tokenId, tokenDatum);
          });
        });
        this.tokenMapIsLoading = false;
      },
      selectTokenLemma(lemma) {
        if (lemma) {
          this.selectLemma(lemma);
        }
      },
      selectTokenIds(ids) {
        if (ids) {
          this.selectIds(ids);
        }
      },
      simpleFetchTei() {
        if (!this.passageData) {
          return null;
        }
        // TODO: Baby steps.  We need to get the fetchers all switched over, but this can now use
        // this.teiXml.
        this.teiObj = fromXml(this.passageData.teiXml);
        const verseMap = new Map();
        // FIXME: Refactor with enrichTokenMap
        this.passageData.passageVerses.forEach(verse => {
          const verseOsisRef = convertUsfmToOsis(verse.usfmRef);
          const tokenMap = verseMap.get(verseOsisRef) || new Map();
          verse.tokens.forEach(token => {
            const tokenId = token.maculaId;
            const existingData = tokenMap.get(tokenId);
            const { value, skipSpaceAfter } = token;
            tokenMap.set(tokenId, {
              ...existingData,
              maculaId: token.maculaId,
              usfmRef: token.ref,
              textValue: value,
              skipSpaceAfter,
            });
          });
          verseMap.set(verseOsisRef, tokenMap);
        });
        return verseMap;
      },
      selectLemma(lemma) {
        this.outputs.selectedLemma.value = lemma;
        this.submit();
      },
      selectIds(ids) {
        this.outputs.selectedTokenIds.value = JSON.stringify(ids);
        this.submit();
      },
    },
  };
</script>

<style scoped lang="scss">
  // TODO: Shared styles across text widgets
  .loading {
    opacity: 50%;
  }
  .tei-passage {
    font-family: 'Gentium Book Plus';
    font-size: 1.2rem;
  }
  .tei-content {
    width: 100%;
  }
  :deep(.tei-content) {
    // TODO: Improve this scoped styling; is too granular
    font-family: 'Gentium Book Plus';
    font-size: 1.2rem;
    word-spacing: normal;
    // NOTE: Mimics the default paragraph spacing
    // .milestone {
    //   margin-bottom: 1rem;
    // }

    // NOTE: From Interlinear Display on beyond-translation
    // .milestone {
    //   margin: 20px 0px;
    // }

    // NOTE: From John 14 demo
    .milestone:not(:first-of-type) {
      margin-top: 2rem;
    }
  }
  .sgr {
    .word {
      display: block;
    }
  }
  .sgl {
    .gloss {
      display: block;
    }
  }
  .osis-ref {
    display: block;
    text-align: right;
    opacity: 50%;
  }
  .controls {
    text-align: right;
    margin-top: 1em;
  }
  .no-token {
    opacity: 10%;
  }
</style>
