<template>
  <div>
    <p v-show="!result">Loading query...</p>
    <pre><code>{{result && JSON.stringify(result, null, 2)}}</code></pre>
  </div>
</template>

<script setup>
  import gql from 'graphql-tag';
  import { useQuery, provideApolloClient } from '@vue/apollo-composable';
  import { maculaClient } from '@/apollo';

  provideApolloClient(maculaClient);
  const { result } = useQuery(gql`
    query getUsers {
      users {
        id
        username
      }
    }
  `);

  defineProps({
    doric: {
      inputs: {
        label: null,
      },
    },
  });
</script>

<style scoped lang="scss">
  code {
    display: block;
    white-space: pre;
    margin: 1em 0;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
</style>
