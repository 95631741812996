<script setup>
  import { ref, computed, defineEmits, defineProps, onMounted } from 'vue';
  import configuration from '../config';

  const { bibleBooks, defaultOsisId, defaultXqueryString } = configuration;

  function matchesDirectId(string) {
    return (
      string.length >= 9 && (string.startsWith('n') || string.startsWith('o'))
      // TODO: Restore support for l, a, v; doesn't seem to be used
    );
  }

  function matchesXquery(string) {
    // TODO: using `.includes` vs `.startsWith` in validateXQueryString
    return (
      string.includes('/') ||
      string.includes('(') ||
      string.includes('for') ||
      string.includes('let')
    );
  }

  const emit = defineEmits({
    'query-object': { query: String, directId: Boolean, xquery: Boolean },
    'language-direction-is-rtl': Boolean,
  });
  const props = defineProps({
    initialTreeQuery: String,
    editorMode: Boolean,
  });
  const flashingSearchBar = ref('false');

  const searchTerm = ref(
    props.initialTreeQuery || (props?.editorMode ? defaultOsisId : defaultXqueryString),
  );
  const languageDirectionIsRTL = ref(false);
  const showAutocomplete = ref(false);
  const usingXQueryInput = computed(() => matchesXquery(searchTerm.value));
  const usingDirectIdInput = computed(
    () => !usingXQueryInput.value && matchesDirectId(searchTerm.value),
  );
  const usingUsfmRef = ref(!usingXQueryInput.value && !usingDirectIdInput.value);

  const searchBooks = computed(() => {
    if (searchTerm.value === '') {
      return [];
    }
    return bibleBooks.filter(book => {
      if (book.name.toUpperCase().includes(searchTerm.value.toUpperCase())) {
        // TODO: validate search string beyond simply lower-casing the string
        languageDirectionIsRTL.value = book.language_direction === 'rtl';
        return true;
      }
      return false;
    });
  });

  function formatSearchTerm(abbreviation, chapterProp, verseProp) {
    const chapter = chapterProp ? chapterProp.value : 1;
    const verse = verseProp ? verseProp.value : 1;
    const formattedString = `${abbreviation} ${chapter}:${verse}`;
    searchTerm.value = formattedString;
    return formattedString;
  }

  function flashSearchBar(milliseconds, error) {
    // FIXME: this is not a vue way of doing things, use a ref() instead of dom manipulation
    flashingSearchBar.value = error ? 'flashError' : 'flashSuccess';
    setTimeout(() => {
      flashingSearchBar.value = '';
    }, milliseconds || 500);
  }

  function handleInputChange(event) {
    const userInput = event.target.value?.split(/[ .:]/);
    const finalCharacter = event.data;
    const finalCharacterIsNumber = finalCharacter?.match(/^\d+$/);
    const finalCharacterIsSpace = finalCharacter === ' ';
    const finalCharacterIsColon = finalCharacter?.match(/^:+$/);
    const finalCharacterIsPeriod = finalCharacter?.match(/^\.+$/);

    if (userInput.length) {
      const inputMaybeXquery = matchesXquery(userInput[0]);
      const inputMaybeDirectId = matchesDirectId(userInput[0]);
      if (inputMaybeDirectId || inputMaybeXquery) {
        return;
      }
    }
    const shouldTriggerAutocomplete =
      userInput?.length > 1 &&
      (finalCharacterIsNumber ||
        finalCharacterIsSpace ||
        finalCharacterIsColon ||
        finalCharacterIsPeriod);
    if (shouldTriggerAutocomplete) {
      let autocompletedInput = '';
      let bookInput = userInput[0];
      let chapterInput = userInput[1];
      let verseInput = userInput[2];
      // if user presses space bar, autocomplete the book name to the abbreviated form
      let matchingBook = bibleBooks.find(book =>
        book.abbr.includes(bookInput.toUpperCase() || bookInput.toUpperCase().includes(book.abbr)),
      );
      if (!matchingBook) {
        matchingBook = bibleBooks.find(
          book =>
            book.name.toUpperCase().includes(bookInput.toUpperCase()) ||
            bookInput.toUpperCase().includes(book.name.toUpperCase()),
        );
      }
      if (matchingBook) {
        bookInput = matchingBook.abbr;
        autocompletedInput += `${bookInput} `;
      }
      const bookMaxChapter = parseInt(matchingBook?.usfm_end?.split(/[ :]/)[1], 10);
      const maxVerseForChapter = matchingBook?.chapters[chapterInput];
      if (chapterInput) {
        chapterInput = parseInt(chapterInput, 10);
        if (chapterInput < 1) {
          chapterInput = 1;
        }
        if (chapterInput > bookMaxChapter) {
          chapterInput = bookMaxChapter;
        }
        autocompletedInput += `${chapterInput}`;
      }
      if (finalCharacterIsColon || finalCharacterIsPeriod) {
        autocompletedInput += ':';
      }
      if (!verseInput) {
        searchTerm.value = autocompletedInput;
      }
      if (verseInput) {
        verseInput = parseInt(verseInput, 10);
        if (verseInput < 1) {
          verseInput = 1;
        }
        if (verseInput > maxVerseForChapter) {
          verseInput = maxVerseForChapter;
        }
        autocompletedInput += `:${verseInput}`;
      }
      if (autocompletedInput.length > 0) {
        searchTerm.value = autocompletedInput;
      }
    }
  }

  function validateXQueryString(string) {
    if (
      string.startsWith('/') ||
      string.startsWith('(') ||
      string.startsWith('for') ||
      string.startsWith('let')
    ) {
      return true;
    }
    return false;
  }

  function validateUsfmRef(string) {
    const chapterAndVerseRegex = /^[1-4A-Z]{3} [0-9]{1,3}:[0-9]{1,3}$/; // NOTE: I think there would only be a 4 Maccabees at the most, thus 1-4A-Z should be precise
    const chapterRegex = /^[1-4A-Z]{3} [0-9]{1,3}$/;
    if (chapterAndVerseRegex.test(string)) {
      return 'chapter-and-verse';
    }
    if (!chapterAndVerseRegex.test(string) && chapterRegex.test(string)) {
      return 'chapter'; // FIXME: this lengthened load-bar animation is not working
    }
    return 'invalid';
  }

  function handleSubmitSearch() {
    const trimmedSearchTerm = searchTerm.value.trim();

    if (usingDirectIdInput.value) {
      emit('query-object', { query: trimmedSearchTerm, directId: true });
      flashSearchBar(300);
    } else if (trimmedSearchTerm.length > 0) {
      const usfmRefType = validateUsfmRef(trimmedSearchTerm);
      if (usfmRefType === 'invalid') {
        usingUsfmRef.value = false;
        const xqueryRefIsValid = validateXQueryString(trimmedSearchTerm);
        if (xqueryRefIsValid) {
          flashSearchBar(3000);
          emit('query-object', { query: trimmedSearchTerm, xquery: true });
        } else {
          flashSearchBar(300, true);
        }
      } else {
        usingUsfmRef.value = true;
        if (usfmRefType === 'chapter-and-verse') {
          flashSearchBar(1000);
          emit('query-object', { query: trimmedSearchTerm });
        }
        if (usfmRefType === 'chapter') {
          flashSearchBar(3000);
          emit('query-object', { query: trimmedSearchTerm });
        }
      }
    }
  }

  function navigateVerse(backward) {
    // eslint-disable-next-line prefer-const
    let [bookAbbrev, chapter, verse] = searchTerm.value.split(/[ :]/);
    chapter = parseInt(chapter, 10);
    verse = parseInt(verse, 10);
    const book = bibleBooks.find(bk => bk.abbr === bookAbbrev);
    const chapterMaxVerse = book.chapters[chapter];
    const bookMaxChapter = parseInt(book.usfm_end.split(/[ :]/)[1], 10);

    if (backward) {
      const previousBook = bibleBooks[bibleBooks.indexOf(book) - 1];
      // verse is always updated
      verse -= 1;
      if (verse < 1) {
        chapter -= 1;
        if (chapter === 0) {
          if (previousBook) {
            // book, chapter, and verse updated
            searchTerm.value = previousBook.usfm_end;
            handleSubmitSearch();
            return;
          }
          // can't go back any further than first book, chapter, and verse
          flashSearchBar(500, true);
          return;
        }
        // chapter and verse updated
        const previousChapterMaxVerse = book.chapters[chapter];
        verse = previousChapterMaxVerse;
        searchTerm.value = `${book.abbr} ${chapter}:${verse}`;
        handleSubmitSearch();
        return;
      }
      searchTerm.value = `${book.abbr} ${chapter}:${verse}`;
      handleSubmitSearch();
      return;
    }
    if (searchTerm.value === book.usfm_end) {
      const nextBook = bibleBooks[bibleBooks.indexOf(book) + 1];
      if (nextBook) {
        searchTerm.value = `${nextBook.abbr} 1:1`;
        handleSubmitSearch();
        return;
      }
      flashSearchBar(500, true);
      return;
    }
    verse += 1;
    if (verse > chapterMaxVerse) {
      chapter += 1;
      if (chapter > bookMaxChapter) {
        // theoretically, this case should be caught above using @usfm_end
        flashSearchBar(500, true);
        return;
      }
      verse = 1;
      searchTerm.value = `${book.abbr} ${chapter}:${verse}`;
      handleSubmitSearch();
      return;
    }
    searchTerm.value = `${book.abbr} ${chapter}:${verse}`;
    handleSubmitSearch();
  }
  onMounted(() => {
    handleSubmitSearch();
  });
</script>

<!-- FIXME: navigate-verse-button doesn't navigate across GNT / HOT boundary -->
<!-- FIXME: Good candidate for collections / idx from server -->
<template>
  <div class="ui-container">
    <button class="navigate-verse-button" v-show="usingUsfmRef" @click="navigateVerse(true)">
      {{ '\u2190' }}
    </button>
    <div id="search-container">
      <input
        id="search"
        type="text"
        placeholder="Enter verse reference, XQuery, or XPath ..."
        v-model="searchTerm"
        @focus="showAutocomplete = true"
        @blur="showAutocomplete = false"
        @keypress.enter="handleSubmitSearch"
        @input="handleInputChange"
      />
      <div id="load-bar" :class="flashingSearchBar" />
      <ul v-show="showAutocomplete">
        <li v-for="book in searchBooks" :key="book.name" @click="formatSearchTerm(book.abbr)">
          <a :href="book.url">{{ `${book.name} (${book.abbr})` }}</a>
        </li>
      </ul>
      <div class="xquery-formatted-search-term" v-if="usingXQueryInput && showAutocomplete">
        <label
          v-for="(word, index) in searchTerm.split(/[ ]/)"
          :class="[
            word,
            { showLabel: usingXQueryInput },
            { path: word.startsWith('/') || word.startsWith('(') },
            { variable: word.startsWith('$') },
            { operator: word === 'for' || word === 'let' || word === 'with' || word === 'return' },
            { separator: word === 'and' || word === 'or' },
          ]"
          :key="index"
        >
          {{ word }}
        </label>
      </div>
    </div>
    <button class="navigate-verse-button" v-show="usingUsfmRef" @click="navigateVerse(false)">
      {{ '\u2192' }}
    </button>
  </div>
</template>

<style scoped>
  #search-container {
    position: relative;
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-self: stretch;
  }
  #search {
    padding: 0.3em;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1.5em;
    font-family: 'Times New Roman', Times, serif;
    outline: none;
    z-index: 4;
    background-color: transparent;
    margin: 0 0.5em;
  }
  label {
    display: flex;
    position: inline-block;
    font-size: 1.5em;
    font-family: monospace;
    z-index: 5;
    background-color: transparent;
    margin: 0 0.5em;
  }
  .operator {
    color: #05afec;
    /** break line before but not after */
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .operator:before {
    /** break lines using invisible full-width content */
    flex-basis: 100%;
    height: 0;
    margin: 0;
    border: 0;
    content: ' ';
  }
  .separator {
    color: #94ca36;
  }
  .path {
    color: lightsalmon;
  }
  .variable {
    color: purple;
  }

  /* input {
    background: transparent;
    background-clip: text;
    color: transparent;
  } */
  .navigate-verse-button {
    display: flex;
    align-self: stretch;
    align-items: center;
    cursor: pointer;
    line-height: inherit;
    text-align: center;
    line-height: inherit;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    outline: none;
  }
  .navigate-verse-button:hover {
    color: #05afec;
  }
  ul {
    list-style: none;
    position: absolute;
    top: 3em;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  .xquery-formatted-search-term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 3em;
    width: 97%;
    margin-left: 0.85em;
    padding: 1em;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  ul.focused {
    display: block;
  }
  li {
    list-style: none;
    margin: 0;
    padding: 0.3em;
  }
  li:hover {
    background-color: #ccc;
  }
  #load-bar {
    position: absolute;
    z-index: 3;
    left: 0;
    margin: 0 0.8em;
    color: blue;
    width: 96.8%;
    height: 100%;
    border-radius: 4px;
  }
  .flashSuccess {
    animation: loadBarAndFade 0.5s ease-in-out;
  }
  @keyframes loadBarAndFade {
    0% {
      opacity: 0.5;
      background: none;
      width: 0%;
    }
    50% {
      background: linear-gradient(45deg, #05afec, #94ca36);
      width: 96.8%;
    }
    100% {
      opacity: 0;
    }
  }

  .flashError {
    animation: errorBarAndFade 0.5s ease-in-out;
  }
  @keyframes errorBarAndFade {
    0% {
      opacity: 0.5;
      background: none;
      width: 0%;
    }
    50% {
      background: linear-gradient(45deg, orangered, red);
      width: 96.8%;
    }
    100% {
      opacity: 0;
    }
  }
</style>
