<template>
  <div class="dictionary-entry">
    <LoadingSpinner v-if="loading" />
    <EmptyMessage v-if="!selectedLemma">
      Select a Greek word to load the matching dictionary entry
    </EmptyMessage>
    <div v-else-if="entries?.length > 0" class="entry-container" v-html="content" />
    <EmptyMessage v-else>No entries found for "{{ selectedLemma }}"</EmptyMessage>
  </div>
</template>

<script>
  import gql from 'graphql-tag';

  import mixins from '@/mixins';
  import { DEFAULT_OSIS_REF, DEFAULT_GROUP_KEY } from '@/store/constants';
  import LoadingSpinner from './components/LoadingSpinner.vue';
  import EmptyMessage from './components/EmptyMessage.vue';

  export default {
    // TODO: Standardize kebab-case transforms like Vue;
    // LSJDictionaryEntryWidget would be l-s-j-dictionary-entry-widget
    // instead of lsj-dictionary-entry-widget
    name: 'LsjDictionaryEntryWidget',
    apollo: {
      entries: {
        client: 'scaifeClient',
        query: gql`
          query Entries($selectedLemma: String!) {
            dictionaryEntries(lemma: $selectedLemma) {
              edges {
                node {
                  dictionary {
                    label
                  }
                  data
                }
              }
            }
          }
        `,
        variables() {
          return {
            selectedLemma: this.selectedLemma,
          };
        },
        skip() {
          return !this.selectedLemma;
        },
        update(data) {
          return data.dictionaryEntries.edges
            .map(e => e.node)
            .filter(n => n.dictionary.label === 'LSJ');
        },
      },
    },
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          // TODO: See above note on kebab-case transforms
          label: 'LSJ Dictionary Entries',
          selectedLemma: {
            groupKey: DEFAULT_GROUP_KEY,
            // FIXME: value means a different thing when it is a local input versus a non-local input
            value: 'selectedLemma',
          },
        },
        outputs: {
          osisRef: DEFAULT_OSIS_REF,
        },
      },
    },
    computed: {
      content() {
        // TODO: Determine if we have a pattern for applying XSLT
        // to this content
        return this?.entries?.length > 0 ? this.entries[0].data.content : null;
      },
      loading() {
        return this.$apollo.queries.entries.loading;
      },
    },
    components: {
      LoadingSpinner,
      EmptyMessage,
    },
  };
</script>

<style scoped lang="scss">
  .dictionary-entry {
    // TODO: Expose style options using CSS config vars?
    :deep(.entry-container) {
      font-family: 'Gentium Book Plus';
      line-height: 1.5;
      note {
        float: right;
      }
      form {
        font-weight: 700;
        font-size: 18pt;
      }
      ref {
        color: #666;
        text-decoration: underline;
        cursor: pointer;
        // TODO: Prefer XSLT for whitespace
        margin-left: 3pt;
      }
      foreign {
        font-weight: 700;
        // TODO: Prefer XSLT for whitespace
        margin-left: 3pt;
        margin-right: 1pt;
      }
    }
  }
</style>
