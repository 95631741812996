function extractGlosses(token) {
  const glossAttrs = [
    'english',
    'greek',
    'mandarin',
    // TODO: Determine if / how to surface the `@gloss` attribute, versus
    // `@english` in the WLC and  SBLGNT datasets.
    // 'gloss'
  ];

  return Object.fromEntries(
    glossAttrs.map(ga => [
      ga,
      [token]
        .map(t => t.data?.[ga]?.trim())
        .filter(gloss => !!gloss)
        .join(' '),
    ]),
  );
}

const HOT_GLOSSES = [
  {
    label: 'English',
    fieldName: 'english',
    langCode: 'eng',
  },
  {
    label: 'Greek',
    fieldName: 'greek',
    langCode: 'grc',
  },
  {
    label: 'Chinese',
    fieldName: 'mandarin',
    langCode: 'zho',
  },
];

const GNT_GLOSSES = [
  {
    label: 'English',
    fieldName: 'english',
    langCode: 'eng',
  },
  {
    label: 'Chinese',
    fieldName: 'mandarin',
    langCode: 'zho',
  },
];
export { HOT_GLOSSES, GNT_GLOSSES, extractGlosses };
