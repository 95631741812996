/* eslint-disable import/prefer-default-export */
export const GLOBAL_DATA_STATE = 'global';
export const LOCAL_DATA_STATE = 'local';
export const DEFAULT_OSIS_REF = process.env.VUE_APP_DEFAULT_OSIS_REF || 'John.14.1';
export const DEFAULT_GROUP_KEY = 'default';
export const SBLGNT = 'SBLGNT';
export const NESTLE_1904 = 'Nestle1904';
export const WLC = 'WLC';
// NOTE: `WORD_TOKEN_ID_FIELD` is used in a refactor away from Osis IDs over to
// maculaIds.   It differs from `TOKEN_ID_FIELD` below which is used to provide
// a "text-agnostic" ID, e.g., the database ID for SBLGNT n40010010011 will differ
// from the BSB n40010010011.
export const WORD_TOKEN_ID_FIELD = 'maculaId';
export const XML_ID = 'xml:id';
export const MACULA_DEFAULT_GLOSS = 'english';

export const DEFAULT_GNT_TEXTUAL_EDITION =
  process.env.VUE_APP_DEFAULT_NT_TEXTUAL_EDITION || 'SBLGNT';
export const DEFAULT_HOT_TEXTUAL_EDITION = process.env.VUE_APP_DEFAULT_OT_TEXTUAL_EDITION || WLC;
export const DEFAULT_ALIGNED_TRANSLATION = process.env.VUE_APP_DEFAULT_ALIGNED_TRANSLATION || 'BSB';

export const MACULA_HEBREW_IMPLIED_ARTICLE = 'ה';
export const DEFAULT_ANNOTATION_SET_URI =
  process.env.VUE_APP_DEFAULT_ANNOTATION_SET_URI ||
  'https://github.com/discoursegrammar/discourse-analysis-annotations';

// Each TEXT_STRUCTURE_* should have a corresponding TEXT_STRUCTURE_LABELS entry
export const TEXT_STRUCTURE_KEY_FOR_VERSES = 'verses';
export const TEXT_STRUCTURE_KEY_FOR_LEVINSOHN_PROPOSITIONS = 'levinsohn-propositions';
export const TEXT_STRUCTURE_KEY_FOR_TREEDOWN_CLAUSES = 'treedown-clauses';
export const TEXT_STRUCTURE_KEY_FOR_DISCOURSE_ANNOTATIONS = 'discourse-annotations';
export const TEXT_STRUCTURE_KEY_FOR_FLAT_DISCOURSE = 'flat-discourse';

const TEXT_STRUCTURE_LABELS = {
  [TEXT_STRUCTURE_KEY_FOR_VERSES]: 'Verses',
  [TEXT_STRUCTURE_KEY_FOR_LEVINSOHN_PROPOSITIONS]: 'Levinsohn Propositions',
  [TEXT_STRUCTURE_KEY_FOR_TREEDOWN_CLAUSES]: 'Clauses',
  [TEXT_STRUCTURE_KEY_FOR_DISCOURSE_ANNOTATIONS]: 'Discourse Annotations',
};
if (process.env.VUE_APP_ENABLED_FLAT_DISCOURSE_STRUCTURE === '1') {
  TEXT_STRUCTURE_LABELS[TEXT_STRUCTURE_KEY_FOR_FLAT_DISCOURSE] = 'Flat Discourse';
}
export { TEXT_STRUCTURE_LABELS };

export const OUTPUTS_TO_PUSH_TO_URL = [
  'osisRef',
  'selectedLemma',
  'treeQuery',
  'structure',
  'textualEdition',
];
export const DORIC_VARIABLE_UNSET = '<UNSET>';
export const DEFAULT_WORKSPACE_ID = process.env.VUE_APP_DEFAULT_WORKSPACE_ID || 'reading';
export const ER_PLUS_PLUS_WORKSPACE_ID = 'er-plus-plus';

export const TOKEN_ID_FIELD = 'id';

export const APOLLO_MAX_TOKENS = 2500;
export const APOLLO_PAGE_SIZE = 10;

export const APOLLO_QUERY_FOR_ANNOTATION_SETS = 'AnnotationSetQuery';
export const APOLLO_QUERY_FOR_ANNOTATION_FEATURES = 'AnnotationFeatureQuery';
export const APOLLO_QUERY_FOR_ANNOTATION_INSTANCES = 'AnnotationInstanceQuery';

export const APOLLO_DISCOURSE_CONTAINER_FEATURE_URI =
  'https://github.com/discoursegrammar/discourse-analysis-annotations/discourse-container';

// APOLLO FETCH POLICIES:
// ----------------------
// Apollo Client first executes the query against the cache.
// If all requested data is present in the cache, that data
// is returned. Otherwise, Apollo Client executes the query
// against your GraphQL server and returns that data after
// caching it.
// Prioritizes minimizing the number of network requests sent
// by your application.
// This is the DEFAULT fetch policy.
export const APOLLO_FETCH_POLICY_CACHE_FIRST = 'cache-first';

// Apollo Client executes the full query against both the
// cache and your GraphQL server. The query automatically
// updates if the result of the server-side query modifies
// cached fields.
// Provides a fast response while also helping to keep cached
// data consistent with server data.
export const APOLLO_FETCH_POLICY_CACHE_AND_NETWORK = 'cache-and-network';

// Apollo Client executes the full query against your GraphQL
// server, without first checking the cache. The query's result
// is stored in the cache.
// Prioritizes consistency with server data, but can't provide
// a near-instantaneous response when cached data is available.
export const APOLLO_FETCH_POLICY_NETWORK_ONLY = 'network-only';

// For other fetch policies, see:
// https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
