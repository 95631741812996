<template>
  <div ref="thisWidget">
    <LoadingSpinner v-if="$apollo.loading" />
    <SelectAnnotationSet
      v-if="mode === modes.NO_ANNOTATIONS_SET"
      @selectAnnotationSet="selectAnnotationSet"
    />
    <SelectRootAnnotationInstance
      v-else-if="mode === modes.NO_ROOT_ANNOTATION_SELECTED"
      :instanceList="rootInstances"
      :osisRef="osisRef"
      @selectInstance="selectRootAnnotation"
      @back="mode = modes.NO_ANNOTATIONS_SET"
    />
    <div v-else-if="mode === modes.ROOT_ANNOTATION_SELECTED">
      <div v-if="$apollo.queries.annotationFlow.loading">
        <!--
          We just want to avoid the "sorry" message if we're busy loading.
          The "Select Root Annotation" button will display behind the
          loading spinner but that's all.
        -->
      </div>
      <div v-else-if="annotationFlow?.annotations?.length" @click="handleClick">
        <TokenProvider :tokens="tokens">
          <div class="title top-level">
            <button
              :disabled="annotationFlow?.currentRoot?.parent === null"
              title="Go to parent annotation"
              @click="selectedRootAnnotationUri = annotationFlow?.currentRoot?.parent?.uri"
            >
              <!-- font awesome back arrow -->
              <font-awesome-icon icon="fa-solid fa-expand" />
            </button>
            <span class="feature">{{ annotationFlow?.currentRoot?.feature?.label?.trim() }}:</span>
            <template v-if="annotationFlow?.currentRoot?.label?.length > 0">
              <span class="label">{{ annotationFlow?.currentRoot?.label }}</span>
            </template>
            <span class="passage-reference">{{ tokenRange }}</span>
          </div>
          <div
            v-for="annotation in annotationFlow.annotations"
            :key="annotation.uri"
            class="discourse-unit"
          >
            <div class="title">
              <span class="feature">{{ annotation.feature.label.trim() }}:</span>
              <template v-if="annotation.label?.length > 0">
                <span class="label">{{ annotation.label }}</span>
              </template>
              <button
                @click="selectedRootAnnotationUri = annotation.uri"
                :disabled="annotation.children.length === 0"
                :title="
                  annotation.children.length === 0
                    ? 'This unit has no annotations'
                    : `See ${annotation.children.length} children`
                "
              >
                <!-- font awesome back arrow -->
                <font-awesome-icon icon="fa-solid fa-layer-group" />
              </button>
            </div>
            <div v-if="annotation.tokens.length > 0">
              <Token
                v-for="token in annotation.tokens"
                :key="token[TOKEN_ID_FIELD]"
                :tokenId="token[TOKEN_ID_FIELD]"
              />
            </div>
            <div v-else-if="annotation.impliedTokens.length > 0">
              <Token
                v-for="token in annotation.impliedTokens"
                :key="token[TOKEN_ID_FIELD]"
                :tokenId="token[TOKEN_ID_FIELD]"
              />
            </div>
          </div>
        </TokenProvider>
      </div>
      <div v-else>
        Sorry, there are no children annotations for the root annotation that you selected at:
        <span class="passage-reference">{{ friendlyReference() }}</span>
      </div>
      <button @click="mode = modes.NO_ROOT_ANNOTATION_SELECTED">Select Root Annotation</button>
    </div>
    <div v-else>
      Unknown mode: {{ mode }}. Something has gone wrong in `FlatDiscourse.vue`. Please report this
      error.
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import formatOsis from 'bible-reference-formatter';
  import { nextTick } from 'vue';
  import { convertOsisToUsfm, sortUsfmIds, convertUsfmToOsis } from '@/common/refUtils';
  import {
    TOKEN_ID_FIELD,
    APOLLO_QUERY_FOR_ANNOTATION_INSTANCES,
    MACULA_DEFAULT_GLOSS,
  } from '@/store/constants';
  import TokenProvider from '../tokens/TokenProvider.vue';
  import Token from '../tokens/Token.vue';
  import SelectAnnotationSet from './discourse/SelectAnnotationSet.vue';
  import SelectRootAnnotationInstance from './discourse/SelectRootAnnotationInstance.vue';
  // FIXME: When the ATW uses graphql, we should try not to depend on child LoadingSpinners
  import LoadingSpinner from '../components/LoadingSpinner.vue';

  const MODES = {
    NO_ANNOTATIONS_SET: 'NO_ANNOTATIONS_SET',
    NO_ROOT_ANNOTATION_SELECTED: 'NO_ROOT_ANNOTATION_SELECTED',
    ROOT_ANNOTATION_SELECTED: 'ROOT_ANNOTATION_SELECTED',
  };

  export default {
    name: 'FlatDiscourse',
    components: {
      SelectAnnotationSet,
      SelectRootAnnotationInstance,
      TokenProvider,
      Token,
      LoadingSpinner,
    },
    props: {
      osisRef: String,
      showGlosses: {
        type: Boolean,
      },
      currentAnnotationSetUri: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        selectedAnnotationSetUri: null,
        selectedRootAnnotationUri: null,
        modes: Object.freeze(MODES),
        mode: MODES.NO_ANNOTATIONS_SET,
        selectedTokenId: null,
        TOKEN_ID_FIELD,
      };
    },
    emits: ['select-token-lemma', 'select-token-ids'],
    watch: {
      currentAnnotationSetUri: {
        handler(val) {
          if (val) {
            this.selectAnnotationSet(val);
          }
        },
        immediate: true,
      },
      rootInstances: {
        handler() {
          if (!this.selectedAnnotationSetUri || !this.selectedRootAnnotationUri) {
            // We don't need to consider changing modes if
            // either of these are not set
            return;
          }

          // If we have a selectedRootAnnotationUri and it is still in the list of root instances, we're good
          if (this.rootInstances.find(r => r?.uri === this.selectedRootAnnotationUri)) {
            return;
          }

          // Otherwise, we need to reset the selectedRootAnnotationUri and mode
          this.selectedRootAnnotationUri = null;
          this.mode = MODES.NO_ROOT_ANNOTATION_SELECTED;
        },
        immediate: true,
      },
    },
    computed: {
      tokens() {
        if (!Array.isArray(this.annotationFlow?.tokens)) {
          return [];
        }
        return this.annotationFlow.tokens.map(token => ({
          ...token,
          color: this.selectedTokenId === token.tokenId ? '#4338ca' : null,
          altTexts: this.showGlosses ? [token.data.gloss] : [],
        }));
      },
      tokenRange() {
        if (this.annotationFlow.tokens.length === 0) {
          return '';
        }
        const sortedTokenRefs = this.annotationFlow.tokens.map(t => t.data.ref).sort(sortUsfmIds);
        const start = convertUsfmToOsis(sortedTokenRefs[0].split('!')[0]);
        const end = convertUsfmToOsis(sortedTokenRefs[sortedTokenRefs.length - 1].split('!')[0]);

        if (start === end) {
          return formatOsis('niv-short', start);
        }
        return formatOsis('niv-short', `${start}-${end}`);
      },
    },
    apollo: {
      annotationFlow: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_INSTANCES}($instanceUri: String!) {
            annotations(filters: { uri: { exact: $instanceUri } }) {
                uri
                label
                feature {
                  label
                }
                parent {
                  uri
                }
                children {
                  uri
                  label
                  feature {
                    label
                  }
                  children {
                    id
                  }
                  tokens {
                    id
                    idx
                    ref
                    lemma
                    value
                    data
                  }
                  impliedTokens {
                    id
                    idx
                    ref
                    lemma
                    value
                    data
                  }
                  linkedBy {
                    tokens {
                      id
                      idx
                      ref
                      lemma
                      value
                      data
                    }
                  }
              }
            }
          }
        `,
        skip() {
          return !this?.selectedRootAnnotationUri;
        },
        variables() {
          return { instanceUri: this.selectedRootAnnotationUri };
        },
        update(data) {
          // There should only be one annotation returned because we're matching on an exact uri
          if (!data.annotations?.length === 1) {
            console.error(
              `Expected exactly one annotation to be returned for uri ${this.selectedRootAnnotationUri}, got ${data.annotations?.length}`,
            );
            return [];
          }

          const linkedTokens = new Set(
            data.annotations[0].children
              .filter(a => a.linkedBy.length > 0)
              .map(a => a.linkedBy.map(l => l.tokens.map(t => t.ref)).flat())
              .flat(),
          );

          const getTokenChapterAndVerseNumber = token => {
            if (token.idx !== 0) {
              return {};
            }
            // Format of usfmRefs is BBB CC:VV!W
            const matches = token.ref.match(/.{4}(\d+):(\d+)!?\d+?/);
            const verseNumber = matches[2];
            return verseNumber === '1'
              ? {
                  chapterNumber: matches[1],
                }
              : {
                  verseNumber,
                };
          };

          const allTokens = data.annotations[0].children
            .map(a => a.tokens)
            .concat(data.annotations[0].children.map(a => a.impliedTokens))
            .concat(data.annotations[0].children.map(a => a.linkedBy.map(l => l.tokens)))
            .flat(Infinity);
          nextTick(() => {
            // Find the first token in the osisRef and scrollIntoView
            const usfmRef = convertOsisToUsfm(this.osisRef);
            // FIXME: We depend on ref "startsWith"
            const tokenIdsInOsisRef = new Set(
              allTokens
                .filter(token => token.ref.startsWith(usfmRef))
                .map(token => String(token[TOKEN_ID_FIELD])),
            );
            const $tokens = this.$refs?.thisWidget?.querySelectorAll('.token-container') || [];
            const $foundToken = Array.from($tokens).find($token =>
              tokenIdsInOsisRef.has($token.dataset.tokenId),
            );
            if ($foundToken) {
              $foundToken.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }
          });

          const { uri, label, feature, parent } = data.annotations[0];
          return {
            tokens: allTokens.map(token => ({
              tokenId: token[TOKEN_ID_FIELD],
              data: {
                ref: token.ref,
                ...getTokenChapterAndVerseNumber(token),
                lemma: token.lemma,
                value: token.value,
                // TODO: Use standard ATLAS glosses when implemented
                gloss: token.data[MACULA_DEFAULT_GLOSS],
              },
              majorUnderline: linkedTokens.has(token.ref) ? '#c7d2fe' : null,
            })),
            annotations: data.annotations[0].children,
            parent: data.annotations[0].parent,
            currentRoot: { uri, label, feature, parent },
          };
        },
      },
      rootInstances: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_INSTANCES}($usfmRef: String!, $annotationSetUri: String!) {
            annotations(
              filters: {
                annotationSetUri: $annotationSetUri
                scriptureReferenceWithAncestors: {
                  usfmRef: $usfmRef
                  resolveAncestors: { minDepth: 1 }
                }
              }
            ) {
              parent {
                id
                uri
                label
                parent {
                  id
                  label
                  uri
                  feature {
                    label
                  }
                }
              }
            }
          }
        `,
        skip() {
          try {
            convertOsisToUsfm(this.osisRef);
          } catch (e) {
            return true;
          }
          return !this.selectedAnnotationSetUri || !this.osisRef;
        },
        variables() {
          return {
            usfmRef: convertOsisToUsfm(this.osisRef),
            annotationSetUri: this.selectedAnnotationSetUri,
          };
        },
        update: data => {
          if (!data?.annotations.some(a => !!a?.parent)) {
            return [];
          }
          return data.annotations
            .map(a => a.parent)
            .filter(a => !!a)
            .reduce((acc, curr) => {
              // Only distinct results
              if (!acc.find(a => a.uri === curr.uri)) {
                acc.push(curr);
              }
              return acc;
            }, []);
        },
      },
    },
    methods: {
      selectAnnotationSet(uri) {
        if (this.selectedAnnotationSetUri !== uri) {
          this.selectedRootAnnotationUri = null;
        }
        this.selectedAnnotationSetUri = uri;
        this.mode = MODES.NO_ROOT_ANNOTATION_SELECTED;
      },
      selectRootAnnotation(uri) {
        this.selectedRootAnnotationUri = uri;
        this.mode = MODES.ROOT_ANNOTATION_SELECTED;
      },
      friendlyReference() {
        return formatOsis('niv-long', this.osisRef);
      },
      handleClick(event) {
        const tokenContainer = event.target.closest('.token-container');
        if (!tokenContainer) {
          return;
        }
        const tokenId = tokenContainer.dataset?.tokenId;
        if (!tokenId) {
          return;
        }

        const token = this.tokens.find(t => t.tokenId === tokenId);
        this.selectedTokenId = tokenId;
        this.$emit('select-token-lemma', token.data.lemma);
        this.$emit('select-token-ids', [token.tokenId]);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .passage-reference {
    font-size: 0.8em;
    color: #666;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 5px;
  }

  .discourse-unit {
    padding: 1rem 0;
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: bold;
      font-size: 0.8rem;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
      &.top-level {
        font-size: 1.2rem;
      }
      .feature {
        color: #4f46e5;
      }
      .label {
        color: #0f172a;
      }
      > * {
        margin-right: 0.5rem;
      }
    }
  }
</style>
