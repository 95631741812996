const LOCAL_STORAGE_KEY = 'FEATURE_LIST_CONFIGURATOR_WIDGET';

// type localFeatures = {
//    uri: string,
//    label: string,
//    visible: boolean,
//  }[]
// type fetchedFeatures = {
//    uri: string,
//    label: string,
//  }[]
export const mergeOrderedFeatures = (localFeatures, fetchedFeatures) => {
  const orderedFeaturesSet = new Set(localFeatures.map(({ uri }) => uri));
  const newFeatures = fetchedFeatures
    .filter(({ uri }) => !orderedFeaturesSet.has(uri))
    .map(({ uri, label }) => ({ uri, label, visible: true }));

  const fetchedFeaturesSet = new Set(fetchedFeatures.map(({ uri }) => uri));
  const purgedLocalFeatures = localFeatures.filter(({ uri }) => fetchedFeaturesSet.has(uri));

  return [...newFeatures, ...purgedLocalFeatures];
};

export const updateOrderedFeaturesForAnnotationSet = (annotationSetUri, newConfig) => {
  let config = {};
  try {
    config = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  } catch (e) {
    console.error(e);
  }
  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({
      ...config,
      [annotationSetUri]: newConfig,
    }),
  );
};

export const getOrderedFeaturesForAnnotationSet = annotationSetUri => {
  try {
    const config = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    return config?.[annotationSetUri] || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const visibleOrderedFeaturesHelper = (annotationSetUri, fetchedFeatures) => {
  if (!annotationSetUri) {
    return [];
  }
  const localFeatures = getOrderedFeaturesForAnnotationSet(annotationSetUri);
  return mergeOrderedFeatures(localFeatures, fetchedFeatures).filter(feature => feature.visible);
};
