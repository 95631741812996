<!-- This component must be a child of a `position: relative` parent -->
<template>
  <div
    class="spinner-container"
    :class="{ bottomPad: heightIsGreaterThan80 }"
    ref="spinnerContainer"
  >
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script setup>
  /* The only reason for this script is to push the spinner to the top of a tall widget container
     The default settings allow the spinner to be vertically centered in short containers. */
  import { ref, onMounted } from 'vue';

  const heightIsGreaterThan80 = ref(false);
  const spinnerContainer = ref(null);

  const handleResize = () => {
    heightIsGreaterThan80.value = spinnerContainer?.value?.clientHeight > 80;
  };

  const observer = new ResizeObserver(handleResize);

  onMounted(() => {
    observer.observe(spinnerContainer.value);
    handleResize();
  });
</script>

<style scoped lang="scss">
  .spinner-container {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(247, 247, 247, 0.8);
    z-index: 100;
    &.bottomPad {
      align-items: flex-start;
    }
  }
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #666;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
</style>
