<template>
  <LoadingSpinner v-if="$apollo.loading" />
  <div v-else>
    <div class="place-label">{{ place.label }}</div>
    <p>{{ place.description }}</p>
    <div class="referred-to-as" v-if="referredToAs.length > 0">
      <em>Referred to as:</em>
      <ul>
        <li v-for="referredToAs in referredToAs" :key="referredToAs.uri">
          <a href="#" @click.stop="$emit('select-place-uri', referredToAs.uri)">{{
            referredToAs.label
          }}</a>
        </li>
      </ul>
    </div>
    <div class="refers-to" v-else-if="refersTo?.uri">
      <em>Refers to: </em>
      <a href="#" @click.stop="$emit('select-place-uri', refersTo.uri)">{{ refersTo.label }}</a>
    </div>
    <button
      v-if="hasMore"
      @click.stop="$emit('select-place-uri', null)"
      title="Show additional places where the current selection is referenced"
    >
      Show additional places
    </button>
  </div>
</template>
<script>
  import gql from 'graphql-tag';
  import LoadingSpinner from '@/symphony/widgets/components/LoadingSpinner.vue';

  export default {
    name: 'PlaceDetail',
    components: {
      LoadingSpinner,
    },
    props: {
      placeUri: {
        type: String,
      },
      hasMore: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['select-place-uri'],
    computed: {
      referredToAs() {
        const values = this.place.referredToAs;
        if (!values) {
          return [];
        }
        const sortedValues = [...values].sort((a, b) => a.label.localeCompare(b.label));
        return sortedValues;
      },
      refersTo() {
        return this.place.refersTo;
      },
    },
    apollo: {
      place: {
        query: gql`
          query Places($filters: PlaceFilter) {
            places(filters: $filters) {
              id
              label
              uri
              description
              referredToAs {
                uri
                label
              }
              refersTo {
                uri
                label
              }
            }
          }
        `,
        variables() {
          return {
            filters: {
              uri: {
                exact: this.placeUri,
              },
            },
          };
        },
        skip() {
          return !this.placeUri;
        },
        update(data) {
          return data.places[0];
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  .place-label {
    font-size: 1.17em;
    font-weight: bold;
  }
  .referred-to-as {
    ul {
      margin-block-start: 0.5em;
      > li {
        margin-block-end: 0.25em;
      }
    }
  }
  .refers-to {
    margin-block-end: 1em;
  }
  a {
    user-select: none;
    cursor: pointer;
    color: unset;
    &:hover {
      color: #4d4ddd;
    }
  }
</style>
