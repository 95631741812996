<template>
  <div :class="{ milestone: milestoneText }">
    <slot>
      {{ milestoneText }}
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'TreeDownMilestone',
    props: ['milestoneText', 'active'],
    methods: {
      scrollToIfActive() {
        if (this.active) {
          this.$nextTick().then(() => {
            setTimeout(() => {
              this.$el.scrollIntoView({ behavior: 'smooth' });
            }, 500);
          });
        }
      },
    },
    watch: {
      active: 'scrollToIfActive',
    },
    mounted() {
      this.scrollToIfActive();
    },
  };
</script>
