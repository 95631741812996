<template>
  <div class="widget" :class="{ expanded: info.expanded, configureMode }">
    <div class="widget-header">
      <div class="widget-title" @click.prevent="toggleCollapse()">
        {{ columnInfo.generation }}.{{ info.generation }} {{ generatedLabel || info.component }}
      </div>
      <div
        class="control configure"
        :class="{ configureMode }"
        @click.prevent="toggleConfigure()"
        v-if="!workspaceIsReadOnly && info.component"
      >
        <font-awesome-icon icon="fa-solid fa-cog" />
      </div>
      <div
        class="control move-up"
        @click.prevent="moveUp()"
        v-if="!workspaceIsReadOnly && widgetIndex > 0"
      >
        <font-awesome-icon icon="fa-solid fa-circle-arrow-up" />
      </div>
      <div
        class="control move-down"
        @click.prevent="moveDown()"
        v-if="!workspaceIsReadOnly && bottomPosition > 0"
      >
        <font-awesome-icon icon="fa-solid fa-circle-arrow-down" />
      </div>
      <div class="delete" @click.prevent="deleteWidget()" v-if="!workspaceIsReadOnly">
        <font-awesome-icon icon="fa-solid fa-times" />
      </div>
      <div class="widget-bottom-border" v-if="showVariableGroupTags">
        <div
          class="variable-group-tag"
          v-for="group in activeVariableGroups"
          :key="group.key"
          :style="`background: ${group.color};`"
        >
          <span class="variable-group-tag-tooltip">
            <div class="up-caret" />
            {{ group.label }}</span
          >
        </div>
      </div>
    </div>
    <div class="widget-body" v-show="info.expanded">
      <widget-picker :columnIndex="columnIndex" :widgetIndex="widgetIndex" v-if="!info.component" />
      <widget-configuration
        :info="info"
        @configuration-update="configurationUpdate($event)"
        v-if="!workspaceIsReadOnly && configureMode"
      />
      <!-- FIXME: another v-show use-case -->
      <component
        :is="info.component"
        :info="info"
        @configuration-update="configurationUpdate($event)"
        v-show="!configureMode"
      />
    </div>
  </div>
</template>

<script>
  import { widgetComponents } from '@/config/widgets';
  import { DORIC_VARIABLE_UNSET, OUTPUTS_TO_PUSH_TO_URL } from '@/store/constants';
  import WidgetPicker from './WidgetPicker.vue';
  import WidgetConfiguration from './WidgetConfiguration.vue';

  export default {
    name: 'Widget',
    components: { ...widgetComponents, WidgetPicker, WidgetConfiguration },
    props: {
      columnInfo: Object,
      info: Object,
      columnIndex: Number,
      widgetIndex: Number,
    },
    data() {
      return {
        expanded: true, // FIXME: This does not appear to be used; info.expanded is used
        configureMode: false,
      };
    },
    computed: {
      workspace() {
        return this.$store.getters.getActiveWorkspace;
      },
      bottomPosition() {
        return this.workspace.columns[this.columnIndex].widgets.length - this.widgetIndex - 1;
      },
      workspaceIsReadOnly() {
        return this.workspace.readOnly;
      },
      generatedLabel() {
        const componentName = this.info.component;
        if (!componentName) {
          // Widget is not specified, thus WidgetPicker is being shown
          return 'Widget Picker';
        }
        const labelInput = this.info.inputs?.label?.value;
        if (labelInput) {
          return labelInput;
        }

        // NOTE: Fallback to a human-friendly version of the component
        // name
        return componentName
          .split('-')
          ?.map(word => word[0].toUpperCase() + word.slice(1))
          ?.join(' ');
      },
      workspaceVariableGroups() {
        return this.$store.getters.getActiveWorkspaceVariableGroups;
      },
      showVariableGroupTags() {
        return this.$store.getters.getActiveWorkspace.showVariableGroupTags;
      },
      activeVariableGroups() {
        // check all input (object) and output (object) variables for the groupKey for each variable
        const activeGroups = [];
        const { inputs, outputs } = this.info;
        if (inputs) {
          Object.values(inputs).forEach(input => {
            if (input.groupKey) {
              activeGroups.push(input.groupKey);
            }
          });
        }

        if (outputs) {
          Object.values(outputs).forEach(output => {
            if (output.groupKey) {
              activeGroups.push(output.groupKey);
            }
          });
        }

        return this.workspaceVariableGroups.filter(group => activeGroups.includes(group.key));
      },
    },
    methods: {
      toggleCollapse() {
        this.$store.commit('toggleWidgetCollapse', {
          columnIndex: this.columnIndex,
          widgetIndex: this.widgetIndex,
        });
      },
      toggleConfigure() {
        this.configureMode = !this.workspaceIsReadOnly && !this.configureMode;
      },
      deleteWidget() {
        this.$store.commit('deleteWidget', {
          columnIndex: this.columnIndex,
          widgetIndex: this.widgetIndex,
        });
      },
      moveUp() {
        this.$store.commit('moveUp', {
          columnIndex: this.columnIndex,
          widgetIndex: this.widgetIndex,
        });
      },
      moveDown() {
        this.$store.commit('moveDown', {
          columnIndex: this.columnIndex,
          widgetIndex: this.widgetIndex,
        });
      },
      configurationUpdate(configuration) {
        this.configureMode = false;
        const { inputs, outputs } = configuration;
        this.$store.commit('addMissingInputVariablesToGroup', { inputs });
        // TODO: Revisit serializing widget inputs / output changes
        // in the store
        // Update global fields
        const query = {
          ...this.$route.query,
        };
        Object.values(outputs).forEach(output => {
          if (output.send && output.value !== DORIC_VARIABLE_UNSET) {
            const name = output.send;
            const { value } = output;
            this.$store.commit('updateVariableInGroup', {
              name,
              value,
              groupKey: output.groupKey,
            });
            if (OUTPUTS_TO_PUSH_TO_URL.includes(name)) {
              // TODO: In the future, we may want to do this update within
              // the Vuex mutation; see if vuex-router-sync can help...
              query[name] = value;
              if (Object.hasOwn(query, name) && value == null) {
                delete query[name];
              }
            }
          }
        });
        this.$router.replace({ query });
      },
    },
  };
</script>

<style scoped lang="scss">
  .widget {
    border-bottom: 1px solid #bbb;
    > .widget-header {
      background: #ddd;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > * {
        padding: 10px;
      }
      > .widget-bottom-border {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        height: 0.25em;
        padding: 0px;
        padding-bottom: 0.2em;
        > .variable-group-tag {
          flex: 1;
          border-radius: 0.25em;
          margin: 0 0.25em;
          > .variable-group-tag-tooltip {
            display: inline-block;
            opacity: 0;
            position: absolute;
            background: #ddd;
            border-radius: 0.25em;
            padding: 0.2em 0.5em;
            font-size: 0.8em;
            margin-top: 0.75em;
            transition: opacity 0.5s;
            transition-delay: 750ms;
            > .up-caret {
              position: absolute;
              top: -0.5em;
              left: 0.5em;
              width: 0;
              height: 0;
              border-left: 0.5em solid transparent;
              border-right: 0.5em solid transparent;
              border-bottom: 0.5em solid #ddd;
            }
          }
          &:hover {
            > .variable-group-tag-tooltip {
              display: block;
              z-index: 100;
              opacity: 0.9;
            }
          }
        }
      }
      > .widget-title {
        cursor: pointer;
        flex: 1;
        font-weight: 500;
        &:hover {
          background: #ccc;
        }
      }
      > .delete,
      .control {
        display: none;
      }
      .control.configureMode {
        display: flex;
        background: yellow;
      }
      &:hover {
        > .delete,
        > .control {
          display: flex;
        }
        > .delete:hover {
          background: red;
          color: white;
          cursor: pointer;
        }
        > .control:hover {
          background: #666;
          color: white;
          cursor: pointer;
        }
      }
    }
    .widget-body {
      position: relative;
      padding: 10px;
    }
    &.configureMode {
      background: #bbb;
    }
  }
</style>
