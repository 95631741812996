<template>
  <span>Translation Alignment Structure</span>

  <div>
    <p>
      <template v-for="token in sortedAlignedText" :key="token[TOKEN_ID_FIELD]">
        <Milestone
          v-if="isFirstVerseOccurrence(token.maculaId)"
          class="aligned-token-milestone"
          :verse-ref="paratextToOsis(token.ref.split('!')[0])"
          :key="`milestone-${paratextToOsis(token.ref.split('!')[0])}`"
        />
        <!-- FIXME: Unify with AnnotatedTextWidget -->
        <span class="aligned-token">
          <word
            :key="token[TOKEN_ID_FIELD]"
            :tokenId="token.sourceTokenId"
            :highlights="highlights"
            :showGreek="showGreek"
            :showGlosses="showGlosses"
            @select-token-lemma="selectTokenLemma"
            @select-token-ids="() => selectTokenIds(token)"
            @hover-token="({ reset }) => highlightTokens(token, reset)"
            :isHighlightedId="isHighlighted(token[TOKEN_ID_FIELD])"
            :tokenShouldBeDisplayedWithReducedVisualContrast="!token.hasAlignmentData"
            :textValueToDisplay="token.value"
            :skipSpaceAfter="token.skipSpaceAfter"
            :targetTokenId="token[TOKEN_ID_FIELD]"
          >
          </word>
        </span>
      </template>
    </p>
  </div>
</template>

<script>
  import { paratextToOsis } from 'bible-reference-formatter';

  import Word from '@/symphony/widgets/Word.vue';
  import { TOKEN_ID_FIELD } from '@/store/constants';
  import Milestone from '@/symphony/widgets/annotated-text-widgets/Milestone.vue';

  export default {
    name: 'AlignedTokensView',
    emits: ['selectTokenLemma', 'selectTokenIds'],
    components: { Milestone, Word },
    props: {
      tokensByMilestone: Object,
      highlights: Object,
      showGreek: Boolean,
      showGlosses: Boolean,
      alignmentData: Object,
      alignedText: Array,
    },
    data() {
      return { hoveredTargetIds: new Map() };
    },
    computed: {
      sortedAlignedText() {
        if (this.alignedText) {
          const internalTextData = this.alignedText.concat([]);
          const sortedAlignedText = internalTextData.sort((a, b) => {
            return a.maculaId.localeCompare(b.maculaId);
          });
          return sortedAlignedText;
        }
        return [];
      },
    },
    methods: {
      getAlignmentForTargetId(tokenId) {
        return this.alignmentData.find(datum => {
          return datum[TOKEN_ID_FIELD] === tokenId;
        });
      },
      getSourceTokenId(tokenId) {
        const datum = this.getAlignmentForTargetId(tokenId);
        if (datum) {
          return datum.sourceIds[0];
        }
        return 0;
      },
      selectTokenLemma(lemma) {
        this.$emit('selectTokenLemma', lemma);
      },
      selectTokenIds(token) {
        const sourceIds = token?.sourceIds || [];
        this.$emit('selectTokenIds', sourceIds);
      },
      highlightTokens(token, reset) {
        const newIds = new Map();
        if (reset) {
          this.hoveredTargetIds = newIds;
          return;
        }
        token.targetIds?.forEach(targetId => {
          newIds.set(targetId, true);
        });
        // TODO: Improve reactivity performance
        this.hoveredTargetIds = newIds;
      },
      isHighlighted(targetTokenId) {
        // TODO: Improve reactivity performance
        return this.hoveredTargetIds.has(targetTokenId);
      },
      isFirstVerseOccurrence(maculaId) {
        const firstMatchingTokenId = this.sortedAlignedText
          .map(datum => datum.maculaId)
          .find(id => id.startsWith(maculaId.substring(0, 9)));
        return firstMatchingTokenId === maculaId;
      },
    },
    created() {
      // NOTE: This is added within `created` to skip reactivity hooks
      // https://github.com/vuejs/vue/issues/1988#issuecomment-402965130
      this.TOKEN_ID_FIELD = TOKEN_ID_FIELD;
      this.paratextToOsis = paratextToOsis;
    },
  };
</script>

<style scoped>
  .aligned-token {
    /* TODO: Mirrors p in TEIElement.vue */
    line-height: 1.7;
  }
  .aligned-token-milestone {
    margin-left: 4px;
    margin-right: 2px;
  }
</style>
