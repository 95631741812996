<template>
  <div>
    <div
      v-for="alignedTranslation in alignedTranslations"
      :key="alignedTranslation.id"
      @click.prevent="setAlignedTranslation(alignedTranslation)"
    >
      <span :class="{ active: outputs.alignedTranslation.value?.id === alignedTranslation.id }">
        {{ alignedTranslation.usfmRef }}
      </span>
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import gql from 'graphql-tag';
  import { DEFAULT_GROUP_KEY, DEFAULT_ALIGNED_TRANSLATION } from '../../store/constants';

  export default {
    name: 'TranslationPickerWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    data() {
      return {
        alignmentData: [],
      };
    },
    props: {
      doric: {
        inputs: {
          osisRef: {
            groupKey: DEFAULT_GROUP_KEY,
            value: { type: String, default: 'osisRef' },
          },
        },
        outputs: {
          alignedTranslation: null,
          selectedTranslation: DEFAULT_ALIGNED_TRANSLATION,
        },
      },
    },
    watch: {
      alignedTranslations: {
        immediate: true,
        handler(alignedTranslations) {
          if (alignedTranslations) {
            this.setAlignedTranslation(
              alignedTranslations.find(
                translation =>
                  translation.usfmRef === this.$options.props.doric.outputs.selectedTranslation,
              ),
            );
          }
        },
      },
    },
    apollo: {
      alignedTranslations: {
        query: gql`
          query AlignedTranslations {
            textualEditions(filters: { isAlignedTranslation: true }, order: { usfmRef: ASC }) {
              id
              usfmRef
              targetAlignments {
                id
                name
              }
            }
          }
        `,
        skip() {
          return !this.osisRef;
        },
        update(data) {
          this.errorMessage = null;
          return data.textualEditions;
        },
        error(error) {
          this.errorMessage = error.message;
          console.error(error);
        },
      },
    },
    methods: {
      setAlignedTranslation(alignedTranslation) {
        this.outputs.alignedTranslation.value = alignedTranslation;
        this.submit();
      },
    },
    computed: {},
  };
</script>

<style scoped lang="scss">
  span {
    cursor: pointer;
    &.active {
      font-weight: bold;
    }
  }
</style>
