<template>
  <div>
    <div class="heading">Create a new Annotation Feature</div>
    <table>
      <tr>
        <td>Label:</td>
        <td>
          <input placeholder="Label" v-model="label" />
          <template v-for="error in fieldErrors.filter(f => f.field === 'label')" :key="error">
            <div class="error-message">{{ error.message }}</div>
          </template>
        </td>
      </tr>
    </table>
    <div v-if="fieldErrors.length > 0">
      <div>The following errors occurred while creating the annotation feature:</div>
      <ul>
        <li v-for="error in fieldErrors" :key="error">
          {{ error.message }}
        </li>
      </ul>
    </div>
    <button @click="create" :disabled="!label">Create Annotation Feature</button>
    <button @click="emit('back')">Back</button>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  defineProps(['fieldErrors']);

  const uri = ref(null);
  const label = ref(null);
  const emit = defineEmits(['createAnnotationFeature', 'back']);

  const create = () => {
    emit('createAnnotationFeature', {
      uri: uri.value,
      label: label.value,
    });
  };
</script>

<style scoped>
  .heading {
    font-weight: bold;
  }
  .error-message {
    color: red;
    font-size: 0.8rem;
  }
</style>
