<template>
  <div>
    <div
      v-for="(proposition, propositionIdx) in propositions"
      :key="propositionIdx"
      :class="['proposition', `p-${proposition.level}`]"
    >
      <span v-for="token in proposition.tokens" :key="token.maculaId" class="proposition-word">
        <Milestone
          v-if="isFirstToken(token.ref)"
          :verse-ref="getRef(token.ref)"
          :key="`milestone-${getRef(token.ref)}`"
        />
        <Word
          :tokenId="token.maculaId"
          :highlights="highlights"
          :showGreek="showGreek"
          :showGlosses="showGlosses"
          :selectedGlosses="selectedGlosses"
          @select-token-lemma="selectTokenLemma"
          @select-token-ids="selectTokenIds"
        />
        <!--eslint-disable-next-line max-len -->
        <span v-if="!loading && !hasToken(token.maculaId)" class="no-token" :title="token.ref">
          &#8230;
        </span>
      </span>
    </div>
  </div>
</template>

<script>
  import Word from '@/symphony/widgets/Word.vue';
  import Milestone from './Milestone.vue';

  export default {
    name: 'LevinsohnPropositions',
    props: {
      propositions: Object,
      tokens: Object,
      highlights: Object,
      showGreek: Boolean,
      showGlosses: Boolean,
      selectedGlosses: Set,
      loading: Boolean,
    },
    emits: ['selectTokenLemma', 'selectTokenIds'],
    components: {
      Milestone,
      Word,
    },
    methods: {
      selectTokenLemma(lemma) {
        this.$emit('selectTokenLemma', lemma);
      },
      selectTokenIds(ids) {
        this.$emit('selectTokenIds', ids);
      },
      hasToken(tokenId) {
        return this.$store.getters.getActiveWorkspace.globalTokens.has(tokenId);
      },
      // TODO: Refactor using common ref parsing libraries
      isFirstToken(value) {
        return value.split('!')[1] === '1';
      },
      getRef(value) {
        const [verseRef] = value.split('!');
        return verseRef;
      },
    },
    data() {
      return {
        isRTL: false,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .proposition {
    margin-bottom: 0.5rem;
    line-height: 1.7;
  }
  .proposition.p-0 {
    margin-left: 5rem;
  }
  .proposition.p-1 {
    margin-left: 10rem;
  }
  .proposition.p-2 {
    margin-left: 15rem;
  }
  // TODO: Refactor with Treedown CSS
</style>
