<template>
  <div>
    <LoadingSpinner v-if="$apollo.loading" />
    <EmptyMessage v-if="mode === modes.NO_FEATURE">
      <!-- TELL USER TO USE ANNOTATION_FEATURE WIDGET -->
      You have not selected an annotation feature. Use the Annotation Sets Widget and Annotation
      Features Widget to select/create one.
    </EmptyMessage>

    <template v-else-if="mode === modes.NO_ANNOT">
      <!-- GIVE OPTIONS FOR LOADING/CREATING ANNOTATION -->
      <div v-if="selectedAnnotationFeature" class="selected-feature-state">
        <span class="field">Annotation Feature:</span>
        <span class="value">
          {{ selectedAnnotationFeature }}
        </span>
      </div>
      <button
        @click="mode = modes.SELECT_ANNOT"
        :disabled="!Array.isArray(rootInstancesOfFeature) || rootInstancesOfFeature.length === 0"
      >
        Select Instance
      </button>
      <button @click="createRootLevelInstance">Create New Instance</button>
    </template>

    <template v-else-if="mode === modes.SELECT_ANNOT">
      <!-- SHOW SELECTOR FOR LOADING EXISTING ANNOTATION -->
      <div v-if="selectedAnnotationFeature" class="selected-feature-state">
        <span class="field">Annotation Feature:</span>
        <span class="value">
          {{ selectedAnnotationFeature }}
        </span>
      </div>
      <div>
        <select v-model="selectedRootInstance">
          <option v-for="instance in rootInstancesOfFeature" :key="instance" :value="instance">
            {{ instance?.label || shortenUri(instance.uri) }}
          </option>
        </select>
        <button @click="loadRootInstance">Load Instance</button>
      </div>
      <button @click="mode = modes.NO_ANNOT">Cancel</button>
    </template>

    <template v-else-if="mode === modes.CREATE_ANNOT">
      <!-- SHOW FORM FOR CREATING NEW FEATURE -->
      <div v-if="selectedAnnotationFeature" class="selected-feature-state">
        <span class="field">Annotation Feature:</span>
        <span class="value">
          {{ selectedAnnotationFeature }}
        </span>
      </div>
      <table>
        <tr>
          <td>Feature:</td>
          <td>
            <span class="value">
              {{ selectedAnnotationFeature }}
            </span>
          </td>
        </tr>
        <tr>
          <td>URI:</td>
          <td>
            <span class="value">URI will be generated automatically</span>
          </td>
        </tr>
        <tr>
          <td>Label:</td>
          <td>
            <input placeholder="Label (optional)" v-model="newInstanceLabel" />
            <template v-for="error in fieldErrors.filter(f => d === 'label')" :key="error">
              <div class="error-message">{{ error.message }}</div>
            </template>
          </td>
        </tr>
      </table>
      <button @click="createAnnotation">Create New Annotation</button>
      <button @click="mode = modes.NO_ANNOT">Cancel</button>
    </template>

    <template v-else-if="mode === modes.ANNOT_SELECTED">
      <div>
        <AnnotationInstanceView
          :instance="currentAnnotationInstance"
          :ancestors="instanceTraversal"
          :rootFeature="rootFeature"
          :features="siblingFeatures"
          :proposedTokens="
            Array.isArray(parsedSelectedTokenIds) &&
            parsedSelectedTokenIds.length > 0 &&
            Array.isArray(apolloProposedSelectedTokens) &&
            apolloProposedSelectedTokens.length > 0
              ? apolloProposedSelectedTokens
              : []
          "
          @push="pushToInstanceTraversal"
          @pop="popFromInstanceTraversal"
          @deleteCurrentInstance="deleteCurrentInstance"
          @addChild="createChildInstance"
          @onTokenClick="handleTokenClick"
          @applyProposedTokens="applyProposedTokens"
          @removeTokens="removeTokens"
        />
      </div>
    </template>

    <EmptyMessage v-else>Unknown mode: {{ mode }}</EmptyMessage>
  </div>
</template>

<script>
  import { gql } from 'graphql-tag';
  import mixins from '@/mixins';
  import {
    DEFAULT_GROUP_KEY,
    TOKEN_ID_FIELD,
    APOLLO_PAGE_SIZE,
    APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
    APOLLO_QUERY_FOR_ANNOTATION_FEATURES,
    APOLLO_QUERY_FOR_ANNOTATION_INSTANCES,
  } from '@/store/constants';
  import { convertUsfmToOsis } from '@/common/refUtils';
  import AnnotationInstanceView from './AnnotationInstanceView.vue';
  import LoadingSpinner from '../components/LoadingSpinner.vue';
  import EmptyMessage from '../components/EmptyMessage.vue';

  const MODES = {
    NO_FEATURE: 'NO_FEATURE',
    NO_ANNOT: 'NO_ANNOT',
    SELECT_ANNOT: 'SELECT_ANNOT',
    CREATE_ANNOT: 'CREATE_ANNOT',
    ANNOT_SELECTED: 'ANNOT_SELECTED',
  };

  export default {
    name: 'AnnotationInstancesWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          selectedAnnotationFeature: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'selectedAnnotationFeature',
          },
          selectedTokenIds: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'selectedTokenIds',
          },
        },
        outputs: {
          selectedLemma: null,
          selectedTokenIds: null,
          osisRef: null,
        },
      },
    },
    data: () => ({
      mode: MODES.NO_FEATURE,
      modes: Object.freeze(MODES),
      fieldErrors: [],
      newInstanceLabel: '',
      parsedSelectedTokenIds: [],
      instanceTraversal: [],
      selectedRootInstance: null,
      currentAnnotationInstance: null,
    }),
    computed: {
      rootFeature() {
        const feature = this.siblingFeatures.find(f => f.uri === this.selectedAnnotationFeature);
        if (!feature) {
          return { uri: this.selectedAnnotationFeature, label: null };
        }
        return feature;
      },
    },
    watch: {
      selectedTokenIds: {
        handler() {
          if (!this.selectedTokenIds) {
            this.parsedSelectedTokenIds = [];
            return;
          }
          try {
            const tokenIds = JSON.parse(this.selectedTokenIds);
            if (!Array.isArray(tokenIds)) {
              this.parsedSelectedTokenIds = [];
              throw new Error('selectedTokenIds is not an array');
            }
            this.parsedSelectedTokenIds = tokenIds;
          } catch (e) {
            this.parsedSelectedTokenIds = [];
          }
        },
        immediate: true,
      },
      selectedAnnotationFeature: {
        handler() {
          if (!this.selectedAnnotationFeature) {
            this.mode = MODES.NO_FEATURE;
            return;
          }
          if (this.mode === MODES.NO_FEATURE) {
            this.mode = MODES.NO_ANNOT;
          }
          // If there is already an active mode, we don't need to do anything,
          // siblingFeatures will update independently.
        },
        immediate: true,
      },
      instanceTraversal() {
        if (this.instanceTraversal.length === 0 && this.mode !== MODES.NO_FEATURE) {
          this.mode = MODES.NO_ANNOT;
        }
      },
    },
    apollo: {
      rootInstancesOfFeature: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_INSTANCES}($featureUri: String!) {
            annotations(filters: { depth: 1, featureUri: $featureUri }, pagination: { limit: 10 }) {
              label
              uri
            }
          }
        `,
        skip() {
          return !this.selectedAnnotationFeature;
        },
        variables() {
          return {
            featureUri: this.selectedAnnotationFeature,
          };
        },
        update(data) {
          this.$nextTick(() => {
            const [first] = data.annotations;
            this.selectedRootInstance = first;
          });
          return data.annotations;
        },
      },
      siblingFeatures: {
        // Get the parent annotation set's children[features] (i.e., siblings)
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_FEATURES}($uri: String!) {
            annotationFeatures(filters: { uri: { exact: $uri } }) {
              annotationSet {
                features {
                  uri
                  label
                }
              }
            }
          }
        `,
        fetchPolicy: APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
        skip() {
          return !this.selectedAnnotationFeature;
        },
        variables() {
          return {
            uri: this.selectedAnnotationFeature,
          };
        },
        update(data) {
          if (data.annotationFeatures.length === 0) {
            return null;
          }
          if (data.annotationFeatures.length > 1) {
            console.warn('More than one annotation feature returned');
          }
          return data.annotationFeatures[0].annotationSet.features;
        },
      },
      apolloCurrentAnnotationInstance: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_INSTANCES}($uri: String!) {
            annotations(filters: { uri: { exact: $uri } }) {
              id
              label
              uri
              feature {
                id
                uri
                label
              }
              linkedBy {
                id
                uri
                label
                feature {
                  label
                }
                tokens {
                  id
                  ref
                }
              }
              linkedTo {
                id
                uri
                label
                feature {
                  label
                }
                tokens {
                  id
                  ref
                }
              }
              children(pagination: { limit: ${APOLLO_PAGE_SIZE} }) {
                id
                uri
                label
                feature {
                  id
                  uri
                  label
                }
              }
              tokens {
                id
                wordValue
                lemma
                ref
              }
            }
          }
        `,
        fetchPolicy: APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
        skip() {
          return this.instanceTraversal.length === 0;
        },
        variables() {
          return {
            uri: this.instanceTraversal.slice(-1)[0],
          };
        },
        update(data) {
          if (data.annotations.length === 0) {
            console.warn('no annotation found for uri', this.instanceTraversal.slice(-1)[0]);
            this.currentAnnotationInstance = null;
            return null;
          }

          if (data.annotations.length > 1) {
            console.warn(
              'more than one annotation found for uri',
              this.instanceTraversal.slice(-1)[0],
            );
          }

          this.outputs.selectedTokenIds.value = JSON.stringify(
            data.annotations[0].tokens.map(t => t[TOKEN_ID_FIELD]),
          );
          this.submit();

          const [first] = data.annotations;
          this.currentAnnotationInstance = first;
          return first;
        },
      },
      apolloProposedSelectedTokens: {
        query: gql`
          query WordTokenQuery($tokenIds: [ID!]!) {
            wordTokens(filters: { ${TOKEN_ID_FIELD}: { inList: $tokenIds } }) {
              id
              wordValue
              lemma
              ref
              textualEdition {
                usfmRef
              }
            }
          }
        `,
        skip() {
          return this.parsedSelectedTokenIds.length === 0;
        },
        variables() {
          return {
            tokenIds: this.parsedSelectedTokenIds,
          };
        },
        update(data) {
          return data.wordTokens;
        },
      },
    },
    methods: {
      shortenUri(uri) {
        if (!uri) return '';
        const parts = uri.split('/').filter(p => p);
        const lastPart = parts[parts.length - 1];
        const lastPartShort =
          lastPart.length > 35 ? `${lastPart.slice(0, 15)}...${lastPart.slice(-20)}` : lastPart;
        return `${uri.slice(0, 10)}.../${lastPartShort}`;
      },
      unloadAndUnset() {
        this.instanceTraversal = [];
      },
      loadRootInstance() {
        this.instanceTraversal = [this.selectedRootInstance.uri];
        this.mode = MODES.ANNOT_SELECTED;
      },
      pushToInstanceTraversal(uri) {
        this.instanceTraversal.push(uri);
      },
      popFromInstanceTraversal(popCount) {
        this.instanceTraversal = this.instanceTraversal.slice(
          0,
          this.instanceTraversal.length - popCount,
        );
        if (this.instanceTraversal.length === 0) {
          this.currentAnnotationInstance = null;
          this.mode = MODES.NO_ANNOT;
        }
      },
      createInstance({ label, featureUri, parentUri }) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($featureUri: String!, $label: String, $parentUri: String) {
                createAnnotation(
                  annotationFeatureUri: $featureUri
                  label: $label
                  parentUri: $parentUri
                ) {
                  __typename
                  ... on Annotation {
                    label
                    uri
                  }
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables: {
              featureUri,
              label,
              parentUri,
            },
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_INSTANCES],
          })
          .then(({ data }) => {
            this.instanceTraversal.push(data.createAnnotation.uri);
            this.mode = MODES.ANNOT_SELECTED;
          })
          .catch(error => {
            console.error('createInstanceOfFeature', error);
          });
      },
      createRootLevelInstance() {
        // We're not going to give labels or uris to root level instances
        // We just automatically create them using the feature uri and leave stuff blank
        this.createInstance({
          // label: this.newInstanceLabel,
          featureUri: this.selectedAnnotationFeature,
          // parentUri is not set for root level instances
        });
      },
      createChildInstance(featureUri) {
        this.createInstance({
          // uri will be set automatically
          // label will be left empty
          featureUri,
          parentUri: this.instanceTraversal.slice(-1)[0],
        });
      },
      deleteCurrentInstance() {
        // eslint-disable-next-line no-alert
        const areYouSure = window.confirm('Are you sure you want to delete this instance?');
        if (!areYouSure) return;
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!) {
                deleteAnnotation(id: $id) {
                  __typename
                  ... on Annotation {
                    label
                    uri
                  }
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables: {
              id: this.apolloCurrentAnnotationInstance.id,
            },
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_INSTANCES],
          })
          .then(() => {
            this.instanceTraversal = this.instanceTraversal.slice(0, -1);
          })
          .catch(error => {
            console.error('deleteInstance', error);
          });
      },
      updateTokens(tokens) {
        return this.$apollo.mutate({
          mutation: gql`
            mutation ($id: ID!, $tokens: [ID!]!) {
              updateAnnotation(id: $id, tokens: $tokens) {
                __typename
                ... on Annotation {
                  label
                  uri
                }
                ... on OperationInfo {
                  messages {
                    kind
                    message
                    field
                  }
                }
              }
            }
          `,
          variables: {
            id: this.apolloCurrentAnnotationInstance.id,
            tokens,
          },
          refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_INSTANCES],
        });
      },
      applyProposedTokens() {
        this.updateTokens(this.parsedSelectedTokenIds).catch(error => {
          console.error('addTokensToInstance', error);
        });
      },
      removeTokens() {
        // eslint-disable-next-line no-alert
        const areYouSure = window.confirm(
          'Are you sure you want to remove the tokens from this instance?',
        );
        if (!areYouSure) return;

        // textualEdition can be none because we're emptying the instance tokens
        this.updateTokens([], 'NONE').catch(error => {
          console.error('addTokensToInstance', error);
        });
      },
      handleTokenClick(token) {
        this.outputs.selectedLemma.value = token.lemma;
        this.outputs.selectedTokenRef.value = token.ref;
        this.outputs.osisRef.value = convertUsfmToOsis(token.ref.split('!')[0]);
        this.submit();
      },
    },
    components: {
      AnnotationInstanceView,
      LoadingSpinner,
      EmptyMessage,
    },
  };
</script>

<style scoped lang="scss">
  .selected-feature-state {
    font-size: 0.8rem;
    color: #555;

    .field {
      font-weight: bold;
    }

    .value {
      margin-left: 0.5rem;
    }
  }

  table .value {
    color: #aaa;
    font-style: italic;
  }
</style>
