<template>
  <div class="content" :class="{ rtl }">
    <div class="left-column">
      <div :style="leftColumnStyle">
        <button
          v-if="annotation?.children?.length"
          @click="expanded = !expanded"
          :title="expanded ? 'Collapse' : 'Expand'"
          class="icon-text-button"
        >
          <div class="icon">
            <font-awesome-icon v-if="expanded" icon="fa-solid fa-angle-down" size="xs" />
            <font-awesome-icon v-else icon="fa-solid fa-angle-right" size="xs" />
          </div>
          <div class="text">
            {{ annotation?.feature?.label || 'unlabeled feature' }}
          </div>
        </button>
        <div v-else class="left-column-text-only">
          {{ annotation?.feature?.label || 'unlabeled feature' }}
        </div>
      </div>
    </div>
    <div class="right-column">
      <div v-if="titleType !== CONTENT_TITLE_TYPE.NO_TITLE">
        <template v-if="titleType === CONTENT_TITLE_TYPE.RANGE_ONLY">
          <span class="passage-reference">{{ scriptureReference }}</span>
        </template>
        <template v-else-if="titleType === CONTENT_TITLE_TYPE.LABEL_ONLY">
          <span class="title">
            {{ annotation?.label }}
          </span>
        </template>
        <template v-else-if="titleType === CONTENT_TITLE_TYPE.LABEL_AND_RANGE">
          <span class="title">
            {{ annotation?.label }}
          </span>
          <span class="passage-reference">{{ scriptureReference }}</span>
        </template>
        <template v-else-if="titleType === CONTENT_TITLE_TYPE.RANGE_AND_PREVIEW">
          <span class="passage-reference">{{ scriptureReference }}</span>
          <!-- Must use span for this "button" (display:inline doesn't work correctly on <button />) -->
          <span class="token-preview tokens-container" @click="expanded = !expanded" title="Expand">
            <span class="hijack-pointer-events">
              <Token
                v-for="tokenId in impliedTokenPreview.tokenIds"
                :key="tokenId"
                :tokenId="tokenId"
              />
              {{ impliedTokenPreview.showEllipsis ? '...' : '' }}
            </span>
          </span>
        </template>
        <template v-else-if="titleType === CONTENT_TITLE_TYPE.LABEL_RANGE_AND_PREVIEW">
          <div>
            <span class="title">
              {{ annotation?.label }}
            </span>
            <span class="passage-reference">{{ scriptureReference }}</span>
          </div>
          <!-- Must use span for this "button" (display:inline doesn't work correctly on <button />) -->
          <span class="token-preview tokens-container" @click="expanded = !expanded" title="Expand">
            <span class="hijack-pointer-events">
              <Token
                v-for="tokenId in impliedTokenPreview.tokenIds"
                :key="tokenId"
                :tokenId="tokenId"
              />
              {{ impliedTokenPreview.showEllipsis ? '...' : '' }}
            </span>
          </span>
        </template>
        <template v-else>There is a bug in our browser, please report: {{ titleType }}</template>
      </div>

      <div class="tokens-container" v-if="annotation?.tokens?.length">
        <!-- FIXME: token vs word clicks; review how this is done within ATW via [MAC-230 : Adapt Discourse Annotations structure for HOT](https://linear.app/biblica-clear-team/issue/MAC-230/adapt-discourse-annotations-structure-for-hot) -->
        <Token
          v-for="token in annotation?.tokens"
          :key="getTokenKey(token)"
          :tokenId="getTokenKey(token)"
        />
      </div>
    </div>
  </div>
  <template v-if="expanded">
    <DiscourseTree
      v-for="(annotation, index) in annotation?.children"
      :annotation="annotation"
      :key="index"
      :rtl="rtl"
      :depth="depth + 1"
    />
  </template>
</template>

<script>
  import formatOsis, { paratextToOsis } from 'bible-reference-formatter';
  import { TOKEN_ID_FIELD } from '@/store/constants';
  import Token from '@/symphony/widgets/tokens/Token.vue';
  import { flattenTree } from '@/common/annotationUtils';

  const PREVIEW_TOKEN_LIMIT = 7;
  export default {
    name: 'DiscourseTree',
    components: {
      Token,
    },
    props: {
      annotation: {
        type: Object,
        default: () => {},
      },
      depth: {
        type: Number,
        default: 0,
      },
      rtl: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      expanded: true,
    }),
    created() {
      if (this.annotation?.tokens?.length > 0 && this.annotation?.children?.length > 0) {
        console.warn(
          'DiscourseTree component created with both tokens and children. This is not supported.',
          this.annotation,
        );
      }
      this.CONTENT_TITLE_TYPE = {
        NO_TITLE: 0,
        RANGE_ONLY: 1,
        LABEL_ONLY: 2,
        LABEL_AND_RANGE: 3,
        RANGE_AND_PREVIEW: 4,
        LABEL_RANGE_AND_PREVIEW: 5,
      };
    },
    computed: {
      // TODO: Refactor with TreeItem
      scriptureReference() {
        const usfmRef = this.annotation?.scriptureReference?.usfmRef;
        if (!usfmRef) {
          return '';
        }
        const osisRef = paratextToOsis(usfmRef);
        return formatOsis('niv-short', osisRef);
      },
      titleType() {
        // If there are tokens, we show the range in the left column
        const showRange = this.annotation?.tokens?.length === 0;
        const showLabel = !!this.annotation?.label?.trim();
        const showPreview = !this.expanded;

        if (showLabel && showPreview) {
          return this.CONTENT_TITLE_TYPE.LABEL_RANGE_AND_PREVIEW;
        }
        if (showLabel && showRange) {
          return this.CONTENT_TITLE_TYPE.LABEL_AND_RANGE;
        }
        if (showLabel) {
          return this.CONTENT_TITLE_TYPE.LABEL_ONLY;
        }
        if (showPreview) {
          return this.CONTENT_TITLE_TYPE.RANGE_AND_PREVIEW;
        }
        if (showRange) {
          return this.CONTENT_TITLE_TYPE.RANGE_ONLY;
        }
        return this.CONTENT_TITLE_TYPE.NO_TITLE;
      },
      // TODO: Refactor this so we no longer need implied tokens
      impliedTokenPreview() {
        const impliedTokens = flattenTree(this.annotation).flatMap(d => d.tokens);
        const tokenIds =
          impliedTokens?.slice(0, PREVIEW_TOKEN_LIMIT)?.map(t => t[TOKEN_ID_FIELD]) || [];
        return {
          tokenIds,
          showEllipsis: this.annotation?.impliedTokens?.length > PREVIEW_TOKEN_LIMIT,
        };
      },
      leftColumnStyle() {
        return {
          marginLeft: `${this.depth * 1.75}rem`,
        };
      },
    },
    methods: {
      getTokenKey(token) {
        return token[TOKEN_ID_FIELD];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon-text-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-width: 0;
    padding: 0;
    margin-left: -1.75rem; // the size of the icon + its right margin
    background-color: transparent;
    font-size: inherit;
    color: rgb(64, 64, 255);
    font-family: inherit;
    overflow: hidden;
    cursor: pointer;
    text-align: left;

    .icon {
      display: inline-block;
      box-sizing: border-box;
      // reset font size so that icon is not affected by parent font size
      font-size: 1rem;
      background-color: #eee;
      border: 1px solid #ddd;
      color: black;
      width: 1.25rem;
      height: 1.25rem;
      aspect-ratio: 1;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
    }

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      .icon {
        background-color: #ccc;
      }
    }
  }

  .label-annotation {
    display: flex;
    flex-direction: row;
    align-items: center;

    span,
    button {
      margin-right: 0.5rem;
    }

    .label {
      font-weight: bold;
      margin-right: 1rem;
      color: #333;
    }
  }

  .passage-reference {
    font-size: 0.8em;
    color: #64748b;
    white-space: nowrap;
  }

  div.content {
    display: flex;
    flex-direction: row;
    align-items: first baseline;
    &.rtl {
      .tokens-container {
        margin-inline-start: 0.1rem;
        // FIXME: Refactor with TextAnnotatorWidget via
        // [MAC-230 : Adapt Discourse Annotations structure for HOT](https://linear.app/biblica-clear-team/issue/MAC-230/adapt-discourse-annotations-structure-for-hot)
        font-family: 'SBLBibLit';
        direction: rtl;
        font-size: 1.4rem;
        line-height: 1.7;
        &:deep(.verse):not([n='1']) {
          margin-inline-end: 0;
          margin-inline-start: 0.75rem;
        }
      }
    }

    > div {
      padding-top: 0.3rem;
    }
  }

  .left-column {
    vertical-align: top;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 0.5rem;
      width: 6rem;

      font-size: 0.8em;
      color: rgb(64, 64, 255);

      > div {
        white-space: nowrap; // This parallels the nowrap on icon-buttons but doesn't look great on longer strings
      }

      > * {
        margin-right: 0.5rem;
      }
    }

    .left-column-text-only {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .right-column {
    display: flex;
    flex-direction: column;
    justify-items: left;

    .title {
      font-weight: bold;
      font-size: small;
      font-variant: small-caps;
      padding-right: 0.5rem;
    }
  }

  .token-preview {
    display: inline;
    border: none;
    background: none;
    font-family: inherit;
    font-size: 0.8em;
    color: #888;
    border-radius: 5px;
    padding-right: 0.5rem;
    cursor: pointer;

    &:hover {
      color: #333;
    }

    .hijack-pointer-events {
      pointer-events: none;
    }
  }
  .unit {
    display: inline-block;
  }
</style>
