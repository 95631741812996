<template>
  <div class="form">
    <template v-if="groupKey === constants.LOCAL_DATA_STATE">
      <label>{{ property }}</label>
      <select v-model="groupKey" @change="fieldUpdate()">
        <option :selected="groupKey == 'local'">local</option>
        <option
          v-for="variableGroup in workspaceVariableGroups"
          :key="variableGroup.key"
          :selected="groupKey == variableGroup.key"
          :value="variableGroup.key"
        >
          {{ variableGroup.label }}
        </option>
      </select>
      <input v-model="value" @change="fieldUpdate()" placeholder="No input yet" />
    </template>
    <template v-else>
      <div class="input-column">
        <label>{{ property }}</label>
        <div class="input-row">
          <div>
            <span>Group:</span>
            <select v-model="groupKey" @change="fieldUpdate()">
              <option :selected="groupKey == 'local'">local</option>
              <option
                v-for="variableGroup in workspaceVariableGroups"
                :key="variableGroup.key"
                :selected="groupKey === variableGroup.key"
                :value="variableGroup.key"
              >
                {{ variableGroup.label }}
              </option>
            </select>
          </div>
          <div>
            <span>Variable:</span>
            <select class="remote" v-model="value" @change="fieldUpdate()">
              <option :value="null || ''" disabled :selected="!value">
                Select variable to listen to
              </option>
              <option v-for="(v, k) in variablesFromSelectedGroup" :key="k" :value="k">
                {{ k }}{{ ` (${!v ? 'not set' : v})` }}
              </option>
            </select>
            <span class="truncated-value-preview">{{ v }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { LOCAL_DATA_STATE } from '@/store/constants';

  export default {
    name: 'InputFieldConfiguration',
    props: {
      property: String,
      initialValue: Object,
    },
    data() {
      return {
        value: this.initialValue?.value || 'not set', // TODO: label is typically null by default. Is there another way we should be handling this?
        groupKey: this.initialValue?.groupKey,
        constants: {
          LOCAL_DATA_STATE,
        },
      };
    },
    computed: {
      globalFields() {
        return this.$store.getters.getActiveWorkspace?.globalFields || {};
      },
      workspaceVariableGroups() {
        return this.$store.getters.getActiveWorkspaceVariableGroups || [];
      },
      variablesFromSelectedGroup() {
        return (
          this.workspaceVariableGroups.find(group => group.key === this.groupKey)?.variables || {}
        );
      },
    },
    methods: {
      fieldUpdate() {
        this.$emit('field-update', {
          property: this.property,
          value: this.value,
          groupKey: this.groupKey,
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .form {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 2px;
    gap: 5px;
    background-color: #ccc;
    > .input-column {
      display: flex;
      flex-direction: column;
      gap: 5px;
      label {
        font-weight: bold;
        display: flex;
      }
      .input-row {
        display: flex;
        flex-flow: row wrap;
        gap: 1em;
        div {
          display: flex;
          font-weight: 500;
          gap: 1em;
        }
        input {
          text-align: right;
          max-width: fit-content;
        }
        select.remote .truncated-value-preview {
          margin-left: 1em;
          color: #666;
          max-width: 3em;
          overflow: hidden;
        }
      }
    }
  }
</style>
