<template>
  <div :class="['column', widthClass, info.expanded ? 'expanded' : '']">
    <div class="column-header">
      <div class="column-title" @click.prevent="toggleCollapse()">
        <div class="column-generation">
          <span v-show="info.expanded">{{ info.label || `Column ${info.generation}` }}</span>
        </div>
      </div>
      <div
        class="control move-left"
        @click.prevent="moveLeft()"
        v-if="!workspaceIsReadOnly && info.expanded && columnIndex > 0"
      >
        <font-awesome-icon icon="fa-solid fa-circle-arrow-left" />
      </div>
      <div
        class="control width"
        @click.prevent="toggleWidth()"
        v-show="!workspaceIsReadOnly && info.expanded"
      >
        <span v-if="info.width === constants.widthNarrow">
          <font-awesome-icon icon="fa-solid fa-arrows-left-right" />
        </span>
        <span v-if="info.width === constants.widthNormal">
          <font-awesome-icon icon="fa-solid fa-arrows-left-right-to-line" />
        </span>
        <span v-if="info.width === constants.widthWide">
          <font-awesome-icon
            icon="fa-solid fa-down-left-and-up-right-to-center"
            class="custom-rotate-icon"
          />
        </span>
      </div>
      <div
        class="control move-right"
        @click.prevent="moveRight()"
        v-if="!workspaceIsReadOnly && info.expanded && rightPosition > 0"
      >
        <font-awesome-icon icon="fa-solid fa-circle-arrow-right" />
      </div>
      <div
        class="control toggle-configuration"
        :class="{ columnConfigurationMode }"
        @click.prevent="toggleConfiguration()"
        v-show="!workspaceIsReadOnly && info.expanded"
      >
        <font-awesome-icon icon="fa-solid fa-cog" />
      </div>
      <div class="delete" @click.prevent="deleteColumn()" v-if="!workspaceIsReadOnly">
        <font-awesome-icon icon="fa-solid fa-times" />
      </div>
    </div>
    <div v-if="columnConfigurationMode" class="column-configuration">
      <column-configuration
        :info="info"
        :columnIndex="columnIndex"
        @configuration-update="columnConfigurationMode = false"
      />
    </div>
    <div v-else class="column-body" v-show="info.expanded">
      <Widget
        v-for="(widgetInfo, widgetIndex) in info.widgets"
        :info="widgetInfo"
        :columnInfo="info"
        :key="widgetInfo.generation"
        :columnIndex="columnIndex"
        :widgetIndex="widgetIndex"
      />
      <div class="controls" v-if="!workspaceIsReadOnly">
        <div class="add" @click.prevent="addWidget()">
          <font-awesome-icon icon="fa-solid fa-circle-plus" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Widget from './Widget.vue';
  import ColumnConfiguration from './ColumnConfiguration.vue';

  export default {
    name: 'Column',
    components: {
      Widget,
      ColumnConfiguration,
    },
    props: {
      info: Object,
      columnIndex: Number,
    },
    computed: {
      workspace() {
        return this.$store.getters.getActiveWorkspace;
      },
      rightPosition() {
        return this.workspace.columns.length - this.columnIndex - 1;
      },
      workspaceIsReadOnly() {
        return this.workspace.readOnly;
      },
      widthClass() {
        const { widthWide, widthNarrow } = this.constants;
        if (this.info.width === widthWide) {
          return 'wide';
        }
        if (this.info.width === widthNarrow) {
          return 'narrow';
        }
        return '';
      },
    },
    data() {
      return {
        expanded: true,
        constants: {
          widthNormal: 1,
          widthWide: 2,
          widthNarrow: 0,
        },
        columnConfigurationMode: false,
      };
    },
    methods: {
      toggleCollapse() {
        this.$store.commit('toggleColumnCollapse', this.columnIndex);
      },
      toggleConfiguration() {
        this.columnConfigurationMode = !this.columnConfigurationMode;
      },
      toggleWidth() {
        const { widthWide, widthNarrow, widthNormal } = this.constants;
        if (this.info.width === widthNarrow) {
          this.$store.commit('updateColumnWidth', {
            width: widthNormal,
            columnIndex: this.columnIndex,
          });
        } else if (this.info.width === widthNormal) {
          this.$store.commit('updateColumnWidth', {
            width: widthWide,
            columnIndex: this.columnIndex,
          });
        } else if (this.info.width === widthWide) {
          this.$store.commit('updateColumnWidth', {
            width: widthNarrow,
            columnIndex: this.columnIndex,
          });
        }
      },
      addWidget() {
        this.$store.commit('addWidget', this.columnIndex);
      },
      deleteColumn() {
        this.$store.commit('deleteColumn', this.columnIndex);
      },
      moveLeft() {
        this.$store.commit('moveLeft', this.columnIndex);
      },
      moveRight() {
        this.$store.commit('moveRight', this.columnIndex);
      },
    },
  };
</script>

<style scoped lang="scss">
  .column {
    background: #f7f7f7;
    display: flex;
    flex-direction: column;

    &.expanded {
      min-width: 300px;
      flex-basis: 100%;
      flex-shrink: 3;
      flex-grow: 1;

      &.wide {
        min-width: 600px;
        max-width: 100%;
        flex-shrink: 1;
        flex-grow: 3;
      }

      &.narrow {
        min-width: 150px;
        max-width: 100%;
        flex-basis: 50%;
      }
    }

    > .column-header {
      background: #666;
      color: #ddd;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > * {
        padding: 10px;
      }
      > .column-title {
        cursor: pointer;
        flex: 1;
        &:hover {
          background: #444;
        }
        > .column-generation {
          font-weight: 700;
        }
      }
      > .delete,
      > .control {
        display: none;
      }
      > .columnConfigurationMode {
        display: flex;
        background: yellow;
        color: #666;
      }
      &:hover {
        > .delete,
        > .control {
          display: flex;
        }
        > .delete:hover {
          color: red;
          cursor: pointer;
        }
        > .control:hover {
          color: #333;
          cursor: pointer;
        }
        > .control.toggle-configuration:hover {
          color: yellow;
          cursor: pointer;
        }
      }
    }
    .column-body {
      overflow-y: auto;
      > .controls {
        display: flex;
        justify-content: center;
        > .add {
          margin: 10px;
          padding: 10px;
          font-size: 20px;
          line-height: 16px;
          &:hover {
            color: green;
            cursor: pointer;
          }
        }
      }
    }
  }
  .custom-rotate-icon {
    transform: rotate(45deg);
  }
</style>
