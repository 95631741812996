<template>
  <div>
    <LoadingSpinner v-if="$apollo.loading" />
    <!-- If available, we always show the currentAnnotationSetUri
      (this is not always in sync with the annotation set widget
      because that widget only sets the output when the set is not empty)
    -->
    <div v-if="currentAnnotationSetUri" class="current-annotation-set-uri">
      <span class="field">Annotation Set:</span>
      <span class="value">
        {{ currentAnnotationSetUri }}
      </span>
    </div>

    <EmptyMessage v-if="mode === modes.NO_ANNOT_SET">
      <!-- TELL USER TO USE ANNOTATION_SET WIDGET -->
      You have not selected an annotation set. Use the Annotation Sets Widget to select/create one.
    </EmptyMessage>

    <template v-else-if="mode === modes.NO_ANNOT_FEATURE">
      <!-- GIVE OPTIONS FOR LOADING/CREATING FEATURE -->
      <div>
        <button
          @click="mode = modes.SELECT_ANNOT_FEATURE"
          :disabled="!Array.isArray(annotationFeatures) || annotationFeatures.length === 0"
        >
          Select Annotation Feature
        </button>
        <button @click="mode = modes.CREATE_ANNOT_FEATURE">Create Annotation Feature</button>
      </div>
    </template>

    <template v-else-if="mode === modes.SELECT_ANNOT_FEATURE">
      <!-- GIVE OPTIONS FOR LOADING EXISTING FEATURE -->
      <div>
        <select v-model="selectedAnnotationFeature" :key="annotationFeatures">
          <option
            v-for="annotFeat in annotationFeatures"
            :key="annotFeat.uri"
            :value="annotFeat.uri"
          >
            {{ annotFeat?.label || annotFeat.uri }}
          </option>
        </select>
        <button @click="loadSelectedAnnotationFeature">Load Annotation Feature</button>
      </div>
      <button @click="unloadAndUnset">Cancel</button>
    </template>

    <template v-else-if="mode === modes.CREATE_ANNOT_FEATURE">
      <!-- SHOW FORM FOR CREATING NEW FEATURE -->
      <table>
        <tr>
          <td>Label:</td>
          <td>
            <input placeholder="Label" v-model="newAnnotationFeatureLabel" />
            <template v-for="error in fieldErrors.filter(f => f.field === 'label')" :key="error">
              <div class="error-message">{{ error.message }}</div>
            </template>
          </td>
        </tr>
      </table>
      <button @click="createAnnotationFeature">Create New Annotation Feature</button>
      <button @click="unloadAndUnset">Cancel</button>
    </template>

    <template v-else-if="mode === modes.ANNOT_FEATURE_SELECTED">
      <!-- SHOW ANNOTATION FEATURE + ALLOW UNLOADING -->
      <table>
        <tr>
          <td>URI:</td>
          <td>
            <div>{{ currentAnnotationFeature.uri }}</div>
          </td>
        </tr>
        <tr>
          <td>Label:</td>
          <td>
            <div>{{ currentAnnotationFeature.label }}</div>
          </td>
        </tr>
      </table>
      <button @click="unloadAndUnset">Unload Annotation Feature</button>
      <button @click="deleteAnnotationFeature">Delete Annotation Feature</button>
    </template>

    <EmptyMessage v-else>Unknown mode: {{ mode }}</EmptyMessage>
  </div>
</template>

<script>
  import { gql } from 'graphql-tag';
  import mixins from '@/mixins';
  import {
    DEFAULT_GROUP_KEY,
    APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
    APOLLO_QUERY_FOR_ANNOTATION_FEATURES,
  } from '@/store/constants';
  import LoadingSpinner from '../components/LoadingSpinner.vue';
  import EmptyMessage from '../components/EmptyMessage.vue';

  const MODES = {
    NO_ANNOT_SET: 'NO_ANNOT_SET',
    NO_ANNOT_FEATURE: 'NO_ANNOT_FEATURE',
    SELECT_ANNOT_FEATURE: 'SELECT_ANNOT_FEATURE',
    CREATE_ANNOT_FEATURE: 'CREATE_ANNOT_FEATURE',
    ANNOT_FEATURE_SELECTED: 'ANNOT_FEATURE_SELECTED',
  };

  export default {
    name: 'AnnotationFeaturesWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    components: {
      LoadingSpinner,
      EmptyMessage,
    },
    props: {
      doric: {
        inputs: {
          currentAnnotationSetUri: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'currentAnnotationSetUri',
          },
        },
        outputs: {
          selectedAnnotationFeature: null,
        },
      },
    },
    data: () => ({
      selectedAnnotationFeature: '',
      currentAnnotationFeature: {},
      fieldErrors: [],
      modes: Object.freeze(MODES),
      mode: MODES.NO_ANNOT_SET,
      newAnnotationFeatureLabel: '',
    }),
    watch: {
      currentAnnotationFeature: {
        handler() {
          if (!this.currentAnnotationFeature?.uri) return;
          this.outputs.selectedAnnotationFeature.value = this.currentAnnotationFeature.uri;
          this.submit();
        },
        immediate: true,
      },
      currentAnnotationSetUri: {
        handler() {
          if (this.currentAnnotationSetUri) {
            this.mode = MODES.NO_ANNOT_FEATURE;
          } else {
            this.mode = MODES.NO_ANNOT_SET;
          }
        },
        immediate: true,
      },
    },
    apollo: {
      annotationFeatures: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_FEATURES}($annotationSet: String!) {
            annotationFeatures(filters: { annotationSet: { uri: { exact: $annotationSet } } }) {
              id
              label
              uri
            }
          }
        `,
        fetchPolicy: APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
        skip() {
          return !this.currentAnnotationSetUri;
        },
        variables() {
          return { annotationSet: this.currentAnnotationSetUri };
        },
        update(data) {
          const [first] = data.annotationFeatures;
          this.selectedAnnotationFeature = first?.uri;
          return data.annotationFeatures;
        },
      },
    },
    methods: {
      unloadAndUnset() {
        this.currentAnnotationFeature = {};
        this.mode = MODES.NO_ANNOT_FEATURE;
        this.fieldErrors = [];
        this.newAnnotationFeatureLabel = '';
      },
      getLabelFromUri(uri) {
        return (
          this.annotationFeatures?.find(({ uri: featureUri }) => featureUri === uri)?.label || uri
        );
      },
      loadSelectedAnnotationFeature() {
        this.mode = MODES.ANNOT_FEATURE_SELECTED;
        this.currentAnnotationFeature = this.annotationFeatures.find(
          ({ uri }) => uri === this.selectedAnnotationFeature,
        );
      },
      createAnnotationFeature() {
        // Only a label is required
        const tempFieldErrors = [];
        if (!this.newAnnotationFeatureLabel) {
          tempFieldErrors.push({
            field: 'label',
            message: 'Label is required',
          });
        }
        if (tempFieldErrors.length) {
          this.fieldErrors = tempFieldErrors;
          return;
        }

        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($label: String!, $annotationSetUri: String!) {
                createAnnotationFeature(label: $label, annotationSetUri: $annotationSetUri) {
                  __typename
                  ... on AnnotationFeature {
                    id
                    label
                    uri
                  }
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables: {
              label: this.newAnnotationFeatureLabel,
              annotationSetUri: this.currentAnnotationSetUri,
            },
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_FEATURES],
          })
          .then(({ data }) => {
            // Check for errors in result
            // eslint-disable-next-line no-underscore-dangle
            if (data?.createAnnotationFeature?.__typename === 'OperationInfo') {
              console.error('createAnnotationFeature error:', data);
              this.fieldErrors = data.createAnnotationFeature.messages.map(
                ({ field, message }) => ({
                  field,
                  message,
                }),
              );
              return;
            }
            const { id, label, uri } = data.createAnnotationFeature;
            this.currentAnnotationFeature = { id, label, uri };
            this.mode = MODES.ANNOT_FEATURE_SELECTED;
          })
          .catch(e => {
            console.error(e);
          });
      },
      deleteAnnotationFeature() {
        // eslint-disable-next-line no-alert
        const areYouSure = window.confirm(
          `Are you sure you want to delete the annotation feature "${this.currentAnnotationFeature.label}"?`,
        );
        if (!areYouSure) return;
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!) {
                deleteAnnotationFeature(id: $id) {
                  __typename
                  ... on AnnotationFeature {
                    label
                    uri
                  }
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables: {
              id: this.currentAnnotationFeature.id,
            },
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_FEATURES],
          })
          .then(({ data }) => {
            // Check for errors in result
            // eslint-disable-next-line no-underscore-dangle
            if (data?.deleteAnnotationFeature?.__typename === 'OperationInfo') {
              console.error('deleteAnnotationFeature error:', data);
              return;
            }
            this.unloadAndUnset();
          })
          .catch(e => {
            console.error(e);
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  .error-message {
    color: red;
    font-size: 0.8rem;
  }

  .current-annotation-set-uri {
    font-size: 0.8rem;
    color: #555;

    .field {
      font-weight: bold;
    }
    .value {
      margin-left: 0.5rem;
    }
  }
</style>
