<template>
  <div>
    <EmptyMessage>{{ emptyMessage }}</EmptyMessage>
    <div>
      <ul>
        <li v-for="instance in instanceList" :key="instance.uri">
          <div class="instance-label">
            <a href="#" @click.prevent="selectInstance(instance.uri)">
              {{ instance.label || instance.uri }}
            </a>
            <div class="action-buttons">
              <button
                v-if="attrs?.onDeleteInstance"
                @click="emit('deleteInstance', instance.id)"
                title="Delete"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div>
      <button @click="emit('createInstance')">Create {{ instanceName }}</button>
      <button v-if="attrs?.onBack" @click="emit('back')">Back</button>
    </div>
  </div>
</template>

<script setup>
  import { useAttrs } from 'vue';
  import EmptyMessage from '../../components/EmptyMessage.vue';

  defineProps({
    instanceList: {
      type: Array,
      default: () => [],
    },
    instanceName: {
      type: String,
      default: 'Instance',
    },
    emptyMessage: {
      type: String,
      default: 'You have not selected an instance',
    },
  });

  // emits ['selectInstance', 'createInstance', 'deleteInstance', 'back']
  // If we defineEmits with these events, they do not appear in attrs
  // because events with names like "click" were causing issues with
  // for Vue at a framework level with the DOM event of the same name.
  const emit = defineEmits();
  const attrs = useAttrs();

  const selectInstance = uri => {
    emit('selectInstance', uri);
  };
</script>

<style scoped lang="scss">
  .instance-label {
    display: flex;
    align-items: center;

    a {
      line-height: 1.5rem;
    }

    .action-buttons {
      display: none;
      padding-left: 1rem;
    }

    &:hover .action-buttons {
      display: block;
    }
  }
</style>
