<script setup>
  import { defineEmits, defineProps, ref } from 'vue';

  const props = defineProps({
    displayOptions: Object,
    disabledButtons: Array,
    expandedMenu: Boolean,
  });

  const emit = defineEmits({
    'toggle-display-option': String,
    'persist-virtual-tree-to-local-storage': String,
    'reset-tree': String,
    'download-tree': String,
  });

  const hideControlsButton = ref(props?.disabledButtons?.includes('controls'));
  const hideAnalysisButton = ref(props?.disabledButtons?.includes('showAnalysis'));
  const hideWordGroupsButton = ref(props?.disabledButtons?.includes('showWordGroups'));
  const hideClausesButton = ref(props?.disabledButtons?.includes('showClauses'));
  const hideMilestonesButton = ref(props?.disabledButtons?.includes('showMilestones'));
  const hideRulesButton = ref(props?.disabledButtons?.includes('showRules'));
  const hidePlainTextButton =
    true || // NOTE: plain text currently not available in doric implementation
    ref(props?.disabledButtons?.includes('usePlainText'));
  const hideDownloadTreeButton = ref(props?.disabledButtons?.includes('downloadTree'));
  const hideSaveTreeButton = ref(props?.disabledButtons?.includes('saveTree'));
  const hideResetTreeButton = ref(props?.disabledButtons?.includes('resetTree'));
  const hideEnglishButton = ref(props?.disabledButtons?.includes('showEnglish'));
  const hideTransliterationButton = ref(props?.disabledButtons?.includes('showTransliteration'));

  const showDropdownMenu = ref(props?.expandedMenu);
  function handleClickDropdownGearIcon() {
    showDropdownMenu.value = !showDropdownMenu.value;
  }

  function handleToggle(option) {
    emit('toggle-display-option', option);
  }
  function persistVirtualTreeToLocalStorage() {
    emit('persist-virtual-tree-to-local-storage');
  }
  function resetTree() {
    emit('reset-tree');
  }
  function downloadTree() {
    emit('download-tree');
  }
</script>

<template>
  <div class="menu">
    <button
      v-if="!expandedMenu"
      class="gear-button"
      :class="{ 'open-menu': showDropdownMenu }"
      @click="handleClickDropdownGearIcon"
    >
      ⚙
    </button>
    <div
      class=""
      :class="[
        expandedMenu ? 'expanded-menu-content' : 'menu-content',
        { visible: showDropdownMenu },
      ]"
    >
      <button
        class="ui-button menu-button"
        @click="handleToggle('usePlainText')"
        v-if="!hidePlainTextButton"
      >
        <font-awesome-icon
          :color="displayOptions?.showEnglish ? 'dodgerblue' : 'grey'"
          icon="fa-solid fa-edit"
        />
        <span class="menu-button-tooltip-content">
          <div class="up-caret" />
          View {{ !displayOptions?.usePlainText ? 'plain text' : 'syntax tree' }}</span
        >
      </button>
      <button
        class="ui-button menu-button"
        @click="handleToggle('showEnglish')"
        v-if="!hideEnglishButton"
      >
        <font-awesome-icon
          :color="displayOptions?.showEnglish ? 'dodgerblue' : 'grey'"
          icon="fa-solid fa-language"
        />
        <span class="menu-button-tooltip-content">
          <div class="up-caret" />
          {{ displayOptions?.showEnglish ? 'Hide' : 'Show' }} English</span
        >
      </button>
      <button
        class="ui-button menu-button"
        @click="handleToggle('showTransliteration')"
        v-if="!hideTransliterationButton"
      >
        <font-awesome-icon
          :color="displayOptions?.showTransliteration ? 'ao' : 'grey'"
          icon="fa-solid fa-language"
        />
        <span class="menu-button-tooltip-content">
          <div class="up-caret" />
          {{ displayOptions?.showTransliteration ? 'Hide' : 'Show' }} Transliteration</span
        >
      </button>
      <button
        v-if="!hideAnalysisButton"
        class="ui-button menu-button"
        @click="handleToggle('showAnalysis')"
      >
        <font-awesome-icon
          :color="displayOptions?.showAnalysis ? 'dodgerblue' : 'grey'"
          icon="fa-solid fa-atom"
        />
        <span class="menu-button-tooltip-content">
          <div class="up-caret" />
          {{ displayOptions?.showAnalysis ? 'Hide' : 'Show' }} analysis</span
        >
      </button>
      <button
        v-if="!hideWordGroupsButton"
        class="ui-button menu-button"
        @click="handleToggle('showWordGroups')"
      >
        <font-awesome-icon
          :color="displayOptions?.showWordGroups ? 'dodgerblue' : 'grey'"
          icon="fa-solid fa-object-ungroup"
        />
        <span class="menu-button-tooltip-content">
          <div class="up-caret" />
          {{ displayOptions?.showWordGroups ? 'Hide' : 'Show' }} word groups</span
        >
      </button>
      <button
        v-if="!hideRulesButton"
        class="ui-button menu-button"
        @click="handleToggle('showRules')"
      >
        <font-awesome-icon
          :color="displayOptions?.showRules ? 'dodgerblue' : 'grey'"
          icon="fa-solid fa-book"
        />
        <span class="menu-button-tooltip-content">
          <div class="up-caret" />
          {{ displayOptions?.showRules ? 'Hide' : 'Show' }} Rules</span
        >
      </button>
      <button
        v-if="!hideClausesButton"
        class="ui-button menu-button"
        @click="handleToggle('showClauses')"
      >
        <font-awesome-icon
          :color="displayOptions?.showClauses ? 'dodgerblue' : 'grey'"
          icon="fa-solid fa-object-group"
        />
        <span class="menu-button-tooltip-content">
          <div class="up-caret" />
          {{ displayOptions?.showClauses ? 'Hide' : 'Show' }} clauses</span
        >
      </button>
      <button
        v-if="!hideMilestonesButton"
        class="ui-button menu-button"
        @click="handleToggle('showMilestones')"
      >
        <font-awesome-icon
          :color="displayOptions?.showMilestones ? 'dodgerblue' : 'grey'"
          icon="fa-solid fa-map-marker-alt"
        />
        <span class="menu-button-tooltip-content">
          <div class="up-caret" />
          {{ displayOptions?.showMilestones ? 'Hide' : 'Show' }} milestones</span
        >
      </button>
      <button
        v-if="!hideControlsButton"
        class="ui-button menu-button"
        @click="handleToggle('showControls')"
      >
        <font-awesome-icon
          :color="displayOptions?.showControls ? 'dodgerblue' : 'grey'"
          icon="fa-solid fa-keyboard"
        />
        <span class="menu-button-tooltip-content"
          ><div class="up-caret" />
          Controls</span
        >
      </button>
      <button
        v-if="!hideSaveTreeButton"
        class="ui-button menu-button"
        @click="persistVirtualTreeToLocalStorage"
      >
        <font-awesome-icon color="ao" icon="fa-solid fa-save" />
        <span class="menu-button-tooltip-content"
          ><div class="up-caret" />
          Save tree to local storage</span
        >
      </button>
      <button v-if="!hideResetTreeButton" class="ui-button menu-button" @click="resetTree">
        <font-awesome-icon color="ao" icon="fa-solid fa-undo" />
        <span class="menu-button-tooltip-content"
          ><div class="up-caret" />
          Reset tree</span
        >
      </button>
      <button v-if="!hideDownloadTreeButton" class="ui-button menu-button" @click="downloadTree">
        <font-awesome-icon color="ao" icon="fa-solid fa-download" />
        <span class="menu-button-tooltip-content"
          ><div class="up-caret" />
          Download tree</span
        >
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .ui-container button {
    margin-left: 0.5em;
    height: 100%;
    line-height: 1.5;
  }
  .menu {
    position: relative;
    display: inline-block;
  }
  .gear-button {
    outline: none;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 3px;
    /* padding: 0.5em; */
    line-height: 1;
    font-size: 1.8em;
    font-weight: bold;
    cursor: pointer;
  }
  .gear-button.open-menu {
    color: #05afec;
  }
  .menu-content {
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    transition: right 0.3s ease-in-out;
    .menu-button {
      z-index: 999;
      position: relative;
      display: inline-block;
      background-color: #f1f1f1;
      min-width: 160px;
    }
  }
  .menu-content.visible {
    display: block;
    right: 0;
    > button > span {
      margin-left: 0.2em;
    }
  }
  .expanded-menu-content {
    display: flex;
    right: unset;
    position: relative;
    flex-flow: row wrap;
    > button {
      min-width: unset;
      > .menu-button-tooltip-content {
        display: none;
        margin-left: 0.5em;
        opacity: 0;
        position: absolute;
        color: white;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0.25em;
        padding: 0.5em 1em;
        font-size: 0.9em;
        margin-top: 0.75em;
        > .up-caret {
          position: absolute;
          top: -0.5em;
          left: 0.5em;
          width: 0;
          height: 0;
          border-left: 0.5em solid transparent;
          border-right: 0.5em solid transparent;
          border-bottom: 0.5em solid rgba(0, 0, 0, 0.7);
        }
      }
      &:hover {
        z-index: 999;
        > .menu-button-tooltip-content {
          display: block;
          z-index: 100;
          opacity: 0.9;
        }
      }
      // display span content on hover
      &:hover {
        > span {
          display: inline;
        }
      }
    }
  }
  .button {
    border-radius: 5px;
    font-size: 0.8em;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0.5em 1em;
    user-select: none;
    border: none;
    /* display: inline-block; */
    /* vertical-align: middle; */
    overflow: hidden;
    /* text-align: center; */
    cursor: pointer;
    white-space: nowrap;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.984);
    margin: 0 0.5em;
    content: '';
  }

  .goButton {
    background-color: #04aa6d !important;
  }
  .goButton:hover {
    background-color: #039c64 !important;
  }

  .stopButton {
    background-color: #ff0000 !important;
    padding: 0.5em 1em;
  }
  .stopButton::after {
    content: 'X';
  }
  .stopButton:hover::after {
    content: 'Delete saved tree';
    padding: 0.5em 1em;
    transition: all 0.2s ease-in-out;
  }
  .ui-button {
    font-size: 1em;
    padding: 0.5em 1em;
    margin: 0.2em;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 10pt;
    background-color: #eee;
    text-transform: uppercase;
    line-height: 1.2em; // Line height keeps emojis from expanding button height
  }
  .ui-button:hover {
    background-color: #ddd;
  }
  .ui-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    line-height: 1.5;
    margin-bottom: 1em;
  }
</style>
