<template>
  <div class="create-instance-form">
    <table>
      <tr>
        <td>Feature:</td>
        <td>
          <select v-model="selectedAnnotationFeature">
            <option
              v-for="annotationFeature in visibleOrderedFeatures || []"
              :value="annotationFeature.uri"
              :key="annotationFeature.uri"
            >
              {{ annotationFeature?.label || annotationFeature.uri }}
            </option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Label:</td>
        <td><input type="text" v-model="newInstanceLabel" /></td>
      </tr>
      <tr v-if="proposedTokenIds.length > 0">
        <td>Tokens:</td>
        <td>
          <div class="token-styles" :class="{ rtl }">
            <Token v-for="tokenId in proposedTokenIds" :key="tokenId" :tokenId="tokenId" />
          </div>
          <button @click="deselectTokens">Deselect Tokens</button>
        </td>
      </tr>
    </table>
    <button @click="createInstance">Create Child</button>
    <button @click="$emit('close')">Cancel</button>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import { APOLLO_QUERY_FOR_ANNOTATION_INSTANCES } from '@/store/constants';
  import Token from '@/symphony/widgets/tokens/Token.vue';
  import { visibleOrderedFeaturesHelper } from '@/common/featureListLocalstorage';

  export default {
    props: {
      parentUri: {
        type: String,
        required: true,
      },
      annotationSetUri: {
        type: String,
        required: true,
      },
      annotationFeatures: {
        type: Array,
        required: true,
      },
      proposedTokenIds: {
        type: Array,
        required: false,
      },
    },
    emits: ['close', 'setRecentlyUpdatedInstanceUri'],
    inject: ['deselectTokens', 'rtl'],
    data() {
      return {
        selectedAnnotationFeature: null,
        newInstanceLabel: null,
      };
    },
    computed: {
      visibleOrderedFeatures() {
        return visibleOrderedFeaturesHelper(this.annotationSetUri, this.annotationFeatures);
      },
    },
    watch: {
      visibleOrderedFeatures: {
        immediate: true,
        handler() {
          this.selectedAnnotationFeature = this.visibleOrderedFeatures?.[0]?.uri;
        },
      },
    },
    methods: {
      createInstance() {
        const variables = {
          featureUri: this.selectedAnnotationFeature,
          parentUri: this.parentUri,
        };
        if (this.newInstanceLabel) {
          variables.label = this.newInstanceLabel;
        }
        if (this.proposedTokenIds.length > 0) {
          variables.tokens = this.proposedTokenIds;
        }

        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($featureUri: String!, $tokens: [ID!], $label: String, $parentUri: String) {
                createAnnotation(
                  annotationFeatureUri: $featureUri
                  tokens: $tokens
                  label: $label
                  parentUri: $parentUri
                  sortTokens: true
                ) {
                  __typename
                  ... on Annotation {
                    id
                    label
                    uri
                  }
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables,
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_INSTANCES],
          })
          .then(({ data }) => {
            // eslint-disable-next-line no-underscore-dangle
            if (data?.createAnnotation?.__typename === 'OperationInfo') {
              console.error('there was an error creating the annotation', data);
              return;
            }
            this.deselectTokens();
            this.$emit('close');
            this.$emit('setRecentlyUpdatedInstanceUri', data.createAnnotation.uri);
          })
          .catch(error => {
            console.error('there was an error sending the query', error);
          });
      },
    },
    components: {
      Token,
    },
  };
</script>

<style scoped>
  .create-instance-form {
    padding: 0.5rem;
    border: 3px solid #eee;
    border-top: none;
  }
</style>
