import { ref, getCurrentInstance } from 'vue';

const useOutputs = props => {
  const { emit } = getCurrentInstance();

  // NOTE: The `ref` wrapper requires
  // outputs.value rather than
  // outputs as in OutputsMixin.
  const outputs = ref({
    ...props.info.outputs,
  });

  function submit() {
    const inputs = props.info.inputs || {};
    emit('configuration-update', { inputs, outputs: outputs.value });
  }

  return {
    submit,
    outputs,
  };
};

export default useOutputs;
