<script setup>
  import { onMounted } from 'vue';
  import { useStore } from 'vuex';
  import useOutputs from '@/composables/useOutputs';
  import inputData from '@/composables/inputData';
  import commonProps from '@/common/props';
  import EmptyMessage from '../components/EmptyMessage.vue';
  import { DEFAULT_GROUP_KEY } from '../../../store/constants';

  const store = useStore();

  const props = defineProps({
    ...commonProps(),
    doric: {
      inputs: {
        savedDrafts: {
          value: 'savedDrafts',
          groupKey: DEFAULT_GROUP_KEY,
        },
        treedownNode: {
          value: 'treedownNode',
          groupKey: DEFAULT_GROUP_KEY,
        },
      },
      outputs: {
        treedownNode: null,
        savedDrafts: null,
      },
    },
  });
  const { submit, outputs } = useOutputs(props);
  const savedDrafts = inputData(store, props.info?.inputs, 'savedDrafts');
  const currentTreedownNode = inputData(store, props.info?.inputs, 'treedownNode');

  onMounted(() => {
    // TODO: fetch savedDrafts from server instead of local storage
    outputs.value.savedDrafts.value = localStorage.getItem('savedDrafts')
      ? JSON.parse(localStorage.getItem('savedDrafts'))
      : [];
    submit();
  });
  function handleDeleteDraft(event, draftId) {
    const filteredDrafts = savedDrafts.value.filter(
      savedTree => savedTree.treeReference !== draftId,
    );
    localStorage.setItem('savedDrafts', JSON.stringify(filteredDrafts));
    outputs.value.savedDrafts.value = localStorage.getItem('savedDrafts')
      ? JSON.parse(localStorage.getItem('savedDrafts'))
      : [];
    // If the draft we just deleted is the current treedown node, set the current treedown node to null
    if (currentTreedownNode.value?.treeReference === draftId) {
      outputs.value.treedownNode.value = null;
    }
    submit();
  }
  function handleSelectDraft(selectedTreeReference) {
    const selectedDraft = savedDrafts.value.find(
      draft => draft.treeReference === selectedTreeReference,
    );
    outputs.value.treedownNode.value = selectedDraft;
    // The savedDrafts doric variable has to be updated as well
    // Otherwise the submit() function will submit the old value

    // @jacobwegner: This is an interesting aspect of doric
    // inputs/outputs that we should discuss. This is another
    // situation where a widget that has an identical input and
    // output value requires special functionality to work properly.
    // To test this, try commenting the next line, and then load
    // a new tree, make a change, save change, select a different
    // draft other than the current tree in the list of drafts widget.
    outputs.value.savedDrafts.value = savedDrafts.value;
    submit();
  }

  function formatSavedTreeReference(reference) {
    const splitReference = reference.split('_');
    const passage = splitReference[0].split('.');
    const formattedReference = `${passage[0]} ${passage[1]}:${passage[2]}`;
    return {
      reference: formattedReference,
      date: splitReference[1],
      time: splitReference[2],
    };
  }

  function deleteAllDrafts() {
    // trigger a confirmation dialogue to make sure the user wants to delete all drafts
    // eslint-disable-next-line no-alert, no-restricted-globals
    const confirmation = confirm('Are you sure you want to delete all saved drafts?');
    if (confirmation) {
      localStorage.removeItem('savedDrafts');
      outputs.value.savedDrafts.value = [];
      // If the current treedown node is a draft, set the current treedown node to null

      const currentTreedownNodeIsDraft = true;
      // TODO: Refactor based on backend persistence changes
      if (currentTreedownNodeIsDraft) {
        outputs.value.treedownNode.value = null;
      }
      submit();
    }
  }
</script>

<template>
  <div id="saved-trees-container">
    <div class="tree-and-button-container" v-if="savedDrafts?.length">
      <span
        class="saved-tree-container"
        v-for="(tree, index) in savedDrafts"
        :key="`${index}_${tree.treeReference}`"
      >
        <div class="row timestamp">
          <span>{{ formatSavedTreeReference(tree.treeReference).date }}</span>
          <span>{{ formatSavedTreeReference(tree.treeReference).time }}</span>
        </div>
        <div class="row">
          <a class="button goButton" @click="handleSelectDraft(tree.treeReference)"
            >{{ formatSavedTreeReference(tree.treeReference).reference
            }}<span v-if="currentTreedownNode?.treeReference === tree.treeReference">
              (current)</span
            ></a
          >

          <span
            class="button stopButton"
            @mouseover="hover = true"
            @focus="hover = true"
            @mouseleave="hover = false"
            @blur="hover = false"
            :label="'Delete draft'"
            @click="handleDeleteDraft(e, tree.treeReference)"
          >
            <font-awesome-icon icon="fa-solid fa-trash" />
          </span>
        </div>
      </span>
      <span class="button deleteAllButton" @click="deleteAllDrafts">
        <font-awesome-icon icon="trash" />{{ ' ' }}Delete All Drafts
      </span>
    </div>
    <EmptyMessage>No saved trees</EmptyMessage>
  </div>
</template>

<style scoped lang="scss">
  #saved-trees-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all 0.2s ease-in-out;
  }
  .saved-tree-container {
    padding-bottom: 0.5rem;
    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
      margin-bottom: 0.5rem;
    }
  }
  .tree-and-button-container {
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }
  .button {
    border-radius: 0.2em;
    font-size: 0.8em;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0.5em 1em;
    user-select: none;
    border: none;
    /* display: inline-block; */
    /* vertical-align: middle; */
    overflow: hidden;
    /* text-align: center; */
    cursor: pointer;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.984);
    margin: 0.25em;
    content: '';
  }

  .goButton {
    background-color: #04aa6d;
  }
  .goButton:hover {
    background-color: #039c64;
  }

  .stopButton {
    background-color: #ff0000;
    padding: 0.5em 1em;
  }
  .stopButton:hover::after {
    position: relative;
    content: attr(label);
    background: #ff0000f2;
    border-radius: 0.2em;
    padding: 0.5em 1em;
    transition: all 0.2s ease-in-out;
  }
  .deleteAllButton {
    background-color: darkorange;
    padding: 0.5em 0.5em;
    margin: 1.5em 0.25em 0.25em auto;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
  }

  .timestamp {
    font-size: 0.7rem;
  }
</style>
