<template>
  <div>
    <p v-show="!store">Loading state...</p>
    <pre><code>{{store && JSON.stringify(store.state, null, 2)}}</code></pre>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex';

  const store = useStore();

  defineProps({
    doric: {
      inputs: {
        label: null,
      },
    },
  });
  // TODO: Revisit this component later and sort out whether it makes sense to actually expose the store contents on a production site.
</script>

<style scoped lang="scss">
  code {
    display: block;
    white-space: pre;
    margin: 1em 0;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
</style>
