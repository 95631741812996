import { ApolloClient } from '@apollo/client/core';
import { InMemoryCache } from '@apollo/client/cache';
import { createApolloProvider } from '@vue/apollo-option';

// https://apollo.vuejs.org/guide/multiple-clients.html
const maculaClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri:
    process.env.VUE_APP_ATLAS_GRAPHQL_ENDPOINT ||
    'https://symphony-api-staging.clearlabs.biblica.com/graphql/',
});

const scaifeClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri:
    process.env.VUE_APP_SCAIFE_ATLAS_GRAPHQL_ENDPOINT ||
    'https://beyond-translation.perseus.org/graphql/',
});

const apolloProvider = createApolloProvider({
  defaultClient: maculaClient,
  clients: {
    maculaClient,
    scaifeClient,
  },
});

// eslint-disable-next-line import/prefer-default-export
export { apolloProvider, maculaClient, scaifeClient };
