<template>
  <div class="tree-item-container" :data-tree-item-uri="item.uri">
    <div
      class="tree-item"
      :class="{ active: currentMode !== MODES.NONE }"
      :style="treeItemStyles"
      @mouseenter="toggleHover($event, true)"
      @focus="toggleHover($event, true)"
      @mouseleave="toggleHover($event, false)"
      @blur="toggleHover($event, false)"
    >
      <!-- Expand/collapse button -->
      <button
        :disabled="item?.children?.length === 0"
        :title="expanded ? 'Collapse' : 'Expand'"
        @click="setExpanded(!expanded)"
        class="icon-button"
      >
        <font-awesome-icon
          v-if="expanded && item?.children?.length > 0"
          icon="fa-solid fa-angle-down"
          size="xs"
        />
        <font-awesome-icon v-else icon="fa-solid fa-angle-right" size="xs" />
      </button>

      <!-- Label -->
      <div class="label">
        <span>
          <span class="feature">
            {{ item?.feature?.label }}
          </span>
          <span class="verse-range" v-if="scriptureReference">
            <button @click="trySetOsisRefRange()">({{ scriptureReference }})</button>
          </span>
        </span>
        <span class="item">
          {{ item?.label }}
        </span>
      </div>

      <!-- Floating action buttons -->
      <div
        class="floating-action-button-container"
        :class="{ show: isHovered || currentMode !== MODES.NONE }"
      >
        <div class="gradient-helper"></div>
        <button @click.stop="pinAsRoot(item.uri)" title="Pin as Root" class="icon-button padded">
          <font-awesome-icon icon="fa-solid fa-thumbtack" size="xs" />
        </button>

        <button
          v-if="currentMode !== MODES.ADD_CHILD"
          @click.stop="setMode(MODES.ADD_CHILD)"
          title="Add Child"
          class="icon-button padded"
        >
          <font-awesome-icon icon="fa-solid fa-plus" size="xs" />
        </button>
        <button v-else @click.stop="setMode(MODES.NONE)" title="Cancel" class="icon-button padded">
          <font-awesome-icon icon="fa-solid fa-xmark" size="xs" />
        </button>

        <button @click.prevent="copyUri(item)" title="Copy instance URI" class="icon-button copy">
          <font-awesome-icon icon="fa-solid fa-share-nodes" />
        </button>

        <button
          v-if="currentMode !== MODES.ADD_LINK"
          @click.stop="setMode(MODES.ADD_LINK)"
          title="Add Link"
          class="icon-button padded"
        >
          <font-awesome-icon icon="fa-solid fa-link" size="xs" />
        </button>
        <button v-else @click.stop="setMode(MODES.NONE)" title="Cancel" class="icon-button padded">
          <font-awesome-icon icon="fa-solid fa-xmark" size="xs" />
        </button>

        <button
          @click.stop="
            () => {
              if (currentMode === MODES.EDIT) {
                setMode(MODES.NONE);
              } else {
                setMode(MODES.EDIT);
              }
            }
          "
          title="Edit"
          class="icon-button padded"
        >
          <font-awesome-icon v-if="currentMode === MODES.EDIT" icon="fa-solid fa-xmark" size="xs" />
          <font-awesome-icon v-else icon="fa-regular fa-pen-to-square" size="xs" />
        </button>

        <button
          @click.stop="deleteInstance(item.id)"
          title="Delete"
          class="icon-button padded delete"
        >
          <font-awesome-icon icon="fa-regular fa-trash-can" size="xs" />
        </button>
      </div>
    </div>

    <template v-if="currentMode === MODES.ADD_CHILD">
      <InlineCreateChildInstance
        :parentUri="item.uri"
        :annotationSetUri="annotationSetUri"
        :annotationFeatures="annotationFeatures"
        :proposedTokenIds="proposedTokenIds"
        @close="setMode(MODES.NONE)"
        @setRecentlyUpdatedInstanceUri="setRecentlyUpdatedInstanceUri"
      />
    </template>
    <template v-if="currentMode === MODES.ADD_LINK">
      <InlineCreateLinkedInstance
        :linkedToUri="item.uri"
        :annotationSetUri="annotationSetUri"
        :annotationFeatures="annotationFeatures"
        :proposedTokenIds="proposedTokenIds"
        @close="setMode(MODES.NONE)"
        @setRecentlyUpdatedInstanceUri="setRecentlyUpdatedInstanceUri"
      />
    </template>
    <template v-if="currentMode === MODES.EDIT">
      <InlineEditInstance
        :uri="item.uri"
        :annotationSetUri="annotationSetUri"
        :annotationFeatures="annotationFeatures"
        :proposedTokenIds="proposedTokenIds"
        @close="setMode(MODES.NONE)"
        @setRecentlyUpdatedInstanceUri="setRecentlyUpdatedInstanceUri"
      />
    </template>
  </div>
</template>

<script setup>
  import formatOsis, { paratextToOsis } from 'bible-reference-formatter';
  import { inject, ref, computed } from 'vue';
  import { getMostLikelyRefFromFreeInput } from '@/common/refUtils';
  import InlineCreateChildInstance from './InlineCreateChildInstance.vue';
  import InlineCreateLinkedInstance from './InlineCreateLinkedInstance.vue';
  import InlineEditInstance from './InlineEditInstance.vue';

  const props = defineProps({
    item: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  });
  const emit = defineEmits(['set-expanded']);

  // TODO: Refactor as a composable / mixin
  // TODO: Refactor for a standardized range
  const scriptureReference = computed(() => {
    const usfmRef = props.item.scriptureReference?.usfmRef;
    if (!usfmRef) {
      return '';
    }
    const osisRef = paratextToOsis(usfmRef);
    return formatOsis('niv-short', osisRef);
  });
  const setExpanded = value => {
    emit('set-expanded', value);
  };

  const MODES = {
    NONE: 'NONE',
    ADD_CHILD: 'ADD_CHILD',
    ADD_LINK: 'ADD_LINK',
    EDIT: 'EDIT',
  };
  const currentMode = ref(MODES.NONE);

  const annotationSetUri = inject('annotationSetUri') ?? '';
  const annotationFeatures = inject('annotationFeatures') ?? [];
  const proposedTokenIds = inject('proposedTokenIds') ?? [];

  const pinAsRoot =
    inject('pinAsRoot') ??
    (() => {
      console.error('pinAsRoot not provided');
    });
  const deleteInstance =
    inject('deleteInstance') ??
    (() => {
      console.error('deleteInstance not provided');
    });
  const setHoveredUri =
    inject('setHoveredUri') ??
    (() => {
      console.error('setHoveredUri not provided');
    });
  const locked = inject('locked') ?? false;
  const setLock =
    inject('setLock') ??
    (() => {
      console.error('setLock not provided');
    });
  const setRecentlyUpdatedInstanceUri =
    inject('setRecentlyUpdatedInstanceUri') ??
    (() => {
      console.error('setRecentlyUpdatedInstanceUri not provided');
    });
  const recentlyUpdatedInstanceUri = inject('recentlyUpdatedInstanceUri') ?? null;

  const treeItemStyles = computed(() => {
    const paddingLeft = `${2 * props.depth + 1}rem`;
    // FIXME: https://trello.com/c/plRmLOQt
    return recentlyUpdatedInstanceUri.value === props.item.uri
      ? { paddingLeft, backgroundColor: '#bae6fd' }
      : { paddingLeft };
  });

  const isHovered = ref(false);
  const toggleHover = (event, value) => {
    isHovered.value = value;
    if (locked.value) {
      return;
    }
    if (value) {
      if (event.ctrlKey) {
        return;
      }
      setHoveredUri(props.item.uri);
    }
  };

  const setMode = value => {
    currentMode.value = value;
    if (value === MODES.NONE) {
      setLock(false);
    } else {
      setHoveredUri(props.item.uri);
      setLock(true);
    }
  };

  const setOsisRefRange = inject('setOsisRefRange') ?? (() => {});
  const trySetOsisRefRange = () => {
    if (!setOsisRefRange) {
      return;
    }
    const scriptureRangeObj = getMostLikelyRefFromFreeInput(scriptureReference?.value, {
      respondWithRange: true,
    });
    setOsisRefRange(scriptureRangeObj);
  };

  const copyUri = item => {
    const { uri, label } = item;
    const copyPayload = label ? `${label}\n\n${uri}` : uri;
    navigator.clipboard.writeText(copyPayload).then(
      () => {
        /* clipboard successfully set */
        const msg = `Copied URI to clipboard: ${copyPayload}`;
        console.log(`Success: ${msg}`);
        // eslint-disable-next-line no-alert
        alert(msg);
      },
      () => {
        /* clipboard write failed */
        console.error('Failed to copy URI to clipboard.');
      },
    );
  };
</script>

<style scoped>
  .tree-item-container {
    width: 100%;
  }
  .tree-item {
    user-select: none;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Note that padding-left is overridden because of depth */
    padding: 0.4rem 1rem;
  }
  .tree-item.active,
  .tree-item:hover {
    background-color: #e5e7eb;
    transition: none;
  }

  .label {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: left;
    overflow: hidden;
  }
  .label > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .label .feature {
    color: #4f46e5;
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .tree-item:hover .label .feature {
    color: #4338ca;
  }
  .label .verse-range {
    font-size: 0.8rem;
    margin-left: 0.5rem;
    font-weight: bold;
  }
  .verse-range button {
    color: #334155;
    background-color: transparent;
    padding: 0.2rem 0.4rem;
    margin: 0;
    font-size: 0.8rem;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 0.3rem;
  }

  .tree-item.active .verse-range button,
  .tree-item:hover .verse-range button {
    border-color: #d1d5db;
  }
  .verse-range button:hover {
    background-color: #d1d5db;
    border-color: #9ca3af !important;
  }
  .verse-range button:active {
    background-color: #94a3b8;
    color: #f9fafb;
  }

  .floating-action-button-container {
    position: absolute;
    right: 0;
    height: 100%;
    background: #e5e7eb;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    visibility: hidden;

    &.show {
      visibility: visible;
    }

    .gradient-helper {
      width: 1.5rem;
      margin-left: -1.5rem;
      height: 100%;
      background: rgb(229, 231, 235); /* #e5e7eb */
      background: linear-gradient(90deg, rgba(229, 231, 235, 0) 0%, rgba(229, 231, 235, 1) 100%);
    }
  }

  .icon-button {
    cursor: pointer;
    box-sizing: border-box;
    /* reset font size so that icon is not affected by parent font size*/
    font-size: 1rem;
    background-color: #e5e7eb;
    border: 1px solid #9ca3af;
    color: #111827;
    width: 1.25rem;
    height: 1.25rem;
    aspect-ratio: 1;
    margin-right: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
  }
  .icon-button.padded {
    width: 1.6rem;
    height: 1.6rem;
  }
  .icon-button:hover {
    background-color: #d1d5db;
  }
  .icon-button:active {
    border-color: #6b7280;
    background-color: #9ca3af;
  }
  .icon-button.delete:hover {
    background-color: #fecaca;
    border-color: #f87171;
  }
  .icon-button.delete:active {
    background-color: #f87171;
    border-color: #ef4444;
  }
  /* disabled styles */
  .icon-button[disabled],
  .icon-button[disabled]:active,
  .icon-button[disabled]:hover {
    border-color: #ddd;
    background-color: #efefef;
    color: #999;
  }
</style>
