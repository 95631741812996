<template>
  <div class="update-instance-form" :style="loadingStyles">
    <table>
      <tr>
        <td>URI:</td>
        <td class="small-uri">{{ uri }}</td>
      </tr>
      <tr>
        <td>Feature:</td>
        <td>
          <select v-model="selectedAnnotationFeature">
            <option
              v-for="annotationFeature in visibleOrderedFeatures || []"
              :value="annotationFeature.uri"
              :key="annotationFeature.uri"
            >
              {{ annotationFeature?.label || annotationFeature.uri }}
            </option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Label:</td>
        <td><input type="text" v-model="newInstanceLabel" /></td>
      </tr>
      <tr>
        <td>Links:</td>
        <td>
          <span v-if="annotation?.linkedBy?.length === 0" class="none-style">None</span>
          <span v-else>
            <div v-for="link in annotation?.linkedBy" :key="link.id">
              {{ link?.feature?.label }}
              <div v-if="link.tokens?.length > 0" class="token-styles" :class="{ rtl }">
                (
                <Token
                  v-for="token in link.tokens"
                  :key="getTokenKey(token)"
                  :tokenId="getTokenKey(token)"
                />
                )
              </div>
              <button
                @click.stop="deleteLinkedInstance(link.id)"
                title="Delete Linked Instance"
                class="delete-linked-instance-button"
              >
                <font-awesome-icon icon="fa-regular fa-trash-can" size="xs" />
              </button>
            </div>
          </span>
        </td>
      </tr>
      <tr>
        <td>Tokens:</td>
        <td>
          <template v-if="shouldUpdateTokens">
            <span v-if="proposedTokenIds.length === 0" class="none-style">None</span>
            <div v-else class="token-styles" :class="{ rtl }">
              <Token v-for="tokenId in proposedTokenIds" :key="tokenId" :tokenId="tokenId" />
            </div>
          </template>
          <template v-else>
            <span v-if="annotation?.tokens?.length === 0" class="none-style">None</span>
            <div v-else class="token-styles" :class="{ rtl }">
              <TokenProvider :tokens="annotationTokens">
                <Token
                  v-for="token in annotation?.tokens"
                  :key="getTokenKey(token)"
                  :tokenId="getTokenKey(token)"
                />
              </TokenProvider>
            </div>
          </template>
          <template v-if="tokensCouldUpdate">
            <template v-if="shouldUpdateTokens">
              <button
                @click.stop="shouldUpdateTokens = false"
                title="Click to use existing tokens"
                class="update-tokens-button"
              >
                Restore
              </button>
            </template>
            <template v-else>
              <button
                @click.stop="shouldUpdateTokens = true"
                title="Click to use selected tokens"
                class="update-tokens-button"
              >
                Update Tokens
              </button>
            </template>
          </template>
          <span v-else class="none-style">(unchanged)</span>
        </td>
      </tr>
    </table>
    <div class="note">Note: Changes will only take effect when you click update below</div>
    <button @click="updateInstance">Update</button>
    <button @click="$emit('close')">Cancel</button>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import {
    TOKEN_ID_FIELD,
    APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
    APOLLO_QUERY_FOR_ANNOTATION_INSTANCES,
  } from '@/store/constants';
  import Token from '@/symphony/widgets/tokens/Token.vue';
  import { visibleOrderedFeaturesHelper } from '@/common/featureListLocalstorage';
  import TokenProvider from '@/symphony/widgets/tokens/TokenProvider.vue';

  export default {
    inject: ['rtl'],
    props: {
      uri: {
        type: String,
        required: true,
      },
      annotationSetUri: {
        type: String,
        required: true,
      },
      annotationFeatures: {
        type: Array,
        required: true,
      },
      proposedTokenIds: {
        type: Array,
        required: false,
      },
    },
    emits: ['close', 'setRecentlyUpdatedInstanceUri'],
    data() {
      return {
        selectedAnnotationFeature: '',
        newInstanceLabel: '',
        shouldUpdateTokens: false,
      };
    },
    computed: {
      annotationTokens() {
        return this.annotation?.tokens?.map(t => {
          return {
            tokenId: t[TOKEN_ID_FIELD],
            data: {
              ...t,
            },
          };
        });
      },
      tokensCouldUpdate() {
        // i.e., return equality check between proposedTokenIds and tokens on annotation
        return (
          JSON.stringify(this.proposedTokenIds) !==
          JSON.stringify(this.annotation?.tokens.map(token => token[TOKEN_ID_FIELD]))
        );
      },
      loadingStyles() {
        return {
          opacity: this.$apollo.loading ? 0.3 : 1,
          pointerEvents: this.$apollo.loading ? 'none' : 'auto',
        };
      },
      visibleOrderedFeatures() {
        return visibleOrderedFeaturesHelper(this.annotationSetUri, this.annotationFeatures);
      },
    },
    apollo: {
      // FIXME: linkedBy tokens by may not be in provider
      annotation: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_INSTANCES}($uri: String!) {
            annotations(filters: { uri: { exact: $uri } }) {
              id
              label
              uri
              linkedBy {
                id
                feature {
                  label
                }
                tokens {
                  id
                }
              }
              feature {
                id
                uri
              }
              tokens {
                value
                id
              }
            }
          }
        `,
        fetchPolicy: APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
        variables() {
          return {
            uri: this.uri,
          };
        },
        skip() {
          return !this.uri;
        },
        update(result) {
          if (result?.annotations.length) {
            this.newInstanceLabel = result.annotations[0].label;
            this.selectedAnnotationFeature = result.annotations[0].feature.uri;
            return result.annotations[0];
          }

          console.error(result);
          console.error('No annotation found for uri', this.uri);
          return null;
        },
      },
    },
    methods: {
      updateInstance() {
        const variables = {
          id: this.annotation.id,
          annotationFeatureUri: this.selectedAnnotationFeature,
        };
        if (this.newInstanceLabel !== this.annotation.label) {
          variables.label = this.newInstanceLabel;
        }
        if (this.shouldUpdateTokens) {
          variables.tokens = this?.proposedTokenIds ?? [];
        }

        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateAnnotation(
                $id: ID!
                $annotationFeatureUri: String
                $label: String
                $tokens: [ID!]
              ) {
                updateAnnotation(
                  id: $id
                  annotationFeatureUri: $annotationFeatureUri
                  label: $label
                  tokens: $tokens
                  sortTokens: true
                ) {
                  __typename
                  ... on Annotation {
                    id
                    label
                    uri
                  }
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables,
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_INSTANCES],
          })
          .then(() => {
            this.$emit('setRecentlyUpdatedInstanceUri', this.uri);
            this.$emit('close');
          })
          .catch(error => {
            console.error('there was an error sending the query', error);
          });
      },
      deleteLinkedInstance(linkId) {
        // eslint-disable-next-line no-alert
        const areYouSure = window.confirm('Are you sure you want to delete this linked instance?');
        if (!areYouSure) {
          return;
        }
        this.$apollo
          .mutate({
            mutation: gql`
              mutation DeleteAnnotation($deleteAnnotationId: ID!) {
                deleteAnnotation(id: $deleteAnnotationId) {
                  __typename
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                  ... on Annotation {
                    uri
                  }
                }
              }
            `,
            variables: {
              deleteAnnotationId: linkId,
            },
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_INSTANCES],
          })
          .then(({ data }) => {
            // eslint-disable-next-line no-underscore-dangle
            if (data?.deleteAnnotation?.__typename !== 'Annotation') {
              console.error('there was an error deleting the annotation', data);
              return;
            }
            this.$emit('setRecentlyUpdatedInstanceUri', this.uri);
            this.$emit('close');
          })
          .catch(error => {
            console.error('there was an error sending the query', error);
          });
      },
      getTokenKey(token) {
        return token[TOKEN_ID_FIELD];
      },
    },
    components: {
      Token,
      TokenProvider,
    },
  };
</script>

<style scoped lang="scss">
  td:not(:first-of-type) {
    word-break: break-all;
  }
  .update-instance-form {
    padding: 0.5rem;
    border: 3px solid #eee;
    border-top: none;
    cursor: default;
  }
  .small-uri {
    font-size: small;
    color: #333;
  }

  td:first-child {
    font-size: 0.8rem;
    font-weight: bold;
    padding-right: 0.5rem;
  }

  .update-tokens-button {
    margin-left: 0.5rem;
  }

  .note {
    font-size: 0.8rem;
    color: #6b7280;
    padding-top: 0.5rem;
  }

  .none-style {
    font-style: italic;
    color: #aaa;
  }
</style>
