import AlignedTranslationWidget from '@/symphony/widgets/aligned-translation-widgets/AlignedTranslationWidget.vue';
import AnnotatedTextWidget from '@/symphony/widgets/annotated-text-widgets/AnnotatedTextWidget.vue';
import AnnotationFeaturesWidget from '@/symphony/widgets/annotation/AnnotationFeaturesWidget.vue';
import AnnotationInstancesWidget from '@/symphony/widgets/annotation/AnnotationInstancesWidget.vue';
import AnnotationSetsWidget from '@/symphony/widgets/annotation/AnnotationSetsWidget.vue';
import ApolloDebugWidget from '@/symphony/widgets/ApolloDebugWidget.vue';
import BookRefsWidget from '@/symphony/widgets/BookRefsWidget.vue';
import ChapterRefsWidget from '@/symphony/widgets/ChapterRefsWidget.vue';
import CreateInstanceFromTokensWidget from '@/symphony/widgets/annotation/CreateInstanceFromTokensWidget.vue';
import DictionaryEntryWidget from '@/symphony/widgets/DictionaryEntryWidget.vue';
import FeatureListConfiguratorWidget from '@/symphony/widgets/annotation/FeatureListConfiguratorWidget.vue';
import GlobalStoreViewerWidget from '@/symphony/widgets/GlobalStoreViewerWidget.vue';
import InterlinearWidget from '@/symphony/widgets/InterlinearWidget.vue';
import JsonWidget from '@/symphony/widgets/treedown-widgets/JsonWidget.vue';
import LemmasListWidget from '@/symphony/widgets/LemmasListWidget.vue';
import LSJDictionaryEntryWidget from '@/symphony/widgets/LSJDictionaryEntryWidget.vue';
import OsisRefWidget from '@/symphony/widgets/OsisRefWidget.vue';
import PassageRangeWidget from '@/symphony/widgets/PassageRangeWidget.vue';
import PassageRefWidget from '@/symphony/widgets/PassageRefWidget.vue';
import RapidDiscourseFlowWidget from '@/symphony/widgets/annotation/RapidDiscourseFlowWidget.vue';
import SavedTreesListWidget from '@/symphony/widgets/treedown-widgets/SavedTreesListWidget.vue';
import SentencesQueryListWidget from '@/symphony/widgets/treedown-widgets/SentencesQueryListWidget.vue';
import TextAnnotatorWidget from '@/symphony/widgets/TextAnnotatorWidget.vue';
import TextDecorationWidget from '@/symphony/widgets/TextDecorationWidget.vue';
import TextStructureWidget from '@/symphony/widgets/TextStructureWidget.vue';
import TextWidget from '@/symphony/widgets/TextWidget.vue';
import TranslationPickerWidget from '@/symphony/widgets/TranslationPickerWidget.vue';
import TreedownDisplayToggleWidget from '@/symphony/widgets/treedown-widgets/TreedownDisplayToggleWidget.vue';
import TreedownInfoWidget from '@/symphony/widgets/treedown-widgets/TreedownInfoWidget.vue';
import TreeEditorWidget from '@/symphony/widgets/treedown-widgets/tree-editor-widget/TreeEditorWidget.vue';
import TreeQueryWidget from '@/symphony/widgets/treedown-widgets/tree-query-widget/TreeQueryWidget.vue';
import VerseRefsWidget from '@/symphony/widgets/VerseRefsWidget.vue';
import PlacesWidget from '@/symphony/widgets/places-widget/PlacesWidget.vue';
import WordInfoWidget from '@/symphony/widgets/WordInfoWidget.vue';
import WordQueryResultsWidget from '@/symphony/widgets/WordQueryResultsWidget.vue';
import WordQueryWidget from '@/symphony/widgets/WordQueryWidget.vue';

import { camelCaseToKebabCase, processWidgets } from '@/common/widgetUtils';

import LoremWidget from '@/doric/components/Widgets/LoremWidget.vue';
import RangeSliderWidget from '@/doric/components/Widgets/RangeSliderWidget.vue';

export const widgetComponents = {
  AlignedTranslationWidget,
  AnnotatedTextWidget,
  AnnotationFeaturesWidget,
  AnnotationInstancesWidget,
  AnnotationSetsWidget,
  ApolloDebugWidget,
  BookRefsWidget,
  ChapterRefsWidget,
  CreateInstanceFromTokensWidget,
  DictionaryEntryWidget,
  FeatureListConfiguratorWidget,
  GlobalStoreViewerWidget,
  InterlinearWidget,
  JsonWidget,
  LemmasListWidget,
  LoremWidget,
  LsjDictionaryEntryWidget: LSJDictionaryEntryWidget,
  OsisRefWidget,
  PassageRangeWidget,
  PassageRefWidget,
  PlacesWidget,
  RangeSliderWidget,
  RapidDiscourseFlowWidget,
  SavedTreesListWidget,
  SentencesQueryListWidget,
  TextAnnotatorWidget,
  TextDecorationWidget,
  TextStructureWidget,
  TextWidget,
  TranslationPickerWidget,
  TreedownDisplayToggleWidget,
  TreedownInfoWidget,
  TreeEditorWidget,
  TreeQueryWidget,
  VerseRefsWidget,
  WordInfoWidget,
  WordQueryResultsWidget,
  WordQueryWidget,
};

const allWidgetsList = Object.keys(widgetComponents).map(key => ({
  component: camelCaseToKebabCase(key),
  ...widgetComponents[key],
}));

export default processWidgets(allWidgetsList, widgetComponents);
