<template>
  <div class="container">
    <LoadingSpinner v-if="$apollo.loading" />
    <div v-if="errorMessage">{{ errorMessage }}</div>
    <div v-else>
      <EmptyMessage v-if="wordTokenList.length === 0">
        Select a word to load its morphology and other tags
      </EmptyMessage>
      <div v-else>
        <div
          class="token"
          v-for="wordData in wordTokenList"
          :key="wordData.ref"
          :data-macula-id="wordData.maculaId"
        >
          <div class="summary">
            <div class="lemma" :class="{ rtl: isHebrew(wordData.lemma) }">{{ wordData.lemma }}</div>
            <div class="gloss">{{ getEngGloss(wordData) }}</div>
            <div class="morph" v-for="(summaryLine, index) in summary(wordData)" :key="index">
              {{ summaryLine }}
            </div>
          </div>
          <div class="extra" v-show="wordData?.ln">Louw-Nida: {{ wordData.ln }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import mixins from '@/mixins';
  import {
    DEFAULT_GROUP_KEY,
    MACULA_HEBREW_IMPLIED_ARTICLE,
    MACULA_DEFAULT_GLOSS,
  } from '@/store/constants';
  import LoadingSpinner from './components/LoadingSpinner.vue';
  import EmptyMessage from './components/EmptyMessage.vue';

  const valueMap = {
    number: {
      singular: 'sg',
      plural: 'pl',
    },
    person: {
      first: '1',
      second: '2',
      third: '3',
    },
  };

  const isGreek = word => {
    const greek = /^[\u0370-\u03FF\u1F00-\u1FFF]+$/;
    return greek.test(word);
  };

  const isHebrew = word => {
    const hebrew = /^[\u0590-\u05FF]+$/;
    return hebrew.test(word);
  };

  const PARTICIPLE_VALUES = new Set(['participle', 'participle active', 'participle passive']);
  const isParticiple = value => PARTICIPLE_VALUES.has(value);

  const getGreekMorphSecondLine = wordData => {
    const { case: case_, voice, mood, tense, number, person, gender, class: class_ } = wordData;

    if (class_ === 'verb') {
      if (isParticiple(wordData.mood)) {
        // participle
        return `${tense} ${voice} ${mood} ${case_} ${gender} ${number}`;
      }

      // non-participle verb
      const personStr = person ? valueMap.person[person] : '';
      const numberStr = number ? valueMap.number[number] : '';
      return `${tense} ${voice} ${mood} ${personStr}${numberStr}`;
    }

    // non-verb
    const numberStr = number ? valueMap.number[number] : '';
    return `${case_} ${gender} ${numberStr}`;
  };

  const getHebrewMorphSecondLine = wordData => {
    const { stem, type: tense, number, person, gender, class: class_ } = wordData;
    if (class_ === 'verb') {
      if (isParticiple(tense)) {
        return `${stem} ${tense} ${gender} ${number}`;
      }
      if (tense === 'infinitive absolute' || tense === 'infinitive construct') {
        return `${stem} ${tense}`;
      }

      // finite verb
      return `${stem} ${tense} ${person} ${gender} ${number}`;
    }

    // non-verbal
    if (person) {
      return `${person} ${gender} ${number}`;
    }
    return ` ${gender} ${number}`;
  };

  const cleanWhitespace = value => value.trim().replace(/ {2+}/g, ' ');

  const getSummary = wordData => {
    if (isGreek(wordData.text)) {
      const partOfSpeech = `${wordData?.type} ${wordData.class}`;
      const morph = getGreekMorphSecondLine(wordData);
      return [partOfSpeech, morph];
    }
    if (isHebrew(wordData.text) || wordData?.maculaId.includes(MACULA_HEBREW_IMPLIED_ARTICLE)) {
      // cj does not strike me as a common abbreviation for conjunction
      const partOfSpeech = wordData?.class === 'cj' ? 'conjunction' : wordData?.class;
      const morph = getHebrewMorphSecondLine(wordData);
      return [partOfSpeech, morph];
    }

    return [`Only Greek and Hebrew word formatting is currently supported.`];
  };

  export default {
    name: 'WordInfoWidget',
    mixins: [mixins.InputDataMixin],
    data() {
      return {
        wordTokenList: [],
        errorMessage: null,
      };
    },
    props: {
      doric: {
        inputs: {
          label: 'Word Info',
          selectedTokenIds: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'selectedTokenIds',
          },
        },
      },
    },
    computed: {
      parsedSelectedTokenIds() {
        try {
          return JSON.parse(this.selectedTokenIds);
        } catch (e) {
          return [];
        }
      },
    },
    methods: {
      summary(wordData) {
        return getSummary(wordData || {}).map(cleanWhitespace);
      },
      isHebrew(word) {
        return isHebrew(word);
      },
      getEngGloss(wordData) {
        return wordData[MACULA_DEFAULT_GLOSS] ? wordData[MACULA_DEFAULT_GLOSS] : wordData.gloss;
      },
    },
    apollo: {
      wordTokens: {
        query: gql`
          query WordTokens($filters: WordTokenFilter) {
            paginatedWordTokens(filters: $filters) {
              results {
                data
                maculaId
              }
            }
          }
        `,
        variables() {
          return {
            filters: {
              id: {
                inList: this.parsedSelectedTokenIds,
              },
            },
          };
        },
        skip() {
          return (
            !Array.isArray(this.parsedSelectedTokenIds) || this.parsedSelectedTokenIds.length === 0
          );
        },
        update(data) {
          this.errorMessage = null;
          const newWordTokens = data.paginatedWordTokens.results.map(t => {
            const { maculaId } = t;
            return {
              maculaId,
              ...t.data,
            };
          });
          this.wordTokenList = newWordTokens;
        },
        error(error) {
          this.errorMessage = error.message;
          console.error(error);
        },
      },
    },
    components: {
      LoadingSpinner,
      EmptyMessage,
    },
  };
</script>

<style scoped lang="scss">
  .token {
    &:not(:last-child) {
      margin-bottom: 1em;
      padding-bottom: 1em;
      border-bottom: 1px solid #ccc;
    }

    .summary {
      background-color: #eee;
      border: 1px solid #ccc;
      border-radius: 0.5em;
      padding: 0.5em;

      div {
        margin: 0.25em;
      }

      .lemma {
        font-weight: bold;
        font-size: 1.4em;
        font-family: 'Gentium Book Plus';
        direction: 'ltr';

        &.rtl {
          font-family: 'SBLBibLit';
          direction: rtl;
          text-align: left;
          // TODO: review styling;
          // https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
          font-weight: normal;
        }
      }

      .morph {
        font-size: 0.8em;
        color: #666;
      }
    }

    .extra {
      margin-top: 0.5em;
      margin-left: 0.5em;
    }
  }
</style>
