<template>
  <div>
    <div class="reference-form">
      <input
        v-model="userRef"
        :class="{ error: inputError }"
        :placeholder="friendlyRef"
        @keyup.enter="handleSubmit"
        @input="handleChange"
      />
    </div>
    <div class="reference-info">
      <div v-show="userRef.length === 0 || !hasChangedSinceSubmit" class="infoMessage">
        Type a new reference and hit enter
      </div>
      <div v-show="!!friendlyImpliedRef && hasChangedSinceSubmit">
        {{ friendlyImpliedRef }}
      </div>
      <div class="errorMessage" v-show="userRef.length > 0">{{ validationState.message }}</div>
      <div><!-- spacer -->&nbsp;</div>
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import formatOsis from 'bible-reference-formatter';
  import bibleBooks from '@/common/bible-books-lookup.json';
  import { getMostLikelyRefFromFreeInput } from '@/common/refUtils';
  import { DEFAULT_OSIS_REF, DEFAULT_GROUP_KEY } from '@/store/constants';

  const validateReference = osisRef => {
    if (!osisRef) {
      return {
        valid: false,
        message: 'Unrecognized reference',
      };
    }
    const [book, chapter, verse] = osisRef.split('.');
    // CHECK that chapter and verse are in book
    const bookData = bibleBooks.find(b => b.osisId === book);
    if (chapter in bookData.chapters) {
      if (verse > bookData.chapters[chapter]) {
        return {
          valid: false,
          message: 'Invalid verse',
        };
      }
      return {
        valid: true,
        message: '',
      };
    }

    return {
      valid: false,
      message: 'Invalid chapter',
    };
  };

  export default {
    name: 'PassageRef',
    data() {
      return {
        userRef: '',
        inputError: false,
        hasChangedSinceSubmit: false,
      };
    },
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          label: 'Passage Reference',
          osisRef: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'osisRef',
          },
        },
        outputs: {
          osisRef: DEFAULT_OSIS_REF,
        },
      },
    },
    watch: {
      osisRef: {
        handler() {
          this.userRef = this.friendlyRef;
          this.hasChangedSinceSubmit = false;
        },
        immediate: true,
      },
    },
    computed: {
      friendlyRef() {
        return formatOsis('niv-long', this.osisRef);
      },
      impliedRef() {
        return getMostLikelyRefFromFreeInput(this.userRef, { respondWithRange: false });
      },
      validationState() {
        return validateReference(this.impliedRef);
      },
      friendlyImpliedRef() {
        if (!this.impliedRef) {
          return '';
        }
        try {
          // We want to attempt to show the friendly ref even if it's invalid (e.g., Gen 123:456)
          return formatOsis('niv-long', this.impliedRef);
        } catch (e) {
          console.warn(e);
          return '';
        }
      },
    },
    methods: {
      handleSubmit() {
        if (this.userRef.length === 0) {
          this.userRef = this.friendlyRef;
        }

        if (!this.validationState.valid) {
          this.inputError = true;
          return;
        }
        this.hasChangedSinceSubmit = false;
        this.outputs.osisRef.value = this.impliedRef;
        this.userRef = this.friendlyRef;
        this.submit();
      },
      handleChange() {
        this.inputError = false;
        this.hasChangedSinceSubmit = true;
      },
    },
  };
</script>

<style scoped lang="scss">
  .reference-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 0.25rem;
      margin-right: 0.25rem;
      font-size: 1.25rem;
      font-weight: bold;
      &.error {
        color: red;
      }
    }
    button {
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 0.25rem;
      background-color: #eee;

      &:hover {
        cursor: pointer;
        background-color: #ddd;
      }
      &:active {
        background-color: #ccc;
      }
    }
  }
  .reference-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5rem;
    margin-left: 0.25rem;
    margin-bottom: 0;
    font-size: 0.75rem;

    > div {
      margin-right: 0.25rem;
    }
    .errorMessage {
      color: red;
    }
    .infoMessage {
      color: #999;
      font-style: italic;
    }
  }
</style>
