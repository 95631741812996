<script setup>
  import { defineEmits, ref } from 'vue';
  import { useStore } from 'vuex';

  const store = useStore();

  const emit = defineEmits(['close']);
  function close() {
    emit('close');
  }

  const workspaceState = ref(store.getters.getActiveWorkspace); // Object
  const workspaceName = ref(workspaceState.value.workspaceName); // String
  const workspaceReadOnly = ref(workspaceState.value.readOnly); // Boolean
  const showVariableGroupTags = ref(workspaceState.value.showVariableGroupTags); // Boolean
  const workspaceGlobalFields = ref(workspaceState.value.globalFields); // Object

  // Need to extract each value manually so that it copies each variable without reference
  const workspaceVariableGroups = ref(
    workspaceState.value.variableGroups.map(variableGroup => ({
      label: variableGroup.label,
      key: variableGroup.key,
      color: variableGroup.color,
      variables: JSON.parse(JSON.stringify(variableGroup.variables)), // NOTE: parsing ensures that the individual variables are copied without reference
    })),
  );

  const variableGroupsExpandedArray = ref(workspaceVariableGroups.value.map(() => false)); // Array of which variable groups are collapsed
  function toggleVariableGroupCollapse(index) {
    variableGroupsExpandedArray.value[index] = !variableGroupsExpandedArray.value[index];
  }

  function submitWorkspaceSettingsChange() {
    store.commit('updateWorkspaceSettings', {
      workspaceName: workspaceName.value,
      readOnly: workspaceReadOnly.value,
      showVariableGroupTags: showVariableGroupTags.value,
      globalFields: workspaceGlobalFields.value,
      variableGroups: workspaceVariableGroups.value,
    });
    close();
  }

  function submitVariableChanges(variableGroupIndex) {
    store.commit('updateVariableGroup', {
      label: workspaceVariableGroups.value[variableGroupIndex].label,
      key: workspaceVariableGroups.value[variableGroupIndex].key,
      color: workspaceVariableGroups.value[variableGroupIndex].color,
      variables: workspaceVariableGroups.value[variableGroupIndex].variables,
    });
    toggleVariableGroupCollapse(variableGroupIndex);
  }

  function handleSettingChange(event, setting, value) {
    if (setting === 'workspaceName') {
      workspaceName.value = value;
    } else if (setting === 'readOnly') {
      workspaceReadOnly.value = value;
    } else if (setting === 'globalFields') {
      workspaceGlobalFields.value = value;
    } else if (setting === 'variableGroups') {
      workspaceVariableGroups.value = value;
    }
  }

  function handleAddVariableGroup() {
    store.commit('addVariableGroup', { label: 'New Group' });
    workspaceVariableGroups.value = store.getters.getActiveWorkspace.variableGroups;
  }
</script>

<template>
  <div class="form-wrapper">
    <div class="configuration-form">
      <div class="form-div">
        <div class="subsection">
          <span>Workspace Settings</span>
          <li>
            <input
              id="form-workspace-name"
              v-model="workspaceName"
              @enter.prevent="handleSettingChange($event, 'workspaceName', workspaceName)"
            /><label for="form-workspace-name">Name</label>
          </li>
          <li>
            <input
              id="form-workspace-read-only"
              type="checkbox"
              v-model="workspaceReadOnly"
              @change="handleSettingChange($event, 'readOnly', workspaceReadOnly)"
            /><label for="form-workspace-read-only">Workspace is Read-Only</label>
          </li>
          <li>
            <label for="form-workspace-show-variable-group-tags"
              ><input
                id="form-workspace-show-variable-group-tags"
                type="checkbox"
                v-model="showVariableGroupTags"
                @change="
                  handleSettingChange($event, 'showVariableGroupTags', showVariableGroupTags)
                "
              />Show variable-group tags on widget headers</label
            >
          </li>
          <div class="close-form-button" @click="submitWorkspaceSettingsChange()">
            <font-awesome-icon icon="fa-solid fa-check-circle" />
            Submit
          </div>
        </div>
        <div class="subsection">
          <span>Global Fields</span>
          <li
            for="form-global-fields"
            v-for="(value, fieldName) in workspaceGlobalFields"
            :key="fieldName"
          >
            <label>{{ fieldName }}</label
            ><input
              id="form-global-fields"
              v-model="workspaceGlobalFields[fieldName]"
              @keyup.enter.exact="
                handleSettingChange($event, 'globalFields', workspaceGlobalFields)
              "
            />
          </li>
        </div>
        <div class="subsection row-subsection">
          <span>Variable Groups</span>
          <div
            class="subsection variable-group"
            v-for="(variableGroup, index) in workspaceVariableGroups"
            :key="index"
          >
            <span
              class="variable-group-label"
              @click="toggleVariableGroupCollapse(index)"
              :style="`border-bottom: 3px solid ${variableGroup.color}`"
            >
              <font-awesome-icon
                v-if="!variableGroupsExpandedArray[index]"
                class="variable-group-cog"
                icon="fa-solid fa-cog"
              />
              <font-awesome-icon
                v-if="variableGroupsExpandedArray[index]"
                class="variable-group-cog"
                icon="fa-solid fa-times-circle"
              />
              {{ store?.getters?.getActiveWorkspace?.variableGroups[index]?.label }}
            </span>
            <div v-show="variableGroupsExpandedArray[index]" class="collapse-content">
              <li class="variable-group-key"><label>Key</label>{{ variableGroup.key }}</li>
              <li>
                <label>Label</label
                ><input
                  v-model="variableGroup.label"
                  @change="handleSettingChange($event, 'variableGroup', variableGroup)"
                />
              </li>
              <li>
                <label>Color</label
                ><input
                  v-model="variableGroup.color"
                  @change="handleSettingChange($event, 'variableGroup', variableGroup)"
                />
              </li>
              <div class="variables-list">
                <label>Variables</label>
                <li
                  v-for="(variableValue, variableName) in variableGroup.variables"
                  :key="variableName"
                >
                  <label>{{ variableName }}</label
                  ><input
                    id="form-variable-groups"
                    v-model="variableGroup.variables[variableName]"
                  />
                </li>
              </div>
              <div class="close-form-button" @click="submitVariableChanges(index)">
                <font-awesome-icon icon="fa-solid fa-check-circle" />
                Submit
              </div>
            </div>
          </div>
          <button class="add-variable-group-button" @mouseup="handleAddVariableGroup">
            <font-awesome-icon icon="fa-circle-plus" class="add-variable-group" /> Add Variable
            Group
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .configuration-form {
    display: flex;
    flex-direction: column;
    max-width: max-content;
    align-items: stretch;
    padding: 1.2em;
    width: 100%;
    gap: 1em;
    > .form-div {
      display: flex;
      flex-direction: row;
      align-self: center;
      gap: 1em;
    }
  }
  .close-form-button {
    height: 1.5em;
    border-radius: 0.5em;
    border: 1px solid #333;
    padding: 0.3em 0.7em;
    margin-top: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      color: #ddd;
      background-color: green;
      border-color: green;
    }
    > svg {
      height: 1.5em;
      margin-right: 0.5em;
    }
  }
  .subsection {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    gap: 0.3em;
    border: 1px solid #333;
    border-radius: 0.5em;
    padding: 1em;
    margin: 0.5em 0;
    height: max-content;
    &.variable-group {
      display: flex;
      width: max-content;
      transition: display 0.5s;
      .variable-group-key {
        font-size: 0.8em;
        margin: 0.5em 0;
        color: white;
        > label {
          display: flex;
          flex-direction: row;
          color: black;
          margin-bottom: 0.5em;
          align-items: center;
        }
      }
      .variable-group-label {
        display: flex;
        align-items: center;
        width: 18em;
        color: attr(color);
        padding-bottom: 0.2em;
        .variable-group-cog {
          margin-right: 0.5em;
          color: #333;
          padding: 0.2em;
          border-radius: 0.2em;
        }
        &:hover > .variable-group-cog {
          cursor: pointer;
          color: #ddd;
        }
      }
      &.collapse-content {
        display: flex;
        flex-direction: column;
        gap: 0.3em;
        padding: 0.5em;
        > label {
          display: flex;
          flex-direction: row;

          margin-bottom: 0.5em;
          align-items: center;
          > label {
            width: 5em;
          }
          > input {
            width: 10em;
          }
        }
      }
    }
    span {
      font-weight: bold;
      text-transform: uppercase;
      width: 100%;
    }
    li {
      display: flex;
      justify-content: space-between;
      gap: 1em;
      > label {
        font-style: italic;
      }
      > input {
        text-align: right;
      }
    }
    &.row-subsection {
      flex-flow: row wrap;
      > li {
        display: flex;
        flex-direction: column;
        gap: 0.3em;
        border: 1px solid black;
        margin: 0.3em;
        padding: 0.3em;
        > label {
          font-style: italic;
        }
        > input {
          text-align: right;
        }
      }
    }
  }
  .add-variable-group-button {
    display: flex;
    align-self: center;
    align-items: center;
    margin: 1em;
    padding: 0.5em;
    border: none;
    border-radius: 0.5em;
    background-color: green;
    color: #ddd;
    &:hover {
      background-color: darkgreen;
      cursor: pointer;
    }
    > svg {
      height: 1.5em;
      margin-right: 0.5em;
    }
  }
  .variables-list {
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    > label {
      font-weight: bold;
      width: 100%;
      margin: 0.5em 0;
    }
  }
</style>
