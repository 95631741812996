<template>
  <div>
    <table>
      <tr>
        <td>Feature</td>
        <td>
          {{ feature?.label || feature.uri }}
        </td>
      </tr>
      <tr>
        <td>Label</td>
        <td><input type="text" v-model="newInstanceLabel" placeholder="Label" required /></td>
      </tr>
    </table>
    <button @click="create" :disabled="!newInstanceLabel">Create Root Instance</button>
    <button @click="emit('back')">Back</button>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  defineProps(['feature']);
  const newInstanceLabel = ref(null);
  const emit = defineEmits(['createRootInstance', 'back']);

  const create = () => {
    emit('createRootInstance', {
      label: newInstanceLabel.value,
    });
  };
</script>

<style scoped>
  td:first-child {
    padding-right: 1em;
    font-weight: bold;
  }
</style>
