import { WORD_TOKEN_ID_FIELD } from '@/store/constants';

export default function enrichTokenMap(tokenMapByVerse, tokens) {
  /*
  Merges provided token data into the existing token map.
  */
  tokenMapByVerse.forEach(value => {
    // const verseId = key;
    const verseMap = value;

    /* eslint-disable no-shadow */
    verseMap.forEach((value, key) => {
      const tokenId = key;
      const existing = value;

      const token = tokens.find(token => {
        return token[WORD_TOKEN_ID_FIELD] === tokenId;
      });
      if (token) {
        // The spread operator is used to ensure that the
        // provided token data overwrites any previous data.
        verseMap.set(tokenId, { ...existing, ...token });
      }
    });
  });

  return tokenMapByVerse;
}
