<template>
  <div class="lemmas-list-body" :class="{ loading }">
    <div class="osis-ref">{{ osisRef }}</div>
    <div v-if="loading"><em>Loading...</em></div>
    <div v-else>
      <ul class="lemmas-list" v-if="lemmas.length > 0">
        <li v-for="(lemma, index) in lemmas" :key="index">
          <button href="#" @click.prevent="selectLemma(lemma)">
            {{ lemma }}
          </button>
        </li>
      </ul>
      <div class="no-lemmas" v-else>
        <p>No lemmas found for this reference</p>
      </div>
    </div>
  </div>
</template>
<script>
  import { fromXml } from 'xast-util-from-xml';

  import mixins from '@/mixins';
  import { convertOsisToUsfm } from '@/common/refUtils';
  import { DEFAULT_GROUP_KEY } from '@/store/constants';

  const SYMPHONY_REST_API_ENDPOINT =
    process.env.VUE_APP_SYMPHONY_REST_API_ENDPOINT ||
    'https://symphony-basex-svc.clearlabs.biblica.com/api';

  export default {
    name: 'LemmasListWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          label: null,
          osisRef: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'osisRef',
          },
        },
        // FIXME: How close to couple with a global selectedLemma
        // TODO: Do we want to specify outputs that are _not_
        // configurable?
        outputs: {
          selectedLemma: null,
        },
      },
    },
    data() {
      return {
        lemmas: [],
        loading: false,
      };
    },
    watch: {
      osisRef: {
        handler() {
          this.loadLemmaList();
        },
        immediate: true,
      },
    },
    // When we work with Jonathan on what these glosses actually are...
    computed: {
      osisRef() {
        return this.osisRef;
      },
      usfmRef() {
        return convertOsisToUsfm(this.osisRef);
      },
    },
    methods: {
      loadLemmaList() {
        if (!this.osisRef) {
          this.lemmas = [];
          return;
        }
        const endpoint = SYMPHONY_REST_API_ENDPOINT;
        const path = `/GNT/Nestle1904/morph?usfm-ref=${this.usfmRef}`;
        const entryUrl = `${endpoint}${path}`;
        this.loading = true;
        fetch(entryUrl).then(resp => {
          resp.text().then(data => {
            setTimeout(() => {
              // TODO: remove this kludge at the API
              const fixedXml = `<words>${data}</words>`;
              const xmlObj = fromXml(fixedXml);
              this.lemmas = xmlObj.children[0].children
                .filter(elem => elem.name === 'w')
                .map(elem => elem.attributes.lemma);
              this.loading = false;
            }, 0);
          });
        });
      },
      // TODO: Refactor this with doricConfiguration.dataFields?
      selectLemma(lemma) {
        this.outputs.selectedLemma.value = lemma;
        this.submit();
      },
    },
  };
</script>

<style scoped lang="scss">
  .loading {
    opacity: 50%;
  }
  .lemmas-list-body {
    font-family: 'Gentium Book Plus';
    font-size: 14pt;
    line-height: 1.7;
  }
  .osis-ref {
    float: right;
    text-align: right;
    opacity: 50%;
  }
  ul.lemmas-list {
    margin-top: 0;
    list-style-type: square;
    > li {
      > button {
        background: none;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
</style>
