<template>
  <div ref="thisWidget" class="discourse-annotations-container">
    <LoadingSpinner v-if="$apollo.loading" />
    <SelectAnnotationSet
      v-if="mode === modes.NO_ANNOTATIONS_SET"
      @selectAnnotationSet="selectAnnotationSet"
      :contextualizedUsfmRef="contextualizedUsfmRef"
    />
    <div v-else-if="mode === modes.NO_ROOT_ANNOTATION_SELECTED">
      <SelectRootAnnotationInstance
        v-if="!$apollo.loading"
        :instanceList="rootInstances || []"
        :contextualizedUsfmRef="contextualizedUsfmRef"
        @selectInstance="selectRootAnnotation"
        @back="clearSelectedAnnotationSet"
      />
    </div>
    <div v-else-if="mode === modes.ROOT_ANNOTATION_SELECTED">
      <div v-if="annotationFlow?.tree?.length">
        <InlineVersionPicker
          v-if="!$apollo.loading"
          :selectedTextualEdition="selectedTextualEdition"
          :textualEditions="availableTextualEditions"
          @set-selected-textual-edition="usfmRef => setSelectedTextualEdition(usfmRef)"
        />
        <div class="discourse-tree" @click="handleClick">
          <TokenProvider :tokens="showAlignedTokens ? targetTokens : tokens">
            <DiscourseTree
              v-for="annotation in showAlignedTokens ? alignedTree : annotationFlow.tree"
              :rtl="isRTL && !showAlignedTokens"
              :key="annotation.id"
              :annotation="annotation"
            />
          </TokenProvider>
        </div>
      </div>
      <div v-else-if="!$apollo.loading">
        Sorry, there are no children annotations for the root annotation that you selected at:
        <span class="passage-reference">{{ friendlyReference() }}</span>
      </div>
      <button v-if="!$apollo.loading" @click="mode = modes.NO_ROOT_ANNOTATION_SELECTED">
        Select Root Annotation
      </button>
    </div>
    <div v-else-if="!$apollo.loading">
      Unknown mode: {{ mode }}. Something has gone wrong in `DiscourseAnnotation.vue`. Please report
      this error.
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import formatOsis from 'bible-reference-formatter';
  import { nextTick } from 'vue';
  import mixins from '@/mixins';
  import {
    TOKEN_ID_FIELD,
    APOLLO_QUERY_FOR_ANNOTATION_INSTANCES,
    APOLLO_DISCOURSE_CONTAINER_FEATURE_URI,
    DEFAULT_ANNOTATION_SET_URI,
    DEFAULT_GNT_TEXTUAL_EDITION,
    DEFAULT_HOT_TEXTUAL_EDITION,
    MACULA_DEFAULT_GLOSS,
    DEFAULT_ALIGNED_TRANSLATION,
  } from '@/store/constants';
  import {
    determineLanguageDirectionForOsisRef,
    determineTestamentForOsisRef,
  } from '@/common/refUtils';
  import TokenProvider from '../tokens/TokenProvider.vue';
  import SelectAnnotationSet from './discourse/SelectAnnotationSet.vue';
  import SelectRootAnnotationInstance from './discourse/SelectRootAnnotationInstance.vue';
  import DiscourseTree from './discourse/DiscourseTree.vue';
  import InlineVersionPicker from './discourse/InlineVersionPicker.vue';
  // FIXME: When the ATW uses graphql, we should try not to depend on child LoadingSpinners
  import LoadingSpinner from '../components/LoadingSpinner.vue';

  const unique = v => Array.from(new Set(v));

  const getVerseNumberForFirstWordInVerse = token => {
    // eslint-disable-next-line no-unused-vars
    const [bch, v, w] = token.ref.split(/[:!]/);
    return token.idx === 0 ? v : null;
  };

  const annotationsAsTree = (descendants, descendantsTree) => {
    return descendantsTree.map(node => {
      const nodeIdString = node.id.toString();
      const annotation = descendants.find(a => a.id === nodeIdString);
      return {
        ...annotation,
        ...(node?.children?.length > 0
          ? {
              children: annotationsAsTree(descendants, node.children),
            }
          : {}),
      };
    });
  };

  const MODES = {
    NO_ANNOTATIONS_SET: 'NO_ANNOTATIONS_SET',
    NO_ROOT_ANNOTATION_SELECTED: 'NO_ROOT_ANNOTATION_SELECTED',
    ROOT_ANNOTATION_SELECTED: 'ROOT_ANNOTATION_SELECTED',
  };

  export default {
    name: 'DiscourseAnnotations',
    mixins: [mixins.RefAdapterMixin],
    components: {
      SelectAnnotationSet,
      DiscourseTree,
      SelectRootAnnotationInstance,
      TokenProvider,
      LoadingSpinner,
      InlineVersionPicker,
    },
    props: {
      osisRef: String,
      showGlosses: {
        type: Boolean,
      },
      currentAnnotationSetUri: {
        type: String,
        default: null,
      },
      selectedTokenIds: {
        type: String,
        default: '[]',
      },
    },
    data() {
      return {
        selectedAnnotationSetUri: null,
        selectedRootAnnotationUri: null,
        selectedTranslationAlignment: null,
        modes: Object.freeze(MODES),
        mode: MODES.NO_ANNOTATIONS_SET,
        selectedTokenId: null,
        autoSelectFirstRootAnnotation: true,
        showAlignedTokens: false,
        alignedTranslation: null,
      };
    },
    emits: ['select-token-lemma', 'select-token-ids'],
    watch: {
      currentAnnotationSetUri: {
        handler(val) {
          if (this.selectedRootAnnotationUri) {
            return;
          }
          if (val) {
            this.selectAnnotationSet(val);
          }
        },
        immediate: true,
      },
      rootInstances: {
        handler() {
          if (this.autoSelectFirstRootAnnotation) {
            const firstInstance =
              this.rootInstances && this.rootInstances?.length ? this.rootInstances[0] : null;
            if (firstInstance) {
              this.selectRootAnnotation(firstInstance.uri, true);
              return;
            }
          }

          if (!this.selectedAnnotationSetUri || !this.selectedRootAnnotationUri) {
            // We don't need to consider changing modes if
            // either of these are not set
            return;
          }

          // If we have a selectedRootAnnotationUri and it is still in the list of root instances, we're good
          if (this.rootInstances.find(r => r.uri === this.selectedRootAnnotationUri)) {
            return;
          }

          // Otherwise, we need to reset the selectedRootAnnotationUri and mode
          this.selectedRootAnnotationUri = null;
          this.mode = MODES.NO_ROOT_ANNOTATION_SELECTED;
        },
        immediate: true,
      },
      osisRef: {
        handler(newValue) {
          if (!this.selectedAnnotationSetUri || !this.selectedRootAnnotationUri) {
            this.selectAnnotationSet(DEFAULT_ANNOTATION_SET_URI);
          }
          if (newValue) {
            this.autoSelectFirstRootAnnotation = true;
            this.autoscroll();
          }
        },
        immediate: true,
      },
      alignedTranslations: {
        immediate: true,
        handler(newValue) {
          if (newValue && this.alignedTranslation === null) {
            // NOTE: This is used to set the initial value.
            this.alignedTranslation = newValue.find(
              translation => translation.usfmRef === this.selectedTranslationAlignment,
            );
          }
        },
      },
      selectedTranslationAlignment: {
        handler(newValue) {
          this.alignedTranslation = this.alignedTranslations.find(
            translation => translation.usfmRef === newValue,
          );
        },
      },
    },
    computed: {
      isRTL() {
        const direction = determineLanguageDirectionForOsisRef(this.osisRef);
        return direction === 'rtl';
      },
      sourceTextTextualEdition() {
        return determineTestamentForOsisRef(this.osisRef) === 'OT'
          ? DEFAULT_HOT_TEXTUAL_EDITION
          : DEFAULT_GNT_TEXTUAL_EDITION;
      },
      selectedTextualEdition() {
        return this.selectedTranslationAlignment || this.sourceTextTextualEdition;
      },
      availableTextualEditions() {
        return [
          { usfmRef: this.sourceTextTextualEdition },
          ...this.alignedTranslations.map(translation => {
            return { usfmRef: translation.usfmRef };
          }),
        ];
      },
      sourceToTargetIdsAlignmentMap() {
        if (!this.alignmentLinks) {
          return {};
        }
        const map = {};
        this.alignmentLinks.forEach(link => {
          link.sourceTokens.forEach(sourceToken => {
            map[sourceToken.id] = link.targetTokens.map(t => t[TOKEN_ID_FIELD]);
          });
        });
        return map;
      },
      targetToSourceIdsAlignmentMap() {
        if (!this.alignmentLinks) {
          return {};
        }
        const map = {};
        this.alignmentLinks.forEach(link => {
          link.targetTokens.forEach(targetToken => {
            map[targetToken.id] = link.sourceTokens.map(t => t[TOKEN_ID_FIELD]);
          });
        });
        return map;
      },
      targetTokens() {
        // this is the equivalent of `tokens` but for **target** (not source)
        if (
          !this.alignmentLinks ||
          Object.keys(this.sourceToTargetIdsAlignmentMap).length === 0 ||
          Object.keys(this.targetToSourceIdsAlignmentMap).length === 0
        ) {
          return [];
        }
        const targetTokens = this.alignmentLinks
          .map(a =>
            a.targetTokens.map(t => ({
              [TOKEN_ID_FIELD]: t[TOKEN_ID_FIELD],
              tokenId: t[TOKEN_ID_FIELD],
              data: {
                ref: t.ref,
                value: t.value,
                verseNumber: getVerseNumberForFirstWordInVerse(t),
              },
            })),
          )
          .flat();
        if (!this.annotationFlow?.tokens) {
          return [];
        }

        const sourceTokensAsMap = Object.fromEntries(this?.tokens.map(t => [t.tokenId, t]));
        const targetTokensAsMap = Object.fromEntries(targetTokens.map(t => [t.tokenId, t]));
        const relevantTargetTokens = unique(
          this?.tokens.map(t => this.sourceToTargetIdsAlignmentMap[t.tokenId]).flat(),
        );

        return relevantTargetTokens
          .map(tId => {
            const sourceTokenIds = this.targetToSourceIdsAlignmentMap[tId];
            if (!sourceTokenIds) {
              return null;
            }

            const color =
              sourceTokenIds.length >= 1 ? sourceTokensAsMap[sourceTokenIds[0]].color : null;
            const majorUnderline =
              sourceTokenIds.length >= 1
                ? sourceTokensAsMap[sourceTokenIds[0]].majorUnderline
                : null;

            return {
              ...targetTokensAsMap[tId],
              tokenId: tId,
              color,
              majorUnderline,
            };
          })
          .filter(t => t);
      },
      alignedTree() {
        // this is the equivalent of `annotationFlow.tree` but for **target** (not source)
        if (
          !this.annotationFlow?.descendants ||
          !this.targetTokens ||
          !this.alignmentLinks ||
          !this.annotationFlow?.descendantsTree ||
          Object.keys(this.sourceToTargetIdsAlignmentMap).length === 0
        ) {
          return [];
        }

        const targetTokensAsMap = Object.fromEntries(this.targetTokens.map(t => [t.tokenId, t]));

        const alignedDescendants = this.annotationFlow?.descendants.map(descendant => {
          const sourceTokenIds = descendant.tokens.map(t => t[TOKEN_ID_FIELD]);
          const targetTokenIds = unique(
            sourceTokenIds
              .map(sid => this.sourceToTargetIdsAlignmentMap[sid])
              .flat()
              .filter(t => t),
          ).sort();
          const tokens = targetTokenIds.map(tId => targetTokensAsMap[tId]);
          return {
            ...descendant,
            tokens,
          };
        });
        return annotationsAsTree(alignedDescendants, this.annotationFlow.descendantsTree);
      },
      parsedSelectedTokenIds() {
        try {
          return new Set(JSON.parse(this.selectedTokenIds));
        } catch (e) {
          return new Set([]);
        }
      },
      tokens() {
        if (!Array.isArray(this.annotationFlow?.tokens)) {
          return [];
        }
        const getMajorUnderline = (isSelected, isLinked) => {
          if (isSelected && isLinked) {
            return '#818cf8';
          }
          if (isSelected) {
            return '#bae6fd';
          }
          if (isLinked) {
            return '#a5b4fc';
          }
          return null;
        };
        return this.annotationFlow.tokens.map(token => ({
          ...token,
          color: this.selectedTokenId === token.tokenId ? '#4338ca' : null,
          majorUnderline: getMajorUnderline(
            this.parsedSelectedTokenIds.has(String(token.tokenId)),
            this.annotationFlow.linkedTokens.has(token.data.ref),
          ),
          altTexts: this.showGlosses ? [token.data.gloss] : [],
        }));
      },
    },
    apollo: {
      annotationFlow: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_INSTANCES}($instanceUri: String!) {
            annotations(filters: { uri: { exact: $instanceUri } }) {
              impliedTokens {
                id
                idx
                ref
                lemma
                value
                skipSpaceAfter
                data
              }
              descendantsTree
              descendants {
                uri
                id
                label
                scriptureReference {
                  usfmRef
                }
                tokens {
                  idx
                  id
                  ref
                }
                feature {
                  id
                  label
                }
                linkedBy {
                  tokens {
                    id
                    ref
                  }
                }
              }
            }
          }
        `,
        skip() {
          return !this?.selectedRootAnnotationUri;
        },
        variables() {
          return { instanceUri: this.selectedRootAnnotationUri };
        },
        update(data) {
          // There should only be one annotation returned because we're matching on an exact uri
          if (!data.annotations?.length === 1) {
            return [];
          }

          const linkedTokens = new Set(
            data.annotations[0].descendants
              .filter(a => a.linkedBy.length > 0)
              .map(a => a.linkedBy.map(l => l.tokens.map(t => t.ref)).flat())
              .flat(),
          );

          nextTick(this.autoscroll);
          return {
            tokens: data.annotations[0].impliedTokens.map(token => ({
              tokenId: token[TOKEN_ID_FIELD],
              data: {
                ref: token.ref,
                verseNumber: getVerseNumberForFirstWordInVerse(token),
                lemma: token.lemma,
                value: token.value,
                skipSpaceAfter: token.skipSpaceAfter,
                // TODO: Use standard ATLAS glosses when implemented
                gloss: token.data[MACULA_DEFAULT_GLOSS],
              },
            })),
            linkedTokens,
            tree: annotationsAsTree(
              data.annotations[0].descendants,
              data.annotations[0].descendantsTree,
            ),
            descendants: data.annotations[0].descendants,
            descendantsTree: data.annotations[0].descendantsTree,
          };
        },
      },
      alignmentLinks: {
        // FIXME: Don't hardcode name and textualEdition; would prefer a URI
        // for the alignment too.
        // May also want to involve
        // [✨ Allow a user to select a translation (not just an alignment)](https://trello.com/c/j5WJGVCM)
        query: gql`
          query ($filters: AlignmentLinkFilter) {
            alignmentLinks(filters: $filters) {
              sourceTokens {
                id
              }
              targetTokens {
                idx
                id
                ref
                value
                skipSpaceAfter
              }
            }
          }
        `,
        skip() {
          return !this.contextualizedUsfmRef?.split(' ')?.[0] || !this.alignedTranslation;
        },
        variables() {
          const book = this.contextualizedUsfmRef.split(' ')[0];
          return {
            filters: {
              alignment: {
                inList: this.alignedTranslation?.targetAlignments?.map(a => a.id),
              },
              sourceScriptureReference: {
                usfmRef: book,
                textualEdition: this.sourceTextTextualEdition,
              },
            },
          };
        },
        update: ({ alignmentLinks }) => {
          return alignmentLinks;
        },
      },
      rootInstances: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_INSTANCES}($usfmRef: String!, $annotationSetUri: String!) {
            annotations(
              filters: {
                annotationSetUri: $annotationSetUri
                featureUri: "${APOLLO_DISCOURSE_CONTAINER_FEATURE_URI}"
                scriptureReferenceWithAncestors: { usfmRef: $usfmRef, resolveAncestors: { minDepth: 1 } }
                depth: 1
              }
            ) {
              id
              uri
              label
            }
          }
        `,
        skip() {
          return !this.selectedAnnotationSetUri || !this.usfmRef;
        },
        variables() {
          return {
            usfmRef: this.contextualizedUsfmRef,
            annotationSetUri: this.selectedAnnotationSetUri,
          };
        },
        update: data => {
          if (!data.annotations) {
            return [];
          }
          return data.annotations;
        },
      },
      // FIXME: Refactor with TranslationPickerWidget.vue
      alignedTranslations: {
        query: gql`
          query AlignedTranslations {
            textualEditions(filters: { isAlignedTranslation: true }, order: { usfmRef: ASC }) {
              id
              usfmRef
              targetAlignments {
                id
                name
              }
            }
          }
        `,
        skip() {
          return !this.osisRef;
        },
        update(data) {
          this.errorMessage = null;
          return data.textualEditions;
        },
        error(error) {
          this.errorMessage = error.message;
          console.error(error);
        },
      },
    },
    methods: {
      selectAnnotationSet(uri) {
        if (this.selectedAnnotationSetUri !== uri) {
          this.selectedRootAnnotationUri = null;
        }
        this.selectedAnnotationSetUri = uri;
        this.mode = MODES.NO_ROOT_ANNOTATION_SELECTED;
      },
      selectRootAnnotation(uri, autoSelect = false) {
        this.selectedRootAnnotationUri = uri;
        this.mode = MODES.ROOT_ANNOTATION_SELECTED;
        this.autoSelectFirstRootAnnotation = autoSelect;
      },
      clearSelectedAnnotationSet() {
        this.mode = this.modes.NO_ANNOTATIONS_SET;
        this.selectedAnnotationSetUri = null;
        this.autoSelectFirstRootAnnotation = false;
      },
      friendlyReference() {
        return formatOsis('niv-long', this.osisRef);
      },
      getSourceTokenFromClickedTargetToken(targetTokenId) {
        const sourceTokenId = this.targetToSourceIdsAlignmentMap[targetTokenId]?.[0];
        const token = this.tokens.find(t => t.tokenId === sourceTokenId);
        return token;
      },
      getSourceTokenFromClickedSourceToken(sourceTokenId) {
        const token = this.tokens.find(t => t.tokenId === sourceTokenId);
        return token;
      },
      handleClick(event) {
        const tokenContainer = event.target.closest('.token-container');
        if (!tokenContainer) {
          return;
        }
        const tokenId = tokenContainer.dataset?.tokenId;
        if (!tokenId) {
          return;
        }

        const token = this.showAlignedTokens
          ? this.getSourceTokenFromClickedTargetToken(tokenId)
          : this.getSourceTokenFromClickedSourceToken(tokenId);

        this.selectedTokenId = tokenId;
        this.$emit('select-token-lemma', token.data.lemma);
        this.$emit('select-token-ids', [token.tokenId]);
      },
      autoscroll() {
        if (!this.annotationFlow?.tokens?.length) {
          return;
        }
        const tokens = this.showAlignedTokens ? this.targetTokens : this.tokens;
        // Find the first token in the osisRef and scrollIntoView
        // FIXME: We depend on ref "startsWith"
        const tokenIdsInOsisRef = new Set(
          tokens
            .filter(token => token.data.ref.startsWith(this.usfmRef))
            .map(token => String(token.tokenId)),
        );
        const $tokens = this.$refs?.thisWidget?.querySelectorAll('.token-container') || [];
        const $foundToken = Array.from($tokens).find($token =>
          tokenIdsInOsisRef.has($token.dataset.tokenId),
        );
        if ($foundToken) {
          $foundToken.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      },
      setSelectedTextualEdition(usfmRef) {
        if (usfmRef !== this.sourceTextTextualEdition) {
          this.selectedTranslationAlignment = usfmRef;
          this.showAlignedTokens = true;
          return;
        }
        this.showAlignedTokens = false;
        this.selectedTranslationAlignment = null;
      },
    },
    mounted() {
      // TODO: Expose this as a proper property for the ATW
      // and / or preference for the user
      if (this.$route.query.workspace === 'annotation') {
        this.setSelectedTextualEdition(DEFAULT_ALIGNED_TRANSLATION);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .discourse-annotations-container {
    // NOTE: Improves display of loading indicator
    // if no other content is within container
    min-height: 2em;
  }
  .discourse-tree {
    width: calc(100% - 1.5rem);
    margin-left: 1.5rem;
  }
  .passage-reference {
    font-size: 0.8em;
    color: #666;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 5px;
  }
</style>
