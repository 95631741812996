import { computed } from 'vue';
import { GLOBAL_DATA_STATE, LOCAL_DATA_STATE } from '@/store/constants';

function getDataFieldValue(store, inputs, key) {
  const workspace = store?.getters?.getActiveWorkspace;
  if (!workspace) {
    return null;
  }
  // TODO: add test to ensure every widget's inputs are properly configured
  let value;
  if (inputs[key]?.groupKey === GLOBAL_DATA_STATE) {
    // If input is listening to global variable
    // TODO: deprecate global input fields?
    value = workspace.globalFields[inputs[key].value];
  } else if (inputs[key]?.groupKey === LOCAL_DATA_STATE) {
    // If input is listening to local hard-coded value
    value = inputs[key]?.value;
  } else if (inputs[key]?.groupKey) {
    // If input is listening to variable group
    value = workspace.variableGroups.find(
      variableGroup => variableGroup.key === inputs[key].groupKey,
    )?.variables[inputs[key].value];
  } else {
    // If input is neither hard-coded nor listening to a variable group
    // then the widget input is misconfigured.
    throw new Error(
      `Invalid input field: ${inputs[key]} on widget with input configuration ${inputs}`,
    );
  }
  return value;
}

function inputData(store, inputs, key) {
  return computed(() => {
    return inputs ? getDataFieldValue(store, inputs, key) : undefined;
  });
}

export default inputData;
