<template>
  <div class="word-query-widget">
    <div>To search for a word, add an attribute to search on and enter the desired value.</div>
    <div class="flex-align">
      <select v-model="selectedAttributeKey" @keyup.enter="addParam">
        <option v-for="field in wordTokenFields" :key="field.name" :value="field.name">
          {{ field.verboseName }}
        </option>
      </select>
      <div>
        <button @click.prevent="addParam" title="Add search parameter" class="icon-button add">
          <font-awesome-icon icon="fa-solid fa-square-plus" />
        </button>
      </div>
    </div>
    <div v-for="(value, key) in params" :key="key" class="flex-align">
      <div class="attribute-key">{{ getWordTokenVerboseName(key) }}</div>
      <div>
        <!-- respond to enter key with search function -->
        <input v-model="params[key]" :ref="`input_${key}`" @keyup.enter="search" />
      </div>
      <div>
        <button
          @click="removeParam(key)"
          title="Remove search parameter"
          class="icon-button delete"
        >
          <font-awesome-icon icon="fa-solid fa-trash" />
        </button>
      </div>
    </div>
    <div v-show="!Object.keys(params).length" class="info-message">
      You have not added any query parameters yet.
    </div>
    <div v-show="Object.keys(params).length" class="passage-filter">
      <div>
        <label>
          Filter search by reference range
          <input type="checkbox" v-model="filterByReference" />
        </label>
      </div>
      <div v-show="filterByReference">
        <span>Search Within</span>
        <input
          type="text"
          v-model="passageReference"
          @keyup.enter="search"
          placeholder="USFM Reference Range"
        />
      </div>
    </div>
    <div class="flex-align">
      <div>
        <button @click.prevent="search" title="search" class="text-and-icon-button">
          <font-awesome-icon icon="fa-solid fa-search" />
          <span> SEARCH </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import wordTokenFields from '@/symphony/shared/wordTokenFields';

  const wordTokenKeys = new Set(wordTokenFields.map(field => field.name));

  const getUrlParamsThatArentOurs = () => {
    const entries = Array.from(new URLSearchParams(window.location.search).entries());
    const params = entries.filter(([key]) => !wordTokenKeys.has(key) && key !== 'passageReference');
    return Object.fromEntries(params);
  };

  const DEFAULT_KEY = 'lemma';

  export default {
    name: 'WordQueryWidget',
    data() {
      // Get query string from URL
      const searchParams = Array.from(new URLSearchParams(window.location.search).entries());
      const initialParams = Object.fromEntries(
        searchParams.filter(([key]) => wordTokenKeys.has(key)),
      );
      const initialPassageReference =
        searchParams.find(([key]) => key === 'passageReference')?.[1] || '';
      return {
        selectedAttributeKey: DEFAULT_KEY,
        selectedAttributeValue: '',
        params: initialParams,
        wordTokenFields,
        passageReference: initialPassageReference,
        filterByReference: false,
      };
    },
    props: {
      doric: {},
    },
    methods: {
      search() {
        const query = {
          ...getUrlParamsThatArentOurs(),
          ...this.params,
        };
        if (this.filterByReference && this.passageReference) {
          query.passageReference = this.passageReference;
        }
        this.$router.replace({ query });
      },
      addParam() {
        const newParams = {
          ...this.params,
          [this.selectedAttributeKey]: '',
        };
        this.params = newParams;
        this.selectedAttributeValue = '';

        const elementKey = `input_${this.selectedAttributeKey}`;
        this.$nextTick(() => {
          this.$refs[elementKey][0].focus();
        });
      },
      removeParam(key) {
        const newParams = { ...this.params };
        delete newParams[key];
        this.params = newParams;
      },
      getWordTokenVerboseName(key) {
        return wordTokenFields.find(field => field.name === key).verboseName;
      },
    },
  };
</script>

<style scoped lang="scss">
  .word-query-widget {
    display: flex;
    flex-direction: column;
    > .info-message {
      margin: 0.5rem;
      text-align: center;
      color: red;
    }
    > .passage-filter {
      text-align: center;
      margin: 0.5rem;
      > label {
        user-select: none;
      }
      span {
        font-weight: bold;
        margin-right: 0.5rem;
      }
    }
    > .flex-align {
      margin: 0.25rem 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      > * {
        flex-grow: 1;
        width: 100%;
        margin: 0.2rem;
        justify-content: center;
        align-items: center;
      }
      > *:last-child {
        flex-basis: 0;
        display: flex;
      }

      select {
        width: 100%;
        height: 2rem;
        border: 1px solid #ccc;
        border-radius: 0.2rem;
        padding: 0.2rem;
        cursor: pointer;
        &:hover {
          border-color: #444;
          background-color: #d8d8d8;
        }
      }
      /* Remove svg styles, they work because of font-awesome-icon's implementation details */
      select + div .icon-button > svg {
        height: 1.5rem;
      }
    }
  }
  .icon-button {
    display: flex;
    aspect-ratio: 1;
    border: 1px solid #ccc;
    border-radius: 0.2rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #ddd;
      border-color: #999;
    }
    > svg {
      height: 1rem;
      fill: #444;
    }
    &:hover {
      color: green;
    }
    &.delete:hover {
      color: red;
    }
    &.add:hover {
      color: blue;
    }
  }

  .attribute-key {
    font-weight: bold;
  }

  .text-and-icon-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: bold;
    border: 1px solid #ccc;
    border-radius: 0.2rem;
    cursor: pointer;
    > * {
      margin: 0.3rem;
    }
    > svg {
      height: 1rem;
      aspect-ratio: 1;
      fill: #444;
    }
    &:hover {
      background-color: #ddd;
      border-color: #999;
    }
  }
</style>
