import bibleBooks from '@/common/bible-books-lookup.json';
import { osisToParatext, paratextToOsis } from 'bible-reference-formatter';

export function lookupByOsisAbbrev(osisAbbr) {
  return bibleBooks.find(bibleBook => bibleBook.osisId === osisAbbr);
}

export function lookupByUSFMAbbrev(usfmAbbr) {
  return bibleBooks.find(bibleBook => bibleBook.abbr === usfmAbbr);
}

export function lookupByBookNumber(bookNumber) {
  return bibleBooks.find(bibleBook => bibleBook.ord === bookNumber);
}

export function convertOsisToUsfm(osisRef) {
  return osisToParatext(osisRef);
}

export function convertUsfmToOsis(paratextRef) {
  return paratextToOsis(paratextRef);
}

export function encodeForURI(nonEncodedURI) {
  return nonEncodedURI.replaceAll(' ', '%20');
}

/**
 * @typedef {Object} ParsedPassageReference
 * @property {string|null} book - osis book name
 * @property {string|null} chapter - chapter number
 * @property {string|null} verse - verse number
 * @property {string|null} word - word number
 */

/**
 * @param {string} osisRef
 * @return {ParsedPassageReference}
 */
export function parseOsisRef(osisRef) {
  const osisRefSegments = osisRef.split(/\.|!/);

  return {
    book: osisRefSegments[0],
    chapter: osisRefSegments[1],
    verse: osisRefSegments[2],
    word: osisRefSegments[3],
  };
}

/**
 * @param {string} usfmRef
 * @return {ParsedPassageReference}
 */
export function parseUsfmRef(usfmRef) {
  const usfmRefSegments = usfmRef.split(/\s|:/);

  return {
    book: usfmRefSegments[0],
    chapter: usfmRefSegments[1],
    verse: usfmRefSegments[2],
    word: null,
  };
}
/**
 * @param {string} osisRef
 * @return {'OT'|'NT'}
 */
export function determineTestamentForOsisRef(osisRef) {
  const parsedOsisRef = parseOsisRef(osisRef);
  const bibleBookDatum = lookupByOsisAbbrev(parsedOsisRef.book);
  return bibleBookDatum.testament;
}

/**
 * @param {string} osisRef
 * @return {'rtl'|'ltr'}
 */

export function determineLanguageDirectionForOsisRef(osisRef) {
  const parsedOsisRef = parseOsisRef(osisRef);
  const bibleBookDatum = lookupByOsisAbbrev(parsedOsisRef.book);
  return bibleBookDatum.language_direction ?? 'ltr';
}

export function createOsisWordIdFromUsfm(usfmWordRef) {
  const parts = usfmWordRef.split('!');
  const osisRef = convertUsfmToOsis(parts[0]);
  return `${osisRef}!${parts[1]}`;
}

const chaptersInBook = osisId => {
  const book = lookupByOsisAbbrev(osisId);
  return Math.max(...Object.keys(book.chapters).map(ch => +ch));
};
const versesInChapter = (osisId, chapter) => {
  const book = lookupByOsisAbbrev(osisId);
  return book.chapters[chapter];
};

const normalizeStr = str => str.toLowerCase().replaceAll(/[. ]/g, '');
const abbreviations = Object.fromEntries(
  bibleBooks
    .map(book => {
      return [
        [normalizeStr(book.name), book.osisId],
        ...book.abbreviations.map(abbr => [normalizeStr(abbr), book.osisId]),
      ];
    })
    .flat(),
);
const abbreviationsKeys = Object.keys(abbreviations);
const closestAbbreviationMatch = abbreviation => {
  const normalizedAbbr = normalizeStr(abbreviation);
  if (normalizedAbbr in abbreviations) {
    return abbreviations[normalizedAbbr];
  }

  for (let i = 0; i < abbreviationsKeys.length; i += 1) {
    const abbr = abbreviationsKeys[i];
    if (abbr.startsWith(normalizedAbbr)) {
      return abbreviations[abbr];
    }
  }
  return '';
};
const singleChapterBooks = new Set(
  bibleBooks.filter(book => Object.keys(book.chapters).length === 1).map(book => book.osisId),
);
/*
 * Regex matches verse references in pretty generous formats:
 * It optionally matches a chapter and a verse
 * Book names can begin with numbers but must end with a letter
 */
const referenceMatch = /^(.*?\D)((\d+)\D*(\d+)?)?$/;
/**
 * @param {string} input
 * @returns {string} osis reference or empty string
 */
export function getMostLikelyRefFromFreeInput(input, { respondWithRange = false }) {
  if (!input) {
    return '';
  }
  // If we want to handle lists like "Gen 1, 3, 5", we should split on ",".
  const [rangeStart, rangeEnd] = input.split(/[-–—]/);
  const startMatch = rangeStart.trim().match(referenceMatch);
  if (!startMatch) {
    return '';
  }

  const startBook = startMatch[1].trim();
  let startChapter = startMatch[3];
  let startVerse = startMatch[4];

  const osisId = closestAbbreviationMatch(startBook);
  if (!osisId) {
    return '';
  }

  if (!startVerse && startChapter && singleChapterBooks.has(osisId)) {
    // i.e., what we parsed as chatper is actually verse
    startVerse = startChapter;
    startChapter = 1;
  }

  if (!respondWithRange) {
    return `${osisId}.${startChapter || 1}.${startVerse || 1}`;
  }

  // ---- So we are supposed to return a range ----

  // But we don't have a rangeEnd...
  if (!rangeEnd || !rangeEnd.trim()) {
    let endChapter;
    let endVerse;
    if (!startChapter) {
      startChapter = 1;
      endChapter = chaptersInBook(osisId);
    } else {
      endChapter = startChapter;
    }

    if (!startVerse) {
      startVerse = 1;
      endVerse = versesInChapter(osisId, endChapter);
    } else {
      endVerse = startVerse;
    }

    return {
      start: `${osisId}.${startChapter || 1}.${startVerse || 1}`,
      end: `${osisId}.${endChapter}.${endVerse}`,
    };
  }

  if (!startVerse) {
    startVerse = 1;
  }
  if (!startChapter) {
    startChapter = 1;
  }
  const startRef = `${osisId}.${startChapter}.${startVerse}`;

  // Check whether we have non-digits at the beginning of the rangeEnd
  // If so, we have another book
  const endBookMatch = rangeEnd.trim().match(/^(\D+)/);
  if (endBookMatch) {
    const match2 = rangeEnd.trim().match(referenceMatch);
    if (!match2) {
      return startRef;
    }
    const endBook = match2[1].trim();
    let endChapter = match2[3];
    let endVerse = match2[4];

    const endOsisId = closestAbbreviationMatch(endBook);
    if (!endOsisId) {
      return startRef;
    }

    if (!endVerse && endChapter && singleChapterBooks.has(endOsisId)) {
      // i.e., what we parsed as chatper is actually verse
      endVerse = endChapter;
      endChapter = 1;
    }

    endChapter = endChapter || chaptersInBook(endOsisId);
    endVerse = endVerse || versesInChapter(endOsisId, endChapter);

    const endRef = `${endOsisId}.${endChapter}.${endVerse}`;

    return {
      start: startRef,
      end: endRef,
    };
  }

  const match2 = rangeEnd.trim().match(/^(\d+)\D*(\d+)?$/);
  let endChapter = match2[1];
  let endVerse = match2[2];

  if (!endVerse && endChapter) {
    if (singleChapterBooks.has(osisId)) {
      endVerse = endChapter;
      endChapter = 1;
    } else if (startMatch[4]) {
      // i.e., we had a start verse but no end verse
      // so what we parsed as chatper is actually verse
      endVerse = endChapter;
      endChapter = startChapter;
    }
  }

  endChapter = endChapter || chaptersInBook(osisId);
  endVerse = endVerse || versesInChapter(osisId, endChapter);

  const endRef = `${osisId}.${endChapter}.${endVerse}`;
  return {
    start: startRef,
    end: endRef,
  };
}

export function sortOsisIds(a, b) {
  // Do a quick check for word refs and respond accordingly
  if (a.includes('!') && b.includes('!')) {
    const [aOsisRef, aWordRef] = a.split('!');
    const [bOsisRef, bWordRef] = b.split('!');
    if (aOsisRef === bOsisRef) {
      return aWordRef - bWordRef;
    }
    return sortOsisIds(aOsisRef, bOsisRef);
  }

  const aParsed = parseOsisRef(a);
  const bParsed = parseOsisRef(b);
  if (aParsed.book !== bParsed.book) {
    // get the index of the book in the bibleBooks array
    const aBookIndex = bibleBooks.findIndex(book => book.osisId === aParsed.book);
    const bBookIndex = bibleBooks.findIndex(book => book.osisId === bParsed.book);
    return aBookIndex - bBookIndex;
  }
  // We handle word refs above
  const acvw = aParsed.chapter * 1_000 + aParsed.verse * 1;
  const bcvw = bParsed.chapter * 1_000 + bParsed.verse * 1;

  return acvw - bcvw;
}
export function sortUsfmIds(a, b) {
  const [refA, tokenInVerseA] = a.split('!');
  const [refB, tokenInVerseB] = b.split('!');

  return sortOsisIds(
    convertUsfmToOsis(refA) + (tokenInVerseA ? `!${tokenInVerseA}` : ''),
    convertUsfmToOsis(refB) + (tokenInVerseB ? `!${tokenInVerseB}` : ''),
  );
}
