<template>
  <component class="title" v-if="node?.name === 'title'" :is="node.name" v-bind="node.attributes">
    {{ humanReadableRef(parent.attributes.ref) }}
  </component>
  <template v-if="node?.type === 'text'">{{ onlyTrailingWhitespace(node) }}</template>
  <Milestone
    v-else-if="node?.name === 'milestone'"
    :n="node.attributes?.n"
    :class="{ rtl: isRTL }"
    :verse-ref="paratextToOsis(node.attributes?.ref)"
    :key="`milestone-${paratextToOsis(node.attributes?.ref)}`"
  />
  <component v-else-if="node?.name == 'samekh'" :is="node?.name">{{ textValue(node) }}</component>
  <component v-else-if="node?.name == 'pe'" :is="node?.name">{{ textValue(node) }}</component>
  <word
    v-else-if="node.name == 'w' && hasId(node)"
    :key="maculaId(node)"
    :tokenId="maculaId(node)"
    :highlights="highlights"
    :showGreek="showGreek"
    :showGlosses="showGlosses"
    :selectedGlosses="selectedGlosses"
    :showWordPartConnector="showGlosses"
    @select-token-lemma="selectTokenLemma"
    @select-token-ids="selectTokenIds"
  />
  <component :is="'w'" class="container-w" v-else-if="node.name == 'w' && !hasId(node)"
    ><word
      v-for="child in node.children.filter(child => child.name === 'm')"
      :key="maculaId(child)"
      :tokenId="maculaId(child)"
      :highlights="highlights"
      :showGreek="showGreek"
      :showGlosses="showGlosses"
      :selectedGlosses="selectedGlosses"
      :showWordPartConnector="showGlosses"
      @select-token-lemma="selectTokenLemma"
      @select-token-ids="selectTokenIds"
      ><template v-slot:text-value>{{ textValue(child) || '' }}</template></word
    ></component
  >
  <component
    v-else-if="!skippables.has(node.name)"
    :is="componentKind(node)"
    :class="{ rtl: isRTL }"
    ><TEIElement
      v-for="(child, idx) in node.children"
      :key="`tei-child-${idx}`"
      :highlights="highlights"
      :showGreek="showGreek"
      :showGlosses="showGlosses"
      :selectedGlosses="selectedGlosses"
      :loading="loading"
      @select-token-lemma="selectTokenLemma"
      @select-token-ids="selectTokenIds"
      :tokensByMilestone="tokensByMilestone"
      :node="child"
      :parent="node"
      :osisRef="osisRef"
  /></component>
</template>
<script>
  import formatOsis, { paratextToOsis } from 'bible-reference-formatter';
  import {
    determineLanguageDirectionForOsisRef,
    createOsisWordIdFromUsfm,
  } from '@/common/refUtils';
  import Word from '@/symphony/widgets/Word.vue';
  import { XML_ID } from '@/store/constants';
  import Milestone from './Milestone.vue';

  export default {
    name: 'TEIElement',
    emits: ['selectTokenLemma', 'selectTokenIds'],
    components: { Milestone, Word },
    props: {
      tokensByMilestone: Object,
      node: Object,
      parent: Object,
      highlights: Object,
      loading: Boolean,
      showGreek: Boolean,
      showGlosses: Boolean,
      selectedGlosses: Set,
      osisRef: String,
    },
    computed: {
      skippables() {
        return new Set(['pc']);
      },
      isRTL() {
        const direction = determineLanguageDirectionForOsisRef(this.osisRef);
        return direction === 'rtl';
      },
    },
    methods: {
      maculaId(node) {
        return node.attributes?.[XML_ID];
      },
      hasId(node) {
        return this.maculaId(node);
      },
      createOsisWordIdFromUsfm(ref) {
        return createOsisWordIdFromUsfm(ref);
      },
      namedChildren(node) {
        return node.children?.filter(child => child.name);
      },
      selectTokenLemma(lemma) {
        this.$emit('selectTokenLemma', lemma);
      },
      selectTokenIds(ids) {
        this.$emit('selectTokenIds', ids);
      },
      paratextToOsis(ref) {
        return ref ? paratextToOsis(ref) : '';
      },
      humanReadableRef(ref) {
        if (!ref) {
          return '';
        }
        const osisRef = paratextToOsis(ref);
        return formatOsis('niv-long', osisRef);
      },
      shortMilestone(ref) {
        return ref.split(' ').slice(-1)[0].split(':').slice(-1)[0];
      },
      // TODO: Expose a better interface for this
      componentKind(elem) {
        // return elem.name === 'q' ? 'blockquote' : elem.name;
        return elem.name;
      },
      textValue(node) {
        return (
          node.children
            .filter(child => child.type === 'text')
            .map(textChild => textChild.value)
            .join('') || ''
        );
      },
      onlyTrailingWhitespace(node) {
        // NOTE: This is trying to mimic `.tail` from lxml;
        // we may find a different JavaScript XML parser that
        // treats whitespace differently in the future.
        // We are using this to render trailing whitespace after
        // the paseq (`׀`) character, since the @after attribute
        // in macula-hebrew does not include trailing whitespace.
        return node.value === ' ' ? ' ' : '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .title {
    font-family: 'Gentium Book Plus';
    display: block;
    text-align: center;
  }
  sup.milestone {
    opacity: 50%;
    display: inline;
    position: relative;
    font-size: 50%;
    font-family: 'Gentium Book Plus';
    // NOTE: Prefer top vs text-top
    // due to .gloss
    vertical-align: top;
    margin-inline-end: 0.25rem;
    &:not([n='1']).rtl {
      margin-inline-start: 0.5rem;
    }
  }
  .ltr {
    // TODO: Review this style; does not seem to be applied
    font-family: 'Gentium Book Plus';
    direction: ltr;
    font-size: 14pt;
  }
  verse {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  verse.rtl {
    display: inline;
    line-height: 2;
  }
  p {
    line-height: 1.7;
  }
  samekh,
  pe {
    display: inline;
    margin-inline-start: 0.25em;
  }
  pe:after {
    content: ' ';
    display: block;
  }
  samekh::after {
    content: '\00a0\00a0\00a0\00a0\00a0\00a0';
  }
  q {
    display: block;

    // TODO: Determine desired styles
    // e.g. Matthew 1:23
    font-style: italic;
    margin: 1em 0;

    &:before,
    &:after {
      content: '';
    }
    > lg {
      display: block;
      margin: 1em 0 0 1em;
      font-style: italic;

      l {
        display: block;
      }
      :first-of-type :deep(:first-child .word) {
        &:before {
          content: open-quote;
        }
      }
      :last-of-type :deep(:last-child .word) {
        &:after {
          content: close-quote;
        }
      }
    }
    > :not(lg, l) {
      &:first-child :deep(.word) {
        &:before {
          content: open-quote;
        }
      }
      &:last-child :deep(.word) {
        &:after {
          content: close-quote;
        }
      }
    }
  }
</style>
