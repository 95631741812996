<template>
  <div class="verse-refs">
    <div
      v-for="(verse, verseIdx) in verses"
      :key="verseIdx"
      class="verse-ref"
      :class="{ active: this.isSelectedVerse(verse) }"
      @click.prevent="selectRef(verse)"
    >
      {{ verse }}
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import { DEFAULT_OSIS_REF, DEFAULT_GROUP_KEY } from '../../store/constants';

  const SYMPHONY_REST_API_ENDPOINT =
    process.env.VUE_APP_SYMPHONY_STRUCTURE_REST_API_ENDPOINT ||
    'https://symphony-basex-svc.clearlabs.biblica.com/api';

  export default {
    name: 'VerseRefsWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          label: null,
          globalOsisRef: {
            value: 'osisRef',
            groupKey: DEFAULT_GROUP_KEY,
          },
        },
        outputs: {
          osisRef: DEFAULT_OSIS_REF,
        },
      },
    },
    watch: {
      osisRef: {
        handler() {
          this.fetchData();
        },
      },
    },
    mounted() {
      this.fetchData();
      this.submit();
    },
    data() {
      return {
        verseData: null,
      };
    },
    computed: {
      verses() {
        const verses = this.verseData?.verses || [];
        return verses.map(verse => verse.split('.').slice(-1)[0]);
      },
      chapterRef() {
        return this.osisRef ? this.osisRef.split('.').slice(0, 2).join('.') : undefined;
      },
    },
    methods: {
      fetchData() {
        const endpoint = SYMPHONY_REST_API_ENDPOINT;
        const path = '/GNT/Nestle1904/verses';
        const structureUrl = `${endpoint}${path}?osis-ref=${this.chapterRef}`;
        this.loading = true;
        fetch(structureUrl).then(resp => {
          resp.json().then(data => {
            this.verseData = data;
            this.$nextTick(() => {
              this.loading = false;
            });
          });
        });
      },
      isSelectedVerse(verse) {
        return verse === this.osisRef.split('.').slice(-1)[0];
      },
      selectRef(verse) {
        const newRef = `${this.chapterRef}.${verse}`;
        this.outputs.osisRef.value = newRef;
        this.submit();
      },
    },
  };
</script>

<style scoped lang="scss">
  .verse-refs {
    display: grid;
    grid-template-columns: repeat(auto-fill, 1em) 5%;
    gap: 10px 1em;
  }
  .verse-ref {
    padding: 10px;
    &.active {
      font-weight: bold;
    }
  }
</style>
