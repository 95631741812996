<template>
  <div class="widget-picker">
    <ul>
      <li v-for="widgetKey in sortedWidgets" :key="widgetKey">
        <a class="widget-option" href="#" @click.prevent="pickWidget(widgetKey)">{{
          getFormattedWidgetName(widgetKey)
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  import hydratedWidgets from '@/config/widgets';
  import { camelCaseToCapitalizeEachWord } from '@/common/widgetUtils';

  export default {
    name: 'WidgetPicker',
    props: {
      info: Object,
      columnIndex: Number,
      widgetIndex: Number,
    },
    data() {
      return {
        // FIXME: Move this out to a configuration
        // singleton, rather than modifying Doric
        // framework files
        hydratedWidgets,
      };
    },
    computed: {
      sortedWidgets() {
        return Object.keys(hydratedWidgets).sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase()),
        );
      },
    },
    methods: {
      pickWidget(widgetKey) {
        try {
          const widgetData = hydratedWidgets[widgetKey];
          this.$store.commit('pickWidget', {
            columnIndex: this.columnIndex,
            widgetIndex: this.widgetIndex,
            widgetData: {
              ...widgetData,
              // FIXME: Kebab-case `component` is not actually necessary
              component: widgetKey,
              generation: this.widgetIndex + 1,
            },
          });
        } catch (e) {
          console.error(
            `Error trying to populate widget default values.`,
            `Ensure the widget uses defineProps.doric to define its inputs and outputs (if using Vue's composition API)`,
            `or a "props.doric" value alongside of the data(), computed(), etc. (if using Vue's options API).`,
            e,
          );
        }
      },
      getFormattedWidgetName(widgetKey) {
        return camelCaseToCapitalizeEachWord(widgetKey);
      },
    },
  };
</script>
<style lang="scss" scoped>
  ul > li {
    list-style: none;
    margin-bottom: 0.3rem;
  }
  .widget-option {
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    &:hover {
      background-color: #05afec;
    }
  }
</style>
