import { ref } from 'vue';
import { maculaClient } from '@/apollo';
import { useQuery, provideApolloClient } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { XML_ID } from '@/store/constants';

const useFetchXML = () => {
  const xml = ref(undefined);
  const errorMessage = ref(undefined);
  const isLoading = ref(true);
  const usingXq = ref(false);
  const pageSize = ref(10);
  const apolloClient = ref(maculaClient);

  const handleError = error => {
    isLoading.value = false;
    errorMessage.value = 'Unable to process query';
    throw new Error(`Error returned from the backend: ${error.message}`);
  };
  const updateXML = queryResult => {
    let sentencesXml = '<sentences>';
    queryResult.data.syntaxTrees.forEach(tree => {
      sentencesXml += tree.data;
    });
    // TODO: Refactor how we're passing XML fragments between the frontend and backend
    // and don't need to have kludgey stuff like this sentence wrapper.
    // see https://github.com/Clear-Bible/symphony-frontend/pull/271#discussion_r1389525761
    sentencesXml += '</sentences>';
    const trimmedXml = sentencesXml.replace(/>\s+</g, '><');
    xml.value = trimmedXml;
    isLoading.value = false;
  };
  const fetchXML = async ({
    query,
    fetchPageSize,
    fetchCursor,
    usingDirectId,
    usingXquery,
    textualEdition,
  }) => {
    isLoading.value = true;
    usingXq.value = usingXquery || usingDirectId;
    if (!query) {
      isLoading.value = false;
      throw new Error('No search string provided');
    }
    const idType = usingXq.value ? 'xq' : 'usfm-ref';
    let xqParam = '';
    const pagination = {
      limit: fetchPageSize || pageSize.value,
      offset: fetchCursor,
    };
    let impliedTextualEdition;
    if (usingXq.value) {
      impliedTextualEdition = textualEdition;
      xqParam = query;
      if (usingDirectId) {
        // If user submits an id, then search for whatever node contains the id
        xqParam = `//*[@${XML_ID}="${query}"]`;
      }
    }

    // NOTE: Because the comparison workspace allows users to override the GraphQL endpoint, we pass
    // the `apolloClient` ref into provideApolloClient.
    // Earlier versions of this code tried to override the `useQuery` `context.uri` option
    // (https://v4.apollo.vuejs.org/api/use-query.html#parameters).
    // Overriding the URI per-query worked fine, but Apollo's caching behavior meant that an identical query
    // was cached at the client level.  We explored using the `fetchPolicy` option to further override caching,
    // but found it to be inconsistent.

    // NOTE: For each GraphQL "document" below, `xmlId` is being used to return the "external id" for each sentence.
    // We are avoiding using `id` because the results are not unique (e.g. `//w` returns multiple)
    // hits for the same sentence.
    // From https://graphql.org/learn/schema/:
    // "The ID scalar type represents a unique identifier"
    // If `id` is not unique, Apollo will deduplicate each hit, which breaks the `matches`
    // functionality.

    const sentencesViaXquery = provideApolloClient(apolloClient.value)(() =>
      useQuery(
        gql`
          query SyntaxTrees($filters: SyntaxTreeFilters, $pagination: SyntaxTreePagination) {
            syntaxTrees(filters: $filters, pagination: $pagination) {
              xmlId
              lang
              data
            }
          }
        `,
        {
          // FIXME: explicit textualEdition for filters
          filters: {
            xquery: xqParam,
            textualEdition: impliedTextualEdition,
          },
          pagination,
        },
        () => ({
          enabled: idType === 'xq',
        }),
      ),
    );
    sentencesViaXquery.onResult(queryResult => updateXML(queryResult));
    sentencesViaXquery.onError(handleError);

    // TODO: refactor sentencesViaXquery and sentencesViaUSFMRef further using callables for document, variables and enabled args
    // TODO: Support retrieving from a particular textual edition;
    // this will fallback to the default as specified by the server.
    const sentencesViaUSFMRef = provideApolloClient(apolloClient.value)(() =>
      useQuery(
        gql`
          query SyntaxTrees($filters: SyntaxTreeFilters) {
            syntaxTrees(filters: $filters) {
              xmlId
              lang
              data
            }
          }
        `,
        {
          filters: {
            scriptureReference: { usfmRef: query },
          },
        },
        () => ({
          enabled: idType === 'usfm-ref',
        }),
      ),
    );
    sentencesViaUSFMRef.onResult(queryResult => updateXML(queryResult));
    sentencesViaUSFMRef.onError(handleError);
  };
  return {
    xml,
    isLoading,
    errorMessage,
    usingXq,
    pageSize,
    apolloClient,
    fetchXML,
  };
};
export default useFetchXML;
