<script setup>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import TreeNode from '../components/TreeNode.vue';
  import { buildPElementOfSentence } from '../util';

  const store = useStore();

  // eslint-disable-next-line no-undef
  const props = defineProps({
    sentence: Object,
    showAnalysis: Boolean,
    showWordGroups: Boolean,
    showClauses: Boolean,
    showChildren: Boolean,
    showMilestones: Boolean,
    showRules: Boolean,
    parentAttributes: Object,
    grandParentAttributes: Object,
    languageDirectionIsRTL: Boolean,
    shouldBreakToNewline: Boolean,
    indexInParent: Number,
    usingPlainText: Boolean,
    showEnglish: Boolean,
    showTransliteration: Boolean,
  });

  const emit = defineEmits({ 'select-node': Object });

  const handleSelectNode = value => {
    emit('select-node', value);
  };

  const sentenceIsCollapsed = ref(props?.sentence.sentenceNumber !== 1);
  function handleClickCollapseContainer() {
    sentenceIsCollapsed.value = !sentenceIsCollapsed.value;
  }
  function getMilestoneContent(milestone) {
    let milestoneContent = milestone.children.find(child => child.type === 'text');
    if (!milestoneContent) {
      milestoneContent = milestone.attributes?.ref;
    } else {
      milestoneContent = milestoneContent.value;
    }
    return milestoneContent;
  }
  function getMatchElement() {
    const matchElement = props?.sentence?.children.find(child => child.name === 'match');
    const matchingElementIds = matchElement?.attributes?.ids.split(' ');
    return { matchElement, matchingElementIds };
  }

  function getPElementMatch() {
    const wordObjects = buildPElementOfSentence(props?.sentence)?.textContentArray;
    wordObjects[0].isFirstWord = true;
    wordObjects[wordObjects.length - 1].isLastWord = true;
    const { matchingElementIds } = getMatchElement();
    if (matchingElementIds) {
      const matchingWords = wordObjects?.filter(word => matchingElementIds.includes(word.id));
      const wordPrecedingFirstMatchingWordIndex =
        wordObjects.indexOf(matchingWords[0]) > 0
          ? wordObjects.indexOf(matchingWords[0]) - 1
          : null;
      const wordPrecedingFirstMatchingWord =
        wordObjects[wordPrecedingFirstMatchingWordIndex] ?? null;
      const wordFollowingLastMatchingWordIndex =
        wordObjects.indexOf(matchingWords[matchingWords.length - 1]) < wordObjects.length - 1
          ? wordObjects.indexOf(matchingWords[matchingWords.length - 1]) + 1
          : null;
      const wordFollowingLastMatchingWord = wordObjects[wordFollowingLastMatchingWordIndex] ?? null;
      const results = [
        { ...wordPrecedingFirstMatchingWord, precedingWord: true },
        ...matchingWords.map(word => ({ ...word, isMatching: true })),
        { ...wordFollowingLastMatchingWord, followingWord: true },
      ];
      return results;
    }
    return wordObjects;
  }

  function getMilestoneRange() {
    const { milestones } = buildPElementOfSentence(props?.sentence);
    if (milestones.length > 1) {
      const firstMilestone = milestones[0];
      const lastMilestone = milestones[milestones.length - 1];
      const separator = '\u2014';
      const milestoneRange = `${getMilestoneContent(
        firstMilestone,
      )}${separator}${getMilestoneContent(lastMilestone)}`;
      return milestoneRange;
    }
    if (milestones.length === 1) {
      return getMilestoneContent(milestones[0]);
    }
    return [];
  }
</script>

<template>
  <div class="collapse-container" @click.self="handleClickCollapseContainer">
    <div
      class="sentence-preview-content"
      @click="handleClickCollapseContainer"
      :class="{
        narrow: store?.state?.workspaces[store?.state?.activeWorkspaceIndex]?.columns?.length > 1,
      }"
    >
      <div class="caret-and-milestones-and-text">
        <span
          class="caret"
          :class="[{ closed: sentenceIsCollapsed }, { rtl: languageDirectionIsRTL }]"
          >{{ '\u25BC' }}</span
        >
        <div class="milestones-container">
          <span class="milestone">{{ getMilestoneRange() }}</span>
        </div>
        <span class="sentence-text">
          <span
            v-for="word in getPElementMatch()"
            :key="word?.id"
            class="sentence-preview-content-word"
            :class="[
              { match: word?.isMatching },
              { precedingWord: word?.id && !word?.isFirstWord && word?.precedingWord },
              { followingWord: word?.id && !word?.isLastWord && word?.followingWord },
              { isFirstWord: word?.isFirstWord },
              { isLastWord: word?.isLastWord },
            ]"
          >
            {{ word?.value }}
          </span>
        </span>
      </div>
    </div>
    <div class="collapse-content" :class="{ collapsed: sentenceIsCollapsed }">
      <TreeNode
        v-for="(child, index) in sentence.children.filter(child => child.name === 'wg')"
        :key="index"
        :node="child"
        :showAnalysis="showAnalysis"
        :showWordGroups="showWordGroups"
        :showClauses="showClauses"
        :showChildren="true"
        :showMilestones="showMilestones"
        :showRules="showRules"
        :parentAttributes="{ class: 'root' }"
        :grandParentAttributes="{ class: 'root' }"
        :languageDirectionIsRTL="languageDirectionIsRTL"
        :matchingElementIds="getMatchElement().matchingElementIds"
        :showEnglish="showEnglish"
        :showTransliteration="showTransliteration"
        @select-node="handleSelectNode"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .sentence-preview-content {
    display: flex;
    flex-direction: row;
    font-size: 1em;
    border-bottom: none;
    &.narrow {
      flex-direction: column;
    }
  }
  .caret-and-milestones-and-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5em;
  }
  .sentence-text {
    flex: 1;
    font-size: 0.8em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .milestones-container {
    display: flex;
    flex-direction: column;
    padding-right: 0.5em;
    align-items: center;
    justify-content: space-evenly;
  }
  .collapse-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    max-width: 94%;
    width: 100%;
    padding: 0.5em;
    margin-top: -1px;
    border: 1px solid #ccc;
  }
  .caret {
    /* height: 0em; */
    /* margin-right: -1.5em; */
    align-self: center;
    font-size: 0.5em;
    transition: transform 0.2s ease-in-out;
    color: #ccc;
  }
  .caret.closed {
    transform: rotate(-90deg);
  }
  .caret.closed.rtl {
    transform: rotate(90deg);
  }
  .collapse-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    overflow-x: auto;
    width: 94%;
    padding: 0.5em;
  }
  .collapsed {
    display: none;
  }
  .milestone {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /* position: relative; */
    align-items: center;
    justify-self: space-evenly;
    height: 0.8em;
    width: max-content;
    color: #fefefe;
    font-size: 0.6em;
    font-weight: bold;
    padding: 0.5em;
    border-radius: 1em;
    background-color: #828282;
    margin-right: 0.2em;
    margin-bottom: 0.2em;
    margin: 0 0.4em;
  }
  .sentence-preview-content-word::after {
    content: attr(after);
  }
  .sentence-preview-content-word.match {
    font-weight: bold;
  }
  .sentence-preview-content-word.precedingWord::before {
    content: '...';
  }
  .sentence-preview-content-word.followingWord::after {
    content: '...';
  }
  .sentence-preview-content-word.isFirstWord::before {
    content: '';
  }
  .sentence-preview-content-word.isLastWord::after {
    content: '';
  }

  /** when window is smaller than 600 pixels, make .sentence-preview-content column */
  @media (max-width: 600px) {
    .sentence-preview-content {
      flex-direction: column;
    }
  }
</style>
