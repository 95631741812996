<template>
  <div class="tei-content" :class="{ rtl: isRTL }" :data-macula-direction="isRTL ? 'rtl' : ''">
    <template v-for="(node, nidx) in teiObj?.children" :key="`node-${nidx}`">
      <TEIElement
        v-if="node?.name"
        :key="`tei-e-${nidx}`"
        :highlights="highlights"
        :showGreek="showGreek"
        :showGlosses="showGlosses"
        :selectedGlosses="selectedGlosses"
        :loading="loading"
        @select-token-lemma="selectTokenLemma"
        @select-token-ids="selectTokenIds"
        :tokensByMilestone="tokensByMilestone"
        :osisRef="osisRef"
        :node="node"
      ></TEIElement>
    </template>
  </div>
</template>

<script>
  import { determineLanguageDirectionForOsisRef } from '@/common/refUtils';
  import TEIElement from './TEIElement.vue';

  export default {
    name: 'TEIWrapper',
    props: {
      tokensByMilestone: Object,
      teiObj: Object,
      highlights: Object,
      loading: Boolean,
      showGreek: Boolean,
      showGlosses: Boolean,
      selectedGlosses: Set,
      osisRef: String,
    },
    emits: ['selectTokenLemma', 'selectTokenIds'],
    components: { TEIElement },
    computed: {
      isRTL() {
        const direction = determineLanguageDirectionForOsisRef(this.osisRef);
        return direction === 'rtl';
      },
      isLTR() {
        const direction = determineLanguageDirectionForOsisRef(this.osisRef);
        return direction === 'ltr';
      },
    },
    methods: {
      selectTokenLemma(lemma) {
        this.$emit('selectTokenLemma', lemma);
      },
      selectTokenIds(ids) {
        this.$emit('selectTokenIds', ids);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tei-content.rtl {
    font-family: 'SBLBibLit';
    direction: rtl;
    // TODO: should consolidate typography styles and units app-wide.
    font-size: 1.4rem;
    line-height: 1.7;
  }
</style>
