<template>
  <span
    class="unit"
    :class="{
      'glosses-enabled': showGlosses,
      ...generateHighlightClasses,
    }"
  >
    <span
      class="word"
      :class="{
        'word-value-active': showGreek,
        'matched-word': isHighlightedId,
        'reduced-visual-contrast': tokenShouldBeDisplayedWithReducedVisualContrast,
      }"
      @click.prevent="handleWordClick(token)"
      @mouseenter="emitTokenUserCursorIsHovering({ tokenId })"
      @mouseleave="emitTokenUserCursorIsHovering({ reset: true })"
      @focusIn="emitTokenUserCursorIsHovering({ tokenId })"
      @focusOut="emitTokenUserCursorIsHovering({ reset: true })"
    >
      <slot name="text-value">
        {{ computedTextValueToDisplay }}
      </slot>
    </span>

    <span v-if="showGlosses" class="glosses">
      <slot name="glosses">
        <span class="gloss" :key="key" v-for="[key, value] in Object.entries(visibleGlosses)">
          {{ value || '&nbsp;' }}
        </span>
      </slot>
    </span>

    <div
      v-for="feature in applicableFeatures"
      :style="`border-top-color: #${
        isTokenRelevantToFeature(feature) ? feature.color : 'transparent'
      };`"
      :key="feature.key"
      class="feature-highlight"
    /> </span
  ><span v-if="addSpaceAfter">{{ ' ' }}</span
  ><span
    v-if="showWordPartConnector && hasFollowingToken"
    class="unit"
    :class="{
      'glosses-enabled': showGlosses,
      ...generateHighlightClasses,
    }"
    ><span
      class="word word-part-connector"
      :class="{
        'word-value-active': showGreek,
        'matched-word': isHighlightedId,
        'reduced-visual-contrast': tokenShouldBeDisplayedWithReducedVisualContrast,
      }"
    ></span
    ><span class="glosses">
      <span class="gloss" :key="key" v-for="[key] in Object.entries(visibleGlosses)">
        {{ '&nbsp;' }}
      </span></span
    ></span
  >
</template>

<script>
  // FIXME: Remove fallbacks by refactoring to a Word
  // component that can be used without tokens
  import { features } from '@/symphony/shared/featuresToTokenData';
  import { TOKEN_ID_FIELD } from '@/store/constants';

  export default {
    name: 'Word',
    // FIXME: restore default behavior to inheritAttrs
    // https://vuejs.org/guide/components/attrs.html#attribute-inheritance
    inheritAttrs: false,
    emits: ['hover-token', 'select-token-ids', 'select-token-lemma'],
    props: [
      'tokenShouldBeDisplayedWithReducedVisualContrast',
      'textValueToDisplay',
      'skipSpaceAfter',
      'tokenIsEmpty',
      'tokenId',
      'highlights',
      'showGreek',
      'showGlosses',
      'selectedGlosses',
      'isHighlightedId',
      'glosses',
      'targetTokenId',
      'showWordPartConnector',
    ],
    computed: {
      token() {
        if (!this.tokenId) {
          return undefined;
        }
        const osisId = this.tokenId;

        return osisId && this.$store.getters.getActiveWorkspace.globalTokens.get(osisId);
      },
      hasFollowingToken() {
        const { wordPartIds, id } = this.token;
        if (!wordPartIds) {
          return false;
        }
        return wordPartIds.lastIndexOf(id) !== wordPartIds.length - 1;
      },
      applicableFeatures() {
        if (!this.tokenId) {
          return [];
        }
        return features.filter(feature => {
          return this.highlights[feature.key] ?? false;
        });
      },
      generateHighlightClasses() {
        if (!this.token) {
          return {};
        }
        return {
          // FIXME: Remove this explicit style
          'pos-verb': this.highlights.verbs && this.token.verb,
          'with-features': this.applicableFeatures.length,
        };
      },
      allGlosses() {
        if (this.glosses) {
          return this.glosses;
        }
        return this.token?.gloss || {};
      },
      visibleGlosses() {
        const visible = {};
        this.selectedGlosses?.forEach(key => {
          visible[key] = this.allGlosses[key] ?? undefined;
        });
        return visible;
      },
      computedTextValueToDisplay() {
        return this.textValueToDisplay || this.token?.textValue;
      },
      relevantTokenIds() {
        if (this.targetTokenId) {
          return [this.targetTokenId];
        }
        if (this.token?.wordPartIds) {
          return this.token?.wordPartIds;
        }
        if (this.token?.[TOKEN_ID_FIELD]) {
          return [this.token[TOKEN_ID_FIELD]];
        }
        return null;
      },
      addSpaceAfter() {
        return this.skipSpaceAfter !== undefined
          ? !this.skipSpaceAfter
          : !this.token?.skipSpaceAfter;
      },
    },
    methods: {
      isTokenRelevantToFeature(feature) {
        if (!this.token) {
          return false;
        }
        return this.token[feature.key] ?? false;
      },
      handleWordClick(token) {
        if (token?.lemma) {
          this.$emit('select-token-lemma', token.lemma);
        }
        this.$emit('select-token-ids', this.relevantTokenIds);
      },
      emitTokenUserCursorIsHovering({ tokenId = null, reset = false }) {
        this.$emit('hover-token', { tokenId, reset });
      },
    },
  };
</script>

<style scoped lang="scss">
  .pos-verb {
    background: #def;
  }
  .focus {
    font-weight: 700;
  }
  .feature-highlight {
    border-top: 4px solid transparent;
    margin-top: 2px;
  }
  .glosses {
    font-size: 11pt;
    word-spacing: 0.25em;
    opacity: 0.8;
    // TODO: Per-language support for glosses
    font-family: 'SBLBibLit';
  }
  .gloss {
    line-height: initial;
    display: block;
    margin-inline-end: 0.5rem;
  }
  .word {
    display: none;
    cursor: pointer;
  }
  .word-value {
    display: none;
  }
  .word-value-active {
    display: inline;
  }
  .inactive {
    display: none;
  }
  // FIXME: De-duplicate from :deep(.tei-content)
  // in annotated text widget
  .unit,
  .no-token {
    // TODO: Refactor all units for consistency
    vertical-align: top;
  }
  .unit.glosses-enabled,
  .unit.with-features {
    display: inline-block;
  }
  .word-part-connector {
    &::before {
      content: '- ';
      color: lightgrey;
      margin-left: -0.2em;
    }
  }
  // FIXME: Deduplicate with .matchedUnit .word
  .matched-word {
    background-color: yellow;
    text-shadow: 0px 0px 1px black;
  }

  .reduced-visual-contrast {
    color: grey;
    cursor: default;
  }
</style>
