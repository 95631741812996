<template>
  <div>
    <div
      @mouseup="handleSelectionChange"
      @mousedown="handleTokenMousedown"
      @click="handleTokenClick"
    >
      <TokenProvider :tokens="tokens">
        <LoadingSpinner v-if="$apollo.queries.apolloTokens.loading" />
        <EmptyMessage v-if="!tokens.length">Navigate to a passage to load text</EmptyMessage>
        <template v-else>
          <div :class="languageDirection">
            <Token
              v-for="token in tokens"
              :key="token.tokenId"
              :tokenId="token.tokenId"
              :useGlosses="true"
              ref="tokens"
            />
          </div>
        </template>
      </TokenProvider>
    </div>
    <div class="annotator-tools">
      <label>
        <input type="checkbox" v-model="highlightActiveTokens" />
        <span>Highlights active tokens</span>
      </label>
      <label>
        <input type="checkbox" v-model="followActiveTokens" />
        <span>Follow active tokens</span>
      </label>
      <label>
        <input type="checkbox" v-model="showGlosses" />
        <span>Show glosses</span>
      </label>
      <button @click="handleDeselect" :disabled="localSelectedTokenIds.length === 0">
        Deselect
      </button>
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import {
    DEFAULT_GROUP_KEY,
    TOKEN_ID_FIELD,
    APOLLO_MAX_TOKENS,
    MACULA_DEFAULT_GLOSS,
  } from '@/store/constants';
  import mixins from '@/mixins';
  import { convertOsisToUsfm, determineLanguageDirectionForOsisRef } from '@/common/refUtils';
  import Token from './tokens/Token.vue';
  import TokenProvider from './tokens/TokenProvider.vue';
  import LoadingSpinner from './components/LoadingSpinner.vue';
  import EmptyMessage from './components/EmptyMessage.vue';

  const colors = ['orange', 'green', 'blue', 'gray', 'black'];
  const colorMap = {
    'https://github.com/discoursegrammar/discourse-analysis-annotations/decision-word': '#6366f1',
    'https://github.com/discoursegrammar/discourse-analysis-annotations/emotive-content': '#ef4444',
  };
  const getColorForFeature = featureUri => {
    // TODO: Allow user to configure colors for features
    // Convert chars in the uri to numbers and sum them
    // This is a dumb way of assigning colors to features,
    // but it's deterministic and works well enough for now.
    if (colorMap[featureUri]) {
      return colorMap[featureUri];
    }

    const uriValue = featureUri
      .split('')
      .map(c => c.charCodeAt(0))
      .reduce((a, v) => a + v);
    colorMap[featureUri] = colors[uriValue % colors.length];
    return colorMap[featureUri];
  };

  export default {
    name: 'TextAnnotatorWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    components: {
      Token,
      TokenProvider,
      LoadingSpinner,
      EmptyMessage,
    },
    props: {
      doric: {
        inputs: {
          // The only time TAW cares about osisRef is when osisRefRange is falsy
          osisRef: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'osisRef',
          },
          osisRefRange: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'osisRefRange',
          },
          hoveredUri: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'hoveredUri',
          },
          selectedTokenIds: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'selectedTokenIds',
          },
        },
        outputs: {
          selectedTokenIds: null,
          selectedLemma: null,
          selectedTokenRef: null,
        },
      },
    },
    data: () => ({
      lastMousedownTokenId: null,
      localSelectedTokenIds: [],
      highlightActiveTokens: true,
      followActiveTokens: true,
      showGlosses: false,
      triggerReset: false,
    }),
    computed: {
      parsedOsisRefRange() {
        if (!this.osisRefRange && this.osisRef) {
          return {
            start: this.osisRef,
            end: this.osisRef,
          };
        }
        try {
          return JSON.parse(this.osisRefRange);
        } catch (e) {
          return {};
        }
      },
      activeTokenIds() {
        if (!this.highlightsByHoveredUri?.tokens) {
          return [];
        }
        if (this.highlightsByHoveredUri.tokens.length > 0) {
          return this.highlightsByHoveredUri.tokens.map(t => t[TOKEN_ID_FIELD]);
        }
        if (this.highlightsByHoveredUri.impliedTokens.length > 0) {
          return this.highlightsByHoveredUri.impliedTokens.map(t => t[TOKEN_ID_FIELD]);
        }
        return [];
      },
      underlinedTokens() {
        if (!this.highlightsByHoveredUri?.linkedBy) {
          return new Map([]);
        }
        const highlightMap = new Map([]);

        this.highlightsByHoveredUri.linkedBy.forEach(link => {
          const { feature, tokens } = link;
          const colorByFeature = getColorForFeature(feature.uri);
          tokens.forEach(token => {
            const id = token[TOKEN_ID_FIELD];
            const existing = highlightMap.get(id) || [];
            highlightMap.set(id, [...existing, colorByFeature]);
          });
        });
        return highlightMap;
      },
      tokens() {
        if (this.triggerReset) {
          return [];
        }

        const getTokenHighlight = tokenId => {
          if (!this.highlightActiveTokens) return undefined;
          return this.activeTokenIds.includes(tokenId) ? '#fef08a' : undefined;
        };
        const getTokenFeatureUnderlines = tokenId => {
          if (!this.highlightActiveTokens) return undefined;
          // To get the feature underlines to have a min of zero rows, we could return `|| []`
          return this.underlinedTokens.get(tokenId) || [null];
        };

        return !this.apolloTokens
          ? []
          : this.apolloTokens.map(t => ({
              tokenId: t.tokenId,
              ...t,
              featureUnderlines: getTokenFeatureUnderlines(t.tokenId),
              backgroundColor: getTokenHighlight(t.tokenId),
              majorUnderline: this.localSelectedTokenIds.includes(t.tokenId)
                ? '#7dd3fc'
                : undefined,
              altTexts: this.showGlosses ? [t.data.gloss] : [],
            }));
      },
      languageDirection() {
        return determineLanguageDirectionForOsisRef(this.parsedOsisRefRange.start);
      },
    },
    apollo: {
      apolloTokens: {
        query: gql`
          query FeaturesAndTokens($reference: String!) {
            tokens: wordTokens(
              filters: { scriptureReference: { usfmRef: $reference } }
              pagination: { limit: ${APOLLO_MAX_TOKENS} }
            ) {
              ${TOKEN_ID_FIELD}
              ref
              lemma
              value
              skipSpaceAfter
              data
              idx
            }
          }
        `,
        variables() {
          const start = convertOsisToUsfm(this.parsedOsisRefRange.start);
          const end = convertOsisToUsfm(this.parsedOsisRefRange.end);
          return {
            reference: `${start}-${end}`,
          };
        },
        skip() {
          if (!this.parsedOsisRefRange?.start || !this.parsedOsisRefRange?.end) {
            return true;
          }
          try {
            convertOsisToUsfm(this.parsedOsisRefRange.start);
            convertOsisToUsfm(this.parsedOsisRefRange.end);
            return false;
          } catch (e) {
            return true;
          }
        },
        update(data) {
          const getVerseNumberForFirstWordInVerse = token => {
            // eslint-disable-next-line no-unused-vars
            const [bch, v, w] = token.ref.split(/[:!]/);
            return token.idx === 0 ? v : null;
          };
          // Massage result into TokenProvider format
          return data.tokens.map(t => ({
            tokenId: t[TOKEN_ID_FIELD],
            data: {
              verseNumber: getVerseNumberForFirstWordInVerse(t),
              ref: t.ref,
              lemma: t.lemma,
              value: t.value,
              pos: t.data.class,
              gloss: t.data[MACULA_DEFAULT_GLOSS],
              skipSpaceAfter: t.skipSpaceAfter,
            },
          }));
        },
        watchLoading(isLoading) {
          if (isLoading === false) {
            this.triggerReset = true;
            this.$nextTick(() => {
              this.triggerReset = false;
            });
          }
        },
      },
      highlightsByHoveredUri: {
        query: gql`
          query ($uri: String!) {
            annotations(filters: { uri: { exact: $uri } }) {
              tokens {
                ${TOKEN_ID_FIELD}
              }
              impliedTokens {
                ${TOKEN_ID_FIELD}
              }
              linkedBy {
                feature {
                  uri
                }
                tokens {
                  ${TOKEN_ID_FIELD}
                }
              }
            }
          }
        `,
        variables() {
          return {
            uri: this.hoveredUri,
          };
        },
        skip() {
          return !this.hoveredUri;
        },
        update(data) {
          if (data.annotations?.length !== 1) {
            return [];
          }
          return data.annotations[0];
        },
      },
    },
    watch: {
      localSelectedTokenIds: {
        handler() {
          this.outputs.selectedTokenIds.value = JSON.stringify(this.localSelectedTokenIds);
          this.submit();
        },
        immediate: true,
      },
      selectedTokenIds() {
        try {
          this.localSelectedTokenIds = JSON.parse(this.selectedTokenIds);
        } catch (e) {
          console.warn('Unable to parse selectedTokenIds');
        }
      },
      activeTokenIds() {
        // Center the first active token in viewport
        if (!this.followActiveTokens) {
          return;
        }
        const firstActiveToken = this.activeTokenIds[0];
        // we findIndex in apolloTokens because this.tokens also gets updated by activeTokenIds
        const indexOfFirstActiveToken = this.apolloTokens.findIndex(
          t => t.tokenId === firstActiveToken,
        );
        if (!firstActiveToken) {
          return;
        }
        const $token = this?.$refs?.tokens?.[indexOfFirstActiveToken]?.$refs?.tokenDiv;
        if (!$token) {
          return;
        }
        $token.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      },
    },
    methods: {
      handleTokenMousedown(event) {
        if (event.shiftKey) {
          // Don't update last token if we're about to use it
          return;
        }
        const tokenContainer = event.target.closest('.token-container');
        if (!tokenContainer) {
          return;
        }
        const tokenId = tokenContainer.dataset?.tokenId;
        if (!tokenId) {
          return;
        }
        this.lastMousedownTokenId = tokenId;
      },
      handleTokenClick(event) {
        const tokenContainer = event.target.closest('.token-container');
        if (!tokenContainer) {
          return;
        }
        const tokenId = tokenContainer.dataset?.tokenId;
        if (!tokenId) {
          return;
        }

        // Do word lookup stuff
        const token = this.tokens.find(t => t.tokenId === tokenId);
        this.outputs.selectedLemma.value = token.data.lemma;
        this.outputs.selectedTokenRef.value = token.data.ref;
        this.submit();

        // Do selection stuff
        if (event.shiftKey) {
          // Shift + click = select all tokens between last clicked token and this token
          const getIndexByTokenId = id => this.tokens.findIndex(t => t.tokenId === id);
          const lastClickedTokenIndex = getIndexByTokenId(this.lastMousedownTokenId);
          const thisClickedTokenIndex = getIndexByTokenId(tokenId);
          const [start, end] =
            lastClickedTokenIndex < thisClickedTokenIndex
              ? [lastClickedTokenIndex, thisClickedTokenIndex]
              : [thisClickedTokenIndex, lastClickedTokenIndex];
          this.localSelectedTokenIds = this.tokens.slice(start, end + 1).map(t => t.tokenId);
        } else if (event.ctrlKey || event.metaKey) {
          // Modifier + click = toggle selection
          this.localSelectedTokenIds = this.localSelectedTokenIds.includes(tokenId)
            ? this.localSelectedTokenIds.filter(t => t !== tokenId)
            : [...this.localSelectedTokenIds, tokenId];
        } else {
          // Click = select only this token unless it's already selected, in which case deselect it
          this.localSelectedTokenIds =
            this.localSelectedTokenIds.length === 1 && this.localSelectedTokenIds[0] === tokenId
              ? []
              : [tokenId];
        }
      },
      handleSelectionChange(event) {
        const selection = window.getSelection();
        if (!selection.toString().length) {
          // No selection
          const tokenContainer = event.target.closest('.token-container');
          const tokenId = tokenContainer?.dataset?.tokenId;
          if (!tokenId) {
            this.localSelectedTokenIds = [];
          }
          selection.removeAllRanges();
          return;
        }
        const selectedTokens = selection
          .getRangeAt(0)
          .cloneContents()
          .querySelectorAll('.token-container');

        if (event.ctrlKey || event.metaKey) {
          // Modifier + click = add to selection
          this.localSelectedTokenIds = [
            ...this.localSelectedTokenIds,
            ...Array.from(selectedTokens)
              .map(t => t.dataset.tokenId)
              .filter(t => !this.localSelectedTokenIds.includes(t)),
          ];
        } else {
          this.localSelectedTokenIds = Array.from(selectedTokens).map(t => t.dataset.tokenId);
        }
        selection.removeAllRanges();
      },
      handleDeselect() {
        this.localSelectedTokenIds = [];
      },
    },
  };
</script>

<style scoped>
  .annotator-tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    background-color: rgba(247, 247, 247, 0.9);
    z-index: 1;
    padding: 0.5rem;
    margin-top: 0.5rem;
    /* shadow above the box */
    box-shadow: 0px -5px 5px -5px rgba(0, 0, 0, 0.2);
  }
  :deep(.token-container) {
    scroll-margin-top: 3rem;
    scroll-margin-bottom: 7rem;
  }

  label {
    user-select: none;
    cursor: pointer;
  }

  .rtl {
    font-family: 'SBLBibLit';
    direction: rtl;
    font-size: 1.4rem;
    line-height: 1.7;
    &:deep(.verse):not([n='1']) {
      margin-inline-end: 0;
      margin-inline-start: 0.75rem;
    }
    &:deep(.token-container > .gloss) {
      margin-top: -0.5rem;
      font-size: 0.5em;
    }
  }

  .ltr {
    font-family: 'Gentium Book Plus';
    direction: ltr;
    font-size: 14pt;
  }
</style>
