<template>
  <div>
    <LoadingSpinner v-if="$apollo.loading" />
    <template v-if="!currentAnnotationSetUri">
      <EmptyMessage>No annotation set selected</EmptyMessage>
    </template>
    <template v-else>
      <template v-if="orderedFeatures?.length > 0">
        <SlickList axis="y" v-model:list="orderedFeatures">
          <SlickItem v-for="(feature, i) in orderedFeatures" :key="feature.uri" :index="i">
            <div class="feature-item">
              <input
                type="checkbox"
                @change="setFeatureVisibility(feature, !feature.visible)"
                :checked="feature.visible"
              />
              <span>
                {{ feature.label }}
              </span>
            </div>
          </SlickItem>
        </SlickList>
      </template>
      <EmptyMessage v-else
        >No features found for annotation set
        {{ currentAnnotationSetUri }}
      </EmptyMessage>
    </template>
  </div>
</template>

<script>
  import { gql } from 'graphql-tag';
  import mixins from '@/mixins';
  import {
    DEFAULT_GROUP_KEY,
    APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
    APOLLO_QUERY_FOR_ANNOTATION_FEATURES,
  } from '@/store/constants';
  import { SlickList, SlickItem } from 'vue-slicksort';
  import {
    mergeOrderedFeatures,
    getOrderedFeaturesForAnnotationSet,
    updateOrderedFeaturesForAnnotationSet,
  } from '@/common/featureListLocalstorage';
  import LoadingSpinner from '../components/LoadingSpinner.vue';
  import EmptyMessage from '../components/EmptyMessage.vue';

  export default {
    name: 'FeatureListConfiguratorWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    components: {
      LoadingSpinner,
      EmptyMessage,
      SlickList,
      SlickItem,
    },
    props: {
      doric: {
        inputs: {
          currentAnnotationSetUri: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'currentAnnotationSetUri',
          },
        },
      },
    },
    data: () => {
      return { orderedFeatures: [], drag: false };
    },
    watch: {
      annotationFeatures: {
        handler() {
          const configOrderedFeatures = getOrderedFeaturesForAnnotationSet(
            this.currentAnnotationSetUri,
          );
          this.setOrderedFeatures(
            mergeOrderedFeatures(configOrderedFeatures, this.annotationFeatures),
          );
        },
        immediate: false,
      },
      orderedFeatures: {
        handler(newValue, oldValue) {
          if (oldValue.length !== newValue.length) {
            return;
          }
          this.setOrderedFeatures(newValue);
        },
      },
    },
    apollo: {
      annotationFeatures: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_FEATURES}($annotationSet: String!) {
            annotationFeatures(filters: { annotationSet: { uri: { exact: $annotationSet } } }) {
              uri
              label
            }
          }
        `,
        fetchPolicy: APOLLO_FETCH_POLICY_CACHE_AND_NETWORK,
        skip() {
          return !this.currentAnnotationSetUri;
        },
        variables() {
          return { annotationSet: this.currentAnnotationSetUri };
        },
        update(data) {
          return data.annotationFeatures;
        },
      },
    },
    methods: {
      setFeatureVisibility(feature, visible) {
        this.setOrderedFeatures(
          this.orderedFeatures.map(f => {
            if (f.uri === feature.uri) {
              return { ...f, visible };
            }
            return f;
          }),
        );
      },
      setOrderedFeatures(orderedFeatures) {
        this.orderedFeatures = orderedFeatures;
        updateOrderedFeaturesForAnnotationSet(this.currentAnnotationSetUri, orderedFeatures);
      },
    },
  };
</script>

<style scoped lang="scss">
  .feature-item {
    user-select: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.6rem;
    padding: 0.2rem 0.2rem;
    display: flex;
    align-items: center;
    cursor: grab;

    > span {
      margin-left: 0.5rem;
    }
  }
</style>
