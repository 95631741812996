/* eslint-disable import/prefer-default-export */
import { visit, SKIP, CONTINUE } from 'unist-util-visit';
import { XML_ID } from '@/store/constants';

export function parseWordId(id) {
  // node.attributes?.n is the id, an example value is '01001001001' first digit is the subcorpus identifying character, next two digits ('01') are the book, next three ('001') are the chapter, next three ('001') are the verse, next three ('001') are the word, next one is either a subword ([0-9]) or a ה
  if (!id) {
    return null;
  }
  const subcorpusId = id.substring(0, 1);
  const bookId = parseInt(id.substring(1, 3), 10);
  const chapterId = parseInt(id.substring(3, 6), 10);
  const verseId = parseInt(id.substring(6, 9), 10);
  const wordchars = id.substring(9);
  const wordchar1 = wordchars.substring(0, 1);
  const wordchar2 = wordchars.substring(1, 2);
  const wordchar3 = wordchars.substring(2, 3);
  const wordchar4 = wordchars.substring(3, 4) || '0';
  const wordId = parseInt(wordchar1 + wordchar2 + wordchar3 + wordchar4, 10);
  const verseWordId = verseId * 1000 + wordId;
  const bookChapterVerseWordId =
    bookId * 1000000000 + chapterId * 1000000 + verseId * 1000 + wordId;
  return {
    subcorpusId,
    bookId,
    chapterId,
    verseId,
    wordId,
    verseWordId,
    bookChapterVerseWordId,
  };
}

export function parseNodeId(nodeId) {
  // NOTE: Deprecated and unused, but useful reference for parsing @n ids
  const nodeToSplit = nodeId?.includes('!') ? nodeId.split('!')[0] : nodeId;
  const book = parseInt(nodeToSplit?.substring(0, 2), 10);
  const chapter = parseInt(nodeToSplit?.substring(2, 5), 10);
  const verse = parseInt(nodeToSplit?.substring(5, 8), 10);
  const word = parseInt(nodeToSplit?.substring(8, 11), 10);
  const range = parseInt(nodeToSplit?.substring(11, 14), 10);
  const level = parseInt(nodeToSplit?.substring(14, 15), 10);
  return {
    book,
    chapter,
    verse,
    word,
    range,
    level,
  };
}

export function getHotkeysFromLabels(syntaxTypes) {
  const labels = [...syntaxTypes.classes, ...syntaxTypes.roles];
  const hotkeys = []; // type: [{label: String, key: String}]
  labels.forEach(label => {
    if (!hotkeys.find(hotkey => hotkey.label === label)) {
      const letters = label.split('');
      let keyNotInHotkeys = letters.find(letter => !hotkeys.find(hotkey => hotkey.key === letter));
      if (!keyNotInHotkeys) {
        // if all the letters in the label are taken, use an unused integer from range 0 to 9
        const unusedInts = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].filter(
          int => !hotkeys.find(hotkey => hotkey.key === int),
        );
        [keyNotInHotkeys] = unusedInts;
      }
      hotkeys.push({
        label,
        key: keyNotInHotkeys,
        unit: syntaxTypes.classes.includes(label) ? 'class' : 'role',
      });
    }
  });
  return hotkeys;
}

export function copyToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
}

export function buildPElementOfSentence(sentence) {
  const textContentArrayUnfiltered = [];
  const milestones = [];
  visit(sentence, null, childNode => {
    if (childNode.name !== 'p') {
      if (childNode?.name === 'milestone') {
        milestones.push(childNode);
        return SKIP;
      }
      if (childNode?.name === 'w') {
        if (childNode.attributes?.after?.length > 0) {
          const textToAdd =
            childNode?.children &&
            // eslint-disable-next-line no-unsafe-optional-chaining
            childNode.children[0]?.value + childNode.attributes.after;
          const textNode = {
            value: textToAdd,
            id: childNode.attributes?.[XML_ID],
          };
          textContentArrayUnfiltered.push(textNode);
          return SKIP;
        }
        const textNode = {
          value: childNode?.children && childNode.children[0]?.value,
          // TODO: Determine if we actually need the :after css and
          // passing this attr
          after: childNode?.attributes?.after,
          id: childNode.attributes?.[XML_ID],
        };
        textContentArrayUnfiltered.push(textNode);
        return CONTINUE;
      }
    }
    return CONTINUE;
  });
  // const textContent = textContentArray.join(' ');
  const textContentArray = textContentArrayUnfiltered.filter(
    node => typeof node.id !== 'undefined' && typeof node.value !== 'undefined',
  );
  return { textContentArray, milestones };
}

export function buildTextContentOfNode(node) {
  const textContentArray = [];
  visit(
    node,
    childNode => childNode.type === 'text',
    (childNode, _index, parent) => {
      if (parent.name !== 'p') {
        if (parent.name === 'milestone') {
          return SKIP;
        }
        if (parent.attributes?.after?.length > 0) {
          const textToAdd = childNode.value + parent.attributes.after;
          textContentArray.push(textToAdd);
          return CONTINUE;
        }
        textContentArray.push(childNode.value);
        return CONTINUE;
      }
      return CONTINUE;
    },
  );
  const textContent = textContentArray.join(' ');
  return textContent;
}

export function isSentenceRTL(sentence) {
  if (!sentence) {
    return false;
  }
  // TODO: Get this from a proper lang attr
  const isRTL = sentence.attributes.externalId.slice(0, 1) === 'o';
  return isRTL;
}
