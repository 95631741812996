<template>
  <!-- FIXME: Named constants at config level for these attrs -->
  <div class="text-structure">
    <div v-for="key in visibleStructures" :key="key">
      <span :class="{ active: currentSelection === key }" @click.prevent="setStructure(key)">
        {{ getLabel(key) }}
      </span>
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
  import {
    DEFAULT_GROUP_KEY,
    TEXT_STRUCTURE_KEY_FOR_VERSES,
    TEXT_STRUCTURE_KEY_FOR_LEVINSOHN_PROPOSITIONS,
    TEXT_STRUCTURE_LABELS,
  } from '@/store/constants';

  import { determineTestamentForOsisRef } from '@/common/refUtils';

  /*
   * Note: To add text structures:
   * 1. Add the structure constant to `src/store/constants.js`.
   * 2. Map the structure to a label in the `TEXT_STRUCTURE_LABELS` constant (also in `src/store/constants.js`).
   * 3. Map the structure to a component using `supportedStructures` in `AnnotatedTextWidget.vue`.
   */

  export default {
    name: 'TextStructureWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    mounted() {
      this.currentSelection = this.structure;
    },
    data() {
      return {
        currentSelection: this.structure,
      };
    },
    props: {
      doric: {
        inputs: {
          osisRef: {
            // groupKey: { type: String, default: 'default' },
            groupKey: DEFAULT_GROUP_KEY,
            // value: { type: String, default: 'osisRef' },
            value: null,
          },
          structure: {
            groupKey: DEFAULT_GROUP_KEY,
            value: null,
          },
        },
        outputs: {
          structure: TEXT_STRUCTURE_KEY_FOR_VERSES,
        },
      },
    },
    watch: {
      structure: {
        handler(newValue) {
          this.currentSelection = newValue;
        },
      },
      osisRef: {
        handler(newOsisRef) {
          if (
            determineTestamentForOsisRef(newOsisRef) === 'OT' &&
            this.outputs.structure.value === TEXT_STRUCTURE_KEY_FOR_LEVINSOHN_PROPOSITIONS
          ) {
            this.setStructure(TEXT_STRUCTURE_KEY_FOR_VERSES);
          }
        },
      },
    },
    methods: {
      setStructure(newStructure) {
        this.currentSelection = newStructure;
        this.outputs.structure.value = newStructure;
        this.submit();
      },
      getLabel(key) {
        return key in TEXT_STRUCTURE_LABELS ? TEXT_STRUCTURE_LABELS[key] : key;
      },
    },
    computed: {
      visibleStructures() {
        // If osisRef is OT, we don't show TEXT_STRUCTURE_KEY_FOR_LEVINSOHN_PROPOSITIONS
        return determineTestamentForOsisRef(this.osisRef) === 'OT'
          ? Object.keys(TEXT_STRUCTURE_LABELS).filter(
              k => k !== TEXT_STRUCTURE_KEY_FOR_LEVINSOHN_PROPOSITIONS,
            )
          : Object.keys(TEXT_STRUCTURE_LABELS);
      },
    },
  };
</script>

<style scoped lang="scss">
  span {
    user-select: none;
    cursor: pointer;

    &:hover {
      color: #4d4ddd;
    }

    &.active {
      font-weight: bold;

      &:hover {
        color: #1f1fbe;
      }
    }
  }
</style>
