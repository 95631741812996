<template>
  <div class="empty-message">
    <slot>No content available</slot>
  </div>
</template>

<style lang="scss" scoped>
  .empty-message {
    color: #999;
    font-style: italic;
  }
</style>
