<template>
  <div id="app-container">
    <div id="app-header">
      <span class="workspace-title">{{ siteName }}</span>
      <div
        v-if="!workspaceIsReadOnly"
        class="control configure"
        :class="{ configureMode }"
        @click.prevent="toggleConfigureMode()"
      >
        <font-awesome-icon icon="fa-solid fa-cog" />
      </div>
      <select v-if="workspaceOptions.length > 1" v-model="activeWorkspaceId">
        <option v-for="option in workspaceOptions" :key="option.value" :value="option.value">
          {{ option.text }}
          <span v-if="option.readOnly" class="read-only-tag"> * read only</span>
        </option>
      </select>
    </div>
    <workspace-configuration
      class="workspace-configuration"
      v-if="configureMode"
      @close="toggleConfigureMode()"
    />
    <div class="columns" v-show="!configureMode">
      <Column
        v-for="(columnInfo, columnIndex) in columns"
        :info="columnInfo"
        :key="columnKey(columnInfo.generation)"
        :columnIndex="columnIndex"
      />
      <div class="controls" v-show="!workspaceIsReadOnly">
        <div class="add" @click.prevent="addColumn()">+</div>
      </div>
    </div>
  </div>
</template>

<script>
  import Column from '@/doric/components/Column.vue';
  import { DEFAULT_WORKSPACE_ID, OUTPUTS_TO_PUSH_TO_URL } from '@/store/constants';
  import WorkspaceConfiguration from '@/doric/components/WorkspaceConfiguration.vue';
  import workspaces from '@/config/workspaces';

  export default {
    name: 'App',
    components: {
      Column,
      WorkspaceConfiguration,
    },
    data() {
      return {
        configureMode: false,
      };
    },
    // TODO: Namespacing / partitioning Doric framework-level
    // state management from larger application state.
    // May also revisit with https://pinia.vuejs.org
    computed: {
      columns() {
        return this.activeWorkspace ? this.activeWorkspace.columns : [];
      },
      workspaceOptions() {
        return this.$store.state.workspaces.map(workspace => ({
          value: workspace.workspaceId,
          text: workspace.workspaceName,
          readOnly: workspace.readOnly,
        }));
      },
      activeWorkspace() {
        return this.$store.getters.getActiveWorkspace;
      },
      activeWorkspaceId: {
        get() {
          const value = this.$store.getters.getActiveWorkspaceId;
          return value;
        },
        async set(newValue) {
          const isInitial = !this.activeWorkspaceId;
          const payload = {
            workspaceId: newValue,
            initial: {},
          };
          const query = {
            ...this.$route.query,
          };
          if (isInitial) {
            Object.keys(this.$route.query)
              .filter(key => OUTPUTS_TO_PUSH_TO_URL.includes(key))
              .forEach(key => {
                const value = this.$route.query[key];
                // If isInitial, we only push values that are not falsy
                // This handles urls that have empty values for some query params
                // e.g., /?osisRef&selectedLemma=
                if (value) {
                  payload.initial[key] = value;
                }
              });
          }
          const nextPayload = isInitial ? query : {};
          await this.$router.push({
            query: {
              ...nextPayload,
              workspace: newValue,
            },
          });
          this.$store.commit('setActiveWorkspace', payload);
        },
      },
      siteName() {
        return this.$store.state.siteName;
      },
      workspaceIsReadOnly() {
        return this.activeWorkspace ? this.activeWorkspace.readOnly : false;
      },
    },
    methods: {
      addColumn() {
        this.$store.commit('addColumn');
      },
      columnKey(columnGeneration) {
        return `${this.activeWorkspaceId}.${columnGeneration}`;
      },
      toggleConfigureMode() {
        this.configureMode = !this.configureMode;
      },
    },
    async mounted() {
      await this.$router.isReady();
      const validWorkspaceIds = new Set(workspaces.map(workspace => workspace.workspaceId));

      if (validWorkspaceIds.has(this.$route.query.workspace)) {
        this.activeWorkspaceId = this.$route.query.workspace;
      } else if (validWorkspaceIds.has(DEFAULT_WORKSPACE_ID)) {
        this.activeWorkspaceId = DEFAULT_WORKSPACE_ID;
      } else {
        this.activeWorkspaceId = workspaces[0].workspaceId;
      }
    },
  };
</script>
<style lang="scss">
  /* FONT IMPORTS */
  @import url('https://fonts.googleapis.com/css?family=Gentium+Book+Plus');

  @font-face {
    font-family: 'SBLBibLit';
    src: local('SBLBibLit'), url(../public/fonts/SBLBibLit/SBLBibLit.woff);
  }
  /* END FONT IMPORTS */

  // TODO: Audit styles for proper scoping
  body {
    margin: 0;
  }
  #app {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif;
    background: #bbb;
  }

  #app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .columns {
    display: flex;
    overflow-y: hidden;
    flex-grow: 1;
    gap: 1px;
    > .controls > .add {
      padding: 10px;
      font-size: 20px;
      line-height: 16px;
      &:hover {
        background: green;
        color: white;
        cursor: pointer;
      }
    }
  }
  #app-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #333;
    color: #ddd;
    padding: 10px;
    & > select {
      /** TODO: style select dropdown */
      background: #666;
      color: #ddd;
      padding: 10px;
    }
    > .control {
      color: #999;
      &:hover {
        color: yellow;
        cursor: pointer;
      }
    }
    > .control.configureMode {
      color: yellow;
      /* spin the cog */
      animation: spin 200ms linear;
    }
  }
  .read-only-tag {
    color: red;
    border: 1px solid red;
    border-radius: 5px;
    padding: 0 5px;
    margin-left: 5px;
    background-color: rgba(255, 0, 0, 0.4);
  }
  .workspace-configuration {
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(45deg);
    }
  }
</style>
