<script setup>
  import { useStore } from 'vuex';
  import { DEFAULT_GROUP_KEY } from '@/store/constants';

  const store = useStore();

  defineProps({
    doric: {
      component: 'json-widget',
      inputs: {
        label: null,
        json: {
          groupKey: DEFAULT_GROUP_KEY,
          value: 'json',
        },
      },
    },
  });
</script>

<template>
  <div>
    <p v-show="!store?.state?.globalFields?.json">
      Select something to view its JSON representation
    </p>
    <pre v-show="store?.state?.workspaces[store?.state?.activeWorkspaceIndex].globalFields?.json">
      <code>{{JSON.stringify(store?.state?.workspaces[store?.state?.activeWorkspaceIndex].globalFields?.json, null, 2)}}</code>
    </pre>
  </div>
</template>

<style scoped lang="scss">
  code {
    display: block;
    white-space: pre;
    margin: 1em 0;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
</style>
