<script setup>
  import { defineProps, defineEmits, ref } from 'vue';

  // eslint-disable-next-line no-unused-vars
  const props = defineProps({
    hotkeys: Array,
  });
  const emit = defineEmits({
    assignLabel: String,
    newLabel: String,
  });

  function handleClick(event, hotkey) {
    // eslint-disable-next-line no-undef
    emit('assign-label', hotkey.label);
  }

  const labelInput = ref('');
  function submitLabel() {
    emit('new-label', labelInput.value);
    labelInput.value = '';
  }
</script>

<template>
  <div id="legend-container">
    <div class="legend-category">
      Roles
      <input
        type="text"
        placeholder="+"
        v-model="labelInput"
        class="label-input button"
        @keyup.enter.exact.prevent="submitLabel"
      />
      <span
        class="legend-item"
        v-for="hotkey in hotkeys.filter(h => h.unit === 'role')"
        :key="hotkey.label"
        @click="handleClick(e, hotkey)"
      >
        <span
          v-for="(letter, index) in hotkey.label"
          :key="`${index}-${hotkey.label}`"
          :class="{ hotkeyLetter: letter === hotkey.key }"
          >{{ letter }}</span
        >
        <span v-if="!hotkey.label.split('').includes(hotkey.key)">
          (<span class="hotkeyLetter">{{ hotkey.key }})</span></span
        >
      </span>
    </div>
    <div class="legend-category">
      Classes
      <span
        class="legend-item"
        v-for="hotkey in hotkeys.filter(h => h.unit === 'class')"
        :key="hotkey"
        @click="handleClick(e, hotkey)"
      >
        <span
          v-for="(letter, index) in hotkey.label"
          :key="index"
          :class="{ hotkeyLetter: letter === hotkey.key }"
          >{{ letter }}</span
        >
        <span v-if="!hotkey.label.split('').includes(hotkey.key)">
          (<span class="hotkeyLetter">{{ hotkey.key }})</span></span
        >
      </span>
    </div>
  </div>
</template>

<style scoped>
  #legend-container {
    display: flex;
    font-family: 'Source Sans Pro', sans-serif;
    text-transform: uppercase;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 0.5em 1em;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    font-size: 0.8em;
  }
  #legend-container > :not(:last-child) {
    padding: 0 0.5em;
    border-right: 1px solid #ccc;
    margin-right: 0.5em;
  }
  .legend-category {
    display: inline-flex;
    align-items: baseline;
    flex-wrap: wrap;
    /* margin-bottom: 1em; */
  }
  .legend-item {
    font-family: monospace;
    text-transform: lowercase;
    font-size: 1.3em;
    margin: 0.5em 0.2em;
    background-color: white;
    padding: 0.2em 0.5em;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
  }
  .hotkeyLetter {
    font-weight: bold;
  }

  .label-input {
    width: 3em;
    transition: all 0.2s ease-in-out;
    color: black;
    text-transform: none;
    align-self: center;
  }
</style>
