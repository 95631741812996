<template>
  <ul class="place-list">
    <li v-for="place in places" :key="place.id">
      <span @click.stop="$emit('select-place-uri', place.uri)">{{ place.label }}</span>
    </li>
  </ul>
</template>
<script>
  export default {
    name: 'PlaceList',
    props: {
      places: {
        type: Array,
      },
    },
    emits: ['select-place-uri'],
  };
</script>

<style scoped lang="scss">
  .place-list {
    margin-block-start: 0;
    margin-block-end: 0;
  }
  span {
    user-select: none;
    cursor: pointer;

    &:hover {
      color: #4d4ddd;
    }
  }
</style>
