<script setup>
  import { watch, ref, reactive, computed } from 'vue';
  import { useStore } from 'vuex';
  import { ApolloClient } from '@apollo/client/core';
  import { InMemoryCache } from '@apollo/client/cache';
  import useOutputs from '@/composables/useOutputs';
  import inputData from '@/composables/inputData';
  import commonProps from '@/common/props';
  import { convertUsfmToOsis } from '@/common/refUtils';
  import {
    LOCAL_DATA_STATE,
    DEFAULT_GROUP_KEY,
    WLC,
    SBLGNT,
    NESTLE_1904,
    DEFAULT_GNT_TEXTUAL_EDITION,
  } from '@/store/constants';

  import { fromXml } from 'xast-util-from-xml';
  import { toXml } from 'xast-util-to-xml';
  import { map as mapXML } from 'unist-util-map';
  import { CONTINUE, EXIT, visit } from 'unist-util-visit';
  import { removePosition } from 'unist-util-remove-position';
  // TREEDOWN SHARED COMPONENTS
  import { maculaClient } from '@/apollo';
  import configuration from '../config';
  import UILoading from '../components/UILoading.vue';
  import { parseWordId, isSentenceRTL } from '../util';
  // TREE QUERY WIDGET COMPONENTS
  import Search from '../components/Search.vue';
  import ResultsCount from '../components/ResultsCount.vue';
  import Sentence from './Sentence.vue';
  import SentenceCSS from './SentenceCSS.vue';
  import DisplayTogglesMenu from '../components/DisplayTogglesMenu.vue';
  import Pagination from '../components/Pagination.vue';
  import useFetchXML from '../composables/useFetchXML';

  const store = useStore();

  // TODO: Factor out to pagination constant
  const INITIAL_CURSOR_POS = 0;
  const props = defineProps({
    ...commonProps(),
    doric: {
      inputs: {
        endpoint: {
          value: null,
          groupKey: LOCAL_DATA_STATE,
        },
        label: null,
        showBaseText: {
          value: 'showBaseText',
          groupKey: DEFAULT_GROUP_KEY,
        },
        selectedGlosses: {
          value: 'selectedGlosses',
          groupKey: DEFAULT_GROUP_KEY,
        },
        treedownDisplay: {
          value: 'treedownDisplay',
          groupKey: DEFAULT_GROUP_KEY,
        },
        treeQuery: {
          value: 'treeQuery',
          groupKey: DEFAULT_GROUP_KEY,
        },
        textualEdition: {
          value: 'textualEdition',
          groupKey: DEFAULT_GROUP_KEY,
        },
      },
      outputs: {
        treedownNode: null,
        json: null,
        treeQuery: null,
        textualEdition: null,
        // FIXME: See PassageRefWidget FIXME
        viewingGNT: 2,
        osisRef: null,
      },
    },
  });
  const initialTreeQueryValue = inputData(store, props?.info?.inputs, 'treeQuery');
  const endpoint = inputData(store, props?.info?.inputs, 'endpoint');
  const { submit, outputs } = useOutputs(props);

  // const { localStorage } = window;
  const numberOfWordsInVirtualTree = ref(0);
  const numberOfNodesInVirtualTree = ref(0);
  const originalTree = ref(undefined);
  const virtualTree = ref(undefined);
  const encounteredError = ref(false); // NOTE: is this needed for viewing only?
  const searchString = ref(configuration.defaultXqueryString || configuration.defaultOsisId);
  const languageDirectionIsRTL = ref(
    configuration.defaultXqueryString ? false : configuration.defaultLanguageDirectionIsRTL,
  );
  const shouldUpdateVirtualTree = ref(undefined);
  const paginationCursor = ref(INITIAL_CURSOR_POS);
  const totalNumberOfResults = ref(0);
  const selectedTextualEdition = ref(undefined);
  const searchQueryObject = ref(undefined);

  const { xml, usingXq, isLoading, errorMessage, pageSize, fetchXML, apolloClient } = useFetchXML();
  // TODO: Query for these values from backend
  const textualEditionOptions = [NESTLE_1904, SBLGNT, WLC];

  const displayOptions = reactive({
    usePlainText: false,
    showAnalysis: true,
    showWordGroups: false,
    showClauses: false,
    showMilestones: false,
    showControls: true,
    showEnglish: false,
    showTransliteration: false,
  });

  /** COMPUTED PROPERTIES */

  const showTextualEditionToggle = computed(() => {
    return usingXq?.value;
  });
  const sentences = computed(() => {
    if (virtualTree.value) {
      const sentencesPrivate = [];
      visit(virtualTree.value, node => {
        if (node.name === 'sentence') {
          sentencesPrivate.push(node);
        }
      });
      return sentencesPrivate;
    }
    return [];
  });

  const showBaseText = computed(() => {
    const { value } = inputData(store, props?.info?.inputs, 'showBaseText');
    return value;
  });

  // TODO: Review for parity with Sentence component
  const showGreek = computed(() => {
    return !!showBaseText.value;
  });
  const selectedGlossesSet = computed(() => {
    const { value } = inputData(store, props?.info?.inputs, 'selectedGlosses');
    const parts = value ? value.split('|') : [];
    return new Set(parts);
  });
  const showGlosses = computed(() => {
    return selectedGlossesSet.value.size > 0;
  });

  // TODO: Watcher for loading as we rearticulate spines
  const treedownDisplay = computed(() => {
    const { value } = inputData(store, props?.info?.inputs, 'treedownDisplay');
    return value;
  });

  const textualEdition = computed(() => {
    const { value } = inputData(store, props?.info?.inputs, 'textualEdition');
    return value;
  });

  const showBasicDisplay = computed(() => {
    return treedownDisplay.value === 1;
  });

  /** COMPONENT METHODS (TODO: subdivide further) */

  function updateOsisRef(value) {
    // outputs.osisRef may be undefined in tree-comparison workspace;
    // this allows two widget instances without requiring
    // two interlinear widgets
    if (props?.info.outputs.osisRef) {
      visit(value, node => {
        if (node.name === 'w') {
          const usfmRef = node.attributes.ref;
          const [usfmVerseRef] = usfmRef.split('!');
          const osisRef = convertUsfmToOsis(usfmVerseRef);
          outputs.value.osisRef.value = osisRef;
          return EXIT;
        }
        return CONTINUE;
      });
    }
  }
  function handleSelectNode(value) {
    outputs.value.treedownNode.value = value;
    outputs.value.json.value = value;

    updateOsisRef(value);
    submit();
  }

  function handleError(error) {
    // NOTE: slight screen flash when error encountered
    // eslint-disable-next-line no-console
    console.info(error);
    encounteredError.value = true;
  }

  function handleUpdatePage({ newCursor, newPageSize }) {
    paginationCursor.value = newCursor;
    pageSize.value = newPageSize;
    fetchXML({
      query: searchString.value,
      usingXquery: true,
      fetchPageSize: newPageSize,
      fetchCursor: newCursor,
      textualEdition: selectedTextualEdition?.value,
    });
  }

  function getWordIdsInVirtualTree(treeToUse) {
    // NOTE: All word ids are sorted in order to determine their order,
    // but since they are not all of the same magnitude
    // (some ids are 11-digit word ids, and some are 12-digit SUBword ids)
    // their final @id digits cannot simply be used as an index
    const wordIdsInVirtualTree = [];
    visit(
      treeToUse,
      node => node.name === 'w',
      currentWord => {
        try {
          const bookChapterVerseWordId =
            currentWord?.attributes?.id &&
            parseWordId(currentWord?.attributes?.id).bookChapterVerseWordId;
          if (bookChapterVerseWordId) {
            wordIdsInVirtualTree.push(bookChapterVerseWordId);
          }
        } catch (error) {
          handleError(error);
        }
      },
    );
    wordIdsInVirtualTree.sort((a, b) => a - b);
    // NOTE: need to use mathematical sort not alphabetical
    // TODO: refactor to check if there are multiple verses in the tree.
    // If there are verses, then sort using the verse number and then the word number.
    // FIXME: when a chapter is queried (e.g., GEN 1:1), it does not return @id AND @n - see issue: https://github.com/Clear-Bible/symphony-backend/issues/67
    return wordIdsInVirtualTree;
  }

  function assignVirtualTreePositions(treeToUse) {
    numberOfWordsInVirtualTree.value = 0;
    numberOfNodesInVirtualTree.value = 0;
    let numberOfSentencesInVirtualTree = 0;
    const wordIdsInVirtualTree = getWordIdsInVirtualTree(treeToUse);
    return mapXML(treeToUse, node => {
      let newNode = node;
      if (node.name === 'w' || node.name === 'wg') {
        numberOfNodesInVirtualTree.value += 1;
        newNode = {
          ...newNode,
          currentPosition: numberOfNodesInVirtualTree.value,
        };
      }
      if (node.name === 'sentence') {
        numberOfSentencesInVirtualTree += 1;
        totalNumberOfResults.value = parseInt(node.attributes?.totalResults, 10);
        newNode = {
          ...node,
          sentenceNumber: numberOfSentencesInVirtualTree,
        };
      }
      if (node.name === 'w') {
        numberOfWordsInVirtualTree.value += 1;
        const bookChapterVerseWordId =
          node?.attributes?.id && parseWordId(node?.attributes?.id).bookChapterVerseWordId;
        newNode = {
          ...newNode,
          originalPosition: wordIdsInVirtualTree.indexOf(bookChapterVerseWordId) + 1,
          // NOTE: numberOfWordsInVirtualTree starts at 1, not 0,
          // but wordIdsInVirtualTree is 0-indexed
          currentWordPosition: numberOfWordsInVirtualTree.value,
        };
      }
      return newNode;
    });
  }

  function setupVirtualTree(shouldReset) {
    let treeToUse = originalTree.value;
    if (!shouldReset) {
      treeToUse = virtualTree.value || originalTree.value;
    }
    if (treeToUse) {
      return assignVirtualTreePositions(treeToUse);
    }
    return null;
  }
  function toggleDisplayOption(toggleValue) {
    displayOptions[toggleValue] = !displayOptions[toggleValue];
  }
  function handleSearch(queryObject) {
    searchQueryObject.value = queryObject;

    try {
      // NOTE: Search.vue calls this function in its mounted hook;
      // we could also do this in a watcher, but doing it here avoids a race condition.
      if (!selectedTextualEdition.value) {
        selectedTextualEdition.value = textualEdition.value || DEFAULT_GNT_TEXTUAL_EDITION;
      }
      const { query, xquery, directId } = queryObject;
      searchString.value = query;
      // TODO: Implement proper pagination when using "verse" display
      let newTextualEdition = selectedTextualEdition.value;
      if (!xquery) {
        newTextualEdition = null;
      }
      fetchXML({
        query,
        usingXquery: xquery,
        usingDirectId: directId,
        fetchCursor: INITIAL_CURSOR_POS,
        textualEdition: selectedTextualEdition.value,
      });
      outputs.value.treeQuery.value = query;
      // FIXME: Submit will clobber outputs, e.g.
      // http://localhost:8080/?workspace=tree-comparison&treeQuery=//w[2]
      paginationCursor.value = INITIAL_CURSOR_POS;

      if ('textualEdition' in outputs.value) {
        outputs.value.textualEdition.value = newTextualEdition;
      }

      submit();
    } catch (error) {
      handleError(error);
    }
  }
  function setLanguageDirectionIsRTL(languageDirectionIsRTLProp) {
    languageDirectionIsRTL.value = !!languageDirectionIsRTLProp;
  }
  function downloadTree() {
    const treeToDownload = JSON.parse(JSON.stringify(virtualTree.value));
    const cleanedTree = mapXML(treeToDownload, node => {
      const newNode = { ...node };
      if (node.currentPosition) {
        delete newNode.currentPosition;
      }
      if (node.depth) {
        delete newNode.depth;
      }
      return newNode;
    });
    const virtualTreeXML = toXml(cleanedTree);
    const blob = new Blob([virtualTreeXML], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${searchString.value}.xml`;
    a.click();
    URL.revokeObjectURL(url);
  }

  /** WATCHERS */

  // TODO: Factor this out to useFetchXML
  watch(
    endpoint,
    newValue => {
      if (!newValue) {
        apolloClient.value = maculaClient;
      } else {
        apolloClient.value = new ApolloClient({
          uri: endpoint.value,
          cache: new InMemoryCache(),
        });
      }
    },
    { immediate: true },
  );
  watch(languageDirectionIsRTL, newValue => {
    // outputs.viewingGNT may be undefined in tree-comparison workspace;
    // this allows two widget instances without requiring
    // two interlinear widgets
    if (props.info?.outputs.viewingGNT) {
      const nowViewingGNT = !newValue;
      outputs.value.viewingGNT.value = nowViewingGNT ? 2 : 1;
      submit();
    }
  });
  watch(xml, newData => {
    if (newData && xml.value) {
      try {
        originalTree.value = removePosition(fromXml(xml.value), true);
      } catch (e) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        encounteredError.value = true;
        // NOTE: this keeps the app from crashing right now when it encounters invalid XML
        return undefined;
      }
    }
    return null;
  });

  watch(originalTree, newTree => {
    if (newTree) {
      virtualTree.value = setupVirtualTree(true);
      updateOsisRef(virtualTree.value);
      submit();
    }
  });

  watch(encounteredError, becomesTrue => {
    if (becomesTrue) {
      setTimeout(() => {
        encounteredError.value = false;
      }, 100);
    }
  });
  watch(
    () => displayOptions.showAnalysis,
    newValue => {
      if (newValue) {
        virtualTree.value = setupVirtualTree();
      }
    },
  );
  watch(
    () => displayOptions.showEnglish,
    newValue => {
      if (newValue) {
        virtualTree.value = setupVirtualTree();
      }
    },
  );
  watch(
    () => displayOptions.showTransliteration,
    newValue => {
      if (newValue) {
        virtualTree.value = setupVirtualTree();
      }
    },
  );
  watch(
    () => displayOptions.showWordGroups,
    newValue => {
      if (newValue) {
        virtualTree.value = setupVirtualTree();
      }
    },
  );
  watch(
    () => displayOptions.showClauses,
    newValue => {
      if (newValue) {
        virtualTree.value = setupVirtualTree();
      }
    },
  );
  watch(shouldUpdateVirtualTree, newValue => {
    if (newValue) {
      virtualTree.value = setupVirtualTree();
      shouldUpdateVirtualTree.value = false;
    }
  });
  watch(sentences, newValue => {
    if (newValue) {
      languageDirectionIsRTL.value = isSentenceRTL(newValue.find(() => true));
    }
  });
  // eslint-disable-next-line no-unused-vars
  watch(selectedTextualEdition, (newValue, oldValue) => {
    if (oldValue) {
      // Checking for the oldValue ensures
      // we avoid a recursive loop in handleSearch
      // when we set the initial selectedTextualEdition value
      handleSearch(searchQueryObject.value);
    }
  });
</script>

<template>
  <div id="tree-editor-container">
    <div class="ui-container">
      <Search
        @language-direction-is-rtl="setLanguageDirectionIsRTL"
        @query-object="handleSearch"
        :initialTreeQuery="initialTreeQueryValue"
      />
      <div>
        <ResultsCount
          v-if="usingXq && !isLoading"
          :count="sentences.length > 0 ? totalNumberOfResults : 0"
        />
        <select v-if="showTextualEditionToggle" v-model="selectedTextualEdition">
          <option v-for="textualEditionOption in textualEditionOptions" :key="textualEditionOption">
            {{ textualEditionOption }}
          </option>
        </select>
      </div>
      <DisplayTogglesMenu
        v-if="!showBasicDisplay"
        @toggle-display-option="toggleDisplayOption"
        @download-tree="downloadTree"
        :displayOptions="displayOptions"
      />
    </div>
    <div v-show="isLoading">
      <UILoading />
    </div>
    <div v-if="errorMessage">
      <pre style="white-space: pre-wrap">
        <code>{{ errorMessage }}</code>
      </pre>
    </div>
    <div
      v-if="virtualTree && !isLoading"
      id="sentence-root"
      :class="[{ errorNotification: encounteredError }, { rtl: languageDirectionIsRTL }]"
      tabindex="0"
    >
      <div v-if="sentences.length < 1">
        <p>No results for query.</p>
      </div>
      <template v-if="showBasicDisplay">
        <div class="interlinear-alert" v-if="!showGreek && !showGlosses">
          <em>To view word values, make at least one selection in the interlinear widget</em>
        </div>
        <SentenceCSS
          v-for="(sentence, index) in sentences"
          :key="sentence.id || 'sentence' + index"
          :sentence="sentence"
          :showAnalysis="displayOptions.showAnalysis"
          :showWordGroups="displayOptions.showWordGroups"
          :showClauses="displayOptions.showClauses"
          :showChildren="true"
          :showMilestones="displayOptions.showMilestones"
          :showRules="displayOptions.showRules"
          :parentAttributes="{ class: 'root' }"
          :grandParentAttributes="{ class: 'root' }"
          :languageDirectionIsRTL="languageDirectionIsRTL"
          :showGreek="showGreek"
          :showGlosses="showGlosses"
          :selectedGlosses="selectedGlossesSet"
          :showTransliteration="displayOptions.showTransliteration"
          :rtl="languageDirectionIsRTL"
          @select-node="handleSelectNode"
        />
      </template>
      <template v-else>
        <Sentence
          v-for="(sentence, index) in sentences"
          :key="sentence.id || 'sentence' + index"
          :sentence="sentence"
          :showAnalysis="displayOptions.showAnalysis"
          :showWordGroups="displayOptions.showWordGroups"
          :showClauses="displayOptions.showClauses"
          :showChildren="true"
          :showMilestones="displayOptions.showMilestones"
          :showRules="displayOptions.showRules"
          :parentAttributes="{ class: 'root' }"
          :grandParentAttributes="{ class: 'root' }"
          :languageDirectionIsRTL="languageDirectionIsRTL"
          :showEnglish="displayOptions.showEnglish"
          :showTransliteration="displayOptions.showTransliteration"
          @select-node="handleSelectNode"
        />
      </template>
    </div>
    <Pagination
      v-if="virtualTree && !isLoading && usingXq"
      :totalNumberOfResults="totalNumberOfResults"
      :paginationCursor="paginationCursor"
      :resultsPerPage="pageSize"
      @update-page="handleUpdatePage"
    />
  </div>
</template>

<style scoped lang="scss">
  #sentence-root {
    font-size: 2em;
    line-height: 1.5;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 1em;
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.6em;
  }
  .ui-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    line-height: 1.5;
    margin-bottom: 1em;
  }
  .ui-container button {
    margin-left: 0.5em;
    height: 100%;
    line-height: 1.5;
  }
  .menu {
    position: relative;
    display: inline-block;
  }
  .gear-button {
    outline: none;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 3px;
    /* padding: 0.5em; */
    line-height: 1;
    font-size: 1.8em;
    font-weight: bold;
    cursor: pointer;
  }
  .gear-button.open-menu {
    color: #05afec;
  }
  .menu-content {
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    transition: right 0.3s ease-in-out;
  }
  .menu-content.visible {
    display: block;
    right: 0;
  }
  .menu-button {
    position: relative;
    display: inline-block;
    background-color: #f1f1f1;
    min-width: 160px;
    z-index: 999;
  }

  /* display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 1em;
  } */
  .rtl {
    direction: rtl;
    font-size: 2.2em;
    font-family: 'SBLBibLit';
  }
  #sentence-root:focus {
    outline: none;
  }
  .focusOnSubword {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5em;
  }
  .errorNotification {
    background-color: #ffcdd2;
    transition: background-color 0.25s ease;
  }
  .isSaving {
    background-color: #b2ebf2;
    transition: background-color 0.25s ease;
  }
  #save-icon {
    font-size: 1em;
    /** shorter top and bottom margins and some right margin */
    margin-right: 0.5em;
  }

  /** From tree-edit app file */
  .button {
    border-radius: 5px;
    font-size: 0.8em;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0.5em 1em;
    user-select: none;
    border: none;
    /* display: inline-block; */
    /* vertical-align: middle; */
    overflow: hidden;
    /* text-align: center; */
    cursor: pointer;
    white-space: nowrap;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.984);
    margin: 0 0.5em;
    content: '';
  }

  .goButton {
    background-color: #04aa6d !important;
  }
  .goButton:hover {
    background-color: #039c64 !important;
  }

  .stopButton {
    background-color: #ff0000 !important;
    padding: 0.5em 1em;
  }
  .stopButton::after {
    content: 'X';
  }
  .stopButton:hover::after {
    content: 'Delete saved tree';
    padding: 0.5em 1em;
    transition: all 0.2s ease-in-out;
  }
  .ui-button {
    font-size: 1em;
    padding: 0.5em 1em;
    margin: 0.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 10pt;
    background-color: #eee;
    text-transform: uppercase;
    line-height: 1.2em; // Line height keeps emojis from expanding button height
  }
  .ui-button:hover {
    background-color: #ddd;
  }
  input {
    font-family: monospace;
    font-size: 1.2em;
    background-color: white;
    padding: 0.2em 0.5em;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
  }
  .interlinear-alert {
    font-family: monospace;
    margin-top: -2em;
    margin-bottom: 1em;
  }
</style>
