<template>
  <div>
    <EmptyMessage v-if="noSelectedTokenIds"> Select a word to show its places </EmptyMessage>
    <template v-else>
      <LoadingSpinner v-if="$apollo.loading" />
      <EmptyMessage v-if="places?.length === 0">
        No places found for the current selection
      </EmptyMessage>
      <PlaceDetail
        v-else-if="selectedPlaceUri"
        :key="selectedPlaceUri"
        :placeUri="selectedPlaceUri"
        :hasMore="places?.length > 1"
        @select-place-uri="uri => (selectedPlaceUri = uri)"
      />
      <PlaceList
        v-else-if="places?.length"
        :places="places"
        @select-place-uri="uri => (selectedPlaceUri = uri)"
      />
    </template>
  </div>
</template>
<script>
  import mixins from '@/mixins';
  import gql from 'graphql-tag';
  import { DEFAULT_GROUP_KEY } from '@/store/constants';
  import LoadingSpinner from '@/symphony/widgets/components/LoadingSpinner.vue';
  import EmptyMessage from '@/symphony/widgets/components/EmptyMessage.vue';
  import PlaceDetail from './PlaceDetail.vue';
  import PlaceList from './PlaceList.vue';

  export default {
    name: 'PlacesWidget',
    mixins: [mixins.InputDataMixin],
    components: {
      LoadingSpinner,
      EmptyMessage,
      PlaceDetail,
      PlaceList,
    },
    props: {
      doric: {
        inputs: {
          label: 'Places',
          selectedTokenIds: {
            groupKey: DEFAULT_GROUP_KEY,
            value: 'selectedTokenIds',
          },
        },
      },
    },
    data() {
      return {
        selectedPlaceUri: null,
      };
    },
    watch: {
      selectedTokenIds() {
        this.selectedPlaceUri = null;
      },
      places(newValue) {
        this.autoSelectSelectedPlaceUri(newValue);
      },
    },
    computed: {
      parsedSelectedTokenIds() {
        try {
          return JSON.parse(this.selectedTokenIds);
        } catch (e) {
          return [];
        }
      },
      noSelectedTokenIds() {
        return (
          !Array.isArray(this.parsedSelectedTokenIds) || this.parsedSelectedTokenIds.length === 0
        );
      },
    },
    methods: {
      autoSelectSelectedPlaceUri(places) {
        if (places?.length === 1) {
          // If there is only one place, select it automatically
          // so the user doesn't have to take an extra action to view
          // place data.
          this.selectedPlaceUri = places[0].uri;
        } else if (places?.length > 1) {
          const primaryPlace = places.find(place => place.refersTo == null);
          if (primaryPlace) {
            this.selectedPlaceUri = primaryPlace.uri;
          }
        }
      },
    },
    apollo: {
      places: {
        query: gql`
          query WordTokens($filters: WordTokenFilter) {
            wordTokens(filters: $filters) {
              id
              places {
                uri
                label
                refersTo {
                  id
                }
              }
            }
          }
        `,
        variables() {
          return {
            filters: {
              id: {
                inList: this.parsedSelectedTokenIds,
              },
            },
          };
        },
        skip() {
          return this.noSelectedTokenIds;
        },
        update(data) {
          // TODO: Consider querying using token ids against the places field directly; would avoid
          // duplicates.
          // via https://linear.app/biblica-clear-team/issue/MAC-143/consider-subselect-interfaces-for-tokens
          const tokenPlaces = data.wordTokens.flatMap(token => token.places);
          return [...new Set(tokenPlaces)];
        },
      },
    },
  };
</script>
