import cloneDeep from 'lodash.clonedeep';
import { createStore } from 'vuex';
import {
  generateRandomHexColor,
  camelCaseToKebabCase,
  defaultColumnValues,
  defaultWidgetStateValues,
} from '@/common/widgetUtils';
import workspaces from '@/config/workspaces';

import { DORIC_VARIABLE_UNSET } from './constants';

// FIXME: Add hook for state, and likely
// namespace for framework vs site instantiation
export default createStore({
  state: {
    siteName: 'Symphony Browser', // NOTE: This could be defined in a config file along with a list of widget names, etc.
    workspaces,
    activeWorkspace: null, // FIXME: conflicts with url workspace state
    // TODO: Check local storage or other storage for saved workspaces
  },
  mutations: {
    addColumn(state) {
      state.activeWorkspace.columns.push({
        ...defaultColumnValues,
        generation: state.activeWorkspace.nextGeneration,
        nextGeneration: 1,
        widgets: [],
      });
      state.activeWorkspace.nextGeneration += 1;
    },
    deleteColumn(state, columnIndex) {
      state.activeWorkspace.columns.splice(columnIndex, 1);
    },
    updateColumnLabel(state, { columnIndex, label }) {
      state.activeWorkspace.columns[columnIndex].label = label;
    },
    updateColumnWidth(state, { columnIndex, width }) {
      state.activeWorkspace.columns[columnIndex].width = width;
    },
    toggleColumnCollapse(state, columnIndex) {
      state.activeWorkspace.columns[columnIndex].expanded =
        !state.activeWorkspace.columns[columnIndex].expanded;
    },
    moveLeft(state, columnIndex) {
      state.activeWorkspace.columns.splice(
        columnIndex - 1,
        0,
        state.activeWorkspace.columns.splice(columnIndex, 1)[0],
      );
    },
    moveRight(state, columnIndex) {
      state.activeWorkspace.columns.splice(
        columnIndex + 1,
        0,
        state.activeWorkspace.columns.splice(columnIndex, 1)[0],
      );
    },
    addWidget(state, columnIndex) {
      state.activeWorkspace.columns[columnIndex].widgets.push({
        ...defaultWidgetStateValues,
        generation: state.activeWorkspace.columns[columnIndex].nextGeneration,
      });
      state.activeWorkspace.columns[columnIndex].nextGeneration += 1;
    },
    deleteWidget(state, payload) {
      const { columnIndex, widgetIndex } = payload;
      state.activeWorkspace.columns[columnIndex].widgets.splice(widgetIndex, 1);
    },
    moveUp(state, payload) {
      const { columnIndex, widgetIndex } = payload;
      state.activeWorkspace.columns[columnIndex].widgets.splice(
        widgetIndex - 1,
        0,
        state.activeWorkspace.columns[columnIndex].widgets.splice(widgetIndex, 1)[0],
      );
    },
    moveDown(state, payload) {
      const { columnIndex, widgetIndex } = payload;
      state.activeWorkspace.columns[columnIndex].widgets.splice(
        widgetIndex + 1,
        0,
        state.activeWorkspace.columns[columnIndex].widgets.splice(widgetIndex, 1)[0],
      );
    },
    toggleWidgetCollapse(state, payload) {
      const { columnIndex, widgetIndex } = payload;
      state.activeWorkspace.columns[columnIndex].widgets[widgetIndex].expanded =
        !state.activeWorkspace.columns[columnIndex].widgets[widgetIndex].expanded;
    },
    pickWidget(state, payload) {
      const { columnIndex, widgetIndex, widgetData } = payload;
      state.activeWorkspace.columns[columnIndex].widgets[widgetIndex].component =
        camelCaseToKebabCase(widgetData.component); // TODO: extract this from populateWorkspaces as the component name generated by the widget factory
      state.activeWorkspace.columns[columnIndex].widgets[widgetIndex] = {
        ...defaultWidgetStateValues,
        ...widgetData,
      };
    },
    // TODO: Remove this mutation if we are deprecating globals in
    // favour of a default variable group.
    // updateGlobal(state, payload) {
    //   const { name, value } = payload;
    //   state.activeWorkspace.globalFields[name] = value;
    // },
    setActiveWorkspace(state, payload) {
      const { workspaceId } = payload;
      const workspace = cloneDeep(state.workspaces.find(w => w.workspaceId === workspaceId));
      if (payload.initial) {
        const defaultGroup =
          workspace.variableGroups.find(group => group.key === 'default') ||
          { variableGroups: { key: 'default', variables: {} } }.variableGroups;
        Object.keys(payload.initial).forEach(key => {
          defaultGroup.variables[key] = payload.initial[key];
        });
      }
      state.activeWorkspace = workspace;
    },
    updateWorkspaceSettings(state, payload) {
      state.activeWorkspace = {
        ...state.activeWorkspace,
        ...payload,
      };
    },
    addVariableGroup(state, payload) {
      const { label } = payload;
      const key = label.replace(/\s/g, '').toLowerCase();
      const randomColor = generateRandomHexColor();

      function appendCopyToKey(keyToCheck) {
        if (
          state.activeWorkspace.variableGroups.find(group => group.key === keyToCheck) ||
          keyToCheck === 'local' // NOTE: 'local' is a protected key, since widgets use it to hard-code values
        ) {
          return appendCopyToKey(`${keyToCheck}-copy`);
        }
        return keyToCheck;
      }

      // Add new variable group if it doesn't exist
      if (state.activeWorkspace.variableGroups.some(group => group.key === key)) {
        // If the key already exists, append 'copy' to the end
        state.activeWorkspace.variableGroups.push({
          label,
          key: appendCopyToKey(key),
          variables: {},
          color: randomColor,
        });
      } else {
        state.activeWorkspace.variableGroups.push({
          label,
          key,
          variables: {},
          color: randomColor,
        });
      }
    },
    updateVariableGroup(state, payload) {
      const { label, key, variables, color } = payload; // Key may be null if new variable group
      const variableGroup = {
        label,
        key,
        variables,
        color,
      };
      // Find group if it exists
      const variableGroupIndex = state.activeWorkspace.variableGroups.findIndex(
        group => group.key === key,
      );
      state.activeWorkspace.variableGroups[variableGroupIndex] = variableGroup;
    },
    updateVariableInGroup(state, payload) {
      const { name, groupKey, value } = payload;
      let variableGroupIndex = state.activeWorkspace.variableGroups.findIndex(
        variableGroup => variableGroup.key === groupKey,
      );
      if (variableGroupIndex === -1) {
        console.warn(
          `Variable group not found, ${groupKey}, adding variable ${value} to first group.`,
        );
        variableGroupIndex = 0;
      }
      state.activeWorkspace.variableGroups[variableGroupIndex].variables[name] = value;
    },
    // check that all input values (i.e., variable names) exist for their respect groupKey (i.e., variable group)
    addMissingInputVariablesToGroup(state, payload) {
      const workspace = state.activeWorkspace;
      if (!workspace) {
        return;
      }
      const { inputs } = payload;
      const inputsArray = Object.keys(inputs)
        .filter(inputKey => inputs[inputKey]) //! This only skips falsey values like input.lable, which is typically null by default
        .map(inputKey => inputs[inputKey]);
      // an input is an object with a value (variable name) and groupKey
      // for each input variable, check if it exists in the group it is supposed to be in
      // if it doesn't exist, add it
      inputsArray.forEach(input => {
        const { value, groupKey } = input;
        if (groupKey !== 'local') {
          // TODO: refactor to account for the fact that local values should already be skipped over
          const variableGroupIndex = workspace.variableGroups.findIndex(
            variableGroup => variableGroup.key === groupKey,
          );
          if (variableGroupIndex === -1) {
            // NOTE: This should never happen, but if it does, add the variable to the first group
            console.warn(
              `Variable group not found, ${groupKey}, adding variable ${value} to first group.`,
            );
          }
          const variableExistsInGroup =
            workspace.variableGroups[variableGroupIndex].variables[value] !== DORIC_VARIABLE_UNSET;
          if (!variableExistsInGroup) {
            console.warn(
              `Unset variable detected [variableName="${value}" value="${variableExistsInGroup}"]`,
            );
            workspace.variableGroups[variableGroupIndex].variables[value] = DORIC_VARIABLE_UNSET;
            console.warn(`Set "${value}" to ${DORIC_VARIABLE_UNSET}`);
          }
        }
      });
    },
  },
  getters: {
    getActiveWorkspaceId: state =>
      state.activeWorkspace ? state.activeWorkspace.workspaceId : null,
    getActiveWorkspace: state => state.activeWorkspace,
    getActiveWorkspaceVariableGroups: state => state.activeWorkspace.variableGroups,
  },
  actions: {},
  modules: {},
});
