<template>
  <div class="configuration">
    <div class="config-section" v-if="inputs">
      <span class="config-section-label"> Inputs: </span>
      <input-field-configuration
        :property="k"
        :initialValue="v"
        v-for="(v, k) in inputs"
        :key="k"
        @field-update="inputsUpdate($event)"
      />
    </div>
    <div class="config-section" v-if="outputs">
      <span class="config-section-label">Outputs:</span>
      <output-field-configuration
        :property="k"
        :initialValue="v"
        v-for="(v, k) in outputs"
        :key="k"
        @field-update="outputsUpdate($event)"
      />
    </div>
    <button @click="submit()">Submit</button>
  </div>
</template>

<script>
  import InputFieldConfiguration from './InputFieldConfiguration.vue';
  import OutputFieldConfiguration from './OutputFieldConfiguration.vue';

  export default {
    name: 'WidgetConfiguration',
    components: {
      InputFieldConfiguration,
      OutputFieldConfiguration,
    },
    props: {
      info: Object,
    },
    data() {
      return {
        inputs: {
          ...this.info.inputs,
        },
        outputs: {
          ...this.info.outputs,
        },
      };
    },
    methods: {
      inputsUpdate(data) {
        this.inputs[data.property].value = data.value; // 'value' is the variable value
        this.inputs[data.property].groupKey = data.groupKey; // 'groupKey' is the variable groupKey
      },
      outputsUpdate(data) {
        this.outputs[data.property].send = data.send; // 'send' is the variable key
        this.outputs[data.property].value = data.value; // 'value' is the variable value
        this.outputs[data.property].groupKey = data.groupKey; // 'groupKey' is the variable groupKey
      },
      submit() {
        this.$emit('configuration-update', {
          inputs: this.inputs,
          outputs: this.outputs,
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .configuration {
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
  }
  .config-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .config-section-label {
      font-weight: bold;
      margin-bottom: 5px;
    }
    & > div {
      margin-left: 10px;
      display: flex;
      flex-flow: row wrap;
    }
  }
</style>
