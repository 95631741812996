<script setup>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { visit } from 'unist-util-visit';
  import { DEFAULT_GROUP_KEY } from '@/store/constants';
  import inputData from '@/composables/inputData';
  import commonProps from '@/common/props';

  import WordInfo from './components/WordInfo.vue';
  import EmptyMessage from '../components/EmptyMessage.vue';

  const store = useStore();

  const props = defineProps({
    ...commonProps(),
    doric: {
      component: 'treedown-info-widget',
      inputs: {
        label: null,
        treedownNode: {
          groupKey: DEFAULT_GROUP_KEY,
          value: 'treedownNode',
        },
      },
    },
  });
  const treedownNode = inputData(store, props?.info?.inputs, 'treedownNode');
  const wordNodes = computed(() => {
    const words = [];
    if (treedownNode.value) {
      visit(
        treedownNode.value,
        node => node?.name === 'w',
        node => {
          words.push(node);
        },
      );
    }
    return words;
  });
</script>

<template>
  <div>
    <EmptyMessage v-show="wordNodes.length < 1">
      Click on a word in the Treedown widget to see information about it
    </EmptyMessage>
    <WordInfo
      v-for="(wordNode, index) in wordNodes"
      :key="wordNode?.attributes?.id"
      :wordNode="wordNode"
      :index="index"
    />
  </div>
</template>
