<template>
  <div>
    <LoadingSpinner v-if="$apollo.loading" />
    <template v-if="mode === modes.NO_ANNOT_SET">
      <!-- GIVE OPTIONS FOR LOADING/CREATING -->
      <EmptyMessage>
        You have not selected an Annotation Set. Select or Create one below.
      </EmptyMessage>
      <div>
        <button @click="mode = modes.SELECT_ANNOT_SET">Select Annotation Set</button>
        <button @click="mode = modes.CREATE_ANNOT_SET">Create Annotation Set</button>
      </div>
    </template>

    <template v-else-if="mode === modes.SELECT_ANNOT_SET">
      <!-- GIVE OPTIONS FOR LOADING EXISTING SET -->
      <div>
        <select v-model="selectedAnnotationSet" :key="annotationSets">
          <option v-for="annotSet in annotationSets" :key="annotSet" :value="annotSet.uri">
            {{ annotSet?.label || annotSet.uri }}
          </option>
        </select>
        <button @click="loadSelectedAnnotationSet">Load Annotation Set</button>
      </div>
      <button @click="unloadAndUnset">Cancel</button>
    </template>

    <template v-else-if="mode === modes.CREATE_ANNOT_SET">
      <!-- SHOW FORM FOR CREATING NEW SET -->
      <table>
        <tr>
          <td>Label:</td>
          <td>
            <input placeholder="Label" v-model="newAnnotationSetLabel" />
            <template v-for="error in fieldErrors.filter(f => f.field === 'label')" :key="error">
              <div class="error-message">{{ error.message }}</div>
            </template>
          </td>
        </tr>
      </table>
      <button @click="createAnnotationSet">Create New Annotation Set</button>
      <button @click="unloadAndUnset">Cancel</button>
    </template>

    <template v-else-if="mode === modes.ANNOT_SET_SELECTED">
      <!-- SHOW ANNOTATION SET + ALLOW UNLOADING -->
      <table>
        <tr>
          <td>URI:</td>
          <td>
            <div>{{ currentAnnotationSet.uri }}</div>
          </td>
        </tr>
        <tr>
          <td>Label:</td>
          <td>
            <div>{{ currentAnnotationSet.label }}</div>
          </td>
        </tr>
      </table>
      <button @click="unloadAndUnset">Unload Annotation Set</button>
      <button @click="deleteAnnotationSet">Delete Annotation Set</button>
    </template>

    <EmptyMessage v-else>Unknown mode: {{ mode }}</EmptyMessage>
  </div>
</template>

<script>
  import { gql } from 'graphql-tag';
  import mixins from '@/mixins';
  import { APOLLO_QUERY_FOR_ANNOTATION_SETS } from '@/store/constants';
  import LoadingSpinner from '../components/LoadingSpinner.vue';
  import EmptyMessage from '../components/EmptyMessage.vue';

  const MODES = {
    NO_ANNOT_SET: 'NO_ANNOT_SET',
    SELECT_ANNOT_SET: 'SELECT_ANNOT_SET',
    CREATE_ANNOT_SET: 'CREATE_ANNOT_SET',
    ANNOT_SET_SELECTED: 'ANNOT_SET_SELECTED',
  };

  export default {
    name: 'AnnotationSetsWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    components: {
      LoadingSpinner,
      EmptyMessage,
    },
    props: {
      doric: {
        outputs: {
          currentAnnotationSetUri: null,
        },
      },
    },
    data() {
      return {
        mode: MODES.NO_ANNOT_SET,
        modes: Object.freeze(MODES),
        selectedAnnotationSet: '',
        currentAnnotationSet: {},
        newAnnotationSetLabel: '',
        fieldErrors: [],
      };
    },
    watch: {
      currentAnnotationSet: {
        handler() {
          if (!this.currentAnnotationSet?.uri) return;
          this.outputs.currentAnnotationSetUri.value = this.currentAnnotationSet.uri;
          this.submit();
        },
      },
    },
    apollo: {
      annotationSets: {
        query: gql`
          query ${APOLLO_QUERY_FOR_ANNOTATION_SETS} {
            annotationSets {
              id
              label
              uri
            }
          }
        `,
        update(data) {
          const first = data.annotationSets[0];
          this.selectedAnnotationSet = first.uri;
          return data.annotationSets;
        },
      },
    },
    methods: {
      shortenUri(uri) {
        const minUriLength = 5;
        if (uri.length <= minUriLength) return uri;

        // Keep popping off the segment after the last slash until we're short enough
        const segments = uri.split('/');
        let shortenedUri = uri;
        while (shortenedUri.length > minUriLength) {
          segments.pop();
          shortenedUri = segments.join('/');
        }
        return shortenedUri;
      },
      unloadAndUnset() {
        this.currentAnnotationSet = {};
        this.mode = MODES.NO_ANNOT_SET;
        this.fieldErrors = [];
        this.newAnnotationSetLabel = '';
      },
      loadSelectedAnnotationSet() {
        this.currentAnnotationSet = this.annotationSets.find(
          annotSet => annotSet.uri === this.selectedAnnotationSet,
        );
        this.mode = MODES.ANNOT_SET_SELECTED;
      },
      createAnnotationSet() {
        // Only a label is required
        const tempFieldErrors = [];
        if (!this.newAnnotationSetLabel) {
          tempFieldErrors.push({
            field: 'label',
            message: 'Label is required',
          });
        }
        if (tempFieldErrors.length) {
          this.fieldErrors = tempFieldErrors;
          return;
        }

        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($label: String!) {
                createAnnotationSet(label: $label) {
                  __typename
                  ... on AnnotationSet {
                    id
                    label
                    uri
                  }
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables: {
              label: this.newAnnotationSetLabel,
            },
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_SETS],
          })
          .then(({ data }) => {
            // Check for errors in the response
            // eslint-disable-next-line no-underscore-dangle
            if (data?.createAnnotationSet?.__typename === 'OperationInfo') {
              console.error('createAnnotationSet error:', data);
              this.fieldErrors = data.createAnnotationSet.messages.map(({ field, message }) => ({
                field,
                message,
              }));
              return;
            }
            const { id, label, uri } = data.createAnnotationSet;
            this.currentAnnotationSet = { id, label, uri };
            this.mode = MODES.ANNOT_SET_SELECTED;
          })
          .catch(e => {
            console.error(e);
          });
      },
      deleteAnnotationSet() {
        // eslint-disable-next-line no-alert
        const areYouSure = window.confirm('Are you sure you want to delete this annotation set?');
        if (!areYouSure) return;

        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!) {
                deleteAnnotationSet(id: $id) {
                  __typename
                  ... on OperationInfo {
                    messages {
                      kind
                      message
                      field
                    }
                  }
                }
              }
            `,
            variables: {
              id: this.currentAnnotationSet.id,
            },
            refetchQueries: [APOLLO_QUERY_FOR_ANNOTATION_SETS],
          })
          .then(({ data }) => {
            // Check for errors in the response
            // eslint-disable-next-line no-underscore-dangle
            if (data?.deleteAnnotationSet?.__typename === 'OperationInfo') {
              console.error('deleteAnnotationSet error:', data);
              return;
            }
            this.unloadAndUnset();
          })
          .catch(e => {
            console.error(e);
          });
      },
    },
  };
</script>

<style scoped>
  .error-message {
    color: red;
    font-size: 0.8rem;
  }
  .loading {
    pointer-events: none;
    opacity: 0.5;
  }
</style>
