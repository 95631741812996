<template>
  <sup :id="verseRef" :data-milestones="[verseRef]" class="milestone" :class="{ active }">
    {{ shortMilestone }}
  </sup>
</template>

<script>
  export default {
    name: 'Milestone',
    props: ['verseRef'],
    // REFACTOR with TreeDownMilestone
    computed: {
      shortMilestone() {
        return this.verseRef.split('.').slice(2).join(':');
      },
      // TODO: prop verses computed
      active() {
        return this.verseRef === this.$route.query.osisRef;
      },
    },
    methods: {
      scrollToIfActive() {
        if (this.active) {
          this.$nextTick().then(() => {
            setTimeout(() => {
              this.$el.scrollIntoView({ behavior: 'smooth' });
            }, 500);
          });
        }
      },
    },
    watch: {
      active: 'scrollToIfActive',
    },
    mounted() {
      this.scrollToIfActive();
    },
  };
</script>
<style lang="scss" scoped>
  sup.milestone {
    opacity: 50%;
    display: inline;
    position: relative;
    font-size: 50%;
    vertical-align: text-top;
  }
</style>
