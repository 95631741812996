<template>
  <div class="text-decoration">
    <!-- FIXME: Named constants at config level for these attrs -->
    <div class="decoration-category" @click.prevent="toggleCollapsed()">
      Levinsohn's Discourse Features
    </div>
    <div v-if="collapsed">...</div>

    <div v-else>
      <div v-if="!$apollo.loading && features.length == 0">
        <em>No features found for the current passage.</em>
      </div>
      <div v-for="feature in features" :key="feature.key" class="list">
        <span
          :class="{ active: getHighlightDisplayValue(feature.key) }"
          @click.prevent="dynamicallyToggle(feature.key)"
          >{{ feature.apiName }}</span
        >

        <span
          v-if="feature.color && getHighlightDisplayValue(feature.key)"
          class="decoration-color-legend"
          :style="`background: #${feature.color}`"
        ></span>
      </div>
      <br />
    </div>

    <div class="decoration-category">Other</div>
    <div class="list">
      <span
        :class="{ active: outputs.highlightVerbs.value }"
        @click.prevent="toggleHighlightVerbs()"
        >pos: verbs</span
      >
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import mixins from '@/mixins';

  import { parseOsisRef, convertOsisToUsfm } from '@/common/refUtils';
  import { featuresByApiName } from '@/symphony/shared/featuresToTokenData';
  import { DEFAULT_GROUP_KEY } from '../../store/constants';

  const LEVINSOHN_ANNOTATION_SET_URI = 'https://github.com/biblicalhumanities/levinsohn';

  export default {
    name: 'TextDecorationWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    data() {
      return { collapsed: false };
    },
    props: {
      info: Object,
      doric: {
        inputs: {
          label: 'Greek Text Decoration Widget',
          osisRef: {
            value: 'osisRef',
            groupKey: DEFAULT_GROUP_KEY,
          },
        },
        outputs: {
          // FIXME: Distinguish between null and object and some other
          // wrapper
          discourseFeatures: {
            value: {},
            groupKey: DEFAULT_GROUP_KEY,
            send: 'discourseFeatures',
          },
          highlightVerbs: false,
        },
      },
    },
    apollo: {
      features: {
        query: gql`
          query Features($filters: AnnotationFeatureFilter) {
            annotationFeatures(filters: $filters) {
              uri
              data
            }
          }
        `,
        skip() {
          return !this.contextualizedUsfmRef;
        },
        variables() {
          return {
            filters: {
              scriptureReference: { usfmRef: this.contextualizedUsfmRef },
              annotationSet: { uri: { exact: LEVINSOHN_ANNOTATION_SET_URI } },
            },
          };
        },
        update(data) {
          const features = data.annotationFeatures.map(feature => {
            // TODO: Handle main clauses or other exclusions
            // server side; https://github.com/biblicalhumanities/levinsohn:main-clauses
            // is not mapped because when we ingest the data, we do not populate
            // data.name on that feature
            // Via: ✨ Improve GraphQL filters around Levinsohn Annotations
            // (https://trello.com/c/xTq6gy2i)
            const apiName = feature.data.name;
            const resolved = {
              ...featuresByApiName[apiName],
            };
            return resolved;
          });
          // TODO: Allow server to specify explicit sort (via `path` / `idx`)
          // TODO: Remove dependency on featuresByApiName for
          // styles / keys in favor of backend
          return features.filter(obj => obj.key);
        },
      },
    },
    computed: {
      discourseFeatures() {
        return this.outputs.discourseFeatures.value;
      },
      parsedOsisRef() {
        return parseOsisRef(this.osisRef);
      },
      contextualizedOsisRef() {
        return `${this.parsedOsisRef.book}.${this.parsedOsisRef.chapter ?? 1}`;
      },
      contextualizedUsfmRef() {
        return this.osisRef ? convertOsisToUsfm(this.contextualizedOsisRef) : null;
      },
    },
    methods: {
      toggleCollapsed() {
        this.collapsed = Boolean(!this.collapsed);
      },
      getFeatureVariableName(featureKey) {
        return `${featureKey.charAt(0).toUpperCase()}${featureKey.slice(1)}`;
      },
      getHighlightVariableName(featureKey) {
        const expectedVariableName = this.getFeatureVariableName(featureKey);
        return ['highlight', expectedVariableName].join('');
      },
      getHighlightDisplayValue(featureKey) {
        const highlightDisplayName = this.getHighlightVariableName(featureKey);
        return Boolean(this.discourseFeatures[highlightDisplayName]);
      },
      dynamicallyToggle(featureKey) {
        const highlightVariableName = this.getHighlightVariableName(featureKey);
        const oldValue = this.outputs.discourseFeatures.value[highlightVariableName] ?? false;
        this.outputs.discourseFeatures.value[highlightVariableName] = !oldValue;
        this.submit();
      },
      toggleHighlightVerbs() {
        this.outputs.highlightVerbs.value = !this.outputs.highlightVerbs.value;
        this.submit();
      },
    },
  };
</script>

<style scoped lang="scss">
  span {
    cursor: pointer;
    &.active {
      font-weight: bold;
    }
  }

  .list {
    margin-left: 0.5rem;
  }

  .decoration-category {
    margin-bottom: 0.5rem;
    cursor: pointer;
  }

  .decoration-color-legend {
    display: inline-block;
    width: 0.7rem;
    height: 0.7rem;
    margin-left: 0.2rem;
  }
</style>
