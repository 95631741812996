<script setup>
  import { defineProps, ref, defineEmits } from 'vue';
  import { useStore } from 'vuex';

  const store = useStore();

  const props = defineProps({
    info: Object,
    columnIndex: Number,
  });
  const emit = defineEmits(['configuration-update']);
  const label = ref(props?.info?.label);
  function updateLabel() {
    store.commit('updateColumnLabel', { label: label.value, columnIndex: props.columnIndex });
    emit('configuration-update');
  }
</script>

<template>
  <div class="configuration">
    <span class="column-configuration-header">Column Configuration</span>
    <div class="config-section">
      <span class="config-section-label"> Settings </span>
      <label>Label</label>
      <input v-model="label" @keypress.enter.exact="updateLabel" />
      <button @click="updateLabel">Submit</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .configuration {
    display: flex;
    flex-direction: column;
    button {
      font-size: 14pt;
    }
  }
  .config-section {
    display: flex;
    flex-direction: column;
    background-color: #ddd;
    padding: 1em;
    gap: 0.5em;
    .config-section-label {
      font-weight: bold;
      margin-bottom: 5px;
    }
    & > div {
      margin-left: 10px;
      display: flex;
      flex-flow: row wrap;
    }
  }
  .column-configuration-header {
    font-weight: bold;
    font-size: 14pt;
    margin: 1em;
  }
</style>
